import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { autorun } from 'mobx';
import { TreeNode } from '../tree/TreeNode';
import { BuiltInComponentMap } from '../built-in-ui/built-in-ui';

/** Renders a single node of the tree */
export const RenderNode = observer(({ node }: { node: TreeNode }) => {
  const dragRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dispose = autorun(() => {
      // Autorun can run before the tree index gets a chance to unrender deleted nodes, so make sure the node.data still exists
      if (dragRef.current && node.data !== undefined) {
        dragRef.current.style.transform = `translate(${node.xInContainer}px, ${node.yInContainer}px)`;
      }
    });

    return () => dispose();
  }, [node, node.isFixedLayout]);

  // Conditional wrapper styles based on the layout mode of this node
  const layoutModeStyles: React.CSSProperties = node.isFixedLayout
    ? // If the node is in fixed free form mode, we need to position it
      { position: 'fixed', left: 0, top: 0, transformOrigin: 'center' }
    : // If the node is in DOM mode, render the wrapper as transparently as possible
      { display: 'contents' };

  // Two reasons we're using a wrapper even if it's in DOM layout mode:
  // 1. The actual component may not use a forwarded ref, in which case we have no access to its dom element and can't calculate its dimensions
  // 2. If you conditionally wrap, React actually creates an entirely new dom element when switch modes... always using a wrapper means it can just change the styles on the same element

  return (
    <div
      ref={dragRef}
      data-node-id={node.id}
      style={{
        ...layoutModeStyles,
        contentVisibility: node.isFrame ? 'auto' : undefined,
        // TODO: prototype code, refactor once happy with behavior
        zIndex: node.styles.zIndex,
      }}
    >
      <RenderNodeComponent node={node} />
    </div>
  );
});

/** Renders the actual component for a given node and applies the styles and props */
const RenderNodeComponent = observer(
  ({ node, nodeDataAttributes }: { node: TreeNode; nodeDataAttributes?: Record<string, string> }) => {
    const ref = useRef<HTMLElement>(null);
    // Keep the TreeNode updated with the DOM element
    useEffect(() => {
      node.registerDomEl(ref.current);
    }, [node, ref]);

    let componentMeta = BuiltInComponentMap[node.component];
    if (!componentMeta) {
      console.warn(`Unknown component: ${node.component}, skipping.`);
      return null;
    }

    return (
      <componentMeta.component ref={ref} node={node} style={{ ...node.styles }} {...node.props} {...nodeDataAttributes}>
        {node.textValue}
        {componentMeta.canHaveChildren &&
          node.children.map((child) => {
            // Sanity check to prevent infinite recursion
            if (child.id === node.id) {
              console.warn(`Unexpected: node attempted to render itself as a child: ${node.id}`);
              return null;
            }
            return <RenderNode key={child.id} node={child} />;
          })}
      </componentMeta.component>
    );
  }
);
