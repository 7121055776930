import { autorun, configure } from 'mobx';
import { useEffect } from 'react';

export function initMobx() {
  configure({
    enforceActions: 'always',
    computedRequiresReaction: false,
    reactionRequiresObservable: false,
    observableRequiresReaction: false, // false, otherwise you can't read observables outside of an observer
    disableErrorBoundaries: false, // debug feature only, leave false by default for production
  });
}
