import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { NumberInput } from './number-input';
import { Field } from '../../components/field';
import { PropertyPanels } from './property-panel';
import { useDeferredValue } from 'react';

export const YProperty = observer(() => {
  const { propertiesState, hudState } = useEditor();

  // Defer rendering since we update x/y/width/height in a ResizeObserver and
  // we want to avoid forcing the browser to do another layout before it paints
  // This input doesn't need to have same-frame precision with resize changes
  const currentValue = useDeferredValue(propertiesState.y ?? '0');

  function commitValue(value: string) {
    for (const node of propertiesState.nodesPerProperty[PropertyPanels.Position]) {
      node.setY(parseFloat(value));
    }

    hudState.requestDraw();
  }

  return (
    <Field.Root>
      <Field.Icon>Y</Field.Icon>
      <Field.Control>
        <NumberInput value={currentValue} onValueCommit={commitValue} />
      </Field.Control>
    </Field.Root>
  );
});
