import { getColorsFromImageNodes } from './get-colors-from-nodes';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { createTreeNodeData } from '@mobius/models/src/file/tree-node-schema';
import { assert } from '../../assert';
import { findNextSafeAreaForRect } from '../canvas/find-safe-area-for-rect';

export async function magicFlowImageSwatches(editorState: EditorState, nodes: TreeNode[]) {
  const colors = await getColorsFromImageNodes(nodes, 6);

  const artboardPadding = 20;
  const swatchSize = 100;
  const swatchGap = 20;
  let artboardNode: TreeNode | null = null;

  let nodeIndex = -1;
  for (const nodeColors of colors) {
    nodeIndex++;

    // Create an artboard for each node
    const artboardData = createTreeNodeData();
    artboardData.component = 'Box';
    artboardData.label = editorState.fileState.makeLabelForComponent('Magic swatches');
    artboardNode = editorState.treeUtils.addNode(artboardData, editorState.treeUtils.rootNode.id);
    assert(artboardNode, 'Swatch artboard node should be created');
    artboardNode.setStyle('backgroundColor', 'white');
    artboardNode.setStyle('borderRadius', '10px');

    let nextXPos = artboardPadding;
    for (const color of nodeColors) {
      const colorNodeData = createTreeNodeData();
      colorNodeData.component = 'Box';
      colorNodeData.label = `Swatch ${color[0]}, ${color[1]}, ${color[2]}`;
      const colorNode = editorState.treeUtils.addNode(colorNodeData, artboardNode.id);

      colorNode!.setStyle('backgroundColor', `rgb(${color[0]}, ${color[1]}, ${color[2]})`);
      colorNode!.setStyle('borderRadius', '4px');
      colorNode?.setWidth(swatchSize);
      colorNode?.setHeight(swatchSize);
      colorNode?.setX(nextXPos);
      colorNode?.setY(artboardPadding);
      nextXPos += swatchSize + swatchGap;
    }

    // Build the y size based on 1 column wide swatch size
    const yPadding = artboardPadding * 2;
    artboardNode.setHeight(yPadding + swatchSize);
    // Build the x size based on how many swatches there are
    const xPadding = artboardPadding * 2;
    const totalSwatchWidth = nodeColors.length * (swatchSize + swatchGap) - swatchGap;
    artboardNode.setWidth(xPadding + totalSwatchWidth);

    // align bottom of artboard with bottom of node to get a good "findNextSafeAreaForRect" starting point
    const bottomOfNode = nodes[nodeIndex]?.y ?? editorState.cameraState.center.y;
    artboardNode.setY(bottomOfNode - artboardNode.height);
    artboardNode.setX(nodes[nodeIndex]?.x ?? editorState.cameraState.center.x);

    const bestPosition = findNextSafeAreaForRect(editorState, artboardNode.bounds, 'down', 20);
    artboardNode.setX(bestPosition.x);
    artboardNode.setY(bestPosition.y);

    editorState.cameraState.movePanToEnsureRectInView(artboardNode.bounds);
  }
}
