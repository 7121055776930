import { runInAction } from 'mobx';
import { EditorState } from '../EditorState';
import type { UserPageChangeMessage } from '@mobius/models/src/websocket/user-page-change-message';

export const handleUserPageChangeMessage = (editorState: EditorState, messageData: UserPageChangeMessage) => {
  const multiplayerState = editorState.multiplayerState;
  const clientId = messageData.clientId;

  if (typeof clientId !== 'string') {
    console.warn('clientId is missing from UserPageChangeMessage, ignoring message');
    return;
  }

  const userData = multiplayerState.userData[clientId];
  if (userData === undefined) {
    // Make sure the user join has made it through first
    console.warn('Received UserPageChangeMessage before the user was in local cache, ignoring page change.');
    return;
  }

  runInAction(() => {
    userData.pageId = messageData.pageId;
  });
};
