import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useEditor } from '../editor-context';
import { transaction } from 'mobx';
import { handleDataTransferItems } from './handle-data-transform-items';

/** Set of HTML tags that should ignore paste events */
const ignoredTags = new Set(['INPUT', 'TEXTAREA', 'SELECT', 'DETAILS']);

/** Function to determine if a paste event should be ignored */
export const shouldIgnorePasteEvent = (event: ClipboardEvent | DragEvent) =>
  event.target instanceof HTMLElement && (ignoredTags.has(event.target.tagName) || event.target.isContentEditable);

export const HandlePaste = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    /** Handle paste */
    async function handlePaste(event: ClipboardEvent) {
      if (shouldIgnorePasteEvent(event)) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      transaction(() => {
        handleDataTransferItems(editorState, event);
      });
    }

    /** Handle drop */
    function handleDrop(event: DragEvent) {
      if (shouldIgnorePasteEvent(event)) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      transaction(() => {
        handleDataTransferItems(editorState, event);
      });
    }

    /** Prevent default browser behavior for dragover */
    function handleDragOver(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
    }

    window.addEventListener('paste', handlePaste);
    window.addEventListener('drop', handleDrop);
    window.addEventListener('dragover', handleDragOver);
    return () => {
      window.removeEventListener('paste', handlePaste);
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('dragover', handleDragOver);
    };
  }, [editorState]);

  return null;
});
