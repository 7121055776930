import { assert } from '../../assert';
import { TreeNode } from '../tree/TreeNode';
import { Channels, extract, hsl, IImage, sortByHsl } from './colorgram';
import { getImageDataFromUrl } from './get-image-data-from-url';
import { getImageDataFromWebGLContext } from './get-image-data-from-webgl-canvas';
import { sortColorsByLightness } from './sort-colors-by-lightness';

/** Node<Colors<[R,G,B,A]>> */
type Colors = Array<Array<Array<number>>>;

export async function getColorsFromImageNodes(nodes: TreeNode[], numberOfColorsPerNode: number): Promise<Colors> {
  /** Node<Colors<[R,G,B,A]>> */
  const colors: Colors = [];

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]!;

    let imageData: ImageData | null = null;
    let hasAlpha: boolean | null = null;

    if (node.component === 'Image' && typeof node.props?.imageUrl === 'string') {
      ({ imageData, hasAlpha } = await getImageDataFromUrl(node.props.imageUrl));
    } else if (node.component === 'Shader' && node.domEl?.tagName.toUpperCase() === 'CANVAS') {
      const canvasEl = node.domEl as HTMLCanvasElement;
      const context = canvasEl?.getContext('webgl');
      if (canvasEl && context) {
        imageData = getImageDataFromWebGLContext(context, canvasEl);
        hasAlpha = true;
      }
      // If the WebGL getPixels ever doesn't work, we can fall back to using the same method we do for image URLs (but it's like 40ms instead of 8ms on M1 Pro)
      // ({ imageData, hasAlpha } = await getImageDataFromUrl(canvasEl.toDataURL()));
    } else {
      continue; // unsupported node type
    }

    // Bail if we couldn't get the info
    if (imageData === null || hasAlpha === null) {
      console.warn('Unexpected: could not get image data for node', node.label);
      continue;
    }

    const iImage: IImage = {
      data: new Uint8Array(imageData.data),
      channels: hasAlpha ? Channels.RGBAlpha : Channels.RGB,
    };

    const colorsUnsorted = extract(iImage, numberOfColorsPerNode);
    const colorsForNode = sortColorsByLightness(colorsUnsorted);

    colors.push(colorsForNode);
  }

  return colors;
}
