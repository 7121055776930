export function isSVGInPlaintext(text: string): boolean {
  // Check for SVG opening tag
  const hasSVGTag = /<svg[^>]*>/i.test(text);

  // Check for common SVG elements
  const hasCommonElements = /<(path|rect|circle|ellipse|line|polyline|polygon|text)[^>]*>/i.test(text);

  // Basic structure validation (optional)
  const hasClosingTag = /<\/svg>/i.test(text);

  return hasSVGTag && hasCommonElements && hasClosingTag;
}
