import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { Button } from '../../components/button';

export const SharePanel = observer(() => {
  const { multiplayerState } = useEditor();
  const users = Object.values(multiplayerState.userData);

  return (
    <div className="flex items-center gap-2 py-2 pr-2 pl-5">
      <span className="grow font-medium">Collaboration</span>

      {users.length > 0 && (
        <div className="flex flex-row-reverse">
          {users.map((user) => (
            <div
              key={user.clientId}
              className="outline-panel -ml-2 flex h-6 w-6 items-center justify-center rounded text-lg font-medium outline outline-2"
              style={{ backgroundColor: user.color }}
            >
              {user.name[0]}
            </div>
          ))}
        </div>
      )}

      <Button>Share</Button>
    </div>
  );
});
