import { Button } from './components/button';

export function ErrorFallback({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  console.error('Error:', error);
  return (
    <div className="flex h-dvh w-dvw cursor-auto items-center justify-center bg-[#080808] pb-9">
      <div className="bg-panel p rounded-4 w-[320px] px-5 py-4">
        <p className="mb-2 text-lg font-medium">We’re sorry, an error occurred</p>
        <p className="mb-4 text-lg">
          We log and review these errors. You can also email us at{' '}
          <a className="decoration-gray-4 underline-offset-2 hover:underline" href="mailto:team@paper.design">
            team@paper.design
          </a>
        </p>
        <Button onClick={() => window.location.reload()}>Refresh the page</Button>
      </div>
    </div>
  );
}
