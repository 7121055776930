import { ulid } from 'ulid';
import { type Static, Type } from '@sinclair/typebox';
import { Value } from '@sinclair/typebox/value';
import { PageSchema } from './page-schema';
import { TreeNodeSchema } from './tree-node-schema';
import { generateName } from './name-generator';

/** The key for node relationships in the file object (referenced elsewhere in the client codebase) */
export const NodeRelationshipsKeys = 'nodeRelationships';

/** The full file information */
export const FileSchema = Type.Object({
  /** The built in MongoDB ID (we set it to the same thing as our ID) */
  _id: Type.String(),
  id: Type.String(),
  schemaVersion: Type.Literal(1),
  name: Type.String(),
  createdAt: Type.Date(),
  updatedAt: Type.Date(),
  labelCounter: Type.Record(Type.String(), Type.Number()),
  pages: Type.Array(PageSchema),
  nodes: Type.Record(Type.String(), TreeNodeSchema),
  /** We store the relationship in one string so that it's an atomic update to change both the parent and the child's sort order */
  [NodeRelationshipsKeys]: Type.Record(Type.String(), Type.String()),
});
export type FileType = Static<typeof FileSchema>;

/** File meta is a smaller subset of the file information that's used in lists */
export const FileMetaSchema = Type.Pick(FileSchema, ['id', 'name']);
export type FileMeta = Static<typeof FileMetaSchema>;

export function createFile(): FileType {
  // Create a blank file
  const blankFile: FileType = Value.Create(FileSchema);

  // Set defaults
  blankFile._id = ulid();
  blankFile.id = blankFile._id;
  blankFile.name = generateName();
  blankFile.createdAt = new Date();
  blankFile.updatedAt = new Date();

  // Return the file
  return blankFile;
}

/** Files when stored in the DB use _id instead of id and have a few extra fields for locks */
export const FileSchemaInDb = Type.Composite([
  FileSchema,
  Type.Object({
    _lock: Type.Union([Type.String(), Type.Null()]),
    _fencingToken: Type.Number(),
  }),
]);
export type FileTypeInDb = Static<typeof FileSchemaInDb>;
