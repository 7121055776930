// Make sure this is in the index.html file before the import happens here:
// <script>
// window._lrAsyncScript = 'https://lr-cdn.paper.design/logger.min.js';
// </script>
import LogRocket from 'logrocket';

// Only initialize LogRocket in deployments and in the browser
if (window && !import.meta.env.DEV) {
  // Use our proxy URL to avoid adblockers

  LogRocket.init('ksduyf/paper', { serverURL: 'https://lr-data.paper.design/i' });
}
