import { useId } from 'react';
import { ColorInput } from '../../components/color-input';
import { Field, FieldGroup } from '../../components/field';
import {
  Color,
  hexToOklch,
  Hsl,
  hslToOklch,
  Oklch,
  oklchToHex,
  oklchToHsl,
  oklchToRgb,
  Rgb,
  rgbToOklch,
} from '../properties/Color';
import { NumberInput } from '../properties/number-input';

type Props = {
  color: Color;
  onChange: (color: Color) => void;
  isUniform: boolean;
  setIsUniform: (isUniform: boolean) => void;
};

export function ColorDetails({ color, onChange, isUniform, setIsUniform }: Props) {
  const currentHsl = oklchToHsl(color.value);
  const currentRgb = oklchToRgb(color.value);
  const currentHex = oklchToHex(color.value);
  const currentAlphaStr = `${color.value.alpha === undefined ? 100 : Math.round(color.value.alpha * 100)}%`;

  const oklchLId = useId();
  const oklchCId = useId();
  const oklchHId = useId();
  const oklchAId = useId();
  const hslHId = useId();
  const hslSId = useId();
  const hslLId = useId();
  const hslAId = useId();
  const rgbRId = useId();
  const rgbGId = useId();
  const rgbBId = useId();
  const rgbAId = useId();
  const hexId = useId();
  const uniformId = useId();

  const handleOklchLChange = (value: string) => {
    const l = parseFloat(value) / 100;
    const newOklch: Oklch = { ...color.value, l };
    const newColor: Color = { ...color, value: newOklch, mode: 'oklch' };
    onChange(newColor);
  };

  const handleOklchCChange = (value: string) => {
    const c = (parseFloat(value) * 0.4) / 100;
    const newOklch: Oklch = { ...color.value, c };
    const newColor: Color = { ...color, value: newOklch, mode: 'oklch' };
    onChange(newColor);
  };

  const handleOklchHChange = (value: string) => {
    const h = parseFloat(value);
    const newOklch: Oklch = { ...color.value, h };
    const newColor: Color = { ...color, value: newOklch, mode: 'oklch' };
    onChange(newColor);
  };

  const handleOklchAlphaChange = (value: string) => {
    const alpha = parseFloat(value) / 100;
    const newOklch: Oklch = { ...color.value, alpha };
    const newColor: Color = { ...color, value: newOklch, mode: 'oklch' };
    onChange(newColor);
  };

  const handleHslHChange = (value: string) => {
    const h = parseFloat(value);
    const newHsl: Hsl = { ...currentHsl, h };
    const newColor: Color = { ...color, value: hslToOklch(newHsl), mode: 'hsl' };
    onChange(newColor);
  };

  const handleHslSChange = (value: string) => {
    const s = parseFloat(value) / 100;
    const newHsl: Hsl = { ...currentHsl, s };
    const newColor: Color = { ...color, value: hslToOklch(newHsl), mode: 'hsl' };
    onChange(newColor);
  };

  const handleHslLChange = (value: string) => {
    const l = parseFloat(value) / 100;
    const newHsl: Hsl = { ...currentHsl, l };
    const newColor: Color = { ...color, value: hslToOklch(newHsl), mode: 'hsl' };
    onChange(newColor);
  };

  const handleHslAlphaChange = (value: string) => {
    const alpha = parseFloat(value) / 100;
    const newHsl: Hsl = { ...currentHsl, alpha };
    const newColor: Color = { ...color, value: hslToOklch(newHsl), mode: 'hsl' };
    onChange(newColor);
  };

  const handleRgbRChange = (value: string) => {
    const r = parseFloat(value) / 255;
    const newRgb: Rgb = { ...currentRgb, r };
    const newColor: Color = { ...color, value: rgbToOklch(newRgb), mode: 'rgb' };
    onChange(newColor);
  };

  const handleRgbGChange = (value: string) => {
    const g = parseFloat(value) / 255;
    const newRgb: Rgb = { ...currentRgb, g };
    const newColor: Color = { ...color, value: rgbToOklch(newRgb), mode: 'rgb' };
    onChange(newColor);
  };

  const handleRgbBChange = (value: string) => {
    const b = parseFloat(value) / 255;
    const newRgb: Rgb = { ...currentRgb, b };
    const newColor: Color = { ...color, value: rgbToOklch(newRgb), mode: 'rgb' };
    onChange(newColor);
  };

  const handleRgbAlphaChange = (value: string) => {
    const alpha = parseFloat(value) / 100;
    const newRgb: Rgb = { ...currentRgb, alpha };
    const newColor: Color = { ...color, value: rgbToOklch(newRgb), mode: 'rgb' };
    onChange(newColor);
  };

  return (
    <>
      <div className="flex flex-col gap-0_5">
        <FieldGroup>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={oklchLId}
                min={0}
                max={100}
                units={['%']}
                value={`${[0, 1].includes(color.value.l) ? color.value.l * 100 : (color.value.l * 100).toFixed(1)}%`}
                className="pl-0 text-center"
                onValueCommit={handleOklchLChange}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={oklchCId}
                min={0}
                max={100}
                units={['%']}
                // Always show 1 decimal if not 0% or 100% (internally, c is between 0 and 0.4)
                value={`${[0, 0.4].includes(color.value.c) ? color.value.c * 100 * 2.5 : (color.value.c * 100 * 2.5).toFixed(1)}%`}
                className="pl-0 text-center"
                onValueCommit={handleOklchCChange}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={oklchHId}
                min={0}
                max={360}
                value={`${Math.round(10 * color.value.h) / 10}`}
                className="pl-0 text-center"
                onValueCommit={handleOklchHChange}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={oklchAId}
                min={0}
                max={100}
                units={['%']}
                value={currentAlphaStr}
                className="pl-0 text-center"
                onValueCommit={handleOklchAlphaChange}
              />
            </Field.Control>
          </Field.Root>
        </FieldGroup>
        <div className="text-gray-2 flex justify-around text-sm">
          <label htmlFor={oklchLId}>L</label>
          <label htmlFor={oklchCId}>C</label>
          <label htmlFor={oklchHId}>H</label>
          <label htmlFor={oklchAId}>A</label>
        </div>
      </div>
      <div className="flex flex-col gap-0_5">
        <FieldGroup>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={hslHId}
                min={0}
                max={360}
                value={`${Math.round(10 * currentHsl.h) / 10}`}
                className="pl-0 text-center"
                onValueCommit={handleHslHChange}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={hslSId}
                min={0}
                max={100}
                units={['%']}
                // Always show 1 decimal if not 0% or 100%
                value={`${[0, 1].includes(currentHsl.s) ? currentHsl.s * 100 : (currentHsl.s * 100).toFixed(1)}%`}
                className="pl-0 text-center"
                onValueCommit={handleHslSChange}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={hslLId}
                min={0}
                max={100}
                units={['%']}
                // Always show 1 decimal if not 0% or 100%
                value={`${[0, 1].includes(currentHsl.l) ? currentHsl.l * 100 : (currentHsl.l * 100).toFixed(1)}%`}
                className="pl-0 text-center"
                onValueCommit={handleHslLChange}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={hslAId}
                min={0}
                max={100}
                units={['%']}
                value={currentAlphaStr}
                className="pl-0 text-center"
                onValueCommit={handleHslAlphaChange}
              />
            </Field.Control>
          </Field.Root>
        </FieldGroup>
        <div className="text-gray-2 flex justify-around text-sm">
          <label htmlFor={hslHId}>H</label>
          <label htmlFor={hslSId}>S</label>
          <label htmlFor={hslLId}>L</label>
          <label htmlFor={hslAId}>A</label>
        </div>
      </div>
      <div className="flex flex-col gap-0_5">
        <FieldGroup>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={rgbRId}
                min={0}
                max={255}
                value={`${currentRgb.r * 255}`}
                className="pl-0 text-center"
                onValueCommit={handleRgbRChange}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={rgbGId}
                min={0}
                max={255}
                value={`${currentRgb.g * 255}`}
                className="pl-0 text-center"
                onValueCommit={handleRgbGChange}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={rgbBId}
                min={0}
                max={255}
                value={`${currentRgb.b * 255}`}
                className="pl-0 text-center"
                onValueCommit={handleRgbBChange}
              />
            </Field.Control>
          </Field.Root>
          <Field.Root>
            <Field.Control>
              <NumberInput
                id={rgbAId}
                min={0}
                max={100}
                units={['%']}
                value={currentAlphaStr}
                className="pl-0 text-center"
                onValueCommit={handleRgbAlphaChange}
              />
            </Field.Control>
          </Field.Root>
        </FieldGroup>
        <div className="text-gray-2 flex justify-around text-sm">
          <label htmlFor={rgbRId}>R</label>
          <label htmlFor={rgbGId}>G</label>
          <label htmlFor={rgbBId}>B</label>
          <label htmlFor={rgbAId}>A</label>
        </div>
      </div>
      <div className="flex flex-col gap-0_5 text-center">
        <Field.Root>
          <Field.Control>
            <ColorInput
              id={hexId}
              color={{ ...color, mode: 'hex', value: hexToOklch(currentHex) }}
              mode="hex"
              className="text-center"
              onColorCommit={onChange}
            />
          </Field.Control>
        </Field.Root>
        <label htmlFor={hexId} className="text-gray-2 text-sm">
          Hex
        </label>
      </div>
      {/* <div className="flex items-center gap-1_5">
        <input type="checkbox" id={uniformId} checked={isUniform} onChange={(e) => setIsUniform(e.target.checked)} />
        <label htmlFor={uniformId}>Perceptually uniform</label>
      </div> */}
    </>
  );
}
