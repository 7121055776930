import { Vec2 } from '@mobius/models/src/math/vec2';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { calculateDomLayoutDirection, LayoutDirection } from './calculate-dom-layout-direction';

export type DropTargetResult = { node: TreeNode; insertAt: number; layoutDirection: LayoutDirection };

/**
 * Provided with a hovered node, this will find the best drop target for the drag
 * Which could be the hovered node itself, its parent if it doesn't allow children, or potentially a sibling to the hovered node if we're near its edge and there isn't much gap between the hovered node and its siblings
 * If the best target is in DOM layout mode, will find the child index to drop at
 *
 * This is designed to be as efficient as possible, anticipating it will be called every frame while dragging
 */
export function findDropTarget(editorState: EditorState, hoveredNode: TreeNode | null): DropTargetResult | null {
  if (hoveredNode === null) {
    return null;
  }

  // If the hovered node doesn't allow children, we'll check its container instead
  let hoverTarget: TreeNode | null = hoveredNode;
  if (hoveredNode.canHaveChildren === false) {
    hoverTarget = hoveredNode.container;
  }

  // Bail if the target is null, which is unexpected as container should always exist
  if (hoverTarget === null) {
    return null;
  }

  // If the target has fixed layout, we'll insert at the end
  if (hoverTarget.childrenAreFixed) {
    return { node: hoverTarget, insertAt: hoverTarget.children.length, layoutDirection: LayoutDirection.Free };
  }

  // Find the layout direction of the target
  const layoutDirection = calculateDomLayoutDirection(hoverTarget);

  // Check if we should instead drop as a sibling to the target because the cursor is near its edge
  const siblingDrop = checkForSiblingDrop(hoverTarget, editorState.pointerState.cursorPosWorld);
  if (siblingDrop !== null) {
    return siblingDrop;
  }

  // If the target has children already, find the insert index within the target based on cursor position relative to children
  if (hoverTarget.children.length > 0) {
    const index = determineInsertOrderWithinParent(
      hoverTarget,
      editorState.pointerState.cursorPosWorld,
      layoutDirection
    );
    return { node: hoverTarget, insertAt: index, layoutDirection };
  }

  // If we made it this far, the target doesn't have children yet, so just insert at the beginning
  return { node: hoverTarget, insertAt: 0, layoutDirection: LayoutDirection.Free };
}

// ----- Sibling drop ----- //

/** The ratio of a hovered node's size that will count as a sibling drop, on each side of it */
const SIBLING_DROP_THRESHOLD = 0.2;

/** Checks a potential drop target to see if we should instead drop as a sibling to it because the cursor is near its edge */
function checkForSiblingDrop(hoveredNode: TreeNode, cursorPos: Vec2): DropTargetResult | null {
  const parent = hoveredNode.parent;
  if (parent === null) return null;

  // Find the parent's layout direction
  const parentLayoutDirection = calculateDomLayoutDirection(parent);

  // Find the cursor's position relative to the hovered node
  let cursorRatio: number;
  if (parentLayoutDirection === LayoutDirection.LTR) {
    cursorRatio = (cursorPos.x - hoveredNode.bounds.minX) / hoveredNode.bounds.width;
  } else if (parentLayoutDirection === LayoutDirection.RTL) {
    cursorRatio = (hoveredNode.bounds.maxX - cursorPos.x) / hoveredNode.bounds.width;
  } else if (parentLayoutDirection === LayoutDirection.TTB) {
    cursorRatio = (cursorPos.y - hoveredNode.bounds.minY) / hoveredNode.bounds.height;
  } else if (parentLayoutDirection === LayoutDirection.BTT) {
    cursorRatio = (hoveredNode.bounds.maxY - cursorPos.y) / hoveredNode.bounds.height;
  } else {
    // The node's parent is in free form layout mode, so sibling drop would not make sense
    return null;
  }

  if (cursorRatio < SIBLING_DROP_THRESHOLD) {
    // Cursor is indicating a previous sibling drop
    const insertAt = hoveredNode.indexOfNodeInParent!;
    return { node: hoveredNode.parent!, insertAt, layoutDirection: parentLayoutDirection };
  } else if (cursorRatio > 1 - SIBLING_DROP_THRESHOLD) {
    // Cursor is indicating a next sibling drop
    const insertAt = hoveredNode.indexOfNodeInParent! + 1;
    return { node: hoveredNode.parent!, insertAt, layoutDirection: parentLayoutDirection };
  }

  // Cursor did not indicate a sibling drop
  return null;
}

// ----- Determining intent within a parent based on cursor position relative to its children ----- //

function determineInsertOrderWithinParent(parent: TreeNode, cursorPos: Vec2, layoutDirection: LayoutDirection): number {
  const axis = layoutDirection === LayoutDirection.LTR || layoutDirection === LayoutDirection.RTL ? 'x' : 'y';
  const maxOnAxis = axis === 'x' ? 'maxX' : 'maxY';

  // Which order should we traverse the children?
  let startIndex = 0;
  let endIndex = parent.children.length;
  let increment = 1;
  // If the layout direction is reversed, we need to traverse the children in reverse order
  if (layoutDirection === LayoutDirection.RTL || layoutDirection === LayoutDirection.BTT) {
    startIndex = parent.children.length - 1;
    endIndex = -1;
    increment = -1;
  }

  // Find the index of the cursor position relative to the children
  for (let i = startIndex; i !== endIndex; i += increment) {
    const child = parent.children[i]!;

    if (cursorPos[axis] < child[axis]) {
      return i;
    } else if (cursorPos[axis] < child.bounds[maxOnAxis]) {
      // If we're over the child itself, we'll check if we're closer to the left or right edge
      const distanceFromLeftEdge = cursorPos[axis] - child[axis];
      const distanceFromRightEdge = child.bounds[maxOnAxis] - cursorPos[axis];
      return distanceFromLeftEdge < distanceFromRightEdge ? i : i + 1;
    }
  }

  // If we've made it through all the children without finding a spot, we'll insert at the end
  return parent.children.length;
}
