/**
 * Fix up floating-point arithmetic issues by applying a incredibly tiny rounding on the value.
 *
 * Example:
 * - `0.05 + 0.01 => 0.060000000000000005`
 * - `decimal(0.05 + 0.01) => 0.06`
 */
export function decimal(number: number) {
  return +number.toFixed(12);
}
