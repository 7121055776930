import { CollapsibleHeading } from '../../components/collapsible-heading';
import { useState } from 'react';
import { XProperty } from './x-property';
import { YProperty } from './y-property';
import { FieldGroup } from '../../components/field';
import { RotateProperty } from './rotate-property';
import { PropertyPanels } from './property-panel';
import { useEditor } from '../editor-context';
import { observer } from 'mobx-react-lite';

export const PositionProperties = observer(() => {
  const { propertiesState } = useEditor();
  const collapsed = propertiesState.collapsedPanels.has(PropertyPanels.Position);

  return (
    <div className="flex flex-col pb-1">
      <CollapsibleHeading
        collapsed={collapsed}
        onCollapsedChange={(collapsed) =>
          collapsed
            ? propertiesState.collapsePanel(PropertyPanels.Position)
            : propertiesState.expandPanel(PropertyPanels.Position)
        }
      >
        Position
      </CollapsibleHeading>

      {!collapsed && (
        <div className="flex h-7 items-center gap-2 pr-2 pl-5">
          <FieldGroup className="w-[172px]">
            <XProperty />
            <YProperty />
          </FieldGroup>
          <RotateProperty />
        </div>
      )}
    </div>
  );
});
