import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { PropsListForComponent } from './props-list-for-component';
import { TreeNode } from '../tree/TreeNode';

/** Looks at all selected components and renders their props, separated by component type */
export const PropsPanel = observer(() => {
  const { selectionState } = useEditor();

  const selectedComponentIds = new Set<string>();
  const nodesByComponentId: Record<string, TreeNode[]> = {};
  for (const node of selectionState.selectedNodes) {
    selectedComponentIds.add(node.component);

    nodesByComponentId[node.component] ??= [];
    nodesByComponentId[node.component]!.push(node);
  }

  return (
    <div>
      {Array.from(selectedComponentIds).map((componentId) => (
        <PropsListForComponent
          key={componentId}
          componentMetaId={componentId}
          nodes={nodesByComponentId[componentId]!}
        />
      ))}
    </div>
  );
});
