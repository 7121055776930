import { makeObservable } from 'mobx';
import { disposeAllChildrenKeys, EditorState } from '../EditorState';
import { DragState } from './DragState';
import { SelectionBrushState } from './SelectionBrushState';
import { QuickPanState } from './QuickPanState';

export class MoveToolState {
  constructor(public editorState: EditorState) {
    makeObservable(this);

    this.dragState = new DragState(this.editorState);
    this.selectionBrushState = new SelectionBrushState(this.editorState);
    this.quickPanState = new QuickPanState(this.editorState);
  }
  dispose = () => disposeAllChildrenKeys(this, new Set(['editorState']));

  dragState: DragState;
  selectionBrushState: SelectionBrushState;
  quickPanState: QuickPanState;

  /** Called by ToolState when the tool is ending because another tool is taking over, just do any possible cleanup here */
  endTool = () => {
    // End dragging
    if (this.dragState.isDragging) {
      this.dragState.endDrag();
    }

    // End selection brushing
    if (this.selectionBrushState.isSelectBrushing) {
      this.selectionBrushState.endSelectionBrush();
    }

    // End temporary panning
    if (this.quickPanState.isQuickPanning) {
      this.quickPanState.endQuickPan();
    }
  };
}
