import { deepDelete } from './deep-delete-value';
import { deepSetValue } from './deep-set-value';
import { deepSplice } from './deep-splice';
import type { Edit } from './edit';

export function applyEdit(target: Record<any, any>, edit: Edit) {
  if (edit.type === 'add' || edit.type === 'update') {
    deepSetValue(target, edit.path, edit.value, edit.type);
  } else if (edit.type === 'remove') {
    deepDelete(target, edit.path);
  } else if (edit.type === 'splice') {
    deepSplice(target, edit.path, edit.removedCount, edit.added);
  }
}
