import { action, makeObservable } from 'mobx';
import { EditorState } from '../EditorState';
import { Vec2, Vec2Utils } from '@mobius/models/src/math/vec2';
import { assert } from '../../assert';

/** in MoveTool, you can use thumb and middle mouse buttons to click and drag the camera pan */
export class QuickPanState {
  constructor(private editorState: EditorState) {
    makeObservable(this);
  }
  dispose = () => {
    this.unbindInputHandlers();
  };

  /** Whether we are currently panning */
  isQuickPanning = false;

  /** The pan when the quick pan started */
  pointerDownPan: Vec2 | null = null;
  /** The pointer position when the pan started in viewport units */
  pointerDownPosViewport: Vec2 | null = null;
  /** The pointer id that we are capturing so we can release it when we are done panning */
  pointerIdForCaptureRelease: number | null = null;

  @action
  startQuickPan(pointerIdForCaptureRelease: number) {
    this.pointerIdForCaptureRelease = pointerIdForCaptureRelease;
    this.pointerDownPan = { ...this.editorState.cameraState.pan };
    this.pointerDownPosViewport = { ...this.editorState.pointerState.cursorPos };
    this.bindInputHandlers();
  }

  @action
  endQuickPan() {
    // Clean up
    this.pointerIdForCaptureRelease = null;
    this.unbindInputHandlers();
  }

  @action
  handleInputWhileQuickPanning = () => {
    const { pointerState, cameraState } = this.editorState;
    assert(this.pointerDownPan, 'pointerDownPan should be set when MoveTool is panning');
    assert(this.pointerDownPosViewport, 'pointerDownPosViewport should be set when MoveTool is panning');

    const delta = Vec2Utils.subtract(pointerState.cursorPos, this.pointerDownPosViewport);
    cameraState.setPan(this.pointerDownPan.x + delta.x, this.pointerDownPan.y + delta.y, true);
  };

  /** Binds pointer handlers to the editor state */
  bindInputHandlers = () => {
    this.editorState.tickState.subToTick(this.handleInputWhileQuickPanning);
    window.addEventListener('pointerup', this.handlePointerUp);
  };
  /** Unbinds pointer handlers from the editor state */
  unbindInputHandlers = () => {
    this.editorState.tickState.unsubToTick(this.handleInputWhileQuickPanning);
    window.removeEventListener('pointerup', this.handlePointerUp);

    if (this.pointerIdForCaptureRelease && this.editorState.hudState.hudEl) {
      // Release pointer capture (the MoveTool uses the hudEl as its pointerDown element)
      this.editorState.hudState.hudEl.releasePointerCapture(this.pointerIdForCaptureRelease);
    }
  };
  handlePointerUp = (e: PointerEvent) => {
    e.preventDefault(); // prevent browser behavior for wheel clicks and thumb clicks
    e.stopPropagation(); // pointer events on the canvas should never go anywhere else
    this.endQuickPan();
  };
}
