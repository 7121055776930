import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { SELECTION_BOUNDS_COLOR } from '../move-tool/select-brush-graphic';
import { Tool } from '../toolbar/ToolState';

/**
 * Renders a dashed line box around the currently hovered text node
 */
export const TextEditHoverHighlight = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { cameraState, hudState, toolState, selectionState, textTool: textTool } = editorState;

    function drawHighlight(ctx: CanvasRenderingContext2D) {
      // Don't draw if we're not using the text drawing or editing tool
      if (toolState.activeTool !== Tool.Text) return;

      const node = textTool.hoveredTextNode;
      if (!node) {
        return;
      }

      // Don't draw if the node is already selected
      if (selectionState.selectedNodeIds.has(node.id)) return;

      ctx.strokeStyle = SELECTION_BOUNDS_COLOR;
      const borderWidth = 1 * cameraState.scaleInverse;
      const offset = borderWidth / 2;
      const dashSize = 2 * cameraState.scaleInverse;
      ctx.setLineDash([dashSize, dashSize]);
      ctx.lineWidth = borderWidth;
      ctx.strokeRect(node.x - offset, node.y - offset, node.width + borderWidth, node.height + borderWidth);
    }

    // Register our drawing function
    hudState.worldDrawingFunctions.add(drawHighlight);
    return () => {
      hudState.worldDrawingFunctions.delete(drawHighlight);
    };
  }, [editorState]);

  return null;
});
