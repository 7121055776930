import type { UserJoinedFileMessage } from './user-joined-file-message';
import type { UserLeftFileMessage } from './user-left-file-message';
import { type CursorMoveMessage } from './cursor-move-message';
import { type UsersAlreadyInFileMessage } from './users-already-in-file-message';
import type { FilePayloadMessage } from './file-payload-message';
import type { FileLoadFailedMessage } from './file-load-failed-message';
import type { FileEditMessage } from './file-edit-message';
import type { UserSelectNodeMessage } from './user-select-node-message';
import type { UserFocusChangeMessage } from './user-focus-change-message';
import type { UserPageChangeMessage } from './user-page-change-message';

/** A key that specifies the message type, all messages must have this */
export const typeKey = '$t';

/** An enum of message type values so we can switch them off to handlers when they arrive */
export enum MessageType {
  CursorMove = 0,
  UserJoinedFile = 1,
  UserLeftFile = 2,
  UsersAlreadyInFile = 3,
  FilePayload = 4,
  FileLoadFailed = 5,
  FileEdit = 6,
  UserSelectNode = 7,
  UserFocusChange = 8,
  UserPageChange = 9,
}

/** All message types should union or extend this base type */
export type SocketMessageBase = {
  /** The type of message */
  [typeKey]: MessageType;
  /** The server will attach clientIds to messages that are specific to a given client */
  clientId?: string;
};

/** An aggregate that includes all possible message types */
export type SocketMessage =
  | CursorMoveMessage
  | UserJoinedFileMessage
  | UserLeftFileMessage
  | UsersAlreadyInFileMessage
  | FilePayloadMessage
  | FileLoadFailedMessage
  | FileEditMessage
  | UserSelectNodeMessage
  | UserFocusChangeMessage
  | UserPageChangeMessage;

/** A type guard to check if a message is a SocketMessage */
export function isSocketMessage(message: any): message is SocketMessage {
  return message[typeKey] !== undefined;
}
