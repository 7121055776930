import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { SELECTION_BOUNDS_COLOR } from '../move-tool/select-brush-graphic';

/**
 * Renders a box around the currently hovered node
 */
export const HoverHighlight = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { pointerState, keyState, cameraState, hudState, toolState, selectionState } = editorState;

    function drawHighlight(ctx: CanvasRenderingContext2D) {
      // Don't draw if we're drawing, dragging, or selecting, or not using the move tool
      if (!toolState.shouldShowHoverHighlight) return;

      const node = pointerState.hoveredNode;
      if (!node) {
        return;
      }

      // Don't draw if the node is already selected
      if (selectionState.selectedNodeIds.has(node.id)) return;

      ctx.strokeStyle = SELECTION_BOUNDS_COLOR;

      const baseWidth = 2;
      const borderWidth = baseWidth / cameraState.scale;
      const offset = borderWidth / baseWidth;

      // We round to the nearest 1/devicePixelRatio so that the line is on exact pixels for this device
      const roundingPrecision = 1 / hudState.devicePixelRatio;
      const boundsX = (node.x * roundingPrecision) / roundingPrecision;
      const boundsY = (node.y * roundingPrecision) / roundingPrecision;
      const boundsWidth = (node.width * roundingPrecision) / roundingPrecision;
      const boundsHeight = (node.height * roundingPrecision) / roundingPrecision;

      ctx.lineWidth = borderWidth;
      ctx.strokeRect(boundsX - offset, boundsY - offset, boundsWidth + borderWidth, boundsHeight + borderWidth);
    }

    // Register our drawing function
    hudState.worldDrawingFunctions.add(drawHighlight);
    return () => {
      hudState.worldDrawingFunctions.delete(drawHighlight);
    };
  }, [editorState]);

  return null;
});
