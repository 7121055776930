import { Vec2 } from '@mobius/models/src/math/vec2';
import { TreeNode } from '../tree/TreeNode';

/**
 * Give it a computed styles object and it will tell you which axis and direction the layout flows in
 *
 * @example
 * { display: 'block' }
 * // => { axis: 'x', reversed: false }
 *
 * @example
 * { display: 'flex', flexDirection: 'row' }
 * // => { axis: 'x', reversed: 'false' }
 *
 * @example
 * { display: 'flex', flexDirection: 'column' }
 * // => { axis: 'y', reversed: 'false' }
 *
 * @example
 * { display: 'flex', flexDirection: 'row-reverse' }
 * // => { axis: 'x', reversed: 'true' }
 *
 * @example
 * { display: 'flex', flexDirection: 'column-reverse' }
 * // => { axis: 'y', reversed: 'true' }
 */
export function calculateDomLayoutDirection(treeNode: TreeNode): LayoutDirection {
  // If it's free form fixed mode, there is no layout direction
  if (treeNode.childrenAreFixed) {
    return LayoutDirection.Free;
  }

  // If it's block mode, the axis is y
  if (treeNode.styles.display === 'block') {
    return LayoutDirection.TTB;
  }

  if (treeNode.styles.display === 'grid') {
    return LayoutDirection.LTR; // TODO: grid layout direction
  }

  if (treeNode.styles.display === 'flex') {
    const flexDirection = treeNode.styles.flexDirection || 'row';
    const axis: keyof Vec2 = flexDirection.startsWith('row') ? 'x' : 'y';
    const reversed = flexDirection.includes('reverse');
    if (axis === 'x') {
      return reversed ? LayoutDirection.RTL : LayoutDirection.LTR;
    } else if (axis === 'y') {
      return reversed ? LayoutDirection.BTT : LayoutDirection.TTB;
    }
  }

  console.warn(`Could not find layout for computed styles... returning { axis: 'y', reversed: 'false' } as a default`);
  console.warn(`Computed... display: ${treeNode.styles.display}, flexDirection: ${treeNode.styles.flexDirection}`);
  return LayoutDirection.Free;
}

export enum LayoutDirection {
  LTR = 0,
  RTL = 1,
  TTB = 2,
  BTT = 3,
  Free = 4,
}
