// Get the current URL of the page
const currentUrl = window.location.origin;
/** The production domain for the client */
const PROD_DOMAIN = 'https://paper-xc3.pages.dev/';

const isProd = currentUrl === PROD_DOMAIN;

// Check if we're using local servers (for development)
const useLocalSync = import.meta.env.VITE_LOCAL_SERVERS === 'true';
// Sanity check that we're not using local servers in production
if (isProd && useLocalSync) {
  throw new Error('VITE_LOCAL_SERVERS cannot be true in production');
}

/** Keep track of addresses to use for the backend based on the current environment */
export const API_ADDRESS = {
  SYNC_URI: useLocalSync ? 'ws://localhost:8080' : 'wss://mobius-sync-server.fly.dev',
  API_URI: useLocalSync ? 'http://localhost:8080' : 'https://mobius-sync-server.fly.dev',
  WORKERS_URI: useLocalSync ? 'http://localhost:8079' : 'https://paper-workers.paper-design.workers.dev',
};
