const vendorPrefixes = new Set(['ms', 'webkit', 'moz', 'o']);

/** Converts an object of JS object syntax styles and converts it into a standard style string */
export function convertObjectToCss(styleObject: React.CSSProperties) {
  return Object.entries(styleObject)
    .map(([key, value]) => {
      if (value === undefined || value === null) return '';

      // Convert camelCase to kebab-case
      let cssKey = key.replace(/[A-Z]/g, '-$&').toLowerCase();

      // Handle vendor prefixes that need the starting dash
      if (vendorPrefixes.has(cssKey.split('-')[0]!)) {
        cssKey = `-${cssKey}`;
      }

      // Handle numbers by adding px where appropriate
      let cssValue;

      // Add px if it's a number, not 0, and not a unitless property
      if (typeof value === 'number' && value !== 0 && unitlessProperties.has(cssKey) === false) {
        cssValue = `${value}px`;
      } else {
        cssValue = value;
      }

      return `${cssKey}: ${cssValue}; `;
    })
    .join('')
    .trim();
}

// Only convert to numbers for specific properties that are commonly unitless
const unitlessProperties = new Set([
  'opacity',
  'z-index',
  'flex',
  'flex-grow',
  'flex-shrink',
  'order',
  'line-height',
  'font-weight',
  'zoom',
  'animation-iteration-count',
  'scale',
  'grid-row',
  'grid-column',
  'column-count',
]);

/** Converts a standard style string into an object of JS object syntax styles */
export function convertCssStringToObject(cssString: string) {
  // Remove comments and extra whitespace
  cssString = cssString.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, '').trim();

  // Split into individual rules
  const rules = cssString.split(';').filter((rule) => rule.trim());

  // Convert to object
  return rules.reduce<Record<string, string | number>>((acc, rule) => {
    const [property, value] = rule.split(':').map((str) => str.trim());
    if (!property || !value) return acc;

    // Convert kebab-case to camelCase
    const camelProperty = property.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

    let cssValue: string | number = value;

    // Convert numeric strings to numbers if appropriate
    if (unitlessProperties.has(property) && !isNaN(Number(value))) {
      cssValue = Number(value);
    }

    // Convert unitless 0 string to number type
    if (value === '0') {
      cssValue = 0;
    }

    acc[camelProperty] = cssValue;
    return acc;
  }, {});
}
