export const TextDrawToolIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.618828 13.1143C0.267265 13.1143 0.0387497 12.9121 0.0387497 12.5781C0.0387497 12.4375 0.0651169 12.2969 0.117851 12.1475L3.89715 1.61816C4.07293 1.15234 4.31023 0.923828 4.74969 0.923828C5.18035 0.923828 5.42645 1.15234 5.60223 1.61816L9.37273 12.1475C9.42547 12.2969 9.46062 12.4375 9.46062 12.5781C9.46062 12.9033 9.22332 13.1143 8.87176 13.1143C8.53777 13.1143 8.34441 12.9561 8.20379 12.543L7.07 9.2207H2.42059L1.2868 12.543C1.15496 12.9561 0.952812 13.1143 0.618828 13.1143ZM2.77215 8.20996H6.71844L4.77605 2.52344H4.72332L2.77215 8.20996ZM16.9222 11.6992C16.4124 12.6133 15.3929 13.1582 14.1888 13.1582C12.3958 13.1582 11.1917 12.0244 11.1917 10.3545C11.1917 8.69336 12.387 7.67383 14.3821 7.67383H16.9398V6.66309C16.9398 5.38867 16.1751 4.7207 14.7601 4.7207C13.8987 4.7207 13.2923 5.0459 12.7562 5.75781C12.5716 5.99512 12.4046 6.06543 12.1673 6.06543C11.8773 6.06543 11.6839 5.87207 11.6839 5.56445C11.6839 5.08105 12.0706 4.55371 12.7474 4.14941C13.2747 3.83301 13.9515 3.65723 14.8304 3.65723C16.9661 3.65723 18.1527 4.71191 18.1527 6.62793V12.4023C18.1527 12.8242 17.9329 13.0791 17.5638 13.0791C17.1946 13.0791 16.9749 12.8242 16.9749 12.4023V11.6992H16.9222ZM12.4486 10.3281C12.4486 11.3828 13.2571 12.0947 14.4788 12.0947C15.8763 12.0947 16.9398 11.1279 16.9398 9.8623V8.72852H14.4085C13.1517 8.72852 12.4486 9.2998 12.4486 10.3281Z"
        fill="currentColor"
        fillOpacity="0.9"
      />
    </svg>
  );
};
