import type { Vec2 } from './vec2';

/** A rectangle, defined by its minimum and maximum x and y coordinates */
export type Rect = {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
};

export type RectWithSize = Rect & { width: number; height: number };

export abstract class RectUtils {
  static width(rect: Rect) {
    return rect.maxX - rect.minX;
  }

  static height(rect: Rect) {
    return rect.maxY - rect.minY;
  }

  static area(rect: Rect) {
    return RectUtils.width(rect) * RectUtils.height(rect);
  }

  static center(rect: Rect): Vec2 {
    return { x: (rect.minX + rect.maxX) / 2, y: (rect.minY + rect.maxY) / 2 };
  }

  static empty(): RectWithSize {
    return { minX: 0, minY: 0, maxX: 0, maxY: 0, width: 0, height: 0 };
  }

  static addVector(rect: RectWithSize, vector: Vec2): RectWithSize {
    return {
      minX: rect.minX + vector.x,
      minY: rect.minY + vector.y,
      maxX: rect.maxX + vector.x,
      maxY: rect.maxY + vector.y,
      width: rect.maxX - rect.minX + vector.x,
      height: rect.maxY - rect.minY + vector.y,
    };
  }

  static roundInPlace(rect: Rect | RectWithSize): Rect | RectWithSize {
    rect.minX = Math.round(rect.minX);
    rect.minY = Math.round(rect.minY);
    rect.maxX = Math.round(rect.maxX);
    rect.maxY = Math.round(rect.maxY);
    if ('width' in rect) {
      rect.width = rect.maxX - rect.minX;
    }
    if ('height' in rect) {
      rect.height = rect.maxY - rect.minY;
    }

    return rect;
  }

  static fromPoints(points: Vec2[]): RectWithSize {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (const point of points) {
      minX = Math.min(minX, point.x);
      minY = Math.min(minY, point.y);
      maxX = Math.max(maxX, point.x);
      maxY = Math.max(maxY, point.y);
    }

    if (isNaN(minX) || isNaN(minY) || isNaN(maxX) || isNaN(maxY)) {
      throw new Error('Invalid rectangle coordinates: NaN values detected');
    }

    return { minX, minY, maxX, maxY, width: maxX - minX, height: maxY - minY };
  }

  static union(rects: Rect[]): RectWithSize {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (const rect of rects) {
      minX = Math.min(minX, rect.minX);
      minY = Math.min(minY, rect.minY);
      maxX = Math.max(maxX, rect.maxX);
      maxY = Math.max(maxY, rect.maxY);
    }

    // If we couldn't make a rect, return a 0 size rect
    if (minX === Infinity && maxX === -Infinity && minY === Infinity && maxY === -Infinity) {
      return RectUtils.empty();
    }

    return {
      minX,
      minY,
      maxX,
      maxY,
      width: maxX - minX,
      height: maxY - minY,
    };
  }
}
