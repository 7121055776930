import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect, useRef } from 'react';
import { assert } from '../../assert';
import { autorun } from 'mobx';

export const CanvasBackgroundAndCursors = observer(({ children }: { children: React.ReactNode }) => {
  const { pageState, cursorState } = useEditor();
  const canvasBackgroundAndCursorsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const canvasEl = canvasBackgroundAndCursorsRef.current;
    assert(canvasEl, 'Canvas background and cursors element is not defined');

    let lastCursorClass: string | null = null;
    const dispose = autorun(() => {
      const cursorClass = cursorState.currentCursorClass;
      // Remove the old cursor class
      if (lastCursorClass) {
        canvasEl.classList.remove(lastCursorClass);
      }
      lastCursorClass = cursorClass;
      // Set the new cursor class
      if (cursorClass) {
        canvasEl.classList.add(cursorClass);
      }
    });
    return () => {
      dispose();
    };
  }, [cursorState]);

  return (
    <div
      ref={canvasBackgroundAndCursorsRef}
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        inset: 0,
        backgroundColor: pageState.activePage.canvasColor,
      }}
    >
      {children}
    </div>
  );
});
