export function EyeClosedIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        transform="translate(0 -1)"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99978 10.9999C5.37917 10.9999 3.17622 9.6313 2.23256 7.30786L1.30606 7.68416C1.52485 8.22286 1.80103 8.71655 2.12625 9.16211L0.646484 10.6419L1.35359 11.349L2.77687 9.9257C3.27759 10.4308 3.85372 10.8499 4.48646 11.1761L3.75102 12.9516L4.6749 13.3343L5.40543 11.5706C6.06445 11.8009 6.76823 11.9411 7.49978 11.985V13.8749H8.49978V11.985C9.23132 11.9411 9.93511 11.8009 10.5941 11.5706L11.3246 13.3343L12.2485 12.9516L11.5131 11.1761C12.1458 10.8499 12.722 10.4308 13.2227 9.9257L14.646 11.349L15.3531 10.6419L13.8733 9.16211C14.1985 8.71655 14.4747 8.22286 14.6935 7.68416L13.767 7.30786C12.8233 9.6313 10.6204 10.9999 7.99978 10.9999Z"
        fill="currentcolor"
      />
    </svg>
  );
}
