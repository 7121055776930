import { EditorState } from './EditorState';

/** Runs our global RAF and lets things register for update calls in priority groups */
export class TickState {
  private rafId: number | null = null;

  private loopBeforePaint: LoopBeforePaint | null = null;

  constructor(private editorState: EditorState) {
    // Set up normal RAF ticks
    this.rafId = requestAnimationFrame(this.tick);

    // Set up our ResizeObserver callback that will fire on every frame
    this.loopBeforePaint = new LoopBeforePaint(this.beforePaint);
  }
  dispose = () => {
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
    }

    this.loopBeforePaint?.dispose();
  };

  // ----- RAF subscribers ----- //
  /** Functions that need to be called every RAF */
  private tickSubs = new Set<() => void>();
  subToTick = (update: () => void) => {
    this.tickSubs.add(update);
  };
  unsubToTick = (update: () => void) => {
    this.tickSubs.delete(update);
  };

  private tick = () => {
    // Uncomment this if we want syncronous logging of every tick
    // (window as any).syncLog('--- request animation frame tick ---');

    for (const subscriberCallback of this.tickSubs) {
      subscriberCallback();
    }
    this.rafId = requestAnimationFrame(this.tick);
  };

  // ----- Before paint subscribers ----- //
  /** Functions that need to be called before paint */
  private beforePaintSubs = new Set<() => void>();
  /** Subscribe to be called after layout but before paint – be careful not to do DOM manipulations or set mobx state that will cause React to re-render (or useDeferredValue in React) */
  subToBeforePaint = (update: () => void) => {
    this.beforePaintSubs.add(update);
  };
  unsubToBeforePaint = (update: () => void) => {
    this.beforePaintSubs.delete(update);
  };

  private beforePaint = () => {
    for (const subscriberCallback of this.beforePaintSubs) {
      subscriberCallback();
    }

    // Measure and update sizes of anything with changes
    this.editorState.fileState.measureNodePositionAndSize.takeMeasurementsBeforePaint();

    // Do drawing after all updates have been applied
    this.editorState.hudState.render();
  };
}

/**
 * IMPORTANT to be careful NOT to do any DOM or style manipulations in this callback
 * or you will cause style recalcs and layout recalcs for the second time in the frame
 * This runs after everything except observer callbacks and paint
 * So only do webgl/canvas or reads from state
 * Layout is already calc'd so things like getBoundingClientRect should be free
 *
 * If you update an observed property it may trigger React re-renders
 * which will cause layout recalcs - useDeferredValue in React is one way to avoid this
 *
 * This class triggers a ResizeObserver callback every frame - which happens after layout is calculated but before paint
 * This is a great time to measure DOM changes and draw to canvas/WebGL to keep them in sync
 */
class LoopBeforePaint {
  #dummyElem: HTMLDivElement | null = null;
  #observer: ResizeObserver | null = null;

  constructor(callback: () => void) {
    // Create dummy element to observe that will never resize other than when it is added or removed to the DOM
    this.#dummyElem = document.createElement('div');
    this.#dummyElem.style.cssText = [
      'position:fixed',
      'width:1px',
      'height:1px',
      'right:-1px',
      'bottom:-1px',
      'pointer-events:none',
      'contain:strict',
      'display:none',
    ].join(';');
    document.body.appendChild(this.#dummyElem);

    // Start our loop that will trigger a RO callback in every frame
    requestAnimationFrame(this.update);

    // Wait to create the RO until after syncronously-created-ROs from app code have run (hopefully placing these callbacks last)
    Promise.resolve().then(() => {
      this.#observer = new ResizeObserver(callback);

      this.#observer.observe(this.#dummyElem!);
    });
  }

  update = () => {
    if (!this.#dummyElem || !this.#observer) return;
    // cycling disconnect and observe is the fastest way to trigger a resize observer callback
    // much faster than resizing a div, which triggers browser layout calcs
    this.#observer.disconnect();
    this.#observer.observe(this.#dummyElem);

    requestAnimationFrame(this.update);
  };

  dispose = () => {
    if (this.#observer) {
      this.#observer.disconnect();
      this.#observer = null;
    }
    if (this.#dummyElem && this.#dummyElem.parentNode) {
      this.#dummyElem.remove();
    }
    this.#dummyElem = null;
  };
}
