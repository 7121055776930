import { useLayoutEffect, useRef } from 'react';
import { useEditor } from '../editor-context';
import { assert } from '../../assert';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';

/**
 * Renders an actual canvas element, useful for drawing lines on top of the canvas.
 * It's a fixed position element that is rendered on top of the canvas.
 * Also useful for attaching pointer events to the canvas.
 */
export const HUD = observer(() => {
  const { hudState, pointerState } = useEditor();
  const ref = useRef<HTMLCanvasElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const canvasEl = ref.current;
      const ctx = canvasEl.getContext('2d');
      assert(ctx);
      hudState.setHUDEl(canvasEl, ctx);

      // Resize the canvas to match the viewport size
      const resizeCanvas = () => {
        // Get the device pixel ratio, falling back to 1.
        const dpr = window.devicePixelRatio || 1;
        // Get the size of the canvas in CSS pixels.
        const width = window.innerWidth;
        const height = window.innerHeight;
        // Give the canvas pixel dimensions of their CSS
        // size * the device pixel ratio.
        canvasEl.width = width * dpr;
        canvasEl.height = height * dpr;
        // Scale all drawing operations by the dpr, so you
        // don't have to worry about the difference.
        ctx.scale(dpr, dpr);
        // Set the canvas to fill the entire viewport
        canvasEl.style.width = `${width}px`;
        canvasEl.style.height = `${height}px`;
      };

      // Initial resize
      resizeCanvas();
      // Add event listener for window resize
      window.addEventListener('resize', resizeCanvas);

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', resizeCanvas);
      };
    } else {
      console.warn('Unexpected: HUD canvas element is not defined');
    }
  }, [hudState]);

  return (
    <canvas
      ref={ref}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        contain: 'content', // performance optimization
      }}
    />
  );
});
