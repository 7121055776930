export const appleSystemFontStyles: Record<string, string> = {
  'Ultralight': '100',
  'Ultralight Italic': '100',
  'Thin': '200',
  'Thin Italic': '200',
  'Light': '300',
  'Light Italic': '300',
  'Regular': '400',
  'Regular Italic': '400',
  'Medium': '500',
  'Medium Italic': '500',
  'Semibold': '600',
  'Semibold Italic': '600',
  'Bold': '700',
  'Bold Italic': '700',
  'Heavy': '800',
  'Heavy Italic': '800',
  'Black': '900',
  'Black Italic': '900',
};

export const googleFontStyles: Record<string, string> = {
  'Thin': '100',
  'Thin Italic': '100',
  'ExtraLight': '200',
  'ExtraLight Italic': '200',
  'Light': '300',
  'Light Italic': '300',
  'Regular': '400',
  'Italic': '400',
  'Medium': '500',
  'Medium Italic': '500',
  'SemiBold': '600',
  'SemiBold Italic': '600',
  'Bold': '700',
  'Bold Italic': '700',
  'ExtraBold': '800',
  'ExtraBold Italic': '800',
  'Black': '900',
  'Black Italic': '900',
};

export const googleFontStylesJson: Record<string, string> = {
  '100': 'Thin',
  '100i': 'Thin Italic',
  '200': 'ExtraLight',
  '200i': 'ExtraLight Italic',
  '300': 'Light',
  '300i': 'Light Italic',
  '400': 'Regular',
  '400i': 'Italic',
  '500': 'Medium',
  '500i': 'Medium Italic',
  '600': 'SemiBold',
  '600i': 'SemiBold Italic',
  '700': 'Bold',
  '700i': 'Bold Italic',
  '800': 'ExtraBold',
  '800i': 'ExtraBold Italic',
  '900': 'Black',
  '900i': 'Black Italic',
};
