import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { BuiltInComponentMap } from '../built-in-ui/built-in-ui';
import { EditorState } from '../EditorState';

export const CustomPanels = observer(() => {
  const editorState = useEditor();
  const { selectionState } = editorState;

  // Find any custom panels for the selected components
  const customPanels = selectionState.selectedNodes.reduce((acc, node) => {
    const componentMeta = BuiltInComponentMap[node.component];
    if (componentMeta?.customPanel) {
      acc.add(componentMeta.customPanel);
    }
    return acc;
  }, new Set<React.FC<{ editorState: EditorState }>>());

  return (
    <div>
      {Array.from(customPanels).map((Panel, index) => (
        <Panel key={index} editorState={editorState} />
      ))}
    </div>
  );
});
