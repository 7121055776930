import clsx from 'clsx';
import { CollapsibleIndicator } from './collapsible-indicator';

interface CollapsibleHeadingProps extends React.ComponentProps<'div'> {
  collapsible?: boolean;
  collapsed?: boolean;
  onCollapsedChange?: (collapsed: boolean) => void;
}

export const CollapsibleHeading = ({
  children,
  className,
  collapsible = true,
  collapsed,
  onCollapsedChange,
  ...props
}: CollapsibleHeadingProps) => {
  return (
    <div className={clsx('flex h-7 items-center justify-start', className)} {...props}>
      <div
        className={clsx('flex h-6 items-center pr-1', className)}
        onPointerDown={() => {
          if (collapsible) {
            onCollapsedChange?.(!collapsed);
          }
        }}
      >
        <span className="flex w-5 items-center justify-center">
          {collapsible && <CollapsibleIndicator collapsed={collapsed} />}
        </span>
        <span className="font-medium">{children}</span>
      </div>
    </div>
  );
};
