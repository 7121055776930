import * as SliderPrimitive from '@radix-ui/react-slider';
import clsx from 'clsx';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

const Slider = forwardRef<
  ElementRef<typeof SliderPrimitive.Root>,
  ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root ref={ref} className={clsx('SliderRoot', className)} {...props}>
    <SliderPrimitive.Track className="SliderTrack">
      <SliderPrimitive.Range className="SliderRange" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="SliderThumb" />
  </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
