import { TreeItemIcon, TreeItemIconName } from '../../components/tree-item-icons';
import { observer } from 'mobx-react-lite';
import { TreeNode } from '../tree/TreeNode';
import { useEditor } from '../editor-context';
import { RectUtils } from '@mobius/models/src/math/rect';
import { BuiltInComponentMap } from '../built-in-ui/built-in-ui';

export const RowIconButton = observer(({ node }: { node: TreeNode }) => {
  const editorState = useEditor();

  function handleDoubleClick(e: React.MouseEvent) {
    e.stopPropagation();
    editorState.cameraState.centerOnPoint(RectUtils.center(node.bounds), 1, true);
  }

  const iconFromComponent = BuiltInComponentMap[node.component]?.layerTreeIcon ?? 'box';
  const icon: TreeItemIconName =
    typeof iconFromComponent === 'function' ? iconFromComponent(editorState, node) : iconFromComponent;

  return (
    <span className="-mx-1 -my-2 flex px-1 py-2" onDoubleClick={handleDoubleClick}>
      <TreeItemIcon icon={icon} />
    </span>
  );
});
