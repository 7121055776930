import { TreeNode } from '../tree/TreeNode';
import { useRef } from 'react';
import { useEditor } from '../editor-context';
import { observer } from 'mobx-react-lite';
import { NodeLabelInput } from './node-label-input';

export const NodeLabel = observer(({ node }: { node: TreeNode }) => {
  const { layerTreeState } = useEditor();
  const isEditing = layerTreeState.editingLabelNodeId === node.id;

  // If editing, return an input
  if (isEditing) {
    return <NodeLabelInput node={node} />;
  }

  // Default, return just the label
  return (
    <div
      onDoubleClick={() => {
        layerTreeState.setEditingLabelNodeId(node.id);
      }}
      className="flex-grow flex items-center"
      style={{ height: '100%' }}
    >
      {node.label}
    </div>
  );
});
