import { Fragment } from 'react/jsx-runtime';
import { magicFlowImageSwatches } from '../color-extractor/magic-flow-image-swatches';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import * as MenuPrim from '@radix-ui/react-context-menu';

/**
 * Used in both the canvas and layer tree context menus
 * This is the menu that appears when there is no selection
 */
export function MenuForNoSelection({ editorState }: { editorState: EditorState }) {
  return (
    <MenuPrim.Group>
      <ToggleUIItem editorState={editorState} />
    </MenuPrim.Group>
  );
}

function ToggleUIItem({ editorState }: { editorState: EditorState }) {
  function toggleUI() {
    editorState.setRenderUI(!editorState.renderUI);
  }

  return (
    <MenuPrim.Item onSelect={toggleUI}>
      <div style={{ backgroundColor: 'white', padding: 20, color: 'black' }}>
        {editorState.renderUI ? 'Hide' : 'Show'} UI (alt + .)
      </div>
    </MenuPrim.Item>
  );
}
