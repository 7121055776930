import { observer } from 'mobx-react-lite';
import { SharePanel } from '../share-panel/share-panel';
import { useEditor } from '../editor-context';
import { FillProperty } from './fill-property';
import { LayoutProperties } from '../properties-layout/layout-properties';
import { PositionProperties } from './position-properties';
import { BuiltInComponentMap } from '../built-in-ui/built-in-ui';
import { EditorState } from '../EditorState';
import { PropsPanel } from '../properties-props/props-panel';
import { CustomPanels } from './custom-panels';
import { TypographyProperties } from '../properties-typography/typography-properties';
import { TreeNode } from '../tree/TreeNode';
import { createContext, useContext } from 'react';
import { NoSelectionProperties } from '../properties-no-selection/no-selection-properties';

export enum PropertyPanels {
  Position = 'Position',
  Layout = 'Layout',
  Fill = 'Fill',
  Typography = 'Typography',
}

export const PropertyPanel = observer(() => {
  const editorState = useEditor();
  const { selectionState, propertiesState } = editorState;

  return (
    <div className="bg-panel fixed top-0 right-0 bottom-0 flex w-[272px] flex-col overflow-hidden">
      <SharePanel />
      <div className="border-bottom" />

      {selectionState.selectedNodeIds.size === 0 ? (
        <div className="py-1">
          <NoSelectionProperties />
        </div>
      ) : (
        // Selection change should unmount the inputs so that side effects run, like committing the value
        <div className="py-1" key={selectionState.timestamp}>
          {propertiesState.nodesPerProperty[PropertyPanels.Position].length > 0 && <PositionProperties />}
          {propertiesState.nodesPerProperty[PropertyPanels.Layout].length > 0 && <LayoutProperties />}
          {propertiesState.nodesPerProperty[PropertyPanels.Typography].length > 0 && <TypographyProperties />}
          {propertiesState.nodesPerProperty[PropertyPanels.Fill].length > 0 && <FillProperty />}

          {/* Props from selected components */}
          <PropsPanel />

          {/* Display custom panels for the selected components */}
          <CustomPanels />
        </div>
      )}
    </div>
  );
});
