import {
  convertLrgbToOklab,
  convertLrgbToRgb,
  convertOklabToLrgb,
  convertRgbToLrgb,
  convertHslToRgb,
  convertRgbToHsl,
} from 'culori/fn';

export interface Hex {
  mode: 'hex';
  hex: number;
  alpha?: number;
}

export interface Rgb {
  mode: 'rgb';
  r: number; // 0-1
  g: number; // 0-1
  b: number; // 0-1
  alpha?: number;
}

export interface Hsl {
  mode: 'hsl';
  h: number; // 0-360
  s: number; // 0-1
  l: number; // 0-1
  alpha?: number;
}

export interface Oklch {
  mode: 'oklch';
  l: number; // 0-1
  c: number; // 0-0.4
  h: number; // 0-360
  alpha?: number;
}

const DEFAULT_VALUE: Oklch = { mode: 'oklch', l: 1, c: 0, h: 0 }; // White

type ColorMode = 'hex' | 'rgb' | 'hsl' | 'oklch';

export interface Color {
  mode: ColorMode;
  value: Oklch; // Every other mode is derived from this Oklch value
  badInput?: boolean;
}

export class ColorUtils {
  static new(rawInput?: string, alpha?: number) {
    const color: Color = { mode: 'hex', value: DEFAULT_VALUE, badInput: false };

    if (rawInput) {
      const parsed = parseColor(rawInput);
      if (parsed) {
        if (parsed.mode === 'oklch') {
          color.mode = 'oklch';
          color.value = parsed;
        } else if (parsed.mode === 'hex') {
          color.mode = 'hex';
          color.value = hexToOklch(parsed);
        } else if (parsed.mode === 'rgb') {
          color.mode = 'rgb';
          color.value = rgbToOklch(parsed);
        } else if (parsed.mode === 'hsl') {
          color.mode = 'hsl';
          color.value = hslToOklch(parsed);
        }
      }
      color.badInput = !parsed;
    }

    if (alpha !== undefined) {
      color.value.alpha = alpha;
    }

    return color;
  }

  static clone(color: Color): Color {
    return { ...color, value: { ...color.value } };
  }

  static isEqual(a: Color, b: Color) {
    // Colors are considered equal if they produce the same hex,
    // including if there is precision loss when converting a decimal alpha to an
    // integer 0-255 hex value – that's where the browser would lose precision too.
    const hexA: Color = { ...a, mode: 'hex' };
    const hexB: Color = { ...b, mode: 'hex' };
    return this.cssString(hexA) === this.cssString(hexB);
  }

  static pretty(color: Color, mode?: Color['mode']): string {
    if (mode === 'hex') return getHexShorthand(oklchToHex(color.value));
    if (mode === 'rgb') return getRgbShorthand(oklchToRgb(color.value));
    if (mode === 'hsl') return getHslShorthand(oklchToHsl(color.value));
    if (mode === 'oklch') return getOklchShorthand(color.value);
    if (color.mode === 'hex') return getHexShorthand(oklchToHex(color.value));
    if (color.mode === 'rgb') return getRgbShorthand(oklchToRgb(color.value));
    if (color.mode === 'hsl') return getHslShorthand(oklchToHsl(color.value));
    if (color.mode === 'oklch') return getOklchShorthand(color.value);

    throw Error('Unexpected: Invalid color mode');
  }

  static cssString(color: Color, mode?: Color['mode']): string {
    if (mode === 'hex') return getHexCssString(oklchToHex(color.value));
    if (mode === 'rgb') return getRgbCssString(oklchToRgb(color.value));
    if (mode === 'hsl') return getHslCssString(oklchToHsl(color.value));
    if (mode === 'oklch') return getOklchCssString(color.value);
    if (color.mode === 'hex') return getHexCssString(oklchToHex(color.value));
    if (color.mode === 'rgb') return getRgbCssString(oklchToRgb(color.value));
    if (color.mode === 'hsl') return getHslCssString(oklchToHsl(color.value));
    if (color.mode === 'oklch') return getOklchCssString(color.value);

    throw Error('Unexpected: Invalid color mode');
  }
}

/* * * * * * * * * * * *
 *         Hex         *
 * * * * * * * * * * * */

/**
 * Parses the following hex-like formats:
 * - "a" → `0xAAAAAA`
 * - "ab" → `0xABABAB`
 * - "abc" → `0xAABBCC`
 * - "abcd" → `0xAABBCCDD, alpha: 0.867`
 * - "abcdef" → `0xABCDEF`
 * - "abcdefwhoops" → `0xABCDEF`
 * - "abcdef12" → `0xABCDEF, alpha: 0.07`
 */
function parseHex(rawInput: string): Hex | undefined {
  const hexRegExp = /^#?([0-9a-f]{8}|[0-9a-f]{1,6})/i;
  const matches = rawInput.trim().match(hexRegExp);
  let match = matches?.[1];
  if (!match) return;
  let alpha;

  if (match.length <= 2) {
    const hex = parseInt(match.repeat(6 / match.length), 16);
    return { mode: 'hex', hex, alpha };
  }

  if (match.length <= 4) {
    const [r, g, b, a] = match;
    const hex = parseInt(r! + r! + g! + g! + b! + b!, 16);
    alpha ??= a === undefined ? a : parseInt(a + a, 16) / 255;
    return { mode: 'hex', hex, alpha };
  }

  // Ignore 5-digit hexes, they can't be interpreted predictibly
  if (match.length === 5) return;

  const rgb = match.substring(0, 6);
  const a = match.substring(6, 8);
  const hex = parseInt(rgb, 16);
  alpha ??= a ? parseInt(a, 16) / 255 : undefined;
  return { mode: 'hex', hex, alpha };
}

function getHexShorthand(color: Hex) {
  const alpha = color.alpha === undefined ? 100 : Math.round(color.alpha * 1000) / 10;
  return `${toHexString(color.hex, 6)} / ${alpha}%`;
}

function getHexCssString(color: Hex) {
  if (color.alpha === undefined || color.alpha === 1) {
    return '#' + toHexString(color.hex, 6);
  }

  const alpha = Math.round(color.alpha * 255);
  return '#' + toHexString(color.hex, 6) + toHexString(alpha, 2);
}

function toHexString(number: number, length: number) {
  return number.toString(16).toUpperCase().padStart(length, '0');
}

export function hexToRgb({ hex, alpha }: Hex): Rgb {
  return {
    mode: 'rgb',
    r: ((hex >> 16) & 0xff) / 255,
    g: ((hex >> 8) & 0xff) / 255,
    b: (hex & 0xff) / 255,
    alpha,
  };
}

/* * * * * * * * * * * *
 *         RGB         *
 * * * * * * * * * * * */

function getRgbShorthand(color: Rgb) {
  const alpha = color.alpha === undefined ? 100 : Math.round(color.alpha * 1000) / 10;
  const { r, g, b } = color;
  return `${Math.round(r * 255)} ${Math.round(g * 255)} ${Math.round(b * 255)} / ${alpha}%`;
}

function getRgbCssString(color: Rgb) {
  const { r, g, b } = color;
  const rgbString = `${Math.round(r * 255)} ${Math.round(g * 255)} ${Math.round(b * 255)}`;

  if (color.alpha === undefined || color.alpha === 1) {
    return `rgb(${rgbString})`;
  }

  return `rgb(${rgbString} / ${color.alpha * 100}%)`;
}

export function rgbToHex({ r, g, b, alpha }: Rgb): Hex {
  const bits = [r, g, b].map((b) => toHexString(b * 255, 2));
  return {
    mode: 'hex',
    hex: parseInt(bits.join(''), 16),
    alpha,
  };
}

/* * * * * * * * * * * *
 *         HSL         *
 * * * * * * * * * * * */

function getHslCssString(color: Hsl) {
  const { h, s, l } = color;

  const hslString = `${Math.round(h * 10) / 10}deg ${Math.round(s * 1000) / 10}% ${Math.round(l * 1000) / 10}%`;

  if (color.alpha === undefined || color.alpha === 1) {
    return `hsl(${hslString})`;
  }

  return `hsl(${hslString} / ${color.alpha * 100}%)`;
}

function hslToRgb({ h, s, l, alpha }: Hsl): Rgb {
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r = 0;
  let g = 0;
  let b = 0;

  if (h === 360 || (h >= 0 && h < 60)) {
    r = c;
    g = x;
    b = 0;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (h >= 300 && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  return {
    mode: 'rgb',
    r: clamp(Math.round((r + m) * 255) / 255),
    g: clamp(Math.round((g + m) * 255) / 255),
    b: clamp(Math.round((b + m) * 255) / 255),
    alpha,
  };
}

function hslToHex(hsl: Hsl): Hex {
  return rgbToHex(hslToRgb(hsl));
}

export function rgbToHsl({ r, g, b, alpha }: Rgb): Hsl {
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const d = max - min;
  let h = 0;
  const l = (max + min) / 2;
  const s = d === 0 ? 0 : d / (1 - Math.abs(2 * l - 1));

  if (max !== min) {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h *= 60;
  }

  return { mode: 'hsl', h, s, l, alpha };
}

function hexToHsl(hex: Hex): Hsl {
  return rgbToHsl(hexToRgb(hex));
}

function getHslShorthand(color: Hsl) {
  const alpha = color.alpha === undefined ? 100 : Math.round(color.alpha * 1000) / 10;
  return `${Math.round(color.h * 10) / 10} ${Math.round(color.s * 1000) / 10}% ${Math.round(color.l * 1000) / 10}% / ${alpha}%`;
}

/* * * * * * * * * * * *
 *        Oklch        *
 * * * * * * * * * * * */

function getOklchShorthand(color: Oklch) {
  const alpha = color.alpha === undefined ? 100 : Math.round(color.alpha * 1000) / 10;
  return `${Math.round(color.l * 1000) / 10}% ${Math.round(color.c * 1000 * 2.5) / 10}% ${Math.round(color.h * 10) / 10} / ${alpha}%`;
}

function getOklchCssString(color: Oklch) {
  const { l, c, h } = color;

  const oklchString = `${Math.round(l * 1000) / 10}% ${Math.round(c * 1000 * 2.5) / 10}% ${Math.round(h * 10) / 10}deg`;

  if (color.alpha === undefined || color.alpha === 1) {
    return `oklch(${oklchString})`;
  }

  return `oklch(${oklchString} / ${color.alpha * 100}%)`;
}

export function oklchToHsl(oklch: Oklch): Hsl {
  const { l, c, h } = oklch;

  const hRad = h * (Math.PI / 180);
  const a = c * Math.cos(hRad);
  const b = c * Math.sin(hRad);
  const oklab = { l, a, b };

  const lrgb = convertOklabToLrgb(oklab);
  const rgb = convertLrgbToRgb({
    r: clamp(lrgb.r),
    g: clamp(lrgb.g),
    b: clamp(lrgb.b),
  });
  const hsl = convertRgbToHsl(rgb);

  return {
    mode: 'hsl',
    h: Math.round(hsl.h! * 10) / 10,
    s: Math.round(clamp(hsl.s) * 1000) / 1000,
    l: Math.round(clamp(hsl.l) * 1000) / 1000,
    alpha: oklch.alpha,
  };
}

export function hslToOklch(hsl: Hsl): Oklch {
  // If the saturation is 0 (any grayscale color), the hue gets lost in the conversion process.
  // We add a tiny amount to the saturation to keep the hue present.
  if (hsl.s === 0) {
    hsl.s = 0.0001;
  }

  // If the lightness is 0 or 1, the chroma and hue get lost in the conversion process.
  // We clamp it to keep them present.
  // Note: In the black area at the bottom of the color canvas, some specific values cause the
  // puck to twitch to the side when dragging. This will need a better fix.
  hsl.l = clamp(hsl.l, 0.000000001, 0.9999);

  const rgb = convertHslToRgb(hsl);
  const lrgb = convertRgbToLrgb(rgb);
  const oklab = convertLrgbToOklab(lrgb);

  const { l, a, b } = oklab;
  const c = Math.sqrt(a * a + b * b);
  let h = Math.atan2(b, a) * (180 / Math.PI);

  // Keep the OKLCH hue slightly above or below the cutoff of its HSL
  // equivalent so that we can distinguish between 0 and 360 in HSL
  if (hsl.h === 0) {
    h = h + 0.0001;
  } else if (hsl.h === 360) {
    h = h - 0.0001;
  }

  h = ((h % 360) + 360) % 360;

  return {
    mode: 'oklch',
    l: clamp(l),
    c: clamp(c),
    h,
    alpha: hsl.alpha,
  };
}

export function oklchToRgb(oklch: Oklch): Rgb {
  return hslToRgb(oklchToHsl(oklch));
}

export function rgbToOklch(rgb: Rgb): Oklch {
  return hslToOklch(rgbToHsl(rgb));
}

export function oklchToHex(oklch: Oklch): Hex {
  return rgbToHex(hslToRgb(oklchToHsl(oklch)));
}

export function hexToOklch(hex: Hex): Oklch {
  return hslToOklch(rgbToHsl(hexToRgb(hex)));
}

/* * * * * * * * * * * *
 *        Misc         *
 * * * * * * * * * * * */

function clamp(number: number, min?: number, max?: number): number;
function clamp(number: undefined, min?: number, max?: number): undefined;
function clamp(number: number | undefined, min?: number, max?: number): number | undefined;
function clamp(number: number | undefined, min = 0, max = 1) {
  return typeof number === 'undefined' ? undefined : Math.max(min, Math.min(number, max));
}

export function parseColor(rawInput: string): Hsl | Rgb | Hex | Oklch | undefined {
  const cleaned = rawInput.trim().toLowerCase();

  // We isolate any wrapping mode function (rgb(), hsl(), oklch()...)
  const functionMatch = cleaned.match(/^(rgb|hsl|oklch)a?\s*\((.*)\)$/);
  const mode = functionMatch?.[1] as 'rgb' | 'hsl' | 'oklch' | undefined;
  const content = functionMatch ? functionMatch[2] : cleaned;

  // We remove any slash and split the content into strings based on spaces or commas
  const strings =
    content
      ?.replace('/', '')
      .split(/(?:\s*,\s*|\s+)/)
      .filter(Boolean) ?? [];

  let mainStrings: string[] = [];
  let alphaString: string | undefined;

  // ['100', '100', '100', '50%'] or ['#000', '50%'] for example
  if (strings.length === 4 || strings.length === 2) {
    mainStrings = strings.slice(0, -1);
    alphaString = strings[strings.length - 1];
  } else {
    // No alpha found
    mainStrings = strings;
  }

  // We then turn strings into parts, which have isolated value and unit
  const [a, b, c] = mainStrings.map(parsePart);

  const alphaPart = alphaString ? parsePart(alphaString) : undefined;
  let alpha: number | undefined;

  if (alphaPart) {
    if (alphaPart.unit === '' && alphaPart.value <= 1) {
      alpha = alphaPart.value;
    } else {
      alpha = alphaPart.value / 100;
    }
    alpha = clamp(alpha);
  }

  // If only one main part was found, we are in a hex color
  if (!b && !c) {
    const hex = parseHex(rawInput);
    // The hex itself might contain an alpha, like 66 in #ff000066. The outer alpha we parsed takes precedence.
    return hex ? { ...hex, mode: 'hex', alpha: alpha ?? hex.alpha } : undefined;
  }

  // If not all three parts are defined, something went wrong
  if (!a || !b || !c) {
    return;
  }

  // Finally, assignColor is called to determine the color mode and create the color object
  return assignColor(a, b, c, alpha, mode);
}

function assignColor(
  a: { value: number; unit: string },
  b: { value: number; unit: string },
  c: { value: number; unit: string },
  alpha?: number,
  mode?: 'oklch' | 'hsl' | 'rgb'
): Hsl | Rgb | Hex | Oklch | undefined {
  if (
    (mode === undefined || mode === 'oklch') &&
    ((a.unit === '%' && b.unit === '' && c.unit === '') ||
      (a.unit === '%' && b.unit === '' && c.unit === 'deg') ||
      (a.unit === '%' && b.unit === '%' && c.unit === '') ||
      (a.unit === '%' && b.unit === '%' && c.unit === 'deg') ||
      (a.unit === '' && b.unit === '' && c.unit === 'deg') ||
      (a.unit === '' && b.unit === '' && c.unit === '' && a.value <= 1 && b.value > 0 && b.value <= 0.4))
  ) {
    const l = clamp(a.unit === '%' ? a.value / 100 : a.value);
    const c_ = clamp(b.unit === '%' ? (b.value * 0.4) / 100 : b.value);
    const h = c.value;

    return { mode: 'oklch', l, c: c_, h, alpha };
  }

  if (
    (mode === undefined || mode === 'hsl') &&
    ((a.unit === '' && b.unit === '%' && c.unit === '%') ||
      (a.unit === 'deg' && b.unit === '%' && c.unit === '%') ||
      (a.unit === 'deg' && b.unit === '' && c.unit === ''))
  ) {
    const h = a.value;
    const s = clamp(b.unit === '%' ? b.value / 100 : b.value);
    const l = clamp(c.unit === '%' ? c.value / 100 : c.value);

    return { mode: 'hsl', h, s, l, alpha };
  }

  if (
    (mode === undefined || mode === 'rgb') &&
    a.unit === '' &&
    b.unit === '' &&
    c.unit === '' &&
    a.value <= 255 &&
    b.value <= 255 &&
    c.value <= 255
  ) {
    const r = clamp(a.value / 255);
    const g = clamp(b.value / 255);
    const b_ = clamp(c.value / 255);

    return { mode: 'rgb', r, g, b: b_, alpha };
  }
}

function parsePart(raw: string) {
  const match = raw.match(/^(-?\d*\.?\d+)(%|deg)?$/);
  if (!match) return undefined;

  const unit = match[2] ?? '';
  const value = parseFloat(match[1] ?? '');

  return { value, unit };
}
