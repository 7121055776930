import { ulid } from 'ulid';
import { findPngDpi } from './find-png-dpi';

export type ImageInfo = {
  /** Generate a unique ID that can be used to track this file throughout the paste process */
  threadedId: string;
  width: number;
  height: number;
  dpi: number;
  pixelDensity: number;
  tempImageUrl: string;
  file: File;
};
export async function getImageInfo(imageFile: File): Promise<ImageInfo> {
  const imageUrl = URL.createObjectURL(imageFile);

  // ------ DPI ------ //
  let dpi = 72;
  if (imageFile.type.includes('png')) {
    dpi = (await findPngDpi(imageFile)) ?? 72;
  }
  const pixelDensity = Math.max(Math.floor(dpi / 72), 1);

  // ------ Dimensions ------ //
  // TODO: new Image is slow if you do a lot of them, consider file based parsers
  const testImg = new Image();
  testImg.src = imageUrl;
  const imageDimensions = await new Promise<[number, number]>((resolve, reject) => {
    testImg.onload = () => {
      resolve([testImg.width, testImg.height]);
    };
  });

  return {
    threadedId: ulid(),
    width: imageDimensions[0],
    height: imageDimensions[1],
    dpi,
    pixelDensity,
    tempImageUrl: imageUrl,
    file: imageFile,
  };
}
