import React, { useCallback, useEffect } from 'react';

import {
  ModifierKey,
  Hotkey,
  US_LAYOUT_QWERTY_DARWIN,
  CleanKey,
  DefaultMappingLayout,
  ReversedDefaultMappingLayout,
  NormalizedHotkeyConfig,
  ModifiersKeyInEvent,
  HotkeyConfig,
  HotkeysZoneConfig,
} from './types';
export * from './types';

/**
 * Logging system for the hotkeys module
 */
type LogLevel = 'debug' | 'warn' | 'error' | 'none';
const LOG_LEVELS = { debug: 0, warn: 1, error: 2, none: 3 } as const;

/**
 * Logger class that handles all logging operations with configurable levels
 * @class
 */
class Logger {
  constructor(private level: LogLevel = 'debug') {}
  debug = (...args: unknown[]) => LOG_LEVELS[this.level] <= 0 && console.log('[Hotkeys:Debug]', ...args);
  warn = (...args: unknown[]) => LOG_LEVELS[this.level] <= 1 && console.warn('[Hotkeys:Warn]', ...args);
  error = (...args: unknown[]) => LOG_LEVELS[this.level] <= 2 && console.error('[Hotkeys:Error]', ...args);
  setLevel = (level: LogLevel) => (this.level = level);
}

// Constants and environment checks
export const isNode = typeof window === 'undefined';
export const isWindows = isNode ? false : navigator.userAgent.includes('Windows');
export const Mod = isWindows ? 'Ctrl' : 'Meta';

/**
 * Maps modifier keys to their corresponding event properties
 */
export const modifierMap: Record<ModifierKey, ModifiersKeyInEvent> = {
  Ctrl: 'ctrlKey',
  Alt: 'altKey',
  Shift: 'shiftKey',
  Meta: 'metaKey',
  Mod: isWindows ? 'ctrlKey' : 'metaKey',
};

/**
 * Override map for special keys to ensure consistent behavior
 */
const fixedOverrideByCode: Record<string, string> = {
  Space: 'Space',
};

/**
 * Set of HTML tags that should ignore hotkey events
 */
const ignoredTags = new Set(['INPUT', 'TEXTAREA', 'SELECT', 'DETAILS']);

/**
 * Default function to determine if a keyboard event should be ignored
 */
export const defaultShouldIgnoreEvent = ({ target }: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) =>
  target instanceof HTMLElement && (ignoredTags.has(target.tagName) || target.isContentEditable);

/**
 * Checks if a key is alphanumeric or space
 * @param key - The key to check
 */
const isAlphanumeric = (key: string) => /^(\p{N}|\p{L}|\s)$/u.test(key);

/**
 * Default props for hotkey zones
 */
const defaultProps = {
  tabIndex: -1,
  style: { outline: 'none' },
};

export type KeyboardLayout = Partial<Record<keyof typeof US_LAYOUT_QWERTY_DARWIN, string>>;
export type NormalizedKeyboardLayout = {
  name: string;
  default: DefaultMappingLayout;
  reversed: ReversedDefaultMappingLayout;
};

export interface ZoneComponent<Context, T extends keyof JSX.IntrinsicElements = 'div'>
  extends React.FC<
    React.ComponentProps<T> & {
      onHotkeyMatch?: (hotkey: Hotkey, e: React.KeyboardEvent<HTMLDivElement>) => void;
    }
  > {
  ['$zone']: HotkeysZone<Context>;
}

/**
 * HotkeysManager is the main class for handling keyboard shortcuts in a React application.
 * It provides zone-based hotkey management, keyboard layout support, and context-aware shortcuts.
 *
 * @template ContextState - Type for the optional context state that can be accessed by hotkey handlers
 * @example
 * ```typescript
 * const hotkeys = new HotkeysManager({
 *   layouts: { US: US_LAYOUT_QWERTY },
 *   logLevel: 'warn'
 * });
 *
 * // Create a zone for handling hotkeys
 * const EditorZone = hotkeys.createHotkeysZone({
 *   id: 'editor',
 *   hotkeys: {
 *     save: {
 *       hotkey: 'Mod S',
 *       onKeyDown: (e) => saveDocument()
 *     }
 *   }
 * });
 * ```
 */
export class HotkeysManager<ContextState = undefined> {
  /** Manages keyboard layout mappings and key resolution */
  keyboardLayoutStore: KeyboardLayoutStore<ContextState>;

  /** Maps zone IDs to their corresponding React components */
  hotkeysZoneMap = new Map<string, ZoneComponent<ContextState>>();

  /** Tracks the last handled event to prevent duplicate processing */
  private lastHandledEvent: React.KeyboardEvent | null = null;

  /** List of zones that should receive global hotkey events */
  private globalHotkeysZone: HotkeysZone<ContextState>[] = [];

  /** React context for passing state to hotkey handlers */
  private context = React.createContext<ContextState>(null as unknown as ContextState);

  /** Function to determine if a keyboard event should be ignored */
  private shouldIgnoreEvent: (event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => boolean;

  /** Function to determine if default event behavior should be prevented */
  private shouldPreventDefault: (event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => boolean;

  /** Logger instance for debugging and error reporting */
  readonly logger: Logger;

  /**
   * Creates a new HotkeysManager instance
   * @param options - Configuration options for the hotkeys manager
   * @param options.layouts - Keyboard layout definitions
   * @param options.shouldIgnoreEvent - Function to determine if an event should be ignored
   * @param options.shouldPreventDefault - Function to determine if default event behavior should be prevented
   * @param options.logLevel - Logging verbosity level
   */
  constructor({
    layouts,
    shouldIgnoreEvent = defaultShouldIgnoreEvent,
    shouldPreventDefault = () => false,
    logLevel = 'debug',
  }: {
    layouts: Record<string, KeyboardLayout>;
    shouldIgnoreEvent?: (event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => boolean;
    shouldPreventDefault?: (event: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => boolean;
    logLevel?: LogLevel;
  }) {
    this.logger = new Logger(logLevel);
    this.keyboardLayoutStore = new KeyboardLayoutStore(layouts, this.logger);
    this.createHotkeysZone = this.createHotkeysZone.bind(this);
    this.createIgnoreZone = this.createIgnoreZone.bind(this);
    this.useZoneHotkeys = this.useZoneHotkeys.bind(this);
    this.shouldIgnoreEvent = shouldIgnoreEvent;
    this.shouldPreventDefault = shouldPreventDefault;
  }

  /**
   * React component that provides context state to hotkey zones
   * @param props.children - Child components that will have access to the context
   * @param props.state - Context state to be provided
   */
  HotkeysProvider = ({ children, state }: { children: React.ReactNode; state: ContextState }) => {
    return <this.context.Provider value={state}>{children}</this.context.Provider>;
  };

  /**
   * Creates a handler function for keyboard events in a specific zone
   * @param hotkeyZoneId - ID of the zone this handler is for
   * @param state - Current context state
   * @param options - Additional handler options
   * @returns Event handler function for keyboard events
   * @private
   */
  private createHotkeysHandler(
    hotkeyZoneId: string,
    state: ContextState,
    {
      onHotkeyMatch,
    }: {
      onHotkeyMatch?: (hotkey: Hotkey, e: React.KeyboardEvent<HTMLDivElement>) => void;
    }
  ) {
    return (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (this.lastHandledEvent === event) {
        return;
      }
      const hotkeysZone = this.hotkeysZoneMap.get(hotkeyZoneId);
      if (!hotkeysZone) {
        this.logger.error('Hotkeys zone not found', hotkeyZoneId);
        return;
      }

      const resolvedKey = this.keyboardLayoutStore.resolveKey(event);

      for (let i = 0; i < hotkeysZone.$zone.registeredHotkeysArray.length; i++) {
        const hotkey = hotkeysZone.$zone.registeredHotkeysArray[i];

        // assert all modifiers are pressed
        const modifiersPressed = Object.entries(modifierMap).every(
          ([_modifier, modifierKey]) => event[modifierKey] == hotkey?.[modifierKey]
        );

        if (
          hotkey?.key === resolvedKey &&
          modifiersPressed &&
          !this.shouldIgnoreEvent(event) &&
          !hotkeysZone.$zone.config.shouldIgnoreEvent?.(event)
        ) {
          if (!hotkey) {
            throw new Error('Hotkey not found');
          }

          const isKeydown = event.type === 'keydown';
          const isKeyup = event.type === 'keyup';

          // Execute hotkey handlers
          this.lastHandledEvent = event;
          if (isKeydown) {
            hotkey.onKeyDown?.(event, state);
          } else if (isKeyup) {
            hotkey.onKeyUp?.(event, state);
          }
          onHotkeyMatch?.(hotkey.hotkey, event);

          const shouldPreventDefault = hotkeysZone.$zone.config.shouldPreventDefault || this.shouldPreventDefault;

          if (shouldPreventDefault(event)) {
            event.preventDefault();
          }

          if (hotkeysZone.$zone.config.shouldStopPropagation?.(event)) {
            event.stopPropagation();
          }
        }
      }
    };
  }

  /**
   * Hook to access and update hotkeys within a zone
   * @param zoneId - ID of the zone to access
   * @returns Tuple containing current hotkeys and update function
   * @throws Error if zone is not found
   * @example
   * ```typescript
   * const [hotkeys, updateHotkey] = hotkeysManager.useZoneHotkeys('editor');
   * ```
   */
  useZoneHotkeys(zoneId: string) {
    const zone = this.hotkeysZoneMap.get(zoneId);
    if (!zone) {
      throw new Error(`Zone with id ${zoneId} not found`);
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [_, forceRerender] = React.useReducer(() => ({}), {});

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const updateHotkey = useCallback(
      (hotkeyId: string, hotkey: Hotkey) => {
        zone.$zone.updateHotkey(hotkeyId, hotkey);
        forceRerender();
      },
      [zone]
    );

    return [zone.$zone.registeredHotkeys, updateHotkey] as const;
  }

  /**
   * Creates a new hotkeys zone component
   * @param zoneConfig - Configuration for the zone including hotkeys and behavior
   * @returns React component that establishes a hotkey zone
   * @example
   * ```typescript
   * const EditorZone = hotkeysManager.createHotkeysZone({
   *   id: 'editor',
   *   hotkeys: {
   *     save: { hotkey: 'Mod S', onKeyDown: handleSave }
   *   }
   * });
   * ```
   */
  createHotkeysZone(zoneConfig: HotkeysZoneConfig<ContextState>) {
    const HotkeysZoneComponent: ZoneComponent<ContextState> = ({ onHotkeyMatch, ...props }) => {
      const context = React.useContext(this.context);
      useEffect(() => {
        if (zoneConfig.isGlobal) {
          const handler = (e: KeyboardEvent) => {
            this.createHotkeysHandler(zoneConfig.id, context, { onHotkeyMatch })(e as any);
          };
          window.addEventListener('keydown', handler);
          window.addEventListener('keyup', handler);
          return () => {
            window.removeEventListener('keydown', handler);
            window.removeEventListener('keyup', handler);
          };
        }
      });
      return (
        <div
          {...defaultProps}
          onKeyDown={this.createHotkeysHandler(zoneConfig.id, context, { onHotkeyMatch })}
          onKeyUp={this.createHotkeysHandler(zoneConfig.id, context, { onHotkeyMatch })}
          {...props}
          data-hotkeys-zone={zoneConfig.id}
        />
      );
    };
    HotkeysZoneComponent.displayName = 'HotkeysZone';
    HotkeysZoneComponent['$zone'] = new HotkeysZone(this.keyboardLayoutStore, zoneConfig);

    if (zoneConfig.isGlobal) {
      this.globalHotkeysZone.push(HotkeysZoneComponent['$zone']);
    }

    this.hotkeysZoneMap.set(zoneConfig.id, HotkeysZoneComponent);
    return HotkeysZoneComponent;
  }

  /**
   * Creates a zone that ignores all hotkey events
   * @returns React component that prevents hotkey processing
   * @example
   * ```typescript
   * const IgnoreZone = hotkeysManager.createIgnoreZone();
   * <IgnoreZone>
   *   <input type="text" /> // Hotkeys won't trigger here
   * </IgnoreZone>
   * ```
   */
  createIgnoreZone() {
    return (props: React.ComponentProps<'div'>) => {
      return <div {...defaultProps} {...props} data-hotkeys-ignore />;
    };
  }
}

/**
 * HotkeysZone manages a collection of keyboard shortcuts within a specific context or area.
 * It handles the registration, normalization, and updating of hotkeys for a zone.
 *
 * @template ContextState - Type for the optional context state that can be accessed by hotkey handlers
 * @example
 * ```typescript
 * const zone = new HotkeysZone(keyboardLayoutStore, {
 *   id: 'editor',
 *   hotkeys: {
 *     save: {
 *       hotkey: 'Mod S',
 *       name: 'Save',
 *       onKeyDown: (e) => saveDocument()
 *     }
 *   }
 * });
 * ```
 */
export class HotkeysZone<ContextState> {
  /** Configuration for this hotkeys zone */
  config: HotkeysZoneConfig<ContextState>;

  /** Map of registered hotkeys by their ID */
  registeredHotkeys: Record<string, NormalizedHotkeyConfig<ContextState>> = {};

  /** Array of registered hotkeys for faster iteration */
  registeredHotkeysArray: NormalizedHotkeyConfig<ContextState>[] = [];

  /**
   * Creates a new HotkeysZone instance
   * @param keyboardLayoutStore - Store managing keyboard layouts and key resolution
   * @param config - Configuration for this zone including hotkeys and behavior
   */
  constructor(
    private keyboardLayoutStore: KeyboardLayoutStore<ContextState>,
    config: HotkeysZoneConfig<ContextState>
  ) {
    this.config = config;
    Object.entries(config.hotkeys).forEach(([id, hotkeyConfig]) => {
      this.registerHotkey(id, hotkeyConfig);
    });
  }

  /**
   * Registers a new hotkey in this zone
   * @param id - Unique identifier for the hotkey
   * @param hotkeyConfig - Configuration for the hotkey including key combination and handlers
   * @throws Error if hotkey ID is already registered
   * @example
   * ```typescript
   * zone.registerHotkey('save', {
   *   hotkey: 'Mod S',
   *   name: 'Save',
   *   onKeyDown: (e) => saveDocument()
   * });
   * ```
   */
  registerHotkey(id: string, hotkeyConfig: HotkeyConfig<ContextState>) {
    if (this.registeredHotkeys[id]) {
      throw new Error('Hotkey already registered');
    }
    const hotkeysArray = Array.isArray(hotkeyConfig.hotkey) ? hotkeyConfig.hotkey : [hotkeyConfig.hotkey];
    hotkeysArray.forEach((hotkey) => {
      this.registeredHotkeys[id] = this.normalizeHotkey(hotkey);
      const normalizedHotkey = this.registeredHotkeys[id];
      normalizedHotkey.id = id;
      normalizedHotkey.name = hotkeyConfig.name;
      normalizedHotkey.onKeyDown = hotkeyConfig.onKeyDown;
      normalizedHotkey.onKeyUp = hotkeyConfig.onKeyUp;
      this.registeredHotkeysArray.push(normalizedHotkey);
    });
  }

  /**
   * Updates an existing hotkey's key combination
   * @param hotkeyId - ID of the hotkey to update
   * @param hotkey - New key combination
   * @returns Updated hotkey configuration
   * @throws Error if hotkey ID is not found
   * @example
   * ```typescript
   * zone.updateHotkey('save', 'Mod Shift S');
   * ```
   */
  updateHotkey(hotkeyId: string, hotkey: Hotkey) {
    const hotkeyToUpdate = this.registeredHotkeys[hotkeyId];
    if (!hotkeyToUpdate) {
      throw new Error('Hotkey not found');
    }

    if (hotkeyToUpdate.hotkey === hotkey) {
      return;
    }
    const hotkeyToReset = this.findHotkey(hotkey);
    Object.assign(hotkeyToUpdate, this.normalizeHotkey(hotkey), { isCustom: true });

    if (hotkeyToReset) {
      Object.assign(hotkeyToReset, this.normalizeHotkey(''));
    }

    return hotkeyToUpdate;
  }

  /**
   * Finds a hotkey configuration by its key combination
   * @param hotkey - Key combination to search for
   * @returns Matching hotkey configuration or undefined
   * @private
   */
  private findHotkey(hotkey: Hotkey) {
    return this.registeredHotkeysArray.find((zoneHotkey) => hotkey === zoneHotkey.hotkey);
  }

  /**
   * Normalizes a hotkey string into a structured configuration
   * Splits the hotkey string into modifier keys and main key
   * @param hotkey - Hotkey string to normalize (e.g., "Mod S", "Ctrl Shift K")
   * @returns Normalized hotkey configuration
   * @private
   */
  private normalizeHotkey(hotkey: Hotkey): NormalizedHotkeyConfig<ContextState> {
    const parts = hotkey.split(' ');

    return parts.reduce((config, key) => {
      if (this.keyboardLayoutStore.isModifier(key)) {
        config[modifierMap[key]] = true;
      } else {
        config.key = key as CleanKey;
      }
      return config;
    }, this.createEmptyHotkeyConfig(hotkey));
  }

  private createEmptyHotkeyConfig(hotkey: Hotkey): NormalizedHotkeyConfig<ContextState> {
    return {
      hotkey,
      key: undefined,
      altKey: false,
      shiftKey: false,
      metaKey: false,
      ctrlKey: false,
    } as unknown as NormalizedHotkeyConfig<ContextState>;
  }
}

/**
 * KeyboardLayoutStore manages keyboard layouts and key resolution for the hotkeys system.
 * It handles different keyboard layouts, native keyboard mapping, and key normalization.
 *
 * @template ContextState - Type for the optional context state that can be accessed by hotkey handlers
 * @example
 * ```typescript
 * const layouts = {
 *   US: US_LAYOUT_QWERTY_DARWIN,
 *   UK: UK_LAYOUT_QWERTY
 * };
 * const store = new KeyboardLayoutStore(layouts);
 * ```
 */
export class KeyboardLayoutStore<ContextState> {
  /**
   * The default layout that's used when defining hotkeys during development.
   * @default US_LAYOUT_QWERTY_DARWIN
   */
  defaultLayout: NormalizedKeyboardLayout;

  /** Collection of all available keyboard layouts */
  layouts: NormalizedKeyboardLayout[] = [];

  /** Native keyboard layout mapping from the browser API */
  nativeKeyboardLayoutMap?: NativeKeyboardMap;

  /** Logger instance for debugging and error reporting */
  private logger: Logger;

  /**
   * Creates a new KeyboardLayoutStore instance
   * @param layouts - Object containing keyboard layout definitions
   * @param logger - Optional logger instance for debugging
   */
  constructor(layouts?: Record<string, KeyboardLayout>, logger?: Logger) {
    this.logger = logger || new Logger('debug');
    this.loadNativeKeyboardMap();

    if (!layouts) {
      this.layouts = [this.normalizeLayout('US_LAYOUT_QWERTY_DARWIN', US_LAYOUT_QWERTY_DARWIN)];
    } else {
      this.layouts = Object.entries(layouts).map(([name, layout]) => this.normalizeLayout(name, layout));
    }

    this.defaultLayout = this.layouts[0]!;
  }

  /**
   * Loads the native keyboard layout mapping from the browser API
   * @returns Promise that resolves to the native keyboard map
   */
  async loadNativeKeyboardMap() {
    this.nativeKeyboardLayoutMap = await navigator.keyboard?.getLayoutMap();
    return this.nativeKeyboardLayoutMap;
  }

  /**
   * Gets the target key index based on modifier keys
   * @param event - Keyboard event to analyze
   * @returns Index for key lookup in layout mapping
   * @private
   */
  private getTargetKeyIndex({ altKey, shiftKey }: React.KeyboardEvent | KeyboardEvent) {
    // @ts-expect-error
    return isWindows ? +shiftKey : (altKey << 1) | shiftKey;
  }

  /**
   * Resolves a keyboard event to a normalized hotkey configuration
   * @param event - Keyboard event to resolve
   * @returns Normalized hotkey configuration
   */
  resolveHotkeyFromEvent(event: React.KeyboardEvent | KeyboardEvent) {
    const resolvedKey = this.resolveKey(event);
    const normalizedHotkey: NormalizedHotkeyConfig<ContextState> = {
      id: '',
      isCustom: true,
      key: resolvedKey as CleanKey,
      altKey: event.altKey,
      shiftKey: event.shiftKey,
      metaKey: event.metaKey,
      ctrlKey: event.ctrlKey,
      hotkey: [
        ...new Set(
          [
            event.ctrlKey && 'Ctrl',
            event.shiftKey && 'Shift',
            event.altKey && 'Alt',
            event.metaKey && 'Meta',
            !['Control', 'Shift', 'Alt', 'Meta'].includes(event.key) && resolvedKey,
          ].filter(Boolean)
        ),
      ].join(' '),
    };
    return normalizedHotkey;
  }

  /**
   * Resolves a keyboard event to a consistent key value across different layouts
   */
  resolveKey(event: React.KeyboardEvent | KeyboardEvent) {
    return (
      fixedOverrideByCode[event.code] ??
      this.resolveFromNativeMap(event) ??
      this.resolveFromLoadedLayouts(event) ??
      this.resolveFromFallback(event) ??
      event.key
    );
  }

  /**
   * Attempts to resolve key using native keyboard map
   * @private
   */
  private resolveFromNativeMap(event: React.KeyboardEvent | KeyboardEvent) {
    this.logger.debug('1. Trying to resolve to native keyboard map.');
    const resolvedKey = this.nativeKeyboardLayoutMap?.get(event.code);

    if (resolvedKey) {
      this.logger.debug(`Native keyboard map found, resolving to ${resolvedKey} on layout ${this.defaultLayout.name}`);
    }

    return resolvedKey;
  }

  /**
   * Attempts to resolve key using loaded keyboard layouts
   * @private
   */
  private resolveFromLoadedLayouts(event: React.KeyboardEvent | KeyboardEvent) {
    this.logger.debug('2. Native keyboard map not found, trying to resolve to loaded layouts.');

    for (const layout of this.layouts) {
      const keyCodeExpectedKeys = layout?.default[event.code as keyof DefaultMappingLayout];
      const targetIndex = this.getTargetKeyIndex(event);

      if (keyCodeExpectedKeys?.[targetIndex] === event.key) {
        this.logger.debug(`Resolved to ${keyCodeExpectedKeys[0]} in ${layout?.name}`);
        return keyCodeExpectedKeys[0];
      }
    }

    return null;
  }

  /**
   * Attempts to resolve key using fallback algorithm
   * @private
   */
  private resolveFromFallback(event: React.KeyboardEvent | KeyboardEvent) {
    this.logger.debug('3. No layout found, Using fallback algorithm');

    if (!isAlphanumeric(event.key)) return null;

    this.logger.debug('Alphanumeric key found, Resolving using code');
    const resolvedKey = this.defaultLayout.default[event.code as keyof DefaultMappingLayout]?.[0];

    if (resolvedKey) {
      this.logger.debug(`Resolved to ${resolvedKey} using code`);
    }

    return resolvedKey;
  }

  /**
   * Checks if a key is a modifier key
   * @param key - Key to check
   * @returns True if the key is a modifier key
   */
  isModifier(key: string): key is ModifierKey {
    return !!modifierMap[key as ModifierKey];
  }

  /**
   * Checks if a key is a valid clean key in the current layout
   * @param key - Key to check
   * @returns True if the key is a valid clean key
   */
  isKey(key: string | undefined): key is CleanKey {
    return !!this.defaultLayout.reversed[key as unknown as CleanKey];
  }

  /**
   * Adds a new keyboard layout to the store
   * @param id - Unique identifier for the layout
   * @param layout - Keyboard layout definition
   */
  loadLayout(id: string, layout: KeyboardLayout) {
    const normalizedLayout = this.normalizeLayout(id, layout);
    this.layouts.push(normalizedLayout);
  }

  /**
   * Sets the default keyboard layout
   * @param id - ID of the layout to set as default
   * @throws Error if layout ID is not found
   */
  setDefaultLayout(id: string) {
    const foundLayout = this.layouts.find((layout) => layout.name === id);
    if (!foundLayout) {
      this.logger.error(`Layout with id ${id} not found`);
      throw new Error(`Layout with id ${id} not found`);
    }
    this.defaultLayout = foundLayout;
  }

  /**
   * Normalizes a keyboard layout into the internal format
   * @param name - Name of the layout
   * @param layout - Raw layout definition
   * @returns Normalized keyboard layout
   * @private
   */
  private normalizeLayout(name: string, layout: KeyboardLayout): NormalizedKeyboardLayout {
    return {
      name,
      default: layout,
      reversed: Object.entries(layout).reduce(
        (acc, curr) => {
          // @ts-expect-error
          acc[curr[1][0]] = curr[0];
          return acc;
        },
        {} as Record<string, string>
      ),
    } as unknown as NormalizedKeyboardLayout;
  }
}

interface NativeKeyboardMap {
  get(key: string): string;
  entries(): IterableIterator<[string, string]>;
  keys(): IterableIterator<string>;
  values(): IterableIterator<string>;
  size: number;
  forEach(callbackfn: (value: string, key: string, map: NativeKeyboardMap) => void, thisArg?: any): void;
  has(key: string): boolean;
  get(key: string): string;
}

declare let navigator: Navigator & {
  keyboard?: {
    getLayoutMap(): Promise<NativeKeyboardMap>;
  };
};
