import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { assert } from '../../assert';

/** Uses a MutationObserver -> raf -> look up sizes and positions of the DOM elements and request updates for the nodes with the new data */
export const NodeDomMutationObserver = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { cameraState, treeUtils, fileState, fontState } = editorState;

    if (!cameraState.cameraEl) {
      return;
    }

    // #region --- Observer wire up
    const obs = new MutationObserver((mutationRecords) => {
      // Find which node changed
      for (const record of mutationRecords) {
        let target: HTMLElement | null = record.target as HTMLElement;
        if (record.type === 'characterData') {
          target = record.target.parentElement;
        }

        const nodeId = target?.closest('[data-node-id]')?.getAttribute('data-node-id');
        if (nodeId && nodeId !== treeUtils.rootNode.id) {
          // Request an update for this node
          const node = treeUtils.getNode(nodeId);
          assert(node);

          // Request a new measurement for this node
          fileState.measureNodePositionAndSize.requestMeasurement(node);
        }
      }
    });
    obs.observe(cameraState.cameraEl, {
      childList: true, // observe direct children
      subtree: true, // and lower descendants too
      attributes: true, // attribute changes
      characterData: true, // and text changes
    });
    // #endregion --- Observer wire up

    // #region --- transitionEnd listener
    /** After transitions, resample dimensions */
    function handleTransitionEnd(e: TransitionEvent) {
      const nodeId = (e.target as HTMLElement)?.closest('[data-node-id]')?.getAttribute('data-node-id');
      if (typeof nodeId === 'string' && nodeId !== treeUtils.rootNode.id) {
        const node = treeUtils.getNode(nodeId);
        assert(node);
        fileState.measureNodePositionAndSize.requestMeasurement(node);
      }
    }
    cameraState.cameraEl.addEventListener('transitionend', handleTransitionEnd);
    // #endregion --- transtiionEnd listener

    // Clean up
    return () => {
      obs.disconnect();
      cameraState.cameraEl?.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [editorState, editorState.cameraState.cameraEl]);

  return null;
});
