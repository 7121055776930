import { appleSystemFontStyles } from './font-styles';
import { Font, FontBook } from './FontState';

export const BUILT_IN_FAMILIES: FontBook = new Map();

BUILT_IN_FAMILIES.set('UI Sans-Serif', new Map());
BUILT_IN_FAMILIES.set('UI Monospace', new Map());

for (const style in appleSystemFontStyles) {
  BUILT_IN_FAMILIES.get('UI Sans-Serif')!.set(style, { family: 'UI Sans-Serif', style });
}

for (const style in ['Regular', 'Regular Italic', 'Bold', 'Bold Italic']) {
  BUILT_IN_FAMILIES.get('UI Monospace')!.set(style, { family: 'UI Monospace', style });
}

export const DEFAULT_FONT = BUILT_IN_FAMILIES.get('UI Sans-Serif')!.get('Regular')!;

export function isBuiltInFamily(family: string) {
  return family === 'UI Sans-Serif' || family === 'UI Monospace';
}
