import { Type, type Static } from '@sinclair/typebox';
import { NodeRelationshipsKeys } from './file-schema';
import { TreeNodeSchema } from './tree-node-schema';

/** A portable part of a tree, useful for copy/paste, cloning, or passing around part of a tree between files */
export const TempTreeSchema = Type.Object({
  id: Type.String(),
  /** Since we don't necessarily have a root node, we store the top level IDs */
  topLevelNodeIds: Type.Array(Type.String()),
  /** The nodes in the partial tree */
  nodes: Type.Record(Type.String(), TreeNodeSchema),
  /** Parent to child index – we store these explicitly for temporary usage since it's easier to use when cloning the tree back into the File than indexing relationship strings */
  parentToChildrenIndex: Type.Record(Type.String(), Type.Array(Type.String())),
  /** Stores the node IDs before they were cloned to the new IDs */
  oldIdToNewIdMap: Type.Record(Type.String(), Type.String()),
});

export type TempTreeType = Static<typeof TempTreeSchema>;
