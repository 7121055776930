import { useVirtualizer } from '@tanstack/react-virtual';
import { observer } from 'mobx-react-lite';
import memoize from 'lodash-es/memoize';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useEditor } from '../editor-context';
import { PropertyPanels } from '../properties/property-panel';
import { Field } from '../../components/field';
import { baseInputProps, defaultPointerDownHandler } from '../../components/input';
import { flushSync } from 'react-dom';
import { FAMILIES_TO_SKIP_PREVIEW_IMAGE } from './families-to-skip-preview-image';
import { getFontCss } from '../fonts/get-font-css';
import { DEFAULT_FONT } from '../fonts/built-in-fonts';
import { Button } from '../../components/button';
import { API_ADDRESS } from '../../root/api-address';

/**
 * README
 * - Focus the search input when the popover opens
 * - A virtual focus is maintained, which is the highlighted list item.
 * - Arrow keys in the input move the highlight up and down the list
 * - Enter selects the highlighted item.
 */

interface TypefacePopoverProps {
  value?: string;
  close: () => void;
}

export const TypefacePopover = observer(function TypefacePopoverImpl({ value = '', close }: TypefacePopoverProps) {
  const keyboardEventRef = useRef<React.KeyboardEvent | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { fontState, propertiesState } = useEditor();
  const [inputValue, setInputValue] = useState(value);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredFamilies, setFilteredFamilies] = useState(() =>
    getFilteredFamilyNames(searchTerm, fontState.sortedFamilyNames)
  );

  /** Initialize highlight index to the current font value */
  const [highlightIndex, setHighlightIndex] = useState(() =>
    filteredFamilies.findIndex((family) => normalize(family) === normalize(value))
  );

  const initialHighlightIndexRef = useRef(highlightIndex);
  const scrollRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: filteredFamilies.length,
    paddingStart: 8,
    paddingEnd: 8,
    scrollPaddingStart: 18,
    scrollPaddingEnd: 18,
    estimateSize: () => 36,
    getScrollElement: () => scrollRef.current,
    overscan: 30,
  });

  // Scroll to center when the virtualizer mounts
  useEffect(() => {
    virtualizer.scrollToIndex(initialHighlightIndexRef.current, { align: 'center' });
  }, [virtualizer]);

  // The previous highlight index for when you are hovering out but picking it back up with arrow keys
  const fallbackHighlightIndex = useRef(-1);

  function commitValue(familyName: string) {
    for (const node of propertiesState.nodesPerProperty[PropertyPanels.Typography] ?? []) {
      if (node.styleMeta.font?.family === familyName) {
        continue;
      }

      // Set the font on the node
      const family = fontState.getFamily(familyName);

      if (family) {
        const previousFont = node.styleMeta.font ?? DEFAULT_FONT;
        const newFont = fontState.getBestMatch(previousFont, family);

        node.setStyleMeta('font', {
          family: newFont.family,
          fullName: newFont.fullName,
          postscriptName: newFont.postscriptName,
          style: newFont.style,
        });

        const isGoogleFont = fontState.isAvailableFromGoogle(newFont);
        const styles = getFontCss(newFont, isGoogleFont);
        for (const property in styles) {
          node.setStyle(property, styles[property]);
        }
      } else {
        console.error('Couldn’t set font family', familyName);
      }
    }
  }

  return (
    <Fragment>
      <div className="bg-panel border-separator shrink-0 border-b p-2">
        <Field.Root>
          <Field.Icon>
            <MagnifyingGlassIcon />
          </Field.Icon>
          <Field.Control>
            <input
              {...baseInputProps}
              ref={inputRef}
              value={inputValue}
              placeholder="Search for a font"
              onKeyDown={(event) => {
                // Set `keyDownRef` for the next tick so it can be used by the corresponding `onChange`
                keyboardEventRef.current = event;
                requestIdleCallback(() => {
                  keyboardEventRef.current = null;
                });

                // Arrow up and down moves the highlight
                if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                  event.preventDefault();
                  const dir = event.key === 'ArrowDown' ? 1 : -1;
                  const startIndex = highlightIndex > -1 ? highlightIndex : fallbackHighlightIndex.current;
                  const newIndex = Math.max(0, Math.min(startIndex + dir, filteredFamilies.length - 1));

                  setHighlightIndex(newIndex);
                  virtualizer.scrollToIndex(newIndex);

                  const familyName = filteredFamilies[newIndex];
                  const searchTermLower = normalize(searchTerm);

                  // Autocomplete the font name if there is a match
                  if (familyName && normalize(familyName).startsWith(searchTermLower)) {
                    flushSync(() => setInputValue(familyName));
                    event.currentTarget.selectionStart = searchTerm.length;
                    event.currentTarget.selectionEnd = familyName.length;
                  } else {
                    setInputValue(searchTerm);
                  }
                }

                // Enter selects the targeted font family
                if (event.key === 'Enter') {
                  const familyName = filteredFamilies[highlightIndex];
                  if (!familyName) return;
                  commitValue(familyName);
                  close();
                }
              }}
              onChange={(event) => {
                const newSearchTerm = event.currentTarget.value;
                const filteredFamilies = getFilteredFamilyNames(newSearchTerm, fontState.sortedFamilyNames);
                const familyName = filteredFamilies[0];
                setFilteredFamilies(filteredFamilies);

                const newSearchTermLower = normalize(newSearchTerm);
                const searchTermLower = normalize(searchTerm);
                const familyNameLower = familyName ? normalize(familyName) : undefined;
                const keyboardEvent = keyboardEventRef.current;
                const hasModifier = keyboardEvent && (keyboardEvent?.ctrlKey || keyboardEvent?.metaKey);
                const isCharacterKey = keyboardEvent?.key && !hasModifier;
                const isTypingAhead = searchTermLower + keyboardEvent?.key.toLowerCase() === newSearchTermLower;

                if (event.currentTarget.value) {
                  // If searching, set the highlight to the first item
                  setHighlightIndex(0);
                  virtualizer.scrollToIndex(0);
                } else {
                  // Otherwise, reset the highlight
                  setHighlightIndex(initialHighlightIndexRef.current);
                  virtualizer.scrollToIndex(initialHighlightIndexRef.current, { align: 'center' });
                }

                // Autocomplete the family name if typing forward (and not backspacing, pasting, etc.)
                if (isCharacterKey && isTypingAhead && familyNameLower?.startsWith(newSearchTermLower)) {
                  setSearchTerm(newSearchTerm);
                  flushSync(() => setInputValue(familyName!));
                  event.currentTarget.selectionStart = newSearchTerm.length;
                  event.currentTarget.selectionEnd = familyName!.length;
                } else {
                  setSearchTerm(newSearchTerm);
                  setInputValue(newSearchTerm);
                }
              }}
              onPointerDown={(event) => defaultPointerDownHandler(event.currentTarget)}
            />
          </Field.Control>
        </Field.Root>
      </div>

      <div
        ref={scrollRef}
        className="w-[300px] grow overflow-auto overscroll-contain outline-0"
        // Clear the highlight when leaving the scroll area, but set a fallback for the arrow key position
        onPointerLeave={() => {
          fallbackHighlightIndex.current = highlightIndex;
          setHighlightIndex(-1);
        }}
      >
        <div className="relative" style={{ height: virtualizer.getTotalSize() }}>
          {virtualizer.getVirtualItems().map((virtualItem) => {
            const familyName = filteredFamilies[virtualItem.index];
            if (familyName === undefined) return null;

            return (
              <div
                key={virtualItem.key}
                tabIndex={-1}
                className="data-[highlighted]:bg-list-item-highlighted data-[selected]:bg-list-item-selected absolute top-0 right-0 left-0 flex items-center overflow-hidden px-2"
                data-highlighted={highlightIndex === virtualItem.index ? true : undefined}
                data-selected={value === familyName ? true : undefined}
                style={{
                  height: virtualItem.size,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
                onPointerDown={(event) => {
                  // Don't steal focus from the input (unnecessary motion)
                  event.preventDefault();
                }}
                onClick={() => {
                  commitValue(familyName);
                  close();
                }}
                onPointerMove={() => {
                  if (highlightIndex !== virtualItem.index) {
                    setHighlightIndex(virtualItem.index);
                  }
                }}
              >
                {FAMILIES_TO_SKIP_PREVIEW_IMAGE.has(familyName) ||
                fontState.builtInFamilies.has(familyName) ||
                fontState.localFamilies.has(familyName) ? (
                  <div
                    className={fallbackClassName}
                    style={getFontCss({
                      // Zapfino has ginormous ascenders and descenders that don't fit into the preview box
                      family: familyName === 'Zapfino' ? DEFAULT_FONT.family : familyName,
                      style: 'Regular',
                    })}
                  >
                    {familyName}
                  </div>
                ) : (
                  <PreviewWithFallback familyName={familyName} />
                )}
              </div>
            );
          })}
        </div>
      </div>

      {fontState.localFontsPermissionState === 'prompt' && (
        <div className="border-separator flex h-[40px] shrink-0 flex-col justify-center border-t px-2">
          <Button
            onClick={async () => {
              const success = await fontState.loadLocalFonts();

              // Update the family list if local fonts were loaded successfully
              if (success) {
                const filteredFamilies = getFilteredFamilyNames(searchTerm, fontState.sortedFamilyNames);
                setFilteredFamilies(filteredFamilies);

                if (searchTerm) {
                  // If searching, set the highlight to the first item
                  setHighlightIndex(0);
                  virtualizer.scrollToIndex(0);

                  // Also update the input with the new autocompletion
                  const familyName = filteredFamilies[0];
                  const searchTermLower = normalize(searchTerm);
                  const familyNameLower = familyName ? normalize(familyName) : undefined;
                  if (inputRef.current && familyNameLower?.startsWith(searchTermLower)) {
                    flushSync(() => setInputValue(familyName!));
                    inputRef.current.selectionStart = searchTerm.length;
                    inputRef.current.selectionEnd = familyName!.length;
                  } else {
                    setInputValue(searchTerm);
                  }
                } else {
                  // Otherwise, reset the highlight to match the original position
                  initialHighlightIndexRef.current = filteredFamilies.findIndex(
                    (family) => normalize(family) === normalize(value)
                  );
                  setHighlightIndex(initialHighlightIndexRef.current);
                  virtualizer.scrollToIndex(initialHighlightIndexRef.current, { align: 'center' });
                }
              }
            }}
          >
            Load local fonts
          </Button>
        </div>
      )}

      {fontState.localFontsPermissionState === 'denied' && (
        <div className="border-separator text-gray-2 flex h-[40px] shrink-0 flex-col justify-center border-t px-2 text-center">
          Reset website permissions to load local fonts.
        </div>
      )}
    </Fragment>
  );
});

interface PreviewWithFallbackProps {
  familyName: string;
}

const PreviewWithFallback = ({ familyName }: PreviewWithFallbackProps) => {
  const [error, setError] = useState(false);

  if (error) {
    return <div className={fallbackClassName}>{familyName}</div>;
  }

  return (
    <img
      className="h-4 max-w-[200px] object-contain object-left opacity-80 dark:opacity-90 dark:invert"
      src={getFontPreviewUrl(familyName)}
      onError={() => setError(true)}
    />
  );
};

/** If we can't render a preview font, use these styles for our plaintext fallback */
const fallbackClassName = 'flex h-6 items-center text-[16px]';

const MagnifyingGlassIcon = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4.5C8 2.567 6.433 1 4.5 1C2.567 1 1 2.567 1 4.5C1 6.433 2.567 8 4.5 8C6.433 8 8 6.433 8 4.5ZM4.5 0C6.98528 0 9 2.01472 9 4.5C9 5.5625 8.63177 6.539 8.01595 7.30884L11.9786 11.2714L11.2714 11.9785L7.30885 8.01594C6.53901 8.63176 5.56251 9 4.5 9C2.01472 9 0 6.98528 0 4.5C0 2.01472 2.01472 0 4.5 0Z"
    />
  </svg>
);

function getFilteredFamilyNames(searchTerm: string, familyNames: string[]) {
  if (!searchTerm) {
    return familyNames;
  }

  const searchTermLower = normalize(searchTerm);

  // Search for families that include the term at the start of word boundary
  const familiesThatIncludeTerm = familyNames.filter((family) => {
    return new RegExp(`\\b${searchTermLower}`).test(normalize(family));
  });

  // Among those, single out the ones that start with the term
  const familiesThatStartWithTerm = familiesThatIncludeTerm.filter((family) => {
    return normalize(family).startsWith(searchTermLower);
  });

  // And separate the rest into another list
  const familiesThatIncludeTermWithin = familiesThatIncludeTerm.filter((family) => {
    return !normalize(family).startsWith(searchTermLower);
  });

  // First display "startsWith" and then the rest of the matches
  return familiesThatStartWithTerm.concat(familiesThatIncludeTermWithin);
}

/**
 * Deburr and convert to lowercase:
 * ```
 * "Söhne Mono" => "sohne mono"
 * ```
 * Use this instead of `string.toLowerCase()` for string comparison.
 * The function is memoized because it's called often over the same set of data.
 */
const normalize = memoize((string: string) => {
  return string
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '');
});

function getFontPreviewUrl(familyName: string) {
  return `https://assets.paper.design/google-font-preview/avif/${familyName.toLowerCase().replace(/\s+/g, '-')}.avif`;
}
