import { observer } from 'mobx-react-lite';
import { PanToolIcon } from './pan-tool-icon';
import { Tool } from './ToolState';
import { useEditor } from '../editor-context';
import { DrawToolIcon } from './draw-tool-icon';
import { MoveToolIcon } from './move-tool-icon';
import { TextDrawToolIcon } from './text-draw-tool-icon';
import { ToolButton } from './tool-button';

export const Toolbar = observer(() => {
  const editorState = useEditor();
  const { toolState, drawToolState } = editorState;

  const activeTool = toolState.activeTool;
  return (
    <div className="bg-panel border-right border-left flex w-[37px] flex-col">
      <ToolButton active={activeTool === Tool.Move} onPointerDown={() => toolState.setActiveTool(Tool.Move)}>
        <MoveToolIcon />
      </ToolButton>
      <ToolButton
        active={activeTool === Tool.Draw && drawToolState.componentToDraw === 'Box'}
        onPointerDown={() => {
          toolState.setActiveTool(Tool.Draw);
          drawToolState.setComponentToDraw('Box');
        }}
      >
        <DrawToolIcon />
      </ToolButton>
      <ToolButton
        active={activeTool === Tool.Draw && drawToolState.componentToDraw === 'Shader'}
        onPointerDown={() => {
          toolState.setActiveTool(Tool.Draw);
          drawToolState.setComponentToDraw('Shader');
        }}
      >
        <DrawToolIcon />
      </ToolButton>
      <ToolButton
        active={activeTool === Tool.Text}
        onPointerDown={() => {
          toolState.setActiveTool(Tool.Text);
          editorState.textTool.setAllowDrawing(true);
          editorState.textTool.startEditingIfSelectionIsSoloTextNode();
        }}
      >
        <TextDrawToolIcon />
      </ToolButton>
      <ToolButton active={activeTool === Tool.Pan} onPointerDown={() => toolState.setActiveTool(Tool.Pan)}>
        <PanToolIcon />
      </ToolButton>
    </div>
  );
});
