import { observer } from 'mobx-react-lite';
import { EditorReactContext } from './editor-context';
import { useRoot } from '../root/root-context';
import { EditorState } from './EditorState';
import { useEffect, useState } from 'react';
import { LayerTree } from './layer-tree/layer-tree';
import { Canvas } from './canvas/canvas';
import { PropertyPanel } from './properties/property-panel';
import { Toolbar } from './toolbar/toolbar';
import { PageList } from './page-list/page-list';
import { HandlePaste } from './copy-paste/handle-paste';
import { HandleCopy } from './copy-paste/handle-copy';
import { makePageTitle } from '../home/home';
import { EditorPointerWatcherAndRightClickBlocker } from './editor-pointer-watcher-and-right-click-blocker';
import { EditorHotkeysZone, HotkeysProvider } from './hotkeys';
import { FontLoader } from './fonts/font-loader';
import { autorun } from 'mobx';

type EditorProps = {
  /** The doc ID for this Editor instance to work on */
  editorState: EditorState;
};

export const Editor = observer(({ editorState }: EditorProps) => {
  // Update the HTML title once the file loads
  useEffect(() => {
    const disposer = autorun(() => {
      document.title = makePageTitle(editorState.fileState.data.name);
    });
    return () => disposer();
  }, [editorState]);

  return (
    <HotkeysProvider state={editorState}>
      <EditorHotkeysZone>
        <div className="select-none">
          <EditorReactContext.Provider value={editorState}>
            <Canvas />
            <HandlePaste />
            <HandleCopy />

            <EditorPointerWatcherAndRightClickBlocker />

            <FontLoader />

            {editorState.renderUI && (
              <>
                <div className="bg-panel fixed top-0 bottom-0 left-0 flex">
                  <div className="flex flex-col">
                    <PageList />
                    <div className="border-bottom" />
                    <LayerTree />
                  </div>
                  <Toolbar />
                </div>

                <PropertyPanel />
              </>
            )}
          </EditorReactContext.Provider>
        </div>
      </EditorHotkeysZone>
    </HotkeysProvider>
  );
});
