import { hsl } from './colorgram';

/** Colorgram's sort only sorts by hue, so this is copy/pasted and changed to sort by lightness */
export function sortColorsByLightness(stats: any[][]): any[][] {
  let i: number;

  for (i = 0; i < stats.length; i++) {
    stats[i]![4] = hsl(stats[i]![0], stats[i]![1], stats[i]![2]);
  }

  stats.sort((a: number[][], b: number[][]): number => {
    return a[4]![2]! - b[4]![2]!;
  });

  for (i = 0; i < stats.length; i++) {
    delete stats[i]![4];
  }

  return stats;
}
