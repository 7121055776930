import { PropertyPanels } from '../properties/property-panel';
import { TreeNode } from '../tree/TreeNode';
import { ComponentMeta } from './built-in-ui';

// Built in components
export const Text = ({
  node,
  ref,
  style,
  children,
}: {
  node: TreeNode;
  ref: React.RefObject<HTMLDivElement>;
  style: React.CSSProperties;
  children: React.ReactNode;
}) => {
  return (
    <div
      ref={ref}
      style={{
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        fontSynthesis: 'none',
        color: 'black',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const TextMeta: ComponentMeta = {
  label: 'Text',
  component: Text,
  canHaveChildren: false,
  props: {},
  propertyPanels: new Set([
    PropertyPanels.Position,
    PropertyPanels.Layout,
    PropertyPanels.Fill,
    PropertyPanels.Typography,
  ]),
  layerTreeIcon: 'text',
};
