import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { NumberInput } from '../properties/number-input';
import { Field } from '../../components/field';
import { PropertyPanels } from '../properties/property-panel';
import { MIXED_STYLE_VALUE, reducePropertyValues } from '../properties/reduce-property-values';

export const PaddingYProperty = observer(() => {
  const { propertiesState } = useEditor();

  const currentValueTop = propertiesState.getStyleValues('paddingTop');
  const currentValueBottom = propertiesState.getStyleValues('paddingBottom');
  const inputValueTop = reducePropertyValues(currentValueTop, '0px');
  const inputValueBottom = reducePropertyValues(currentValueBottom, '0px');

  let finalValue = inputValueTop;
  if (inputValueTop !== inputValueBottom) {
    finalValue = MIXED_STYLE_VALUE;
  }

  function commitValue(value: string) {
    for (const node of propertiesState.allNodes) {
      if (node.layoutModeForChildren === 'flex') {
        node.setStyle('paddingTop', value);
        node.setStyle('paddingBottom', value);
      }
    }
  }

  return (
    <Field.Root>
      <Field.Icon>p y</Field.Icon>
      <Field.Control>
        <NumberInput value={finalValue} onValueCommit={commitValue} />
      </Field.Control>
    </Field.Root>
  );
});
