import clsx from 'clsx';

export type TreeItemIconName = 'box' | 'text' | 'image' | 'page' | 'flex-direction-row' | 'flex-direction-column';

interface TreeItemIconProps extends React.ComponentProps<'div'> {
  icon: TreeItemIconName;
}

const TreeItemIcon = ({ className, icon, ...props }: TreeItemIconProps) => {
  return (
    <div
      className={clsx('text-gray-1 flex h-3 w-3 items-center justify-center opacity-60 [&_svg]:shrink-0', className)}
      {...props}
    >
      {icon === 'box' && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <rect x="3" y="3" width="10" height="10" fillOpacity="0.125" />
          <path d="M4 7L4 4H7V3H4H3V4L3 7H4ZM9 13H12H13V12V9H12V12H9V13ZM12 3H9V4H12V7H13V4V3H12ZM4 13H7V12H4L4 9H3L3 12V13H4Z" />
        </svg>
      )}

      {icon === 'flex-direction-row' && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.14998 14V1H0.849976V14H2.14998ZM6.14998 14V1H4.84998V14H6.14998ZM10.15 1V14H8.84998V1H10.15ZM14.15 14V1H12.85V14H14.15Z"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </svg>
      )}

      {icon === 'flex-direction-column' && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 12.85L1 12.85L1 14.15L14 14.15L14 12.85ZM14 8.85002L1 8.85002L1 10.15L14 10.15L14 8.85002ZM1 4.85003L14 4.85003L14 6.15003L1 6.15002L1 4.85003ZM14 0.850025L1 0.850025L1 2.15002L14 2.15002L14 0.850025Z"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </svg>
      )}

      {icon === 'text' && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.96063 12.0703C1.59148 12.0703 1.33953 11.8535 1.33953 11.5137C1.33953 11.373 1.37469 11.2207 1.45086 10.998L3.64813 4.78125C3.8532 4.19531 4.14031 3.9375 4.67352 3.9375C5.20672 3.9375 5.49383 4.18945 5.69305 4.78125L7.89031 10.998C7.95477 11.1914 7.98992 11.3496 7.98992 11.4961C7.98992 11.8477 7.73797 12.0703 7.35711 12.0703C6.99969 12.0703 6.78875 11.8887 6.65398 11.4375L6.1032 9.78516H3.20867L2.65789 11.4375C2.51727 11.8945 2.31805 12.0703 1.96063 12.0703ZM3.52508 8.76562H5.7868L4.69109 5.38477H4.62664L3.52508 8.76562ZM12.8851 11.1973C12.5452 11.7715 11.9241 12.0879 11.1566 12.0879C9.94953 12.0879 9.13508 11.3262 9.13508 10.2012C9.13508 9.05859 9.96711 8.36133 11.3499 8.35547H12.8968V7.80469C12.8968 7.10742 12.4691 6.74414 11.678 6.74414C11.1859 6.74414 10.846 6.91992 10.4886 7.2832C10.3304 7.43555 10.178 7.49414 9.95539 7.49414C9.66828 7.49414 9.48078 7.30078 9.48078 7.01953C9.48078 6.66211 9.72688 6.31055 10.2484 6.0293C10.6234 5.82422 11.1155 5.71289 11.7601 5.71289C13.3245 5.71289 14.1976 6.46289 14.1976 7.81055V11.3555C14.1976 11.8008 13.9691 12.0586 13.5648 12.0586C13.2073 12.0586 12.9788 11.8418 12.9495 11.4727V11.1973H12.8851ZM10.4417 10.1367C10.4417 10.7109 10.8929 11.0977 11.5609 11.0977C12.3167 11.0977 12.8968 10.582 12.8968 9.91406V9.24609H11.5316C10.8402 9.24609 10.4417 9.56836 10.4417 10.1367Z" />
        </svg>
      )}

      {icon === 'image' && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.6538 12.5H3.51135H3.34615C2.87908 12.5 2.5 12.0968 2.5 11.6V10V4.4C2.5 3.9032 2.87908 3.5 3.34615 3.5H12.6538C13.1209 3.5 13.5 3.9032 13.5 4.4V11.6C13.5 12.0968 13.1209 12.5 12.6538 12.5Z"
            fill="currentcolor"
            fillOpacity="0.125"
          />
          <path
            d="M2.5 10V11.6C2.5 12.0968 2.87908 12.5 3.34615 12.5H3.51135H12.6538C13.1209 12.5 13.5 12.0968 13.5 11.6V4.4C13.5 3.9032 13.1209 3.5 12.6538 3.5H3.34615C2.87908 3.5 2.5 3.9032 2.5 4.4V10ZM2.5 10L5.5 6.5L11 12"
            stroke="currentcolor"
            strokeLinecap="square"
          />
          <path
            d="M10.5 7.75C11.1904 7.75 11.75 7.19036 11.75 6.5C11.75 5.80964 11.1904 5.25 10.5 5.25C9.80964 5.25 9.25 5.80964 9.25 6.5C9.25 7.19036 9.80964 7.75 10.5 7.75Z"
            fill="currentcolor"
          />
        </svg>
      )}

      {icon === 'page' && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.5 5.5L9.5 2.5H4.5C3.948 2.5 3.5 2.948 3.5 3.5V12.5C3.5 13.052 3.948 13.5 4.5 13.5H11.5C12.052 13.5 12.5 13.052 12.5 12.5V5.5Z"
            fill="currentcolor"
            fillOpacity="0.125"
          />
          <path
            d="M12.5 5.5L9.5 2.5M12.5 5.5V12.5C12.5 13.052 12.052 13.5 11.5 13.5H4.5C3.948 13.5 3.5 13.052 3.5 12.5V3.5C3.5 2.948 3.948 2.5 4.5 2.5H9.5M12.5 5.5H9.5V2.5"
            stroke="currentcolor"
          />
        </svg>
      )}
    </div>
  );
};

export { TreeItemIcon };
