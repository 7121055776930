import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { RootReactContext } from './root/root-context';
import { Root } from './root/Root';
import { Route, Switch } from 'wouter';
import { Home } from './home/home';
import { initMobx } from './mobx';
import Demo from './hotkey/demo';
import { FileLoader } from './editor/file-and-observers/file-loader';
import { Toaster } from 'sonner';
import './request-idle-callback-polyfill';
import './logrocket';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './react-error-boundary';

/**
 * Notes: do not export anything from main.tsx or it can cause issues with Hot Reloading
 */

// set up mobx config
initMobx();

const App = () => {
  const [rootStore, setRootStore] = useState<Root | null>(null);

  useEffect(() => {
    const rootStore = Root.CreateRoot();
    setRootStore(rootStore);

    return () => {
      rootStore.dispose(true);
    };
  }, []);

  if (!rootStore) {
    return null;
  }

  return (
    <RootReactContext.Provider value={rootStore}>
      {/* Toast notifications */}
      <Toaster position="bottom-center" />

      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {/* Routing */}
        <Switch>
          {/* File home page */}
          <Route path="/" component={Home} />
          <Route path="/hotkeys-demo" component={Demo} />

          {/* Editor */}
          <Route path="/design/:fileId">{({ fileId }: { fileId: string }) => <FileLoader fileId={fileId} />}</Route>

          {/* Default route in a switch */}
          <Route>404</Route>
        </Switch>
      </ErrorBoundary>
    </RootReactContext.Provider>
  );
};

// Render the app
const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Missing root element to attach React app.');
}
ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
