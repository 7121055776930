import { CursorMoveMessage } from '@mobius/models/src/websocket/cursor-move-message';
import { runInAction } from 'mobx';
import { EditorState } from '../EditorState';

export const handleCursorMoveMessage = (editorState: EditorState, messageData: CursorMoveMessage) => {
  const multiplayerState = editorState.multiplayerState;
  const clientId = messageData.clientId;

  if (typeof clientId !== 'string') {
    console.warn('clientId is missing from CursorMoveMessage, ignoring message');
    return;
  }

  const userData = multiplayerState.userData[clientId];
  if (userData === undefined) {
    // Make sure the user join has made it through first
    return;
  }

  runInAction(() => {
    userData.cursorPos.x = messageData.x;
    userData.cursorPos.y = messageData.y;
  });
};
