import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { TreeNode } from '../tree/TreeNode';
import { CollapsibleIndicator } from '../../components/collapsible-indicator';
import { Spacer } from '../../components/spacer';
import { useEffect, useRef } from 'react';

type Props = {
  node: TreeNode;
};

/** How much space to reserve for collapsible indicators, whether they're present or not */
export const COLLAPSIBLE_INDICATOR_SPACE = 20;

export const ExpandAndCollapseButton = observer(({ node }: Props) => {
  const { layerTreeState } = useEditor();
  const shouldShow = node.children.length > 0;
  const ref = useRef<HTMLDivElement>(null);

  // We need to use native event listeners here so we can stop propagation effectively from selecting this node
  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    function handlePointerDown(e: PointerEvent) {
      e.stopPropagation(); // we don't want to select this node
      // Expand or collapse the node
      layerTreeState.toggleExpandedOrCollapsed(node.id);
    }

    el.addEventListener('pointerdown', handlePointerDown);
    return () => {
      el.removeEventListener('pointerdown', handlePointerDown);
    };
  }, [node, shouldShow, layerTreeState]);

  if (shouldShow === false) {
    return <Spacer width={COLLAPSIBLE_INDICATOR_SPACE} />;
  }

  const isClosed = layerTreeState.expandedNodeIds.has(node.id) === false;

  return <CollapsibleIndicator ref={ref} style={{ width: COLLAPSIBLE_INDICATOR_SPACE }} collapsed={isClosed} />;
});
