import clsx from 'clsx';

interface CollapsibleIndicatorProps extends React.ComponentProps<'span'> {
  collapsed?: boolean;
}

function CollapsibleIndicator({ collapsed, className, ...props }: CollapsibleIndicatorProps) {
  return (
    <span className={clsx('CollapsibleIndicator', className)} {...props}>
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path transform={collapsed ? 'rotate(-90, 4, 4)' : undefined} d="M1 2.5L4 5.5L7 2.5" stroke="currentcolor" />
      </svg>
    </span>
  );
}

export { CollapsibleIndicator };
