import { action, makeObservable, observable } from 'mobx';
import { EditorState } from '../EditorState';
import { isSocketMessage, MessageType, typeKey } from '@mobius/models/src/websocket/socket-message';
import { API_ADDRESS } from '../../root/api-address';
import { handleMessageOnClient } from './handle-message-on-client';
import { FilePayloadMessage } from '@mobius/models/src/websocket/file-payload-message';
import { FileLoadFailedMessage } from '@mobius/models/src/websocket/file-load-failed-message';

export class SocketState {
  socket: WebSocket | null = null;

  @observable accessor connected: boolean = false;
  @action setConnected = (connected: boolean) => {
    this.connected = connected;
  };

  /** The last date.now() we tried to connect */
  lastConnectionAttemptTime = 0;
  /** A timeout for the next connection retry attempt */
  nextConnectionRetryTimeout = 0;

  /** SocketState comes first and loads the file before EditorState is instantiated */
  public editorState: EditorState | null = null;

  constructor(
    /** The file ID to load */
    private fileId: string,
    /** Callback to run once the file payload is received */
    private fileLoadSuccessCallback: (filePayload: FilePayloadMessage) => void,
    /** Callback to run if the file load fails */
    private fileLoadErrorCallback: (error: FileLoadFailedMessage) => void
  ) {
    makeObservable(this);

    this.initializeSocket();

    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }
  dispose = () => {
    if (this.socket !== null) {
      this.socket.onclose = null;
      this.socket.onerror = null;
      this.socket.onmessage = null;
      this.socket.onopen = null;

      // Only try to close if not already closing or closed
      if (this.socket.readyState !== WebSocket.CLOSED) {
        try {
          // Dev warning that this will print a warning when useEffect runs twice on load
          if (import.meta.env.DEV) {
            console.warn('Dev mode: WebSocket error is expected and necessary (from useEffect running twice)');
          }
          // Close the socket
          this.socket.close();
        } catch (e) {
          console.error('Error while closing socket:', e);
        }
      }

      // Null the socket
      this.socket = null;
    }

    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  };

  /** SocketState comes before EditorState since it needs to do the initial load, but then we basically behave as a child of EditorState after that */
  setEditorStateIsReady = (editorState: EditorState) => {
    this.editorState = editorState;
  };

  private initializeSocket = () => {
    if (navigator.onLine === false) {
      console.log(`Offline status - not attempting connection`);
      return;
    }

    try {
      this.lastConnectionAttemptTime = Date.now();

      const socketUrl = `${API_ADDRESS.SYNC_URI}/sync/${this.fileId}`;
      this.socket = new WebSocket(socketUrl);
      this.socket.onopen = this.onSocketOpen;
      this.socket.onerror = this.onSocketError;
      this.socket.onmessage = this.onSocketMesage;
      this.socket.onclose = this.onSocketClose;
    } catch (e) {
      console.warn('WebSocket connection error:', e);
    }
  };

  reconnectIntervalSeconds = 4;
  private handleOnline = () => {
    console.log(`Internet connection detected - attempting reconnect immediately`);
    window.clearTimeout(this.nextConnectionRetryTimeout);
    this.reconnectIntervalSeconds = 4; // try every 4 seconds while online
    this.initializeSocket();
  };
  private handleOffline = () => {
    console.log(`Internet connection lost - attempting to reconnect periodically`);
    this.reconnectIntervalSeconds = 30; // try every 30 seconds while offline
    this.queueReconnect();
  };

  private queueReconnect = () => {
    window.clearTimeout(this.nextConnectionRetryTimeout);
    this.nextConnectionRetryTimeout = window.setTimeout(this.initializeSocket, this.reconnectIntervalSeconds * 1000);
  };

  @action onSocketOpen = () => {
    console.log(`Sync established`);
    this.setConnected(true);
  };

  @action onSocketClose = () => {
    // Attempt to reconnect every N seconds
    console.log(`Sync offline: reconnect attempt in ${this.reconnectIntervalSeconds} seconds`);
    this.editorState?.multiplayerState.resetUserData();
    this.queueReconnect();
    this.setConnected(false);
  };

  @action onSocketError = (err: Event) => {
    console.error('WebSocket error', err);
    this.setConnected(false);
  };

  @action onSocketMesage = (message: MessageEvent<any>) => {
    // Parse the socket message and validate it
    let messageData = null;
    try {
      messageData = JSON.parse(message.data);
    } catch (error) {
      console.warn('Error parsing socket message', error);
      return;
    }
    if (messageData === null) {
      return;
    }
    if (isSocketMessage(messageData) === false) {
      console.warn('Received unexpected socket message', message);
      return;
    }

    if (this.editorState === null) {
      // Haven't loaded the file yet
      switch (messageData[typeKey]) {
        case MessageType.FilePayload: {
          this.fileLoadSuccessCallback(messageData);
          break;
        }
        case MessageType.FileLoadFailed: {
          this.fileLoadErrorCallback(messageData);
          break;
        }
      }
    } else {
      // File is loaded, normal message handling
      handleMessageOnClient(this.editorState, messageData);
    }
  };
}
