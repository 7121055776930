import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { Field } from '../../components/field';
import { type Color, ColorUtils } from '../properties/Color';
import { ColorInput } from '../../components/color-input';
import { TreeNode } from '../tree/TreeNode';
import { PropertyPanels } from '../properties/property-panel';
import { ColorPicker } from '../color-picker/color-picker';

export const TextColorProperty = observer(() => {
  const { propertiesState } = useEditor();

  const nodes = propertiesState.nodesPerProperty[PropertyPanels.Typography];
  const isMixed = isMixedTextColor(nodes);
  const color = nodes[0]?.styleMeta.textColor || ColorUtils.new('000');

  function commitValue(color: Color) {
    for (const node of nodes) {
      saveColor(node, color);
    }
  }

  if (isMixed) {
    return (
      <div className="text-gray-2 flex h-7 items-center" onPointerDown={() => replaceMixedColor(nodes)}>
        Click to replace mixed colors
      </div>
    );
  }

  return (
    <div className="flex h-7 items-center gap-2">
      <ColorPicker color={color} onChange={commitValue} />
      <Field.Control>
        <ColorInput color={color} onColorCommit={commitValue} />
      </Field.Control>
    </div>
  );
});

function replaceMixedColor(nodes: TreeNode[]) {
  let color: Color | undefined;
  const nodesToBackfill: TreeNode[] = [];

  for (const node of nodes) {
    if (!node.styleMeta.textColor) {
      // These nodes will be backfilled ones we find a real color
      nodesToBackfill.push(node);
      continue;
    }

    // First color value will be used for all nodes
    color ??= node.styleMeta.textColor;
    saveColor(node, color);
  }

  // Apply the color value to all the nodes we initially
  // saw that didn't have a color value on them.
  if (color) {
    for (const node of nodesToBackfill) {
      saveColor(node, color);
    }
  }
}

function saveColor(node: TreeNode, color: Color) {
  color = ColorUtils.clone(color);
  node.setStyleMeta('textColor', color);
  node.setStyle('color', ColorUtils.cssString(color));
}

function isMixedTextColor(nodes: TreeNode[]) {
  let previous: TreeNode;

  for (const current of nodes) {
    previous ??= current;
    if (previous === current) continue;

    const previousColor = previous.styleMeta.textColor;
    const currentColor = current.styleMeta.textColor;

    // If either is empty, they are mixed if another one isn't empty
    if (!previousColor || !currentColor) {
      return Boolean(previousColor || currentColor);
    }

    if (!ColorUtils.isEqual(previousColor, currentColor)) {
      return true;
    }

    previous = current;
  }

  return false;
}
