import { isBuiltInFamily } from './built-in-fonts';
import { appleSystemFontStyles, googleFontStyles } from './font-styles';
import { Font } from './FontState';

export const DEFAULT_FONT_CSS = {
  fontFamily: 'system-ui, sans-serif',
};

export function getFontCss(font: Font, isGoogleFont = false): Record<string, string | undefined> {
  const { family, style, fullName, postscriptName } = font;

  if (isBuiltInFamily(family)) {
    switch (family) {
      case 'UI Sans-Serif':
        return {
          fontFamily: 'system-ui, sans-serif',
          fontWeight: appleSystemFontStyles[style],
          fontStyle: style.includes('Italic') ? 'italic' : undefined,
        };
      case 'UI Monospace':
        return {
          fontFamily: 'ui-monospace, "SFMono-Regular", "SF Mono", "Menlo", "Consolas", "Liberation Mono", monospace',
          fontWeight: appleSystemFontStyles[style],
          fontStyle: style.includes('Italic') ? 'italic' : undefined,
        };
      default:
        const unreachable: never = family;
    }
  }

  let fontFamily: string[] = [`"${family}"`, 'system-ui', 'sans-serif'];

  if (fullName && fullName !== family) {
    fontFamily.unshift(`"${fullName}"`);
  }

  if (postscriptName && postscriptName !== family) {
    fontFamily.unshift(`"${postscriptName}"`);
  }

  // TODO Vlad extract weight from the font file
  const fontWeight = isGoogleFont ? googleFontStyles[style] : undefined;

  return {
    fontFamily: fontFamily.join(', '),
    fontStyle: /italic|oblique|sloped|slanted/i.test(style) ? 'italic' : undefined,
    fontWeight,
  };
}
