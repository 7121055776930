import { useRef } from 'react';
import { Color, ColorUtils } from '../properties/Color';

type Props = {
  color: Color;
  onChange: (color: Color) => void;
};

export function Swatches({ color, onChange }: Props & {}) {
  const initialColorRef = useRef(color);

  return (
    <div className="flex flex-col gap-0_5">
      <div
        className="rounded-1 flex h-[56px] overflow-hidden bg-white"
        style={{
          backgroundImage: `
            linear-gradient(45deg, #e6e6e6 25%, transparent 25%),
            linear-gradient(-45deg, #e6e6e6 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, #e6e6e6 75%),
            linear-gradient(-45deg, transparent 75%, #e6e6e6 75%)
          `,
          backgroundSize: '16px 16px, 16px 16px, 16px 16px, 16px 16px',
          backgroundPosition: '0px 8px, 0px 16px, 8px 0px, -8px 8px',
        }}
      >
        <div
          className="border-gray-5 w-1/2 border border-r-0"
          style={{ backgroundColor: ColorUtils.cssString(initialColorRef.current) }}
          onClick={() => onChange(initialColorRef.current)}
        />
        <div className="border-gray-5 w-1/2 border border-l-0" style={{ background: ColorUtils.cssString(color) }} />
        <div />
      </div>
      <div className="flex">
        <div className="text-gray-2 w-1/2 text-center text-sm">Previous</div>
        <div className="text-gray-2 w-1/2 text-center text-sm">New</div>
      </div>
    </div>
  );
}
