/**
 * Sets a value in a nested object using a JSON Pointer string.
 * Creates the path if it doesn't exist.
 * @param {Object} obj - The object to modify
 * @param {string} pointer - JSON Pointer string (e.g., "/a/b/0/c") – will NOT handle slashes in keys or array special push key '-'
 * @param {*} value - The value to set
 * @returns {Object} The modified object
 */
export function deepSetValue(obj: Record<any, any>, pointer: string, value: any, type: 'update' | 'add') {
  // Remove leading slash if present
  const path = pointer.charAt(0) === '/' ? pointer.slice(1) : pointer;
  const keys = path.split('/');
  let current = obj;

  // Loop through all keys except the last one
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i] as string;

    // Check if we need to create a new {} or [] for this key
    if (current[key] === undefined) {
      if (type === 'update') {
        // 2024-12-05: Choosing to be loud here so we notice cases that cause bad data in the DB immediately, feel free to change this later
        throw new Error(
          `deepSetValue: UPDATE had to create a new object, unexpected: pointer: ${pointer}, value: ${JSON.stringify(value)}`
        );
      }

      // Check if the next key is an array index (if we need to create a new {} or [] for this key)
      const nextKey = keys[i + 1];
      const nextIsArrayIndex = nextKey === '-' || !isNaN(Number(nextKey));
      current[key] = nextIsArrayIndex ? [] : {};
    }

    current = current[key];
  }

  // Set the final value
  const lastKey = keys[keys.length - 1] as string;
  if (lastKey === '-' && Array.isArray(current)) {
    current.push(value);
  } else {
    current[lastKey] = value;
  }

  return true;
}
