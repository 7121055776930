import type { Rect } from './rect';
import type { Vec2 } from './vec2';

/**
 * Returns true if a vec2 is inside a rect
 */
export function isPointInRect(point: Vec2, rect: Rect) {
  return point.x >= rect.minX && point.x <= rect.maxX && point.y >= rect.minY && point.y <= rect.maxY;
}

/**
 * Returns true if a and b overlap at all
 */
export function intersects(a: Rect, b: Rect) {
  return a.minX < b.maxX && a.maxX > b.minX && a.minY < b.maxY && a.maxY > b.minY;
}

/**
 * Returns true if target is completely inside wrapper
 */
export function contains(wrapper: Rect, target: Rect) {
  return (
    target.minX >= wrapper.minX &&
    target.maxX <= wrapper.maxX &&
    target.minY >= wrapper.minY &&
    target.maxY <= wrapper.maxY
  );
}
