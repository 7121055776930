import { useEffect } from 'react';
import { useEditor } from '../editor-context';
import { observer } from 'mobx-react-lite';

export const SELECTION_BOUNDS_COLOR = 'oklch(55% 45% 258)';
export const SELECTION_BRUSH_FILL_COLOR = 'oklch(55% 45% 258 / 10%)';

/** Draws the brush graphic for the selection tool */
export const SelectBrushGraphic = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { hudState, moveToolState, cameraState } = editorState;
    const selectionBrushState = moveToolState.selectionBrushState;

    function drawBrush(ctx: CanvasRenderingContext2D) {
      const brushPoint1 = selectionBrushState.brushPoint1;
      const brushPoint2 = selectionBrushState.brushPoint2;
      if (!brushPoint1 || !brushPoint2) {
        return;
      }

      ctx.fillStyle = SELECTION_BRUSH_FILL_COLOR;
      ctx.strokeStyle = SELECTION_BOUNDS_COLOR;
      ctx.lineWidth = 1 * cameraState.scaleInverse;

      const x = Math.min(brushPoint1.x, brushPoint2.x);
      const y = Math.min(brushPoint1.y, brushPoint2.y);
      const width = Math.abs(brushPoint2.x - brushPoint1.x);
      const height = Math.abs(brushPoint2.y - brushPoint1.y);
      ctx.strokeRect(x, y, width, height);
      ctx.fillRect(x, y, width, height);
    }

    hudState.worldDrawingFunctions.add(drawBrush);
    return () => {
      hudState.worldDrawingFunctions.delete(drawBrush);
    };
  }, [editorState]);

  return null;
});
