import { assert } from '../../assert';

// Cache these outside the function so we don't create them every time (they will be created the first time the feature is used)
let testImg: HTMLImageElement;
let canvas: HTMLCanvasElement;
let ctx: CanvasRenderingContext2D | null;

export async function getImageDataFromUrl(url: string) {
  if (!testImg) {
    testImg = new Image();
    testImg.crossOrigin = 'Anonymous';

    canvas = document.createElement('canvas');
    ctx = canvas.getContext('2d');
    assert(ctx, '2d context not supported');
  }

  testImg.src = url;
  const [width, height] = await new Promise<[number, number]>((resolve, reject) => {
    testImg.onload = () => {
      resolve([testImg.width, testImg.height]);
    };
  });

  canvas.width = width;
  canvas.height = height;

  // Use the canvas to get the image data
  ctx!.drawImage(testImg, 0, 0);
  const imageData = ctx!.getImageData(0, 0, testImg.width, testImg.height);

  // Determine if the image has an alpha channel
  const hasAlpha = imageData.data.length / (testImg.width * testImg.height) === 4;

  return { imageData, hasAlpha };
}

// ----- Commenting out the WebGL way of doing it -- it gave strange results, I think it requires more manual color space management ----- //
// const texture = gl.createTexture();
// gl.bindTexture(gl.TEXTURE_2D, texture);
// gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, testImg);

// const framebuffer = gl.createFramebuffer();
// gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
// gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);

// const pixels = new Uint8Array(width * height * 4);
// gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);

// Clean up
// gl.deleteTexture(texture);
// gl.deleteFramebuffer(framebuffer);
// ----- End the WebGL way ----- //
