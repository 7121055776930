import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { MultiplayerCursor } from './multiplayer-cursor';
import { MultiplayerSelectionHighlight } from './multiplayer-selection-highlight';
import { Fragment } from 'react/jsx-runtime';

export const MultiplayerClients = observer(() => {
  const { multiplayerState, pageState } = useEditor();

  return (
    <>
      {Object.keys(multiplayerState.userData).map((socketClientId) => {
        const user = multiplayerState.userData[socketClientId];
        if (!user || user.pageId !== pageState.activePageId) {
          // Bail if they're not on the same page as our editor
          return null;
        }

        return (
          <Fragment key={socketClientId}>
            <MultiplayerCursor key={`cursor-${socketClientId}`} clientId={socketClientId} />
            <MultiplayerSelectionHighlight key={`highlight-${socketClientId}`} clientId={socketClientId} />
          </Fragment>
        );
      })}
    </>
  );
});
