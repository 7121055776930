import { observer } from 'mobx-react-lite';
import { BuiltInComponentMap, PropConfigSlider } from '../built-in-ui/built-in-ui';
import { CollapsibleHeading } from '../../components/collapsible-heading';
import { useState } from 'react';
import { PropsInputColor } from './props-input-color';
import { TreeNode } from '../tree/TreeNode';
import { PropsInputSwitch } from './props-input-switch';
import { PropsInputSlider } from './props-input-slider';

/** Renders the props for a given component meta and set of nodes */
export const PropsListForComponent = observer(
  ({ componentMetaId, nodes }: { componentMetaId: string; nodes: TreeNode[] }) => {
    const [collapsed, setCollapsed] = useState(false);

    const meta = BuiltInComponentMap[componentMetaId];
    if (!meta || Object.keys(meta.props).length === 0) {
      return null;
    }

    return (
      <div className="flex flex-col pb-1">
        <CollapsibleHeading collapsed={collapsed} onCollapsedChange={(collapsed) => setCollapsed(collapsed)}>
          {meta.label} settings
        </CollapsibleHeading>

        {!collapsed && (
          <>
            {Object.values(meta.props).map((propConfig) => {
              if (propConfig.inputType === 'slider') {
                return (
                  <PropsInputSlider config={propConfig as PropConfigSlider} nodes={nodes} key={propConfig.propName} />
                );
              } else if (propConfig.inputType === 'switch') {
                return <PropsInputSwitch config={propConfig} nodes={nodes} key={propConfig.propName} />;
              } else {
                return <PropsInputColor config={propConfig} nodes={nodes} key={propConfig.propName} />;
              }
            })}
          </>
        )}
      </div>
    );
  }
);
