import * as Popover from '@radix-ui/react-popover';
import { useState } from 'react';
import { Popup } from '../../components/popup';
import { Color, ColorUtils } from '../properties/Color';
import { ColorDetails } from './color-details';
import { HueSlider, OpacitySlider } from './color-sliders';
import { Swatches } from './color-swatches';
import { ColorCanvas } from './color-canvas';

type Props = {
  color: Color;
  onChange: (color: Color) => void;
};

export function ColorPicker(props: Props) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isUniform, setIsUniform] = useState(false);

  return (
    <Popover.Root open={popoverOpen} onOpenChange={setPopoverOpen}>
      <Popover.Trigger asChild>
        <button
          className="border-gray-5 focus-visible:outline-focus aspect-square size-4 rounded border focus-visible:outline-2"
          style={{ backgroundColor: ColorUtils.cssString(props.color) }}
        />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content asChild align="start" alignOffset={-16} side="left" sideOffset={8} collisionPadding={16}>
          <Popup className="flex gap-3 p-3 select-none">
            <div className="flex gap-2_5">
              <ColorCanvas {...props} isUniform={isUniform} />
              <OpacitySlider {...props} />
              <HueSlider {...props} isUniform={isUniform} />
            </div>
            <div className="flex w-[192px] flex-col gap-2">
              <Swatches {...props} />
              <ColorDetails {...props} isUniform={isUniform} setIsUniform={setIsUniform} />
            </div>
          </Popup>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
