import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { DROP_PREVIEW_COLOR } from './drop-target-highlight';

/**
 * Renders ghost outlines for drag positions when dragging items that are in flex layouts
 * When dragging from DOM layouts, we don't move the actual nodes until the drag is complete
 * They dim in place and we render these ghost outlines in the shape of the nodes at the user's cursor
 */
export const DragGhosts = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { hudState, moveToolState } = editorState;
    const dragState = moveToolState.dragState;

    function drawDragGhosts(ctx: CanvasRenderingContext2D) {
      // Don't draw if we're not dragging
      if (dragState.isDragging === false) return;
      // Don't draw if there are no ghosts
      if (dragState.dragGhostPositions.size === 0) return;

      for (const ghost of dragState.dragGhostPositions.values()) {
        ctx.fillStyle = DROP_PREVIEW_COLOR;
        const prevAlpha = ctx.globalAlpha;
        ctx.globalAlpha = 0.15;
        ctx.fillRect(ghost.position.x, ghost.position.y, ghost.size.width, ghost.size.height);
        ctx.globalAlpha = prevAlpha;
      }
    }

    hudState.worldDrawingFunctions.add(drawDragGhosts);
    return () => {
      hudState.worldDrawingFunctions.delete(drawDragGhosts);
    };
  }, [editorState]);

  return null;
});
