import { action, computed, makeObservable, observable } from 'mobx';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { RectWithSize } from '@mobius/models/src/math/rect';
import { Vec2 } from '@mobius/models/src/math/vec2';

export enum TextIntent {
  Draw = 'draw',
  Select = 'select',
  Editing = 'editing',
}

export class TextToolState {
  constructor(private editorState: EditorState) {
    makeObservable(this);

    // Any time a new font loads, tell TextToolState to recompute if it's editing
    document.fonts.addEventListener('loadingdone', this.computeFullIndex);

    // Create a Mutation Observer to watch the text elem for size changes
    this.textElemMutationObserver = new MutationObserver((mutations) => {
      const hasStyleChange = mutations.some(
        (mutation) => mutation.attributeName === 'style' || mutation.attributeName === 'class'
      );
      if (hasStyleChange) {
        // take new range measurement
        this.computeFullIndex();
      }
    });
  }
  dispose = () => {
    document.fonts.removeEventListener('loadingdone', this.computeFullIndex);
    this.textElemMutationObserver?.disconnect();
  };

  /** Watches the editing text elem so we can recompute its character sizes if something causes it size to change, like a style change */
  textElemMutationObserver: MutationObserver | null = null;

  startTool = () => {
    // Reset flags
    this.setAllowDrawing(false);
    this.setCurrentIntent(TextIntent.Select);
  };

  endTool = () => {
    // Stop editing if we were editing
    if (this.editingTextNode) {
      this.stopEditingTextNode();
    }
  };

  /** What the user is currently doing with the text tool */
  currentIntent: TextIntent = TextIntent.Select;
  setCurrentIntent = (intent: TextIntent) => {
    this.currentIntent = intent;
  };

  /** Whether clicking in blank canvas space should start a draw -- if not, clicking in blank space ends Text Tool and goes back to Move Tool */
  allowDrawing = false;
  /** Setting this to true will allow drawing a text node until the next text editing session */
  setAllowDrawing = (allowDrawing: boolean) => {
    this.allowDrawing = allowDrawing;
  };

  /** When switching to the text tool, sometimes we want to start editing the selection if it's a solo text node */
  startEditingIfSelectionIsSoloTextNode = () => {
    // If the current selection is one solo text node, start editing it and select all its contents
    const selectedNodes = this.editorState.selectionState.selectedNodes;
    if (selectedNodes.length === 1 && selectedNodes[0] && selectedNodes[0].canEditText) {
      const selectAll = true;
      this.startEditingTextNode(selectedNodes[0], selectAll);
    }
  };

  @computed get hoveredTextNode(): TreeNode | null {
    return this.editorState.pointerState.hoveredNode && this.editorState.pointerState.hoveredNode.canEditText
      ? this.editorState.pointerState.hoveredNode
      : null;
  }

  /** The textarea that takes actual input when we're editing, hidden from view */
  textareaEl: HTMLTextAreaElement | null = null;
  registerTextareaEl = (el: HTMLTextAreaElement) => {
    this.textareaEl = el;
  };

  @observable accessor editingTextNode: TreeNode | null = null;

  @action startEditingTextNode(node: TreeNode, selectAll: boolean = false) {
    this.editingTextNode = node;
    // Do not allow drawing after we start editing - clicking in blank space should end the text tool
    this.setAllowDrawing(false);
    this.setCurrentIntent(TextIntent.Editing);

    // TODO: do something better than this probably? (although it works fine)
    this.textElem = document.querySelector(`[data-node-id="${node!.id}"] > div`);
    if (!this.textElem) {
      console.warn('Unexpected: cannot edit text elem for', node.label);
      return;
    }
    this.computeFullIndex();

    if (selectAll && this.chars.length > 0) {
      this.caretPosition = this.chars.length;
      this.anchorPosition = 0;
    }

    // Focus the textarea to capture input
    this.textareaEl!.focus();

    // Start watching for text elem style changes that might need a recompute of character sizes
    this.textElemMutationObserver!.observe(this.textElem, {
      attributes: true,
      attributeFilter: ['style', 'class'], // optionally watch class too
    });

    // Kick off constant HUD drawing while we're editing text since we use it to draw the caret and selection
    this.editorState.hudState.requestContinuousDraw('text-tool-caret');
  }

  @action stopEditingTextNode() {
    if (this.editingTextNode === null) return;
    // Remove empty text nodes
    if (this.editingTextNode.textValue.trim() === '') {
      this.editorState.treeUtils.deleteNodes(this.editingTextNode.id);
    }

    this.setCurrentIntent(TextIntent.Select);
    this.textareaEl!.blur();
    this.editingTextNode = null;

    // Stop watching for text elem size changes
    this.textElemMutationObserver!.disconnect();

    this.editorState.hudState.cancelContinuousDraw('text-tool-caret');
  }

  /** The current caret index in the text element */
  @observable accessor caretPosition: number = 0;
  /** The current caret line */
  @computed({ keepAlive: true }) get caretLine(): LineMeasurement | null {
    return this.getLineFromCharIndex(this.caretPosition);
  }
  /** When selecting text, the anchor position is the end of the selection opposite from the caret */
  @observable accessor anchorPosition: number = 0;
  /** The current anchor line */
  @computed({ keepAlive: true }) get anchorLine(): LineMeasurement | null {
    return this.getLineFromCharIndex(this.anchorPosition);
  }
  /** The last time the cursor blinked, so we can reset blinks when we change caret positions */
  lastCaretBlinkTime = 0;
  /** Whether the cursor is solid or hidden */
  caretIsBlinkedOn = false;
  /** The DOM text element we measure for character positions */
  textElem: HTMLElement | null = null;
  /** Cache of line information with position measurements */
  @observable accessor lines: Array<LineMeasurement> = [];
  /** Our cache of chars of text with position measurements */
  chars: Array<CharMeasurement> = [];
  /** The range is used to select and measure individual characters in the DOM */
  range = document.createRange();
  /** Whether the textarea is focused */
  isFocused = false;

  /** Stores the X position caret preference, updated any time we set the position and used to position the caret when moving up and down lines */
  upAndDownXPosPreference: number = 0;

  @action
  setCaretPosition = (charIndex: number, moveAnchorToo = true, setNewXPosPreference: boolean = true) => {
    // Do nothing if there isn't a change (just a UX improvement that prevents the caret from blinking off time)
    const caretIsTheSame = this.caretPosition === charIndex;
    const anchorIsTheSame = moveAnchorToo === false || (moveAnchorToo === true && this.anchorPosition === charIndex);
    // Bail if there's no change
    if (caretIsTheSame && anchorIsTheSame) return;

    // Set the caret position to the provided index
    let newCaretPosition = charIndex;
    this.caretPosition = newCaretPosition;
    if (moveAnchorToo) {
      this.anchorPosition = newCaretPosition;
    }

    // Update the x-pref, used if you move the caret up or down to maintain around the same x position
    if (setNewXPosPreference) {
      // Use the char we're currently on, or the previous char if we're at the end of the line
      let prefX = this.chars[newCaretPosition]?.minX;
      if (prefX === undefined) {
        prefX = this.chars[newCaretPosition - 1]?.maxX;
      }
      // It's possible to setCaret before the new index has been computed, so if we can't calculate the new character yet, just skip preference update
      if (prefX) {
        this.upAndDownXPosPreference = prefX;
      }
    }

    // Reset caret blink state
    this.caretIsBlinkedOn = false;
    this.lastCaretBlinkTime = 0;
  };

  @action
  setAnchorPosition = (charIndex: number) => {
    this.anchorPosition = charIndex;
  };

  @action
  computeFullIndex = () => {
    if (!this.textElem || !this.editingTextNode) return; // bail if we're not editing
    const textHtmlNode = this.textElem.firstChild;
    const textContent = textHtmlNode?.textContent;
    if (!textHtmlNode || !textContent) return;

    const startTime = performance.now();

    // Reset the caches
    const textLength = textContent!.length;
    this.chars = new Array(textLength);
    this.lines = [];

    let currentLine: LineMeasurement;
    let currentLineIndex = -1;
    let currentLineY = -Infinity;

    // Grab the camera transform to apply to the text node measurements
    const scale = this.editorState.cameraState.scale;
    const panX = this.editorState.cameraState.pan.x;
    const panY = this.editorState.cameraState.pan.y;

    // We store sizes relative to the text node, not global world space
    // This is useful if something else moves the node while we're editing - the selection moves with it
    // To create a "local space" version of a transformed getBoundingClientRect:
    // (value - pan) / scale - nodeOffset
    // Note that the nodeOffset is already in untransformed space
    const textNode = this.editingTextNode;
    const textNodeOffsetX = textNode.x;
    const textNodeOffsetY = textNode.y;
    const range = this.range;

    // Process each character
    for (let i = 0; i < textLength; i++) {
      const char = textContent[i];
      range.setStart(textHtmlNode, i);
      range.setEnd(textHtmlNode, i + 1);
      const rect = range.getBoundingClientRect();

      // Add the new char to the cache
      this.chars[i] = {
        code: char?.charCodeAt(0) ?? 0,
        minX: (rect.x - panX) / scale - textNodeOffsetX,
        maxX: (rect.right - panX) / scale - textNodeOffsetX,
        width: rect.width / scale,
      };

      // Update the line cache
      if (rect.y !== currentLineY) {
        // We're on a new line
        currentLineIndex = currentLineIndex + 1;
        this.lines.push({
          index: currentLineIndex,
          rect: {
            minX: (rect.x - panX) / scale - textNodeOffsetX,
            minY: (rect.y - panY) / scale - textNodeOffsetY,
            maxX: (rect.right - panX) / scale - textNodeOffsetX,
            maxY: (rect.bottom - panY) / scale - textNodeOffsetY,
            width: 0,
            height: 0,
          },
          startCharIndex: i,
          endCharIndex: i,
        });
        currentLine = this.lines[this.lines.length - 1]!;
        currentLineY = rect.y;
      } else {
        // Still on the same line
        currentLine!.rect.maxX = this.chars[i]!.maxX;
        currentLine!.endCharIndex = i;
      }
    }

    // Loop the lines to set their width/height
    for (const line of this.lines) {
      line.rect.width = line.rect.maxX - line.rect.minX;
      line.rect.height = line.rect.maxY - line.rect.minY;
    }

    const endTime = performance.now() - startTime;
    console.log(`computed full index time: ${endTime}`);
  };

  /** Finds the closest character for a given point in world space */
  findIndexForPoint = (pointInWorld: Vec2): number | null => {
    const editingTextNode = this.editingTextNode;
    if (!editingTextNode) return null;
    if (this.lines.length === 0) return null;

    const pointInNode = {
      x: pointInWorld.x - editingTextNode.x,
      y: pointInWorld.y - editingTextNode.y,
    };

    // Common case: check if it's below the last line
    const lastLine = this.lines[this.lines.length - 1]!;
    if (pointInNode.y > lastLine.rect.maxY) {
      return this.chars.length;
    }
    // Common case: check if it's above the first line
    const firstLine = this.lines[0]!;
    if (pointInNode.y < firstLine.rect.minY) {
      return 0;
    }

    // Find which line we're on
    let lineIndex = 0;
    for (let i = 0; i < this.lines.length; i++) {
      const testLine = this.lines[i]!;
      if (pointInNode.y <= testLine.rect.maxY) {
        lineIndex = i;
        break;
      }
    }
    const matchedLine = this.lines[lineIndex]!;

    // Common case is to be to the right of the max char of the line
    if (pointInNode.x > matchedLine.rect.maxX) {
      // Return an extra index if we're on the last line so the caret goes at the very end
      return this.getEndOfLineIndex(matchedLine);
    }

    // Otherwise, find which character we're on
    for (let i = matchedLine.startCharIndex; i <= matchedLine.endCharIndex; i++) {
      const char = this.chars[i]!;
      if (pointInNode.x <= char.maxX) {
        if (pointInNode.x >= char.minX + char.width / 2) {
          // It's past the halfway mark so use the next index
          return i + 1;
        } else {
          return i;
        }
      }
    }

    // Shouldn't make it this far (really – double check for bugs above)
    console.warn('Unexpected: no character found for point', pointInWorld);
    return null;
  };

  /** Returns the current selection in absolute index */
  getCurrentSelectionAbsolute = () => {
    let startIndex = Math.min(this.caretPosition, this.anchorPosition);
    let endIndex = Math.max(this.caretPosition, this.anchorPosition);
    return { startIndex, endIndex };
  };

  /** Returns the current selection as a string */
  getCurrentSelectionAsString = () => {
    if (!this.editingTextNode) return '';

    const { startIndex, endIndex } = this.getCurrentSelectionAbsolute();
    return this.editingTextNode!.textValue.slice(startIndex, endIndex);
  };

  // ----- Text editing ----- //
  insertText = (newText: string) => {
    if (!this.textElem) return;

    const { startIndex, endIndex } = this.getCurrentSelectionAbsolute();

    this.editingTextNode!.setTextValue(
      this.editingTextNode!.textValue.slice(0, startIndex) + newText + this.editingTextNode!.textValue.slice(endIndex)
    );

    this.setCaretPosition(startIndex + newText.length, true);

    // Recompute the full index after waiting for mobx -> react -> DOM updates
    requestIdleCallback(() => {
      this.computeFullIndex(); // POSSIBLE TODO: only compute from this position forward
    });
  };

  /** Deletes text, either in the provided direction or the current selection */
  deleteText = (direction?: -1 | 1) => {
    if (!this.textElem) return;

    const currentSelection = this.getCurrentSelectionAbsolute();
    let startIndex = currentSelection.startIndex;
    let endIndex = currentSelection.endIndex;

    // If we have a selection, we just delete it... otherwise we delete in the provided direction
    if (startIndex === endIndex) {
      if (direction === -1) {
        // Delete to the left
        startIndex -= 1;
      } else if (direction === 1) {
        // Delete to the right
        endIndex += 1;
      }
    }

    // Clamp the start and end to the bounds of the text
    startIndex = Math.max(startIndex, 0);
    endIndex = Math.min(endIndex, this.chars.length);

    this.editingTextNode!.setTextValue(
      this.editingTextNode!.textValue.slice(0, startIndex) + this.editingTextNode!.textValue.slice(endIndex)
    );

    this.setCaretPosition(startIndex, true);

    // Recompute the full index after waiting for mobx -> react -> DOM updates
    requestIdleCallback(() => {
      this.computeFullIndex(); // POSSIBLE TODO: only compute from this position forward
    });
  };

  deleteWordBackward = () => {
    const startPosition = this.findStartOfPreviousWord(this.caretPosition);
    if (startPosition) {
      this.setAnchorPosition(startPosition);
      this.deleteText();
    }
  };

  deleteWordForward = () => {
    const endPosition = this.findEndOfNextWord(this.caretPosition);
    if (endPosition) {
      this.setAnchorPosition(endPosition);
      this.deleteText();
    }
  };

  /** Moves the caret to the beginning of the current line */
  moveCaretBeginningOfLine = (moveAnchorToo: boolean = true) => {
    const currentLine = this.getLineFromCharIndex(this.caretPosition);
    this.setCaretPosition(currentLine.startCharIndex, moveAnchorToo);
  };
  /** Moves the caret to the end of the current line */
  moveCaretEndOfLine = (moveAnchorToo: boolean = true) => {
    const currentLine = this.getLineFromCharIndex(this.caretPosition);
    this.setCaretPosition(this.getEndOfLineIndex(currentLine), moveAnchorToo);
  };
  getEndOfLineIndex = (line: LineMeasurement) => {
    if (line.index === this.lines.length - 1) {
      // The final line gets +1 to let text to be inserted at the end
      return this.chars.length;
    } else {
      // All other lines get the end char index
      return line.endCharIndex;
    }
  };

  /** Moves the caret to the beginning of the current element */
  moveCaretBeginningOfText = (moveAnchorToo: boolean = true) => {
    this.setCaretPosition(0, moveAnchorToo);
  };
  /** Moves the caret to the end of the current element */
  moveCaretEndOfText = (moveAnchorToo: boolean = true) => {
    this.setCaretPosition(this.chars.length, moveAnchorToo);
  };

  /** Moves one character to the left/previous, will traverse up rows if at the start of the line */
  moveCaretPreviousChar = (moveAnchorToo: boolean = true) => {
    const previousPosition = Math.max(0, this.caretPosition - 1);
    this.setCaretPosition(previousPosition, moveAnchorToo);
  };
  /** Moves one character to the right/next, will traverse down rows if at the end of the line */
  moveCaretNextChar = (moveAnchorToo: boolean = true) => {
    const nextPosition = Math.min(this.chars.length, this.caretPosition + 1);
    this.setCaretPosition(nextPosition, moveAnchorToo);
  };

  moveCaretUpOrDownOneLine = (upOrDown: -1 | 1, moveAnchorToo: boolean = true) => {
    const currentLine = this.getLineFromCharIndex(this.caretPosition);
    const targetLine = this.lines[currentLine.index + upOrDown] ?? null;

    if (targetLine === null) {
      // No target line found, we're either on the first or last line already
      if (upOrDown === -1) {
        // We are already on the first line, move the caret to 0,0
        this.setCaretPosition(0, moveAnchorToo);
      } else if (upOrDown === 1) {
        // We are already on the last line, move the caret to the end of the last line
        this.setCaretPosition(this.chars.length, moveAnchorToo);
      }
    } else {
      // Find the best X position match on the target line
      for (let i = targetLine.startCharIndex; i < targetLine.endCharIndex; i++) {
        const char = this.chars[i]!;
        const midPoint = char.minX + char.width / 2;
        if (this.upAndDownXPosPreference <= midPoint) {
          // Found a matching character around the same x pos
          this.setCaretPosition(i, moveAnchorToo, false);
          return;
        }
      }

      // Didn't find a match so use the end of the target line
      const endOfLineIndex = this.getEndOfLineIndex(targetLine);
      this.setCaretPosition(endOfLineIndex, moveAnchorToo, false);
    }
  };

  /** Moves the caret to the end of the next word */
  moveCaretToEndOfNextWord = (moveAnchorToo: boolean = true) => {
    const caretPosition = this.findEndOfNextWord(this.caretPosition);
    if (caretPosition) {
      this.setCaretPosition(caretPosition, moveAnchorToo);
    }
  };
  findEndOfNextWord = (startPosition: number): number => {
    let absoluteIndex = startPosition;

    // We skip over any whitespace that's immediately prior to the caret
    let hasSeenNonWhitespace = false;
    while (absoluteIndex < this.textElem!.textContent!.length) {
      absoluteIndex += 1;
      if (this.isWhitespace(this.textElem!.textContent!.charCodeAt(absoluteIndex))) {
        if (hasSeenNonWhitespace === false) {
          // Skip whitespace until we see a solid character
          continue;
        }
        // Break out, the last set absoluteIndex is correct
        break;
      } else {
        hasSeenNonWhitespace = true;
      }
    }

    return absoluteIndex;
  };

  /** Moves the caret to the start of the previous word */
  moveCaretToStartOfPreviousWord = (moveAnchorToo: boolean = true) => {
    const caretPosition = this.findStartOfPreviousWord(this.caretPosition);
    if (caretPosition) {
      this.setCaretPosition(caretPosition, moveAnchorToo);
    }
  };
  findStartOfPreviousWord = (startPosition: number): number => {
    // We skip over any whitespace that's immediately prior to the caret
    let hasSeenNonWhitespace = false;
    let absoluteIndex = startPosition;
    while (absoluteIndex > 0) {
      absoluteIndex -= 1;
      if (this.isWhitespace(this.textElem!.textContent!.charCodeAt(absoluteIndex))) {
        if (hasSeenNonWhitespace === false) {
          // Skip whitespace until we see a solid character
          continue;
        }
        // Add one back on so we don't insert before the whitespace we found
        absoluteIndex += 1;
        break;
      } else {
        hasSeenNonWhitespace = true;
      }
    }
    return absoluteIndex;
  };

  /** Finds the continuous chunk of full text block or full amount of white space at the provided insertion caret */
  findContinuousChunk = (charIndex: number): { startIndex: number; endIndex: number } => {
    // If the provided character is whitespace, match whitespace, otherwise match text
    const char = this.chars[charIndex];
    if (!char) {
      console.warn('Unexpected: no character found in chunk search for', charIndex);
      return { startIndex: charIndex, endIndex: charIndex };
    }
    const matchWhitespace = this.isWhitespace(char.code);

    // Select the full whitespace or text block
    // Find the start of the whitespace
    let prevIndex = charIndex;
    let prevChar = this.textElem!.textContent![prevIndex]!;
    while (prevIndex > 0) {
      prevIndex -= 1;
      prevChar = this.textElem!.textContent![prevIndex]!;
      if (this.isWhitespace(prevChar.charCodeAt(0)) !== matchWhitespace) {
        prevIndex += 1;
        break;
      }
    }
    // Find the end of the whitespace
    let nextIndex = charIndex;
    let nextChar = this.textElem!.textContent![nextIndex]!;
    while (
      nextIndex < this.textElem!.textContent!.length &&
      this.isWhitespace(nextChar.charCodeAt(0)) === matchWhitespace
    ) {
      nextIndex += 1;
      nextChar = this.textElem!.textContent![nextIndex]!;
    }

    return { startIndex: prevIndex, endIndex: nextIndex };
  };

  /** Used during double click drags drags - selects all continuous chunks from the starting caret to the current caret */
  selectContinuousChunks = (startCharIndex: number, currentCharIndex: number) => {
    const startChunk = this.findContinuousChunk(startCharIndex);
    const currentChunk = this.findContinuousChunk(currentCharIndex);

    // Determine whether the caret goes at the start or the beginning
    const caretIsAtEnd = currentCharIndex > startCharIndex;

    let anchorPos: number;
    let caretPos: number;
    if (caretIsAtEnd) {
      // If the caret is at the end, the anchor should use the start of the starting chunk
      anchorPos = startChunk.startIndex;
      // And the caret should use the end of the current chunk
      caretPos = currentChunk.endIndex;
    } else {
      // If the caret is before where we started, the anchor should use the end of where we started
      anchorPos = startChunk.endIndex;
      // And the caret should use the start of the current position's chunk
      caretPos = currentChunk.startIndex;
    }

    this.setCaretPosition(caretPos, false);
    this.setAnchorPosition(anchorPos);
  };

  selectFullLine = (startCharIndex: number, endCharIndex: number) => {
    const startLine = this.getLineFromCharIndex(startCharIndex);
    const endLine = this.getLineFromCharIndex(endCharIndex);
    if (!startLine || !endLine) {
      console.warn('Unexpected: could not find lines for selectFullLine', startCharIndex, endCharIndex);
      return;
    }

    const caretIsAtEnd = endCharIndex >= startCharIndex;

    if (caretIsAtEnd) {
      // If the caret is at the end, the anchor should use the start of the starting line
      this.setAnchorPosition(startLine.startCharIndex);
      // And the caret should use the end of the current line
      const endOfLineIndex = this.getEndOfLineIndex(endLine);
      this.setCaretPosition(endOfLineIndex, false);
    } else {
      // If the caret is before where we started, the anchor should use the end of the starting line
      this.setAnchorPosition(startLine.endCharIndex);
      // And the caret should use the start of the current line
      const startOfLineIndex = endLine.startCharIndex;
      this.setCaretPosition(startOfLineIndex, false);
    }
  };

  selectAll = () => {
    this.setAnchorPosition(0);
    this.setCaretPosition(this.chars.length, false);
  };

  /** Given an absolute index, returns the line index. O(N) for N lines */
  getLineFromCharIndex = (charIndex: number): LineMeasurement => {
    for (let i = 0; i < this.lines.length; i++) {
      const line = this.lines[i]!;
      const endOfLineIndex = this.getEndOfLineIndex(line);
      if (charIndex >= line.startCharIndex && charIndex <= endOfLineIndex) {
        return line;
      }
    }

    if (charIndex >= this.chars.length) {
      // Beyond the last line, return the last line
      return this.lines[this.lines.length - 1]!;
    } else {
      // Before the first line, return the first line
      return this.lines[0]!;
    }
  };

  /** Checks if a character code is whitespace */
  isWhitespace = (charCode: number) => {
    // https://en.wikipedia.org/wiki/Whitespace_character
    // 9 horizontal tab, 10 line feed, 13 carriage return, 32 space
    return charCode === 32 || charCode === 9 || charCode === 10 || charCode === 13;
  };
}

export type LineMeasurement = {
  /** The index of this line in the lines array */
  index: number;
  /** The bounding rect of this line */
  rect: RectWithSize;
  /** The overall index of the first character in this line */
  startCharIndex: number;
  /** The overall index of the last character in this line */
  endCharIndex: number;
};
type CharMeasurement = { code: number; minX: number; maxX: number; width: number };
