import {
  LayoutModeForChildrenSchema,
  LayoutModeForChildrenType,
  TreeNodeType,
} from '@mobius/models/src/file/tree-node-schema';
import { Rect, RectWithSize } from '@mobius/models/src/math/rect';
import { action, computed, makeObservable } from 'mobx';
import { EditorState } from '../EditorState';
import { BuiltInComponentMap } from '../built-in-ui/built-in-ui';
import { NodeRelationshipsKeys } from '@mobius/models/src/file/file-schema';
import { splitTreeRelationshipString } from '@mobius/models/src/file/tree-node-relationships';
import { assert } from '@mobius/models/src/assert';
import { Fill } from '../properties/fill-property';
import { Font } from '../fonts/FontState';
import { Color } from '../properties/Color';

/** TreeNode class is deserialized on demand when the editor references a nodeId, and then cached as long as it is observed */
export class TreeNode {
  constructor(
    public editorState: EditorState,
    private readonly nodeId: string
  ) {
    makeObservable(this);
  }

  @computed({ keepAlive: true }) get data(): TreeNodeType {
    return this.editorState.fileState.data.nodes[this.nodeId]!;
  }

  get id(): string {
    return this.data.id;
  }

  domEl: HTMLElement | SVGElement | null = null;
  registerDomEl = (el: HTMLElement | SVGElement | null) => {
    this.domEl = el;

    // Request a measurement now that the dom element is rendered
    this.editorState.fileState.measureNodePositionAndSize.requestMeasurement(this);
  };

  /** ----- Positioning and layout ----- */

  @computed({ keepAlive: true }) get x(): number {
    if (this.container) {
      return this.data.x + this.container.x;
    }
    return this.data.x;
  }
  get xInContainer(): number {
    return this.data.x;
  }
  @action setX = (x: number) => {
    if (typeof x !== 'number' || Number.isNaN(x)) {
      console.warn('TreeNode.x set to NaN, ignoring', x);
      return;
    }

    if (this.container) {
      this.data.x = x - this.container.x;
    } else {
      this.data.x = x;
    }
  };

  @computed({ keepAlive: true }) get y(): number {
    if (this.container) {
      return this.data.y + this.container.y;
    }
    return this.data.y;
  }
  get yInContainer(): number {
    return this.data.y;
  }
  @action setY = (y: number) => {
    if (typeof y !== 'number' || Number.isNaN(y)) {
      console.warn('TreeNode.y set to NaN, ignoring', y);
      return;
    }
    if (this.container) {
      this.data.y = y - this.container.y;
    } else {
      this.data.y = y;
    }
  };

  get width(): number {
    return this.editorState.sizeIndex.sizeMap.get(this.id)?.width ?? 0;
  }
  @action
  setWidth = (width: string | number) => {
    this.setStyle('width', width);
  };

  get height(): number {
    return this.editorState.sizeIndex.sizeMap.get(this.id)?.height ?? 0;
  }
  @action
  setHeight = (height: string | number) => {
    this.setStyle('height', height);
  };

  /** ----- Layout mode ----- */
  /** Whether children should be laid out in fixed freeform or flex mode, defaults to fixed */
  @computed({ keepAlive: true }) get layoutModeForChildren(): LayoutModeForChildrenType {
    return this.styles?.display === 'flex' ? 'flex' : 'fixed';
  }

  /** Whether this node is (true) free form fixed layout or (false) being controlled by DOM positioning */
  @computed({ keepAlive: true }) get isFixedLayout(): boolean {
    return this.parent?.styles?.display !== 'flex';
  }

  /** Since other layout modes give control to the DOM, it's useful to check specifically for fixed, where our code controls the layout */
  @computed({ keepAlive: true }) get childrenAreFixed(): boolean {
    if (this.isRoot) {
      return true;
    }

    return this.styles?.display !== 'flex';
  }

  /** ----- Styles ----- */

  @computed({ keepAlive: true }) get styles(): React.CSSProperties {
    return this.data.styles ?? {};
  }

  @action
  setStyle = (property: string, value: string | number | undefined) => {
    if (value === undefined) {
      delete this.data.styles[property];
      return;
    }

    this.data.styles[property] = value;
  };

  get styleMeta(): {
    fills?: Fill[] | null;
    font?: Font | null;
    textColor?: Color | null;
  } {
    return this.data.styleMeta ?? {};
  }

  setStyleMeta(property: 'fills', value: Fill[] | null): void;
  setStyleMeta(property: 'font', value: Font | null): void;
  setStyleMeta(property: 'textColor', value: Color | null): void;
  @action setStyleMeta(property: string, value: any) {
    this.data.styleMeta ??= {};
    this.data.styleMeta[property] = value;
  }

  @action
  setProp(prop: string, value: any) {
    this.data.props ??= {};
    this.data.props[prop] = value;
  }

  get props() {
    return this.data.props;
  }

  get label(): string {
    return this.data.label;
  }
  @action setLabel(label: string) {
    this.data.label = label;
  }

  get textValue(): string {
    return this.data.textValue;
  }
  @action setTextValue(textValue: string) {
    this.data.textValue = textValue;
  }

  get component(): string {
    return this.data.component;
  }

  /** Returns the closest Box or root node, the container that is setting this node's position */
  @computed({ keepAlive: true }) get container(): TreeNode | null {
    // Loop backwards through the ancestor chain, starting with the closest ancestor
    for (let i = this.ancestors.length - 1; i >= 0; i--) {
      const ancestor = this.ancestors[i]!;
      if (ancestor.component === 'Box' || ancestor.isRoot) {
        return ancestor;
      }
    }
    return null;
  }

  @computed({ keepAlive: true }) get canHaveChildren(): boolean {
    if (this.isRoot) {
      return true;
    }

    const componentMeta = BuiltInComponentMap[this.component];
    return componentMeta?.canHaveChildren ?? false;
  }

  @computed({ keepAlive: true }) get canEditText(): boolean {
    return this.component === 'Text';
  }

  /** Returns null if this is a Root Node */
  @computed({ keepAlive: true }) get parentId(): string | null {
    const parentId = this.editorState.treeIndex.nodeToParent[this.id];
    return parentId ?? null;
  }

  /** Returns null if this is a Root Node */
  @computed({ keepAlive: true }) get parent(): TreeNode | null {
    const parentId = this.parentId;
    if (parentId === null) {
      return null;
    }
    const parentNode = this.editorState.treeUtils.getNode(parentId);
    return parentNode;
  }

  @computed({ keepAlive: true }) get children(): TreeNode[] {
    const children = this.editorState.treeIndex.parentToChildren[this.id];
    if (children === undefined) {
      return [];
    }
    let childNodes: Array<TreeNode> = [];
    for (const child of children) {
      const childNode = this.editorState.treeUtils.getNode(child.nodeId);
      if (childNode === null) {
        // Can happen temporarily when deleting a node before the index updates
        continue;
      }
      childNodes.push(childNode);
    }
    return childNodes;
  }

  /** Returns all of the ancestors of the node, sorted root node first, all of the way up the tree, including the root node */
  @computed({ keepAlive: true }) get ancestors(): Array<TreeNode> {
    const ancestors: Array<TreeNode> = [];
    let currentParent = this.parent;
    while (currentParent) {
      ancestors.push(currentParent);
      currentParent = currentParent.parent;
    }
    return ancestors.reverse();
  }

  /** Returns all of the descendents of the node, closest first, all of the way down the children chain */
  @computed({ keepAlive: true }) get descendants(): Array<TreeNode> {
    const descendants: Array<TreeNode> = [];

    function searchDownChildren(node: TreeNode) {
      for (const child of node.children) {
        descendants.push(child);
        searchDownChildren(child);
      }
    }
    searchDownChildren(this);
    return descendants;
  }

  /**
   * Returns this node itself (at index 0), its last child (at index 1), its last child's last child (at index 2), etc.
   * Useful for things like layer tree positioning for drag and drop
   */
  @computed({ keepAlive: true }) get selfAndDescendingLastChildren(): Array<TreeNode> {
    const selfAndDescendingLastChildren: Array<TreeNode> = [this];

    let childrenToCheck = this.children;
    while (childrenToCheck.length > 0) {
      selfAndDescendingLastChildren.push(childrenToCheck[childrenToCheck.length - 1]!);
      childrenToCheck = childrenToCheck[childrenToCheck.length - 1]!.children;
    }
    return selfAndDescendingLastChildren;
  }

  /**
   * Each page has exactly one root node, which all other nodes spawn from
   * The root node generally is not returned from product logic API functions
   * It's more of an internal concern for the editor
   */
  @computed({ keepAlive: true })
  get isRoot(): boolean {
    return this.id === this.editorState.treeUtils.rootNode.id;
  }

  /** Returns true only when this direct node is selected */
  @computed({ keepAlive: true })
  get isSelected(): boolean {
    return this.editorState.selectionState.selectedNodeIds.has(this.data.id);
  }

  /** Returns true if this node or any ancestor is selected */
  @computed({ keepAlive: true })
  get isWithinSelection(): boolean {
    return this.isSelected || (this.parent?.isWithinSelection ?? false);
  }

  @computed({ keepAlive: true })
  get isHovered(): boolean {
    return this.editorState.pointerState.hoveredNode?.id === this.data.id;
  }

  @computed({ keepAlive: true })
  get sortKey(): string | null {
    if (this.isRoot) return null;
    const relationship = this.editorState.fileState.data[NodeRelationshipsKeys][this.id];
    assert(typeof relationship === 'string', `Could not find relationship for node: ${this.id}`);
    const sortKey = splitTreeRelationshipString(relationship)[1];
    assert(typeof sortKey === 'string', `Invalid sort key: ${sortKey}`);
    return sortKey;
  }

  @computed({ keepAlive: true })
  get indexOfNodeInParent(): number | null {
    const parent = this.parent;
    if (!parent) {
      return null;
    }
    return parent.children.indexOf(this);
  }

  @computed({ keepAlive: true })
  get previousSibling(): TreeNode | null {
    const index = this.indexOfNodeInParent;
    if (index === null || index === 0) {
      return null;
    }
    return this.parent?.children[index - 1] ?? null;
  }

  @computed({ keepAlive: true })
  get nextSibling(): TreeNode | null {
    const index = this.indexOfNodeInParent;
    const siblings = this.parent?.children ?? [];
    if (index === null || index === siblings.length - 1) {
      return null;
    }
    return siblings[index + 1] ?? null;
  }

  /** Returns the next node in the tree absolutely, whether it's a child, a sibling, back up to a less indented node */
  @computed({ keepAlive: true })
  get nextNode(): TreeNode | null {
    // If we have children, the first child is the next node
    if (this.children.length > 0) {
      return this.children[0]!;
    }
    // Otherwise check for siblings and go up the chain until we find a next sibling
    let nodeToCheck: TreeNode | null = this;
    while (nodeToCheck !== null) {
      if (nodeToCheck.nextSibling) {
        return nodeToCheck.nextSibling;
      }
      nodeToCheck = nodeToCheck.parent;
    }

    return null;
  }

  /** Returns the previous node in the tree absolutely, whether it's a sibling, a sibling's descendant, or the parent */
  @computed({ keepAlive: true })
  get previousNode(): TreeNode | null {
    if (this.previousSibling) {
      // Return the last descendant of the previous sibling or the previous sibling itself if it has no descendants
      return this.previousSibling.descendants[this.previousSibling.descendants.length - 1] ?? this.previousSibling;
    }
    // If no previous sibling, this node's parent is the previous node unless it's the root node, in which case this is the first node in the tree and has no previous node
    if (this.parent?.isRoot) {
      return null;
    }
    return this.parent;
  }

  @computed
  get lastChild(): TreeNode | null {
    return this.children[this.children.length - 1] ?? null;
  }

  @computed
  get firstChild(): TreeNode | null {
    return this.children[0] ?? null;
  }

  /**
   * Whether this node is currently hidden or visible in the layer tree
   * Note: Root will return true
   * Note: This is recursive
   * keepAlive is a good idea here since the time complexity of this can get wild if many things move in the tree
   */
  @computed({ keepAlive: true })
  get isVisibleInLayerTree(): boolean {
    if (!this.parentId || !this.parent) {
      return false;
    }

    // Root counts as always visible and its direct children are always visible
    if (this.isRoot || this.parent.isRoot) {
      return true;
    }

    // If the parent is expanded, and the parent is visible, then this node is visible
    const parentIsExpanded = this.editorState.layerTreeState.expandedNodeIds.has(this.parentId);
    return parentIsExpanded && this.parent.isVisibleInLayerTree;
  }

  /** Returns true if this is a top level Box that should behave as a "Frame" */
  @computed({ keepAlive: true })
  get isFrame(): boolean {
    return this.parent?.isRoot ?? false;
  }

  /**
   * Returns true if this is a top level frame that should not be directly hoverable and selectable
   * The logic is: top level frame + has children + is fixed layout mode (flex layout should be directly selectable)
   */
  @computed({ keepAlive: true })
  get isNonSelectableFrame(): boolean {
    return this.isFrame && this.children.length > 0 && this.layoutModeForChildren === 'fixed';
  }

  /** Returns the top level Box on Canvas  */
  @computed({ keepAlive: true })
  get containerFrame(): TreeNode | null {
    if (this.isFrame || this.parent?.isRoot === true) {
      return null;
    }
    for (let ancestor of this.ancestors) {
      if (ancestor.isFrame) {
        return ancestor;
      }
    }
    return null;
  }

  @computed({ keepAlive: true })
  get bounds(): RectWithSize {
    return {
      minX: this.x,
      minY: this.y,
      maxX: this.x + this.width,
      maxY: this.y + this.height,
      width: this.width,
      height: this.height,
    };
  }
}
