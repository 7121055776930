import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import Menu from '../../components/context-menu';
import { useState } from 'react';
import { TreeNode } from '../tree/TreeNode';
import { selectAllBetween } from './select-all-between';
import { MenuForNodes } from '../context-menu-for-nodes/menu-for-nodes';
import { MenuForNoSelection } from '../context-menu-for-nodes/menu-for-no-selection';

export const LayerTreeContextMenu = observer(
  ({ children, containerRef }: { children: React.ReactNode; containerRef: React.RefObject<HTMLElement> }) => {
    const editorState = useEditor();
    const { treeUtils, selectionState } = editorState;
    const [nodes, setNodes] = useState<Array<TreeNode> | null>(null);

    /** Since we're using one trigger for the entire Layer Tree, we need to manually figure out which node we're targeting */
    function handleTriggerContextMenu(e: React.MouseEvent) {
      const targetNodeEl = ((e.target as HTMLElement)?.closest('[data-node-id]') as HTMLElement) ?? null;
      const nodeId = targetNodeEl?.getAttribute('data-node-id');
      if (nodeId) {
        // Select the node if it's not already part of the selection
        if (e.shiftKey && containerRef.current) {
          // Select all between this node and the last node
          selectAllBetween(editorState, containerRef.current, nodeId, targetNodeEl);
        } else {
          // Select this node (additive if meta key is pressed)
          if (!selectionState.selectedNodeIds.has(nodeId)) {
            selectionState.selectIds([nodeId], { additive: e.metaKey });
          }
        }
      } else {
        // Click in blank space below the layer tree
        selectionState.selectIds([]);
      }

      // Clone the selected node array to get a snapshot of the nodes this context menu should operate on
      setNodes([...selectionState.selectedNodes]);
    }

    return (
      <Menu.Root>
        <Menu.Trigger style={{ display: 'contents' }} onContextMenu={handleTriggerContextMenu}>
          {children}
        </Menu.Trigger>

        <Menu.Portal>
          <Menu.Content>
            {nodes !== null && nodes.length > 0 ? (
              <MenuForNodes editorState={editorState} nodes={nodes} />
            ) : (
              <MenuForNoSelection editorState={editorState} />
            )}
          </Menu.Content>
        </Menu.Portal>
      </Menu.Root>
    );
  }
);
