import { makeObservable, observable, action } from 'mobx';
import { TreeNode } from '../tree/TreeNode';
import { EditorState } from '../EditorState';

export class DrawToolState {
  constructor(private readonly editorState: EditorState) {
    makeObservable(this);
  }

  @observable accessor componentToDraw = 'Box';
  @action setComponentToDraw(component: string) {
    this.componentToDraw = component;
  }

  /** Invoked by the ToolState if another tool takes over */
  endTool = () => {
    if (this.drawingNode) {
      this.stopDrawing();
    }
  };

  @observable accessor drawingNode: TreeNode | null = null;
  @action setDrawingNode(node: TreeNode | null) {
    this.drawingNode = node;
  }
  @action stopDrawing() {
    this.drawingNode = null;
  }
}
