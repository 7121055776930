import React, { useMemo } from 'react';
import { TreeNode } from '../tree/TreeNode';
import { ComponentMeta } from './built-in-ui';
import { PropertyPanels } from '../properties/property-panel';
import { MeshGradient } from '@paper-design/shaders-react';
import { convertObjectToCss } from '../../code-gen/css-to-object';

export type ShaderProps = {
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  speed: string;
  animated: boolean;
};

export const Shader = ({
  color1 = '#000000',
  color2 = '#ffffff',
  color3 = '#000000',
  color4 = '#ffffff',
  speed = '0.2',
  style,
  animated = true,
  ref,
}: {
  node: TreeNode;
  style: React.CSSProperties;
  children: React.ReactNode;
  ref?: React.RefObject<HTMLCanvasElement>;
} & ShaderProps) => {
  // We need to memo this or it will cause the ShaderMount to dispose and re-create every time the props change
  const contextAttributes = useMemo(() => {
    // We need this in Paper to allow you get image data from the shader. Do not embed this in the library for general usage.
    return { preserveDrawingBuffer: true };
  }, []);

  const speedParsed = Number.parseFloat(speed);
  const speedNumber = isNaN(speedParsed) ? 0.2 : speedParsed;

  return (
    <MeshGradient
      color1={color1}
      color2={color2}
      color3={color3}
      color4={color4}
      speed={speedNumber}
      ref={ref}
      style={style}
      animated={animated}
      webGlContextAttributes={contextAttributes}
    />
  );
};

export const ShaderMeta: ComponentMeta = {
  label: 'Shader',
  component: Shader,
  canHaveChildren: false,
  propertyPanels: new Set([PropertyPanels.Position, PropertyPanels.Layout]),
  props: {
    color1: { propName: 'color1', label: 'Color 1', inputType: 'color', defaultValue: '#000000' },
    color2: { propName: 'color2', label: 'Color 2', inputType: 'color', defaultValue: '#ffffff' },
    color3: { propName: 'color3', label: 'Color 3', inputType: 'color', defaultValue: '#000000' },
    color4: { propName: 'color4', label: 'Color 4', inputType: 'color', defaultValue: '#ffffff' },
    speed: {
      propName: 'speed',
      label: 'Speed',
      inputType: 'slider',
      min: 0,
      max: 2,
      defaultValue: '0.3',
      step: 0.01,
    },
    animated: { propName: 'animated', label: 'Animated', inputType: 'switch', defaultValue: true },
  },
  layerTreeIcon: 'image',
  generateJsx: (editorState, node) => {
    const imports = 'import { MeshGradient } from "@paper-design/shaders-react";';

    const props = node.props ?? {};
    const propsString = Object.entries(props)
      .map(([key, value]) => {
        if (value === undefined || value === null) return '';

        if (typeof value === 'boolean') {
          // Boolean
          return `${key}={${value.toString()}}`;
        } else if (typeof value === 'number') {
          // Number
          return `${key}={${value}}`;
        } else {
          // String
          return `${key}="${value}"`;
        }
      })
      .join(' ');

    let jsxString = '';
    // Add styles if there are any to set
    const stylesString = convertObjectToCss(node.styles ?? {});
    if (stylesString.length > 0) {
      jsxString = `<MeshGradient ${propsString} style="${stylesString}" />`;
    } else {
      jsxString = `<MeshGradient ${propsString} />`;
    }

    return [imports, jsxString];
  },
};
