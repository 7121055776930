interface SpacerProps extends React.ComponentProps<'div'> {
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  minWidth?: React.CSSProperties['minWidth'];
  minHeight?: React.CSSProperties['minHeight'];
  maxWidth?: React.CSSProperties['maxWidth'];
  maxHeight?: React.CSSProperties['maxHeight'];
}

export const Spacer = ({ width, height, minWidth, minHeight, maxWidth, maxHeight, style, ...props }: SpacerProps) => {
  return <div style={{ width, height, minWidth, minHeight, maxWidth, maxHeight, ...style }} {...props} />;
};
