const prefixHtml = '<meta charset="utf-8">';

const embedStartIdentifier = '<!--<paper-paste-start data-embed="';
const embedEndIdentifier = '"></paper-paste-start>-->';

/** Embeds a given string inside HTML so it can be easily pasted back into the app and retrieved */
export function makeEmbeddedHtmlForClipboard(stringToEmbedInHtml: string, plainTextPaste?: string) {
  const embeddedHtml = `${embedStartIdentifier}${stringToEmbedInHtml}${embedEndIdentifier}`;

  const plainTextContent =
    typeof plainTextPaste === 'string' ? `<span style="white-space:pre-wrap;">${plainTextPaste}</span>` : '';

  return `${prefixHtml}${embeddedHtml}${plainTextContent}`;
}

export function parseEmbeddedHtmlFromClipboard(clipboardHtml: string) {
  const startIndex = clipboardHtml.indexOf(embedStartIdentifier);
  const endIndex = clipboardHtml.indexOf(embedEndIdentifier);
  if (startIndex === -1 || endIndex === -1) {
    return null;
  }

  return clipboardHtml.slice(startIndex + embedStartIdentifier.length, endIndex);
}
