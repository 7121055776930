import { observer } from 'mobx-react-lite';
import { PropertyPanels } from '../properties/property-panel';
import { useEditor } from '../editor-context';
import { reducePropertyValues } from '../properties/reduce-property-values';
import { Field } from '../../components/field';
import { NumberInput } from '../properties/number-input';

export const FontWeightProperty = observer(() => {
  const { propertiesState } = useEditor();

  const values = propertiesState.getStyleValues('fontWeight');
  const inputValue = reducePropertyValues(values, '400');

  function commitValue(value: string) {
    for (const node of propertiesState.nodesPerProperty[PropertyPanels.Typography]) {
      node.setStyle('fontWeight', value);
    }
  }

  return (
    <Field.Root>
      <Field.Icon>
        <svg width="14" height="16" viewBox="0 0 14 16" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M3.44189 10L6.32927 2.00293H7.67043L10.5578 10H9.2499L8.58323 8H5.41607L4.74426 10H3.44189ZM6.94998 3.40505H7.04419L8.24989 7H5.74981L6.94998 3.40505Z"
          />
          <path d="M1.25 13.5L4 11.25L4 13L10 13L10 11.25L12.75 13.5L10 15.75L10 14L4 14L4 15.75L1.25 13.5Z" />
        </svg>
      </Field.Icon>
      <Field.Control>
        <NumberInput
          min={100}
          max={900}
          units={[]}
          increments={[100, 100]}
          value={inputValue}
          onValueCommit={commitValue}
          format={(value) => weightsMap[value] ?? value}
          parse={(value) => weightsReverseMap[value] ?? value}
        />
      </Field.Control>
    </Field.Root>
  );
});

const weightsMap: Record<string, string> = {
  '100': 'Extra Light',
  '200': 'Light',
  '300': 'Semilight',
  '400': 'Regular',
  '500': 'Medium',
  '600': 'Semibold',
  '700': 'Bold',
  '800': 'Extra Bold',
  '900': 'Black',
};

const weightsReverseMap: Record<string, string> = {
  'Extra Light': '100',
  'Light': '200',
  'Semilight': '300',
  'Regular': '400',
  'Medium': '500',
  'Semibold': '600',
  'Bold': '700',
  'Extra Bold': '800',
  'Black': '900',
};
