import React, { useContext } from 'react';
import { Root } from './Root';

export const RootReactContext = React.createContext<Root | null>(null);

export function useRoot(): Root {
  const root = useContext(RootReactContext);

  if (!root) {
    throw new Error(`Can't find Root — are you using useRoot outside the RootReactContext.Provider?`);
  }

  return root;
}
