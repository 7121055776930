import { NodeRelationshipsKeys } from '@mobius/models/src/file/file-schema';
import { EditorState } from '../EditorState';
import { ulid } from 'ulid';
import { TreeNodeType } from '@mobius/models/src/file/tree-node-schema';
import { TreeNode } from './TreeNode';
import { createMultipleSortOrderKeys } from '@mobius/models/src/file/create-sort-order-key';
import { TempTreeType } from '@mobius/models/src/file/temp-tree-schema';

/** Creates a mini file containing clones of the given nodes and their relationships */
export const cloneNodesIntoTempTree = (editorState: EditorState, nodeIds: string[]): TempTreeType => {
  const partialTree: TempTreeType = {
    id: ulid(),
    topLevelNodeIds: [],
    nodes: {},
    parentToChildrenIndex: {},
    oldIdToNewIdMap: {},
  };

  // Clone the nodes
  for (const nodeId of nodeIds) {
    const node = editorState.treeUtils.getNode(nodeId);
    if (node) {
      const cloneId = cloneNodeIntoPartialTree(node);
      partialTree.topLevelNodeIds.push(cloneId);
      partialTree.oldIdToNewIdMap[node.id] = cloneId;
    }
  }

  /** Recursively clones a node into the temp tree, mutating the temp tree */
  function cloneNodeIntoPartialTree(sourceNode: TreeNode, parentId?: string) {
    const clone: TreeNodeType = JSON.parse(JSON.stringify(sourceNode.data));
    // Create a new ID
    clone.id = ulid();
    // Pop it into the tree
    partialTree.nodes[clone.id] = clone;
    // Add the relationship to the parent if there is one
    if (typeof parentId === 'string') {
      (partialTree.parentToChildrenIndex[parentId] ??= []).push(clone.id);
    }

    // Recursively clone the children
    for (let i = 0; i < sourceNode.children.length; i++) {
      const childNode = sourceNode.children[i]!;
      cloneNodeIntoPartialTree(childNode, clone.id);
    }

    return clone.id;
  }

  return partialTree;
};
