import { action, makeObservable, observable } from 'mobx';
import { splitTreeRelationshipString } from '@mobius/models/src/file/tree-node-relationships';
import { EditorState } from '../EditorState';

type ChildIdAndSortKey = { nodeId: string; sortKey: string };
/** Can include the page ID as a key */
export type ParentToChildrenIndex = Record<string, ChildIdAndSortKey[]>;
export type NodeToParentIndex = Record<string, string>;

/** Maintains an index of all the relationships between tree nodes */
export class TreeIndex {
  constructor(readonly editorState: EditorState) {
    makeObservable(this);

    // Build the index from scratch
    for (const [nodeId, relationshipString] of Object.entries(editorState.fileState.data.nodeRelationships)) {
      this.updateIndex(nodeId, relationshipString);
    }
  }

  @observable
  accessor parentToChildren: ParentToChildrenIndex = {};
  @observable
  accessor nodeToParent: NodeToParentIndex = {};

  /** Will remove a node from its current parent's list of children, if it exists in a parent index, or do nothing if it doesn't */
  @action
  private removeFromParentToChildren = (nodeId: string) => {
    const parentId = this.nodeToParent[nodeId];
    if (!parentId) {
      return;
    }
    const parentIndex = this.parentToChildren[parentId];
    if (!parentIndex) {
      return;
    }

    // Remove the node from the parent's list of children
    for (let i = 0; i < parentIndex.length; i++) {
      if (parentIndex[i]!.nodeId === nodeId) {
        parentIndex.splice(i, 1);
        break;
      }
    }
  };

  @action
  private addToParentToChildren = (nodeId: string, parentId: string, sortKey: string) => {
    if (nodeId === parentId) {
      // Sanity check to prevent infinite recursion
      return;
    }
    const parentToChildren = this.parentToChildren[parentId];
    if (!parentToChildren) {
      this.parentToChildren[parentId] = [{ nodeId, sortKey }];
      return;
    }

    // If we're the only child, just create the index for this parent and continue out
    if (parentToChildren === undefined || parentToChildren.length === 0) {
      this.parentToChildren[parentId] = [{ nodeId, sortKey }];
      return;
    }

    // Insert at the end if we're greater than the last child (most common case)
    const highestSiblingSortKey = parentToChildren[parentToChildren.length - 1]!.sortKey;
    if (sortKey > highestSiblingSortKey) {
      // We're the last child (most common case), just add us to the end
      parentToChildren.push({ nodeId: nodeId, sortKey });
      return;
    }

    // We're not last, so we need to find the right place to insert ourselves
    let inserted = false;
    for (let i = 0; i < parentToChildren.length; i++) {
      const siblingSortKey = parentToChildren[i]!.sortKey;
      if (sortKey < siblingSortKey) {
        parentToChildren.splice(i, 0, { nodeId: nodeId, sortKey });
        inserted = true;
        break;
      }
    }
    if (!inserted) {
      console.warn(
        'Unexpected: could not find a place to insert node in index. Adding to end.',
        { nodeId },
        { parentId },
        { sortKey }
      );
      parentToChildren.push({ nodeId: nodeId, sortKey });
    }
  };

  /** Updates the index for a node, which can include adds or updates */
  @action
  updateIndex = (nodeId: string, newRelationship: string) => {
    const [parentId, sortKey] = splitTreeRelationshipString(newRelationship);
    if (typeof parentId !== 'string' || typeof sortKey !== 'string') {
      console.warn('Unexpected: invalid relationship string', { newRelationship });
      return;
    }
    if (nodeId === parentId) {
      // Ignore root nodes, which lists itself as its parent
      return;
    }

    // Remove it from the old parent's list of children
    this.removeFromParentToChildren(nodeId);
    // Update the node's parent
    this.nodeToParent[nodeId] = parentId;
    // Add it to the new parent's list of children
    this.addToParentToChildren(nodeId, parentId, sortKey);
  };

  @action
  deleteFromIndex = (nodeId: string) => {
    this.removeFromParentToChildren(nodeId);
    delete this.nodeToParent[nodeId];
  };

  /** Returns the sort key of the highest child, or null if there are no children */
  getHighestChildSortKeyForParent = (parentId: string) => {
    const parentToChildren = this.parentToChildren[parentId];
    if (!parentToChildren) {
      // No children have forced a parent index to exist yet
      return null;
    }
    return parentToChildren[parentToChildren.length - 1]?.sortKey ?? null;
  };
}
