import clsx from 'clsx';
import * as Primitive from '@radix-ui/react-toggle-group';

function ToggleGroupRoot({
  className,
  loop = false,
  onValueChange,
  ...props
}: React.ComponentProps<typeof Primitive.Root>) {
  return (
    <Primitive.Root
      loop={loop}
      className={clsx('ToggleGroupRoot', className)}
      onKeyDown={(event) => {
        if (event.key === 'Escape' && document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }}
      onValueChange={(value: any) => {
        if (value) onValueChange?.(value);
      }}
      {...props}
    />
  );
}

function ToggleGroupItem({ className, ...props }: React.ComponentProps<typeof Primitive.Item>) {
  return (
    <Primitive.Item
      className={clsx('ToggleGroupItem', className)}
      onClick={(event) => event.currentTarget.blur()}
      {...props}
    />
  );
}

export const ToggleGroup = {
  Root: ToggleGroupRoot,
  Item: ToggleGroupItem,
};
