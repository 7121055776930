import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { CollapsibleHeading } from '../../components/collapsible-heading';
import { useState } from 'react';
import { Field } from '../../components/field';
import { type Color, ColorUtils } from './Color';
import { ColorInput } from '../../components/color-input';
import { TreeNode } from '../tree/TreeNode';
import { IconButton } from '../../components/icon-button';
import { PlusIcon } from '../../icons/plus-icon';
import { MinusIcon } from '../../icons/minus-icon';
import { EyeIcon } from '../../icons/eye-icon';
import { EyeClosedIcon } from '../../icons/eye-closed-icon';
import { PropertyPanels } from './property-panel';
import { ColorPicker } from '../color-picker/color-picker';

export interface Fill {
  type: 'color';
  color: Color;
  isVisible: boolean;
}

export const FillProperty = observer(() => {
  const { propertiesState } = useEditor();
  const collapsed = propertiesState.collapsedPanels.has(PropertyPanels.Fill);

  const nodes = propertiesState.nodesPerProperty[PropertyPanels.Fill];
  const isMixed = isMixedFill(nodes);
  const fills = nodes[0]?.styleMeta.fills;
  const hasFills = isMixed || hasFillValues(fills);

  const panelHeader = (
    <div
      data-empty={hasFills ? undefined : ''}
      className="flex items-center justify-between pr-2 data-[empty]:-my-1 data-[empty]:py-1 data-[empty]:opacity-60 data-[empty]:hover:opacity-100"
      onPointerDown={(event) => {
        if (hasFills) return;
        if (event.target instanceof HTMLButtonElement) return;
        addFill(nodes);
        propertiesState.expandPanel(PropertyPanels.Fill);
      }}
    >
      <CollapsibleHeading
        collapsible={hasFills}
        collapsed={collapsed}
        onCollapsedChange={(collapsed) =>
          collapsed
            ? propertiesState.collapsePanel(PropertyPanels.Fill)
            : propertiesState.expandPanel(PropertyPanels.Fill)
        }
      >
        Background
      </CollapsibleHeading>
      <IconButton
        onClick={(event) => {
          let handler = isMixed ? replaceMixedFills : addFill;
          handler(nodes);
          propertiesState.expandPanel(PropertyPanels.Fill);
          event.currentTarget.blur();
        }}
      >
        <PlusIcon />
      </IconButton>
    </div>
  );

  if (collapsed) {
    return <div className="flex flex-col pb-1">{panelHeader}</div>;
  }

  return (
    <div className="flex flex-col pb-1">
      {panelHeader}
      {isMixed ? (
        <div className="text-gray-2 px-5 py-1" onPointerDown={() => replaceMixedFills(nodes)}>
          Click to replace mixed fills
        </div>
      ) : (
        fills?.toReversed().map((fill, reverseIndex, { length }) => {
          const index = length - reverseIndex - 1;
          return (
            <FillRow
              key={index}
              fill={fill}
              onFillRemove={() => removeFill(nodes, index)}
              onFillChange={(fill) => upsertFill(nodes, fill, index)}
            />
          );
        })
      )}
    </div>
  );
});

interface FillRowProps {
  fill: Fill;
  onFillChange: (fill: Fill) => void;
  onFillRemove: () => void;
}

const FillRow = ({ fill, onFillChange, onFillRemove }: FillRowProps) => {
  return (
    <div className="flex h-7 items-center gap-2 pr-2 pl-5">
      <div
        data-inactive={fill.isVisible ? undefined : ''}
        className="data-[inactive]:text-gray-3 flex grow items-center gap-2"
      >
        <ColorPicker color={fill.color} onChange={(color) => onFillChange({ type: 'color', color, isVisible: true })} />
        <Field.Control>
          <ColorInput
            color={fill.color}
            onColorCommit={(color) => onFillChange({ type: 'color', color, isVisible: true })}
          />
        </Field.Control>
      </div>

      <div className="flex gap-2">
        <IconButton
          onClick={(event) => {
            onFillChange({ ...fill, isVisible: !fill.isVisible });
            event.currentTarget.blur();
          }}
        >
          {fill.isVisible ? <EyeIcon /> : <EyeClosedIcon />}
        </IconButton>
        <IconButton
          onClick={(event) => {
            onFillRemove();
            event.currentTarget.blur();
          }}
        >
          <MinusIcon />
        </IconButton>
      </div>
    </div>
  );
};

function replaceMixedFills(nodes: TreeNode[]) {
  let fills: Fill[] | undefined;
  const nodesToBackfill: TreeNode[] = [];

  for (const node of nodes) {
    if (!hasFillValues(node.styleMeta.fills)) {
      // These nodes will be backfilled ones we find a real fill
      nodesToBackfill.push(node);
      continue;
    }

    // First fill value will be used for all nodes
    fills ??= node.styleMeta.fills;
    saveFills(node, fills);
  }

  // Apply the fill value to all the nodes we initially
  // saw that didn't have a fill value on them.
  if (fills) {
    for (const node of nodesToBackfill) {
      saveFills(node, fills);
    }
  }
}

export function addFill(nodes: TreeNode[]) {
  upsertFill(nodes, { type: 'color', color: ColorUtils.new(), isVisible: true });
}

function upsertFill(nodes: TreeNode[], fill: Fill, index?: number) {
  for (const node of nodes) {
    let fills = node.styleMeta.fills?.slice() ?? [];
    fills[index ?? fills.length] = fill;
    saveFills(node, fills);
  }
}

function removeFill(nodes: TreeNode[], index: number) {
  for (const node of nodes) {
    const fills = node.styleMeta.fills?.toSpliced(index, 1) ?? [];
    saveFills(node, fills);
  }
}

function saveFills(node: TreeNode, fills: Fill[]) {
  fills = cloneFills(fills);
  node.setStyleMeta('fills', fills.length ? fills : null);
  const styles = getCssStyles(fills);
  for (const key in styles) {
    node.setStyle(key, styles[key]);
  }
}

function cloneFills(fills: Fill[]) {
  return fills.map((fill) => ({ ...fill, color: ColorUtils.clone(fill.color) }));
}

function getCssStyles(fills: Fill[]) {
  let styles: Record<string, string | undefined> = {
    backgroundColor: undefined,
    backgroundImage: undefined,
  };

  for (const fill of fills) {
    if (!fill.isVisible) {
      continue;
    }

    const cssString = ColorUtils.cssString(fill.color);

    if (styles.backgroundColor === undefined) {
      styles.backgroundColor = cssString;
      continue;
    }

    const backgroundImage = `linear-gradient(${cssString}, ${cssString})`;
    styles.backgroundImage = styles.backgroundImage ? `${backgroundImage}, ${styles.backgroundImage}` : backgroundImage;
  }

  return styles;
}

function isMixedFill(nodes: TreeNode[]) {
  let previous: TreeNode;

  for (const current of nodes) {
    previous ??= current;
    if (previous === current) continue;

    const previousHasFills = hasFillValues(previous.styleMeta.fills);
    const currentHasFills = hasFillValues(current.styleMeta.fills);

    // If either is empty, they are mixed if another one isn't empty
    if (!previousHasFills || !currentHasFills) {
      return previousHasFills || currentHasFills;
    }

    const previousFills = previous.styleMeta.fills!;
    const currentFills = current.styleMeta.fills!;

    // Mixed if the number of fills mismatches
    if (previousFills.length !== currentFills.length) return true;

    // Mixed if at least any of the values is different
    for (let i = 0; i < previousFills.length; i++) {
      const prev = previousFills[i]!;
      const cur = currentFills[i]!;
      if (prev.type !== cur.type) return true;
      if (prev.isVisible !== cur.isVisible) return true;
      if (!ColorUtils.isEqual(previousFills[i]!.color, currentFills[i]!.color)) {
        return true;
      }
    }

    previous = current;
  }

  return false;
}

function hasFillValues(value: Fill[] | null | undefined): value is Fill[] {
  return Array.isArray(value) && value.length !== 0;
}
