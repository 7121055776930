import { useEffect } from 'react';
import { useEditor } from '../editor-context';
import { observer } from 'mobx-react-lite';

/**
 * Handles the Pan Tool logic
 * Requires a canvas overlay element so we can attach our pointer events
 */
export const PanTool = observer(({ overlayEl }: { overlayEl: HTMLCanvasElement }) => {
  const editorState = useEditor();

  useEffect(() => {
    const { cameraState, cursorState } = editorState;
    let pointerDown = false;

    function onPointerDown(e: PointerEvent) {
      // Only pay attention to left clicks
      if (e.button !== 0) return;

      // Capture the pointer to ensure all events are sent to this element
      overlayEl.setPointerCapture(e.pointerId);
      pointerDown = true;
      cursorState.setToolCursorClass('cursor-grabbing');
    }

    function onPointerMove(e: PointerEvent) {
      if (pointerDown) {
        cameraState.setPan(cameraState.pan.x + e.movementX, cameraState.pan.y + e.movementY, true);
      }
    }

    function onPointerUp(e: PointerEvent) {
      // Only pay attention to left clicks
      if (e.button !== 0) return;

      // Release the pointer capture when the pointer is up
      overlayEl.releasePointerCapture(e.pointerId);
      pointerDown = false;
      cursorState.setToolCursorClass('cursor-grab');
    }

    overlayEl.addEventListener('pointerdown', onPointerDown);
    overlayEl.addEventListener('pointermove', onPointerMove);
    window.addEventListener('pointerup', onPointerUp);

    cursorState.setToolCursorClass('cursor-grab');

    return () => {
      overlayEl.removeEventListener('pointerdown', onPointerDown);
      overlayEl.removeEventListener('pointermove', onPointerMove);
      window.removeEventListener('pointerup', onPointerUp);

      cursorState.setToolCursorClass(null);
    };
  }, [editorState, overlayEl]);

  return null;
});
