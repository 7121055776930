import { observer } from 'mobx-react-lite';
import { PropertyPanels } from '../properties/property-panel';
import { useEditor } from '../editor-context';
import { reducePropertyValues } from '../properties/reduce-property-values';
import { NumberInput } from '../properties/number-input';
import { Field } from '../../components/field';

export const LineHeightProperty = observer(() => {
  const { propertiesState } = useEditor();

  const values = propertiesState.getStyleValues('lineHeight');
  const inputValue = reducePropertyValues(values, '120%');

  function commitValue(value: string) {
    for (const node of propertiesState.nodesPerProperty[PropertyPanels.Typography]) {
      node.setStyle('lineHeight', value);
    }
  }

  return (
    <Field.Root>
      <Field.Icon>
        <svg width="12" height="14" viewBox="0 0 12 14" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M2.44189 11L5.32927 3.00293H6.67043L9.55781 11H8.2499L7.58323 9H4.41607L3.74426 11H2.44189ZM5.94998 4.40505H6.04419L7.24989 8H4.74981L5.94998 4.40505Z"
          />
          <rect x="0" width="12" height="1" fillOpacity="0.8" />
          <rect x="0" y="13" width="12" height="1" fillOpacity="0.8" />
        </svg>
      </Field.Icon>
      <Field.Control>
        <NumberInput min={0} units={['px', '%']} value={inputValue} onValueCommit={commitValue} />
      </Field.Control>
    </Field.Root>
  );
});
