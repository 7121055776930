/**
 * Finds the median of an array of numbers.
 * If the array length is odd, returns the middle element.
 * If the array length is even, returns the average of the two middle elements.
 */
export function median(numbers: number[]): number {
  if (numbers.length === 0) return 0;

  // Sort the array in ascending order
  const sorted = [...numbers].sort((a, b) => a - b);
  const middleIndex = Math.floor(sorted.length / 2);

  // If array length is odd, return middle element
  // If even, return average of two middle elements
  return sorted.length % 2 === 0 ? (sorted[middleIndex - 1]! + sorted[middleIndex]!) / 2 : sorted[middleIndex]!;
}
