import { CollapsibleHeading } from '../../components/collapsible-heading';
import { WidthProperty } from '../properties/width-property';
import { HeightProperty } from '../properties/height-property';
import { BorderRadiusProperty } from '../properties/border-radius-property';
import { FieldGroup } from '../../components/field';
import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { PropertyPanels } from '../properties/property-panel';
import { LayoutModeForChildren } from './layout-mode-for-children';

export const LayoutProperties = observer(() => {
  const { propertiesState } = useEditor();
  const collapsed = propertiesState.collapsedPanels.has(PropertyPanels.Layout);

  return (
    <div className="flex flex-col pb-1">
      <CollapsibleHeading
        collapsed={collapsed}
        onCollapsedChange={(collapsed) =>
          collapsed
            ? propertiesState.collapsePanel(PropertyPanels.Layout)
            : propertiesState.expandPanel(PropertyPanels.Layout)
        }
      >
        Layout
      </CollapsibleHeading>

      {!collapsed && (
        <div>
          <div className="flex h-7 items-center gap-2 pr-2 pl-5">
            <FieldGroup className="w-[172px]">
              <WidthProperty />
              <HeightProperty />
            </FieldGroup>
            <BorderRadiusProperty />
          </div>

          {/* Flex layout and conditional children layout properties */}
          <LayoutModeForChildren />
        </div>
      )}
    </div>
  );
});
