import type { Static } from '@sinclair/typebox/type';
import { Type } from '@sinclair/typebox/type';
import { Value } from '@sinclair/typebox/value';
import { ulid } from 'ulid';

/** The layout mode for the children of a node, like fixed freeform or flexbox */
export const LayoutModeForChildrenSchema = Type.Union([Type.Literal('fixed'), Type.Literal('flex')]);
export type LayoutModeForChildrenType = Static<typeof LayoutModeForChildrenSchema>;

// NOTE: using .default in TypeBox gives strange behavior
// in the static type, it changes the property to be readonly
// and I'm not 100% sure Value.Create even uses the default values
// "defaults" in TypeBox seem to come with a lot of very nuanced
// technical spec decisions rather than DX decisions
// so I think we should just avoid them

/** The schema for a page */
export const TreeNodeSchema = Type.Object({
  id: Type.String(),
  label: Type.String(),

  x: Type.Number(),
  y: Type.Number(),

  textValue: Type.String(),

  /**
   * The component to render for this node – for now just built ins like Box and Image
   * TODO: This will be more complicated when we allow users to create custom components and kits
   * My current thinking is that we'll have a kit field that allows you to reference published libraries from other files
   * and perhaps also, as a different project, the ability to specify packages from npm.
   */
  component: Type.String(),

  /** The layout mode for the children of this node, like free form "fixed" or flexbox "flex" */
  layoutModeForChildren: Type.Optional(LayoutModeForChildrenSchema),

  /** The styles to apply to the node */
  styles: Type.Record(Type.String(), Type.Union([Type.String(), Type.Number(), Type.Null(), Type.Undefined()])),

  /** The metadata related to the styles */
  styleMeta: Type.Optional(Type.Record(Type.String(), Type.Any())),

  /** The props to pass to the component, can be undefined */
  props: Type.Optional(Type.Record(Type.String(), Type.Any())),
});
export type TreeNodeType = Static<typeof TreeNodeSchema>;

/** Creates a new TreeNodeType object with default values */
export function createTreeNodeData(): TreeNodeType {
  const node = Value.Create(TreeNodeSchema);

  node.id = ulid();
  node.x = 0;
  node.y = 0;

  return node;
}

/** By convention, every page has a root node, its ID will be this prefix plus the page ID */
export const ROOT_NODE_PREFIX = 'root_node_';
export function makeRootNodeId(pageId: string) {
  return `${ROOT_NODE_PREFIX}${pageId}`;
}
