import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import * as MenuPrim from '@radix-ui/react-context-menu';
import { useState } from 'react';
import { TreeNode } from '../tree/TreeNode';
import { MenuForNodes } from '../context-menu-for-nodes/menu-for-nodes';
import { MenuForNoSelection } from '../context-menu-for-nodes/menu-for-no-selection';
import { isPointInRect } from '@mobius/models/src/math/collision';

export const CanvasContextMenu = observer(({ children }: { children: React.ReactNode }) => {
  const editorState = useEditor();
  const { selectionState, pointerState, treeUtils } = editorState;
  const [nodes, setNodes] = useState<Array<TreeNode> | null>(null);

  function handleTriggerPointerDown(e: React.MouseEvent) {
    const pointerDownInWorld = pointerState.cursorPosWorld;

    // We need to check for hovered node manually because when the context menu is open, it blocks pointerState from having a hovered node (which is good everywhere else)
    const hoveredNode = treeUtils.getNodeFromPoint(pointerDownInWorld);

    let pointerDownIsInSelectionBounds = false;
    const selectionBoundsRect = selectionState.selectionBoundsRect;
    if (selectionBoundsRect !== null && e.metaKey === false) {
      // We have a selection, check if the pointer is down inside the selection bounds
      pointerDownIsInSelectionBounds = isPointInRect(pointerDownInWorld, selectionBoundsRect);
    }

    if (pointerDownIsInSelectionBounds) {
      // If we're in selection bounds, we don't want to change selection state
      // empty case
    } else if (hoveredNode === null) {
      // If we're not in selection bounds and not hovering over a node, deselect all
      selectionState.selectIds([]);
    } else {
      // If we're not in selection bounds and hovering over a node, select the hovered node
      selectionState.selectIds([hoveredNode.id], { additive: e.shiftKey });
    }

    // Clone the selected node array to get a snapshot of the nodes this context menu should operate on
    setNodes([...selectionState.selectedNodes]);
  }

  return (
    <MenuPrim.Root>
      <MenuPrim.Trigger onContextMenu={handleTriggerPointerDown}>{children}</MenuPrim.Trigger>

      <MenuPrim.Portal>
        <MenuPrim.Content>
          {nodes !== null && nodes.length > 0 ? (
            <MenuForNodes editorState={editorState} nodes={nodes} />
          ) : (
            <MenuForNoSelection editorState={editorState} />
          )}
        </MenuPrim.Content>
      </MenuPrim.Portal>
    </MenuPrim.Root>
  );
});
