import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { TreeNode } from '../tree/TreeNode';
import { addFlexToNode, wrapNodesWithFlex } from '../tree/wrap-nodes-with-flex';

export const componentsThatShouldOfferFlexLayout = new Set(['Box', 'Text']);

export const AddFlexButton = observer(() => {
  const editorState = useEditor();
  const { propertiesState } = editorState;

  const action = determineFlexAction(propertiesState.allNodes);

  if (action === 'add-flex-to-node') {
    return (
      <button
        onClick={() => {
          // Make sure we only have a single component that supports flex layout selected
          if (
            propertiesState.allNodes.length !== 1 ||
            !componentsThatShouldOfferFlexLayout.has(propertiesState.allNodes[0]!.component)
          ) {
            console.warn('Unexpected: expected a single supporting component selected to add flex layout mode');
            return;
          }
          // Add flex layout to the selected node
          const targetNode = propertiesState.allNodes[0]!;
          // Apply the flex settings to the node
          addFlexToNode(editorState, targetNode);
        }}
      >
        Add Flex
      </button>
    );
  } else if (action === 'remove-flex') {
    return <button onClick={() => removeFlexFromNode(propertiesState.allNodes[0]!)}>Remove Flex</button>;
  } else if (action === 'wrap-in-flex') {
    return <button onClick={() => wrapNodesWithFlex(editorState, propertiesState.allNodes)}>Wrap in Flex</button>;
  }

  return null;
});

// Things that can be selected:
// 1. single component that supports flex layout selected
//   a. using autolayout: show option to remove
//   b. not using autolayout: show option to add
// 2. multiple things selected: show option to add
//   a. everything in same parent: wrap it all in a flex
//   b. not in same parent: each thing in a common parent gets wrapped in its own flex
// 3. single non-supporting component selected: show option to "wrap in flex"
type PossibleActions = 'wrap-in-flex' | 'remove-flex' | 'add-flex-to-node';

export function determineFlexAction(nodes: Array<TreeNode>): PossibleActions {
  if (nodes.length === 1) {
    const singleNode = nodes[0]!;
    if (componentsThatShouldOfferFlexLayout.has(singleNode.component)) {
      // single component that supports flex layout selected, show option to add or remove flex layout depending on whether it's already turned on
      return singleNode.layoutModeForChildren === 'flex' ? 'remove-flex' : 'add-flex-to-node';
    } else {
      // Single non-supporting component selected, show option to "wrap in flex"
      return 'wrap-in-flex';
    }
  } else if (nodes.length > 1) {
    // Multiple things selected: show option to wrap in flex layout
    return 'wrap-in-flex';
  } else {
    // 0 things selected, unexpected case
    console.warn('Unexpected: 0 nodes passed to determineFlexAction');
    return 'wrap-in-flex';
  }
}

/** Removes flex layout from the given component */
export function removeFlexFromNode(node: TreeNode) {
  if (!node || !componentsThatShouldOfferFlexLayout.has(node.component)) {
    console.warn('Unexpected: expected a flex-supporting component to remove flex layout mode');
    return;
  }

  // If the node width or height auto, change it to hard set width
  if (node.styles?.width === 'auto') {
    node.setStyle('width', node.width);
  }
  if (node.styles?.height === 'auto') {
    node.setStyle('height', node.height);
  }

  node.setStyle('display', undefined);
  node.setStyle('flexDirection', undefined);
  node.setStyle('justifyContent', undefined);
  node.setStyle('alignItems', undefined);
  node.setStyle('gap', undefined);
}
