import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { NumberInput } from '../properties/number-input';
import { Field } from '../../components/field';
import { PropertyPanels } from '../properties/property-panel';
import { reducePropertyValues } from '../properties/reduce-property-values';

export const GapProperty = observer(() => {
  const { propertiesState, hudState } = useEditor();

  const currentValue = propertiesState.getStyleValues('gap');
  const inputValue = reducePropertyValues(currentValue, '0px');

  function commitValue(value: string) {
    for (const node of propertiesState.allNodes) {
      if (node.layoutModeForChildren === 'flex') {
        node.setStyle('gap', value);
      }
    }
  }

  return (
    <Field.Root>
      <Field.Icon>Gap</Field.Icon>
      <Field.Control>
        <NumberInput value={inputValue} onValueCommit={commitValue} />
      </Field.Control>
    </Field.Root>
  );
});
