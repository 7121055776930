import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useEditor } from '../editor-context';
import { UserFocusType } from '@mobius/models/src/websocket/multiplayer-user';
import { lerp } from '@mobius/models/src/math/lerp';

// We blur the cursor when the user is not focused on the canvas
const opacityPerFocus = {
  [UserFocusType.Canvas]: 0.8,
  [UserFocusType.Interface]: 0.5,
  [UserFocusType.Blurred]: 0.25,
};

type Props = {
  clientId: string;
};
export const MultiplayerCursor = observer(({ clientId }: Props) => {
  const cursorRef = useRef<HTMLDivElement>(null);
  const editorState = useEditor();

  useEffect(() => {
    let targetX = 0;
    let targetY = 0;
    let lastFrameX = Infinity;
    let lastFrameY = Infinity;

    // Move the cursor whenever the position changes
    const cursorWatcher = autorun(() => {
      const multiplayerUserData = editorState.multiplayerState.userData[clientId];
      if (!multiplayerUserData) {
        return;
      }

      // Update our target position for this cursor
      targetX = multiplayerUserData.cursorPos.x;
      targetY = multiplayerUserData.cursorPos.y;
    });

    // Lerp toward the newest position every frame to give a smooth cursor movement
    function onTick() {
      if (cursorRef.current && lastFrameX !== targetX && lastFrameY !== targetY) {
        const distanceX = Math.abs(targetX - lastFrameX);
        const distanceY = Math.abs(targetY - lastFrameY);
        let newCursorPosX;
        let newCursorPosY;
        if (distanceX > 10_000 || distanceY > 10_000) {
          // If the cursor has moved a lot, jump to the target position immediately
          // This is mostly used for initial load or entering a new page with an existing user
          newCursorPosX = targetX;
          newCursorPosY = targetY;
        } else {
          // Otherwise, lerp toward the target position
          newCursorPosX = lerp(lastFrameX, targetX, 0.3);
          newCursorPosY = lerp(lastFrameY, targetY, 0.3);
        }
        cursorRef.current.style.transform = `translate(${newCursorPosX}px, ${newCursorPosY}px) scale(${editorState.cameraState.scaleInverse})`;
        lastFrameX = newCursorPosX;
        lastFrameY = newCursorPosY;
      }
    }

    editorState.tickState.subToTick(onTick);

    return () => {
      cursorWatcher();
      editorState.tickState.unsubToTick(onTick);
    };
  }, [editorState, clientId]);

  const opacity = opacityPerFocus[editorState.multiplayerState.userData[clientId]?.focus ?? UserFocusType.Blurred];
  const color = editorState.multiplayerState.userData[clientId]?.color ?? 'black';
  return (
    <div
      ref={cursorRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: color,
        borderRadius: '50%',
        width: '18px',
        height: '18px',
        opacity,
      }}
    ></div>
  );
});
