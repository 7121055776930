import { action, computed, makeObservable, observable } from 'mobx';
import { EditorState } from '../EditorState';
import { MIXED_STYLE_VALUE } from './reduce-property-values';
import { createTransformer } from 'mobx-utils';
import { PropertyPanels } from './property-panel';
import { TreeNode } from '../tree/TreeNode';
import { BuiltInComponentMap } from '../built-in-ui/built-in-ui';

export class PropertiesState {
  constructor(readonly editorState: EditorState) {
    makeObservable(this);
  }

  @observable accessor collapsedPanels: Set<PropertyPanels> = new Set();
  @action collapsePanel(panel: PropertyPanels) {
    this.collapsedPanels.add(panel);
  }
  @action expandPanel(panel: PropertyPanels) {
    this.collapsedPanels.delete(panel);
  }

  @computed({ keepAlive: true }) get allNodes() {
    return this.editorState.selectionState.selectedNodes;
  }

  /** Map of property panels to the nodes that use them */
  @computed({ keepAlive: true }) get nodesPerProperty() {
    const propertyNodeMap: Record<PropertyPanels, TreeNode[]> = {
      [PropertyPanels.Position]: [],
      [PropertyPanels.Layout]: [],
      [PropertyPanels.Fill]: [],
      [PropertyPanels.Typography]: [],
    };

    for (const node of this.editorState.selectionState.selectedNodes) {
      const componentMeta = BuiltInComponentMap[node.component];
      if (componentMeta?.propertyPanels) {
        componentMeta.propertyPanels.forEach((panel) => propertyNodeMap[panel].push(node));
      }
    }
    return propertyNodeMap;
  }

  @computed get x() {
    let x: string | null = null;

    for (const node of this.nodesPerProperty[PropertyPanels.Position]) {
      if (x === null) {
        x = String(node.x);
        continue;
      }
      if (x !== String(node.x)) {
        x = MIXED_STYLE_VALUE;
      }
    }

    return x;
  }

  @computed get y() {
    let y: string | null = null;

    for (const node of this.nodesPerProperty[PropertyPanels.Position]) {
      if (y === null) {
        y = String(node.y);
        continue;
      }
      if (y !== String(node.y)) {
        y = MIXED_STYLE_VALUE;
      }
    }

    return y;
  }

  /**
   * Returns an object that contains corresponding `nodeId`'s keyed by unique property values, e.g.:
   * ```
   * {
   *   '12px': ['node-a', 'node-b'],
   *   '13px': ['node-c']
   * }
   * ```
   */
  getStyleValues = createTransformer((property: keyof typeof propertyToPanelMap) => {
    const result: Record<string, string[]> = {};

    // Retrieve values only from the nodes that may have the property
    const panel = propertyToPanelMap[property];
    const nodes = this.nodesPerProperty[panel];

    for (const node of nodes) {
      // Treat `undefined` values as empty strings (so we can use them as a key)
      const value = node.styles[property] ?? '';
      result[value] ??= [];
      result[value].push(node.id);
    }

    return result;
  });
}

// Complete with more properties as needed
const propertyToPanelMap = {
  rotate: PropertyPanels.Position,

  alignItems: PropertyPanels.Layout,
  borderRadius: PropertyPanels.Layout,
  flexDirection: PropertyPanels.Layout,
  gap: PropertyPanels.Layout,
  height: PropertyPanels.Layout,
  justifyContent: PropertyPanels.Layout,
  paddingBottom: PropertyPanels.Layout,
  paddingLeft: PropertyPanels.Layout,
  paddingRight: PropertyPanels.Layout,
  paddingTop: PropertyPanels.Layout,
  width: PropertyPanels.Layout,

  fontSize: PropertyPanels.Typography,
  fontWeight: PropertyPanels.Typography,
  letterSpacing: PropertyPanels.Typography,
  lineHeight: PropertyPanels.Typography,
  textAlign: PropertyPanels.Typography,
  textDecoration: PropertyPanels.Typography,
} satisfies {
  [K in keyof React.CSSProperties]?: PropertyPanels;
};
