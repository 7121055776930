import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { NumberInput } from './number-input';
import { Field } from '../../components/field';
import { reducePropertyValues } from './reduce-property-values';
import { getNumberAndUnit } from './get-number-and-unit';
import { PropertyPanels } from './property-panel';

export const RotateProperty = observer(() => {
  const { propertiesState, hudState } = useEditor();

  const values = propertiesState.getStyleValues('rotate');
  const inputValue = reducePropertyValues(values, '0deg');

  function commitValue(value: string) {
    for (const node of propertiesState.nodesPerProperty[PropertyPanels.Layout]) {
      node.setStyle('rotate', value);
    }

    hudState.requestDraw();
  }

  return (
    <Field.Root>
      <Field.Icon>
        <DegreeIcon />
      </Field.Icon>
      <Field.Control>
        <NumberInput
          units={['deg']}
          format={formatAngle}
          parse={parseAngle}
          value={inputValue}
          onValueCommit={commitValue}
        />
      </Field.Control>
    </Field.Root>
  );
});

const DegreeIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.53415 8.27384L4.49729 11.0002H7.99509C8.12208 9.87393 7.53223 8.78695 6.53415 8.27384ZM7.13893 7.46434L9.86434 3.81628L9.06322 3.21777L3.09906 11.201L2.50195 12.0002H3.49962H12.9996V11.0002H8.99988C9.11898 9.55664 8.38202 8.17597 7.13893 7.46434Z"
        fill="currentcolor"
      />
    </svg>
  );
};

function formatAngle(value: string) {
  // Format degrees using the fancy degree symbol
  if (/(\d)deg$/.test(value)) {
    return value.replace(/(\d)deg$/, '$1°');
  }

  return value;
}

function parseAngle(value: string) {
  const unit = 'deg';
  const min = -360;
  const max = 360;

  // Make sure "Infinity" keyword isn't parsed
  if (/^\s+-?Infinity/.test(value)) {
    return null;
  }

  let [number] = getNumberAndUnit(value);

  if (Number.isNaN(number)) {
    return null;
  }

  if (number >= max) {
    number = number % 360;
  }
  if (number <= min) {
    number = number % -360;
  }

  return number + unit;
}
