import { action, computed, makeObservable, observable } from 'mobx';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { googleFontStylesJson } from './font-styles';
import { BUILT_IN_FAMILIES } from './built-in-fonts';
import { binaryInsert } from 'binary-insert';
import { IMobxChange } from '../file-and-observers/FileDataObserver';

/**
 * README
 *
 * We do not use "font" and "font family" interchangeably.
 * A "font" is a specific font variant, a "family" is a collection of fonts.
 *
 * Dictionary:
 * - Font is an object that represents a particular variant of a typeface
 * - Font style represents the corresponding field in the font metadata, like "Bold", "Compressed Light", "Black Italic"
 * - FontFamily is a map of Font objects that share the same family name
 * - FontBook is a map of FontFamily objects
 *
 * For example:
 * - "SF Pro Text Compressed Light" is the full name of a font.
 * - "Compressed Light" is its style name
 * - "SF Pro Text" is its family name
 *
 * Individual fonts are identified by their style name within a family.
 *
 * We assemble our FontBooks from:
 * - Built-in families - always available in the app for all users
 * - Google families - available on Google Fonts
 * - Local families - available locally on a user's computer
 * - Document families - what's used in the document.
 *
 * Document families aren't necessarily present in any other category.
 *
 * Google families (or just a particular font in a family) may be also available locally, and vice versa. For example,
 * "IBM Plex Mono" is a family that may be both installed locally and be on Google Fonts. However, "IBM Plex Mono Book"
 * is a particular style from that family that is not on Google Fonts.
 */

/** Keyed by `family => style => Font` */
export type FontBook = Map<string, FontFamily>;
export type FontFamily = Map<string, Font>;

export interface Font extends Partial<FontData> {
  family: string;
  style: string;
}

/** Maintains an index of actively needed fonts and loads/stores google font data*/
export class FontState {
  /**
   * All family names (Google, local, built-in, document) sorted alphabetically
   * This list is maintained manually as a performance optimization
   * Use `updateSortedFamilyNames` to regenerate it entirely
   * Use `addToSortedFamilyNames` to add one family
   * Use `removeFromSortedFamilyNames` to remove one family
   */
  @observable accessor sortedFamilyNames: string[] = [];

  /** When updating family lists, call this to regenerate sorted family names */
  @action updateSortedFamilyNames = () => {
    let result: string[] = [];
    this.builtInFamilies.forEach((_, key) => {
      result.push(key);
    });
    this.googleFamilies.forEach((_, key) => {
      result.push(key);
    });
    this.documentFamilies.forEach((_, key) => {
      result.push(key);
    });
    this.localFamilies.forEach((_, key) => {
      result.push(key);
    });
    result = Array.from(new Set(result));
    result.sort((a, b) => a.localeCompare(b, 'en-US'));
    this.sortedFamilyNames = result;
  };

  @action addToSortedFamilyNames = (family: string) => {
    if (this.sortedFamilyNames.some((value) => value === family) === false) {
      // Insert the family into its sorted position (much cheaper than sorting the whole thing)
      binaryInsert(this.sortedFamilyNames, family, (a, b) => a.localeCompare(b, 'en-US'));
    }
  };

  @action removeFromSortedFamilyNames = (family: string) => {
    const index = this.sortedFamilyNames.indexOf(family);
    if (index > -1) {
      this.sortedFamilyNames.splice(index, 1);
    }
  };

  localFamilies: FontBook = new Map();
  builtInFamilies: FontBook = BUILT_IN_FAMILIES;

  /**
   * Google Fonts are a map keyed by `family => style[]`.
   *
   * Google Fonts are NOT a FontBook because we don't have the information to assemble
   * a real `Font` object for each font. We could extrapolate this information, but
   * that'd be just a waste of CPU cycles and RAM.
   */
  googleFamilies: Map<string, string[]> = new Map();

  /** In the browser the user must explicitly allow us to gather info about their local fonts  */
  localFontsPermissionState: PermissionState | 'unsupported' = 'unsupported';

  constructor(readonly editorState: EditorState) {
    makeObservable(this);

    // Schedule async loading of fonts
    requestIdleCallback(async () => {
      await this.loadGoogleFonts();

      // Get local fonts. As of 2024, this is supported in Chrome only
      if ('queryLocalFonts' in window) {
        // Check whether the permission had been granted before
        const permission = 'local-fonts' as PermissionName;
        const { state } = await window.navigator.permissions.query({ name: permission });
        this.localFontsPermissionState = state;

        if (state === 'granted') {
          await this.loadLocalFonts();
        }
      }

      // Discover families used in the document
      this.generateFontToNodeIndexForFirstLoad();

      // Build a sorted family name index
      this.updateSortedFamilyNames();
    });
  }

  /** Loads fonts available on Google */
  loadGoogleFonts = async () => {
    const response = await fetch('/static/google-fonts/generated-fonts-data.json');
    const data: Record<string, string[]> = await response.json();
    for (const family in data) {
      this.googleFamilies.set(family, data[family]!);
    }
  };

  /**
   * Attempts to load local fonts available locally.
   * Will display a local fonts permission request if it hadn't been granted before.
   * @returns success: boolean
   */
  loadLocalFonts = async () => {
    if (this.localFamilies.size) {
      return true;
    }

    // As of 2024, this is supported in Chrome only
    const localFontsRaw = await window.queryLocalFonts();

    // Update permission state after the request
    const permission = 'local-fonts' as PermissionName;
    const { state } = await window.navigator.permissions.query({ name: permission });
    this.localFontsPermissionState = state;

    // Group individual font styles into font families
    for (const fontData of localFontsRaw) {
      if (!this.localFamilies.has(fontData.family)) {
        this.localFamilies.set(fontData.family, new Map());
      }

      this.localFamilies.get(fontData.family)?.set(fontData.style, fontData);
    }

    if (this.localFamilies.size) {
      this.updateSortedFamilyNames();
      return true;
    }

    return false;
  };

  /**
   * Whether a font is available locally.
   *
   * Notes:
   * - A font may be present both among local fonts and among Google Fonts.
   * - Built-in families aren't considered to be among local fonts because
   *   we can't reliably verify whether a particular font style exists for them.
   */
  isAvailableLocally = (font: Font) => {
    return Boolean(this.localFamilies.get(font.family)?.has(font.style));
  };

  /**
   * Whether a font is available on Google Fonts.
   *
   * Note: a font may be present both among local fonts and among Google Fonts.
   */
  isAvailableFromGoogle = (font: Font) => {
    return Boolean(
      this.googleFamilies.get(font.family)?.some((style) => {
        return googleFontStylesJson[style] === font.style;
      })
    );
  };

  /**
   * Create a `FontFamily` from all the `Font` objects we have access to.
   *
   * Priorities:
   * 1. Local fonts
   * 2. Document fonts
   * 3. Google Fonts
   * 4. Built-in fonts
   *
   * A family may be interweaved from fonts that exist in different sources.
   * For example, a family may have a few fonts sourced locally and a few sourced from Google.
   */
  getFamily = (familyName: string) => {
    const family: FontFamily = new Map();

    this.builtInFamilies.get(familyName)?.forEach((font, style) => {
      family.set(style, font);
    });
    this.googleFamilies.get(familyName)?.forEach((googleFontStyle) => {
      const style = googleFontStylesJson[googleFontStyle];

      if (style) {
        family.set(style, { family: familyName, style });
      } else {
        console.error('Incorrect Google font style', googleFontStyle);
      }
    });
    this.documentFamilies.get(familyName)?.forEach((font, style) => {
      family.set(style, font);
    });
    this.localFamilies.get(familyName)?.forEach((font, style) => {
      family.set(style, font);
    });

    return family;
  };

  getBestMatch = (font: Font, family: FontFamily) => {
    // TODO Vlad implement this for real (currently returns just the regular weight)
    const familyArr = Array.from(family.values());
    return familyArr.find((candidate) => /^(regular|roman|normal)$/i.test(candidate.style)) || familyArr[0]!;
  };

  /** An index of font names to nodes, organized by `family => style => nodes` */
  @observable accessor fontToNodeIndex: Map<string, Map<string, Set<TreeNode>>> = new Map();

  @computed get documentFamilies(): FontBook {
    const result: FontBook = new Map();
    this.fontToNodeIndex.forEach((styleMap, familyName) => {
      const family: FontFamily = new Map();
      result.set(familyName, family);
      styleMap.forEach((_, style) => {
        family.set(style, { family: familyName, style });
      });
    });
    return result;
  }

  /** Loops every node in the file and maps fonts to nodes that use them */
  private generateFontToNodeIndexForFirstLoad = () => {
    for (const node of this.editorState.treeUtils.rootNode.descendants) {
      if (node.styleMeta.font) {
        this.addFontToNodeIndex(node.styleMeta.font, node);
      }
    }
  };

  /**
   * This is called by the FileDataObserver if it sees a change the a property named "font"
   * Possible TODO: consider registering these as callbacks to the FileDataObserver rather than hard coding calls in
   */
  handleFontFamilyChange = (path: string, change: IMobxChange) => {
    const pathSplit = path.split('/');
    const nodeId = pathSplit[1];
    if (pathSplit[0] !== 'nodes' || !nodeId) {
      console.warn('Unexpected: received non-node path for font change', path);
      return;
    }
    const node = this.editorState.treeUtils.getNode(nodeId);
    if (!node) {
      console.warn('Unexpected: could not find node for font family change', path);
      return;
    }

    let oldValue: Font | null = null;
    let newValue: Font | null = null;
    if (change.type === 'add') {
      // add to index
      newValue = change.newValue;
    } else if (change.type === 'update') {
      // remove old value from index
      // add new value to index
      oldValue = change.oldValue;
      newValue = change.newValue;
    } else if (change.type === 'remove') {
      // remove from index
      oldValue = change.oldValue;
    }

    if (oldValue) {
      if ('family' in oldValue && 'style' in oldValue) {
        this.removeFontFromNodeIndex({ family: oldValue.family, style: oldValue.style }, node);
      } else {
        console.warn('Unexpected: could not parse oldValue in font family change', oldValue);
      }
    }

    if (newValue) {
      if ('family' in newValue && 'style' in newValue) {
        this.addFontToNodeIndex({ family: newValue.family, style: newValue.style }, node);
      } else {
        console.warn('Unexpected: could not parse newValue in font family change', newValue);
      }
    }
  };

  /** When adding a font to a node, call this to add it to the index */
  @action
  private addFontToNodeIndex = (font: Font, node: TreeNode) => {
    if (this.fontToNodeIndex.has(font.family) === false) {
      this.fontToNodeIndex.set(font.family, new Map());
      this.addToSortedFamilyNames(font.family);
    }

    const styleMap = this.fontToNodeIndex.get(font.family)!;

    if (styleMap.has(font.style) === false) {
      styleMap.set(font.style, new Set());
    }

    styleMap.get(font.style)!.add(node);
  };

  /** When removing a font from a node, call this to remove it from the index */
  @action
  private removeFontFromNodeIndex = (font: Font, node: TreeNode) => {
    this.fontToNodeIndex.get(font.family)?.get(font.style)?.delete(node);

    if (this.fontToNodeIndex.get(font.family)?.get(font.style)?.size === 0) {
      this.fontToNodeIndex.get(font.family)!.delete(font.style);
    }

    if (this.fontToNodeIndex.get(font.family)?.size === 0) {
      this.fontToNodeIndex.delete(font.family);

      // Remove the font from the list of all families
      // if it's not available anymore from another source
      if (
        this.builtInFamilies.has(font.family) === false &&
        this.googleFamilies.has(font.family) === false &&
        this.localFamilies.has(font.family) === false
      ) {
        this.removeFromSortedFamilyNames(font.family);
      }
    }
  };
}
