import { observer } from 'mobx-react-lite';
import { CollapsibleHeading } from '../../components/collapsible-heading';
import { TypefaceProperty } from './typeface-property';
import { FontSizeProperty } from './font-size-property';
import { FontWeightProperty } from './font-weight-property';
import { useEditor } from '../editor-context';
import { PropertyPanels } from '../properties/property-panel';
import { LineHeightProperty } from './line-height-property';
import { LetterSpacingProperty } from './letter-spacing-property';
import { TextAlignProperty } from './text-align-property';
import { TextDecorationProperty } from './text-decoration-property';
import { TextColorProperty } from './text-color-property';

export const TypographyProperties = observer(() => {
  const { propertiesState } = useEditor();
  const collapsed = propertiesState.collapsedPanels.has(PropertyPanels.Typography);

  return (
    <div className="flex flex-col pb-1">
      <CollapsibleHeading
        collapsed={collapsed}
        onCollapsedChange={(collapsed) =>
          collapsed
            ? propertiesState.collapsePanel(PropertyPanels.Typography)
            : propertiesState.expandPanel(PropertyPanels.Typography)
        }
      >
        Text
      </CollapsibleHeading>

      {!collapsed && (
        <>
          <div className="flex flex-col gap-2 pr-2 pl-5">
            <TextColorProperty />
            <TypefaceProperty />
            <div className="grid grid-cols-2 gap-2">
              <FontSizeProperty />
              <FontWeightProperty />
              <LineHeightProperty />
              <LetterSpacingProperty />
              <TextAlignProperty />
              <TextDecorationProperty />
            </div>
          </div>
        </>
      )}
    </div>
  );
});
