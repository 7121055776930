import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { SELECTION_BOUNDS_COLOR } from '../move-tool/select-brush-graphic';

/**
 * Renders a box around the currently selected node
 */
export const SelectionHighlights = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { selectionState, cameraState, hudState, moveToolState } = editorState;

    function drawSelectionHighlight(ctx: CanvasRenderingContext2D) {
      // Don't do any work if nothing is selected
      if (selectionState.selectedNodes.length === 0) return;
      // Don't draw if we're dragging
      if (moveToolState.dragState.isDragging) return;

      // Calculate line width and offset to make the line be on exact pixels
      // In this case we don't care about devicePixelRatio because we want as fine a line as possible
      const baseWidth = 1;
      const borderWidth = baseWidth / cameraState.scale;

      // We round to the nearest 1/devicePixelRatio so that the line is on exact pixels for this device
      const roundingPrecision = 1 / hudState.devicePixelRatio;

      for (const node of selectionState.selectedNodes) {
        const boundsX = (node.x * roundingPrecision) / roundingPrecision;
        const boundsY = (node.y * roundingPrecision) / roundingPrecision;
        const boundsWidth = (node.width * roundingPrecision) / roundingPrecision;
        const boundsHeight = (node.height * roundingPrecision) / roundingPrecision;

        ctx.strokeStyle = SELECTION_BOUNDS_COLOR;
        ctx.lineWidth = borderWidth;
        ctx.strokeRect(boundsX, boundsY, boundsWidth, boundsHeight);
      }
    }

    hudState.worldDrawingFunctions.add(drawSelectionHighlight);
    return () => {
      hudState.worldDrawingFunctions.delete(drawSelectionHighlight);
    };
  }, [editorState]);

  return null;
});
