import { BuiltInComponentMap } from '../built-in-ui/built-in-ui';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';

export function copyAsJsx(editorState: EditorState, nodes: TreeNode[]) {
  const imports: string[] = [];
  const jsx: string[] = [];

  for (const node of nodes) {
    const codeGen = BuiltInComponentMap[node.component]?.generateJsx?.(editorState, node);

    if (codeGen) {
      imports.push(codeGen[0]);
      jsx.push(codeGen[1]);
    }
  }

  const allImports = imports.join('\n');
  const allJsx = jsx.join('\n');

  navigator.clipboard.writeText(`${allImports}\n\n${allJsx}`);
}
