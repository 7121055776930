import type { Vec2 } from '../math/vec2';

export enum UserFocusType {
  Canvas = 0,
  Interface = 1,
  Blurred = 2,
}

export type MultiplayerUser = {
  /** The ID of the socket client */
  clientId: string;
  /** A friendly name to broadcast to other clients */
  name: string;
  /** Assigned color for this user */
  color: string;
  /** The world position of the user's pointer */
  cursorPos: Vec2;
  /**
   * The page id that the user is currently on
   * Starts null and the client will send it's initial page once it chooses one (the URL may specify a page)
   */
  pageId: string | null;
  /** The focus state of the user */
  focus: UserFocusType;
  /** The ids of the nodes that the user has selected */
  selectedNodes: string[];
};
