import { observer } from 'mobx-react-lite';
import { Field } from '../../components/field';
import { Input } from '../../components/input';

import { useEditor } from '../editor-context';

export const CanvasColorProperty = observer(() => {
  const editorState = useEditor();
  const { pageState } = editorState;

  const currentValue = pageState.activePage.canvasColor;

  const commitValue = (value: string) => {
    pageState.setActivePageCanvasColor(value);
  };

  return (
    <div className="flex flex-col py-1">
      <div className="pl-5 text-sm font-medium">Canvas</div>
      <div className="flex h-7 items-center gap-2 pr-2 pl-5">
        <input
          className="TemporaryColorInput"
          type="color"
          value={currentValue}
          onChange={(e) => commitValue(e.target.value)}
        />
        <Field.Control>
          <Input
            value={currentValue}
            onValueCommit={commitValue}
            format={(value) => (value.startsWith('#') ? value.toUpperCase() : value)}
            parse={(value) => (value.startsWith('#') ? value.trim().replace(/\s+/g, ' ') || null : null)}
          />
        </Field.Control>
      </div>
    </div>
  );
});
