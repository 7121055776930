import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { RenderNode } from './render-node';

/** The root of the render tree, starting from the active page */
export const RenderPage = observer(() => {
  const editorState = useEditor();
  const rootNode = editorState.treeUtils.rootNode;

  return (
    <>
      {rootNode.children.map((child) => {
        if (child.id !== rootNode.id) {
          return <RenderNode key={child.id} node={child} />;
        }
      })}
    </>
  );
});
