import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { EditorState } from '../EditorState';
import throttle from 'lodash-es/throttle';
import { CursorMoveMessage } from '@mobius/models/src/websocket/cursor-move-message';
import { MessageType, typeKey } from '@mobius/models/src/websocket/socket-message';
import { type MultiplayerUser } from '@mobius/models/src/websocket/multiplayer-user';
import { UserPageChangeMessage } from '@mobius/models/src/websocket/user-page-change-message';
import { UserFocusChangeMessage } from '@mobius/models/src/websocket/user-focus-change-message';
import { UserSelectNodeMessage } from '@mobius/models/src/websocket/user-select-node-message';

export class MultiplayerState {
  constructor(public editorState: EditorState) {
    makeObservable(this);

    // If we switch from single player to multiplayer, send all of our info to the server
    reaction(
      () => this.isSinglePlayer,
      (isSinglePlayer, prevIsSinglePlayer) => {
        if (prevIsSinglePlayer === false && isSinglePlayer === true) {
          // We're switching from single player to multiplayer
          this.sendPageChange(true);
          this.sendCursorPos(true);
          this.sendFocusChange(true);
          this.sendSelectionChange(true);
        }
      },
      {
        fireImmediately: true,
      }
    );
  }

  /** Stores data about the other users in the file */
  @observable accessor userData: Record<string, MultiplayerUser> = {};

  /** True when nobody else is connected to the same file */
  @computed get isSinglePlayer() {
    return Object.keys(this.userData).length === 0 || this.editorState.socketState.connected === false;
  }

  /** Resets the user data, useful if we lose connection to the server */
  @action resetUserData = () => {
    this.userData = {};
  };

  /** Sends this client's cursor position to the server */
  sendCursorPos = throttle((force = false) => {
    if (this.isSinglePlayer && !force) {
      // No need to send the cursor position when we're single player
      return;
    }

    const message: CursorMoveMessage = {
      [typeKey]: MessageType.CursorMove,
      x: this.editorState.pointerState.cursorPosWorld.x,
      y: this.editorState.pointerState.cursorPosWorld.y,
    };

    this.editorState.socketState.socket?.send(JSON.stringify(message));
  }, 1000 / 33); // about 33fps feels good for cursor movement (it has a lerp on the other end too)

  /** Send this client's current page id to the server */
  sendPageChange = (force = false) => {
    if (this.isSinglePlayer && !force) {
      // No need to send the page change when we're single player
      return;
    }

    const message: UserPageChangeMessage = {
      [typeKey]: MessageType.UserPageChange,
      pageId: this.editorState.pageState.activePageId,
    };

    this.editorState.socketState.socket?.send(JSON.stringify(message));
  };

  /** Send this client's focus state to the server */
  sendFocusChange = (force = false) => {
    if (this.isSinglePlayer && !force) {
      // No need to send the focus change when we're single player
      return;
    }

    const message: UserFocusChangeMessage = {
      [typeKey]: MessageType.UserFocusChange,
      focus: this.editorState.pointerState.focus,
    };

    this.editorState.socketState.socket?.send(JSON.stringify(message));
  };

  sendSelectionChange = (force = false) => {
    if (this.isSinglePlayer && !force) {
      // No need to send the selection change when we're single player
      return;
    }

    const message: UserSelectNodeMessage = {
      [typeKey]: MessageType.UserSelectNode,
      nodeIds: Array.from(this.editorState.selectionState.selectedNodeIds),
    };

    this.editorState.socketState.socket?.send(JSON.stringify(message));
  };
}
