import { observer } from 'mobx-react-lite';
import { RenderPage } from './render-page';
import { Camera } from '../camera/camera';
import { SelectionHighlights } from '../selection/selection-highlights';
import { useEditor } from '../editor-context';
import { HoverHighlight } from '../selection/hover-highlight';
import { MultiplayerClients } from '../multiplayer/multiplayer-clients';
import { HUD } from '../hud/hud';
import { ActiveTool } from '../toolbar/active-tool';
import { SelectionBoundsGraphic } from '../selection/selection-bounds-graphic';
import { SnapsGraphic } from '../snaps/snaps-graphic';
import { TextCaretAndSelectionGraphics } from '../text-tool/text-caret-and-selection-graphic';
import { TextareaHiddenForTextEditing } from '../text-tool/textarea-hidden-for-text-editing';
import { CanvasBackgroundAndCursors } from './canvas-background-and-cursors';
import { CanvasContextMenu } from './canvas-context-menu';
import { NodeDomMutationObserver } from '../file-and-observers/node-dom-mutation-observer';

export const Canvas = observer(() => {
  const editorState = useEditor();
  const { pageState, hudState } = editorState;

  if (pageState.activePage === null) {
    return null;
  }

  const canvasOverlayEl = hudState.hudEl;
  return (
    <CanvasContextMenu>
      <CanvasBackgroundAndCursors>
        <Camera hudOverlay={<HUD />}>
          {/* Renderer of the content */}
          <RenderPage />

          {/* Multiplayer */}
          <MultiplayerClients />

          {/* Highlights and HUD graphics*/}
          <HoverHighlight />
          <SelectionHighlights />
          <SelectionBoundsGraphic />
          <SnapsGraphic />

          {/* Text editing */}
          <TextCaretAndSelectionGraphics />
          <TextareaHiddenForTextEditing />

          {/* Position and size observer */}
          <NodeDomMutationObserver />
        </Camera>

        {canvasOverlayEl && <ActiveTool overlayEl={canvasOverlayEl} />}
      </CanvasBackgroundAndCursors>
    </CanvasContextMenu>
  );
});
