import { observer } from 'mobx-react-lite';
import { Button } from '../components/button';
import { API_ADDRESS } from '../root/api-address';
import { useLocation } from 'wouter';

export const CreateFileButton = observer(() => {
  const [, setLocation] = useLocation();

  function handleClick() {
    const createFileRoute = API_ADDRESS.API_URI + '/files';

    fetch(createFileRoute, {
      method: 'POST',
      body: JSON.stringify({ name: 'Untitled' }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.id) {
          const fileId = data.id;
          setLocation(`/design/${fileId}`);
        }
      });
  }

  return <Button onClick={handleClick}>Create File</Button>;
});
