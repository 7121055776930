import { action, computed, makeObservable, observable } from 'mobx';
import { EditorState } from '../EditorState';
import { CursorToNode, cursorIsOverSelectableNode } from '../move-tool/cursor-is-over-selectable-node';

export enum Tool {
  Move = 'move',
  Pan = 'pan',
  Draw = 'draw',
  Text = 'text',
}

/**
 * Tracks the current tool and related state
 */
export class ToolState {
  constructor(public editorState: EditorState) {
    makeObservable(this);
  }

  @computed({ keepAlive: true }) get shouldShowHoverHighlight() {
    const shouldShow =
      this.activeTool === Tool.Move &&
      this.editorState.textTool.editingTextNode === null &&
      this.editorState.moveToolState.selectionBrushState.isSelectBrushing === false &&
      this.editorState.moveToolState.dragState.isDragging === false &&
      this.editorState.drawToolState.drawingNode === null &&
      this.editorState.pointerState.isHoveringHandle === null;

    if (shouldShow === false) {
      return false;
    }

    // Additionally:
    // Don't show hover highlight if we're on a non-selectable node like a frame with children
    const isSelectableNode = cursorIsOverSelectableNode(
      this.editorState.keyState,
      this.editorState.selectionState,
      this.editorState.pointerState.hoveredNode
    );
    if (isSelectableNode === CursorToNode.NotSelectable) {
      return false;
    }

    // If we survived, we should show the hover state
    return true;
  }

  @observable accessor activeTool: Tool = Tool.Move;
  @action setActiveTool(tool: Tool) {
    if (this.activeTool === Tool.Move) {
      this.editorState.moveToolState.endTool();
    }
    if (this.activeTool === Tool.Draw) {
      this.editorState.drawToolState.endTool();
    }
    if (this.activeTool === Tool.Text) {
      this.editorState.textTool.endTool();
    }

    this.activeTool = tool;

    if (this.activeTool === Tool.Text) {
      this.editorState.textTool.startTool();
    }

    this.editorState.hudState.requestDraw();
  }
}
