type JSONValue = string | number | boolean | null | JSONValue[] | { [key: string]: JSONValue };

/**
 * Looks deep into a target object to pull the value at the given path
 * Returns undefined if the path or value cannot be read (null is a valid value)
 */
export function deepGetValue(obj: Record<any, any>, path: string): JSONValue | undefined {
  let current: JSONValue = obj;
  let start = 0;
  let end = path.length;

  // Skip leading slash if present
  if (path.charCodeAt(0) === 47) {
    // '/'
    start++;
  }

  while (start < end) {
    if (current == null || typeof current !== 'object') {
      return undefined;
    }

    let segmentEnd = path.indexOf('/', start);
    if (segmentEnd === -1) {
      segmentEnd = end;
    }

    // Decode segment inline
    let segment = '';
    for (let i = start; i < segmentEnd; i++) {
      const char = path.charAt(i);
      if (char === '~') {
        if (path.charAt(i + 1) === '1') {
          segment += '/';
          i++;
        } else if (path.charAt(i + 1) === '0') {
          segment += '~';
          i++;
        } else {
          segment += char;
        }
      } else {
        segment += char;
      }
    }

    if (Array.isArray(current)) {
      const index = parseInt(segment, 10);
      if (isNaN(index)) {
        return undefined;
      }
      if (typeof current[index] === 'undefined') {
        return undefined;
      }
      current = current[index];
    } else {
      const value: JSONValue | undefined = (current as { [key: string]: JSONValue })[segment];
      if (typeof value === 'undefined') {
        return undefined;
      }
      current = value;
    }

    start = segmentEnd + 1;
  }

  return current;
}
