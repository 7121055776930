import { UserJoinedFileMessage } from '@mobius/models/src/websocket/user-joined-file-message';
import { EditorState } from '../EditorState';
import { runInAction } from 'mobx';

export const handleClientJoinedFileMessage = (editorState: EditorState, messageData: UserJoinedFileMessage) => {
  const { multiplayerState } = editorState;

  const clientId = messageData.user.clientId;
  runInAction(() => {
    multiplayerState.userData[clientId] = messageData.user;
  });
};
