import { observer } from 'mobx-react-lite';
import { PropConfig } from '../built-in-ui/built-in-ui';
import { TreeNode } from '../tree/TreeNode';

export const PropsInputSwitch = observer(({ config, nodes }: { config: PropConfig; nodes: Array<TreeNode> }) => {
  const currentValue = nodes[0]!.props?.[config.propName] ?? config.defaultValue ?? false;

  const commitValue = (value: boolean) => {
    for (const node of nodes) {
      node.setProp(config.propName, value);
    }
  };

  return (
    <div className="flex flex-col py-1">
      <div className="pl-5 text-sm font-medium">{config.label}</div>
      <div className="flex h-7 items-center gap-2 pr-2 pl-5">
        <input type="checkbox" checked={currentValue} onChange={(e) => commitValue(e.target.checked)} />
      </div>
    </div>
  );
});
