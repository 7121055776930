import { clamp } from 'lodash-es';
import { PointerEvent as ReactPointerEvent, KeyboardEvent, useState, useRef } from 'react';
import { useEditor } from '../editor-context';
import { Color, ColorUtils, hslToOklch, oklchToHsl } from '../properties/Color';

// Even though we store the color in HSL, we use an HSV canvas for the user to pick
// saturation and value (lightness). Unlike HSL, with HSV those values are linear
// along the X and Y axes of the canvas, making it easier to convert from color
// to coordinates and vice versa. HSV<>HSL conversion functions are kept in this
// file rather than in ColorUtils because they are only used here.

// To constrain the puck inside a smaller space in the canvas.
// It feels better if it's not right on the edges. Clicking exactly 1 pixel
// from the edges will result in the puck being at the same position as clicking
// exactly on the edge though. But with 1px it is barely noticeable.
const CANVAS_PADDING = 1;

type Props = {
  color: Color;
  onChange: (color: Color) => void;
  isUniform: boolean;
};

export function ColorCanvas({ color, onChange }: Props) {
  const { fileState } = useEditor();
  const puckRef = useRef<HTMLDivElement | null>(null);
  const [puckSize, setPuckSize] = useState<number>(0);
  const [rect, setRect] = useState<DOMRect | null>(null);
  const maxWidth = rect?.width ?? 1;
  const maxHeight = rect?.height ?? 1;

  const hsl = oklchToHsl(color.value);
  const hsv = hslToHsv(hsl);
  const scaledX = hsv.s * maxWidth;
  const scaledY = hsv.v * maxHeight;

  const clampedX = clamp(scaledX, CANVAS_PADDING, maxWidth - CANVAS_PADDING);
  const clampedY = clamp(scaledY, CANVAS_PADDING, maxHeight - CANVAS_PADDING);

  const canvasBgColor = `hsl(${hsl.h}deg 100% 50%)`;
  const puckBgColor = ColorUtils.cssString(ColorUtils.new(ColorUtils.cssString(color), 1));

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!rect) return;

    if (!['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) return;

    e.preventDefault();

    const verticalStep = e.shiftKey ? 0.1 : 5 / rect.height;
    const horizontalStep = e.shiftKey ? 0.1 : 5 / rect.width;

    const newX =
      e.key === 'ArrowLeft'
        ? clamp(hsv.s - horizontalStep, 0, 1)
        : e.key === 'ArrowRight'
          ? clamp(hsv.s + horizontalStep, 0, 1)
          : hsv.s;

    const newY =
      e.key === 'ArrowUp'
        ? clamp(hsv.v + verticalStep, 0, 1)
        : e.key === 'ArrowDown'
          ? clamp(hsv.v - verticalStep, 0, 1)
          : hsv.v;

    onChange(calculateColor(newX, newY, color));
  };

  const handlePointerDown = (downEvent: ReactPointerEvent) => {
    if (downEvent.buttons !== 1) return;

    // To move the puck a bit away from the mouse pointer.
    const X_OFFSET = -1;
    const Y_OFFSET = -2;

    const newRect = downEvent.currentTarget.getBoundingClientRect();
    setRect(newRect);

    const normalizedX = clamp((downEvent.clientX - newRect.left + X_OFFSET) / newRect.width, 0, 1);
    const normalizedY = 1 - clamp((downEvent.clientY - newRect.top + Y_OFFSET) / newRect.height, 0, 1);

    onChange(calculateColor(normalizedX, normalizedY, color));

    const handlePointerMove = (moveEvent: PointerEvent) => {
      const normalizedX_ = clamp((moveEvent.clientX - newRect.left + X_OFFSET) / newRect.width, 0, 1);
      const normalizedY_ = 1 - clamp((moveEvent.clientY - newRect.top + Y_OFFSET) / newRect.height, 0, 1);
      onChange(calculateColor(normalizedX_, normalizedY_, color));
    };

    const handlePointerUp = () => {
      fileState.fileDataObserver.endTreatingChangesAsTransient();
      window.removeEventListener('pointermove', handlePointerMove);
      window.removeEventListener('pointerup', handlePointerUp);
      puckRef.current?.focus();
    };

    fileState.fileDataObserver.startTreatingChangesAsTransient();
    window.addEventListener('pointerup', handlePointerUp);
    window.addEventListener('pointermove', handlePointerMove);
  };

  return (
    <div
      ref={(node) => {
        if (node && !rect) {
          setRect(node.getBoundingClientRect());
        }
      }}
      tabIndex={-1}
      onFocus={(event) => {
        // Fixes inputs stealing focus when hovered while dragging the puck on Safari.
        if (event.target !== puckRef.current) {
          puckRef.current?.focus();
        }
      }}
      onPointerDown={handlePointerDown}
      className="rounded-1 border-gray-5 relative inset-0 w-[240px] border bg-origin-border"
      style={{
        backgroundColor: canvasBgColor,
        backgroundImage: `
          linear-gradient(to bottom, transparent, black),
          linear-gradient(to right, white, transparent)
        `,
      }}
    >
      <div
        ref={(node) => {
          if (node && !puckSize) {
            setPuckSize(node.getBoundingClientRect().width);
            puckRef.current = node;
          }
        }}
        // mx-[-1px] my-[-1px] are to account for the border width.
        className="pointer-events-none absolute mx-[-1px] my-[-1px] aspect-square size-4 rounded outline-none"
        style={{
          transform: `translate(${clampedX - puckSize / 2}px, ${maxHeight - clampedY - puckSize / 2}px)`,
          boxShadow: '0px 1px 3px 0.5px rgb(0 0 0 / 0.25), inset 0 0 0 1.5px white',
          backgroundColor: puckBgColor,
        }}
        onKeyDown={handleKeyDown}
        tabIndex={-1}
      />
    </div>
  );
}

// X and Y are normalized coordinates, between 0 and 1. Y goes upwards.
const calculateColor = (x: number, y: number, color: Color) => {
  const currentHue = oklchToHsl(color.value).h;
  const hsl = hsvToHsl({ h: currentHue, s: x, v: y });
  const oklch = hslToOklch({ ...hsl, alpha: color.value.alpha, mode: 'hsl' });
  return { ...color, value: oklch };
};

function hsvToHsl({ h, s, v }: { h: number; s: number; v: number }) {
  const l = v * (1 - s / 2);

  let s2 = 0;
  if (l !== 0 && l !== 1) {
    s2 = (v - l) / Math.min(l, 1 - l);
  } else if (l === 0) {
    s2 = s;
  }

  return { h, s: s2, l };
}

function hslToHsv({ h, s, l }: { h: number; s: number; l: number }) {
  const v = l + s * Math.min(l, 1 - l);

  let s2 = s;
  if (v !== 0) {
    s2 = 2 * (1 - l / v);
  }

  return { h, s: s2, v };
}
