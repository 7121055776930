import { deepGetValue } from '@mobius/models/src/delta/deep-get-value';
import { FileState } from '../file-and-observers/FileState';
import { Instruction } from './UndoManager';
import { assert } from '../../assert';
import { Edit } from '@mobius/models/src/delta/edit';

/** Builds a redo using the current value in the file state at time of undo */
function buildRedoEdits(fileState: FileState, undoInstruction: Instruction): Array<Edit> {
  const edits: Array<Edit> = [];

  // Loop through the edits and build the redo per edit
  for (const edit of undoInstruction.edits ?? []) {
    switch (edit.type) {
      case 'update': {
        edits.push({
          path: edit.path,
          type: 'update',
          value: deepGetValue(fileState.data, edit.path),
        });
        break;
      }
      case 'add': {
        edits.push({
          path: edit.path,
          type: 'remove',
        });
        break;
      }
      case 'remove': {
        edits.push({
          path: edit.path,
          type: 'add',
          value: deepGetValue(fileState.data, edit.path),
        });
        break;
      }
      case 'splice': {
        // Grab the index from the JSON pointer
        const splitPath = edit.path.split('/');
        const index = splitPath[splitPath.length - 1];
        if (!index) {
          console.warn('No index found in path', edit.path);
          break;
        }
        const indexNumber = parseInt(index, 10);
        const pathWithoutIndex = splitPath.slice(0, -1).join('/');
        const arrayInState = deepGetValue(fileState.data, pathWithoutIndex);
        if (!Array.isArray(arrayInState)) {
          console.warn('Path does not end in an array', pathWithoutIndex);
          break;
        }
        const addedCount = edit.added.length;
        const willBeRemoved = arrayInState.slice(indexNumber, indexNumber + edit.removedCount);
        edits.push({
          path: edit.path,
          type: 'splice',
          removedCount: addedCount,
          added: willBeRemoved,
        });
        break;
      }
      default:
        console.warn('Unknown edit type', edit);
        break;
    }
  }

  return edits;
}

/** We build the redo at the time of Undo, because a multiplayer user may have changed state between history events */
export function buildRedo(fileState: FileState, undoInstruction: Instruction): Instruction {
  let redo: Instruction = {
    edits: [],
  };

  if (undoInstruction.edits && undoInstruction.edits.length > 0) {
    redo.edits = buildRedoEdits(fileState, undoInstruction);
  }

  if (undoInstruction.activePageId) {
    const activePageId = fileState.editorState.pageState.activePageId;
    redo.activePageId = activePageId;
  }

  if (undoInstruction.selectedIds) {
    redo.selectedIds = Array.from(fileState.editorState.selectionState.selectedNodeIds);
  }

  return redo;
}
