import { EditorState } from './EditorState';
import { SnapAxis } from './snaps/find-snaps';
import { Tool } from './toolbar/ToolState';
import { AZERTY_FR_DARWIN, HotkeysManager, QWERTZ_DE_DARWIN, US_LAYOUT_QWERTY_DARWIN } from '../hotkey';

export const { createHotkeysZone, createIgnoreZone, HotkeysProvider } = new HotkeysManager<EditorState>({
  layouts: {
    US_LAYOUT_QWERTY_DARWIN,
    AZERTY_FR_DARWIN,
    QWERTZ_DE_DARWIN,
  },
  logLevel: 'none',
});

export const EditorHotkeysZone = createHotkeysZone({
  id: 'editor',
  name: 'Editor',
  description: 'Keyboard shortcuts for the editor',
  isGlobal: true,
  shouldPreventDefault: () => true,
  hotkeys: {
    delete: {
      name: 'Delete Selection',
      hotkey: ['Backspace', 'Delete'],
      onKeyDown: (_e, editorState) => {
        for (const node of editorState.selectionState.selectedNodes ?? []) {
          editorState.treeUtils.deleteNodes([node.id]);
        }
      },
    },
    selectAll: {
      name: 'Select All',
      hotkey: 'Mod a',
      onKeyDown: (_e, editorState) => {
        editorState.selectionState.selectIds(editorState.treeUtils.rootNode.children.map((node) => node.id));
      },
    },
    togglePanTool: {
      name: 'Pan Tool',
      hotkey: 'Space',
      onKeyDown: (_e, editorState) => {
        if (!editorState.pointerState.pointerIsBusy) {
          editorState.toolState.setActiveTool(Tool.Pan);
        }
      },
      onKeyUp: (_e, editorState) => {
        if (!editorState.pointerState.pointerIsBusy && editorState.toolState.activeTool === Tool.Pan) {
          editorState.toolState.setActiveTool(Tool.Move);
        }
      },
    },
    preventSave: {
      name: 'Prevent Save',
      hotkey: 'Mod s',
      onKeyDown: (e) => {
        e.preventDefault();
      },
    },
    drawTool: {
      name: 'Draw Tool',
      hotkey: ['a', 'r', 'b', 'f'],
      onKeyDown: (_e, editorState) => {
        editorState.toolState.setActiveTool(Tool.Draw);
        editorState.drawToolState.setComponentToDraw('Box');
      },
    },
    textTool: {
      name: 'Text Tool',
      hotkey: 't',
      onKeyDown: (e, editorState) => {
        editorState.toolState.setActiveTool(Tool.Text);
        editorState.textTool.setAllowDrawing(true);
        editorState.textTool.startEditingIfSelectionIsSoloTextNode();
        e.preventDefault();
      },
    },
    editText: {
      name: 'Edit Text',
      hotkey: 'Enter',
      onKeyDown: (e, editorState) => {
        if (
          editorState.selectionState.selectedNodes.length === 1 &&
          editorState.selectionState.selectedNodes[0]?.canEditText
        ) {
          editorState.toolState.setActiveTool(Tool.Text);
          editorState.textTool.startEditingTextNode(editorState.selectionState.selectedNodes[0], true);
          e.preventDefault();
        }
      },
    },
    toggleUI: {
      name: 'Toggle UI',
      hotkey: ['Mod .', 'Mod \\'],
      onKeyDown: (_e, editorState) => {
        editorState.setRenderUI(!editorState.renderUI);
      },
    },
    moveTool: {
      name: 'Move Tool',
      hotkey: ['v', 'Escape'],
      onKeyDown: (_e, editorState) => {
        if (!editorState.pointerState.pointerIsBusy) {
          editorState.toolState.setActiveTool(Tool.Move);
        }
      },
    },
    // Nudge commands
    nudgeUp: {
      name: 'Nudge Up',
      hotkey: 'ArrowUp',
      onKeyDown: (e, editorState) => handleNudge(editorState, 'up', e.shiftKey, e.metaKey),
    },
    nudgeDown: {
      name: 'Nudge Down',
      hotkey: 'ArrowDown',
      onKeyDown: (e, editorState) => handleNudge(editorState, 'down', e.shiftKey, e.metaKey),
    },
    nudgeLeft: {
      name: 'Nudge Left',
      hotkey: 'ArrowLeft',
      onKeyDown: (e, editorState) => handleNudge(editorState, 'left', e.shiftKey, e.metaKey),
    },
    nudgeRight: {
      name: 'Nudge Right',
      hotkey: 'ArrowRight',
      onKeyDown: (e, editorState) => handleNudge(editorState, 'right', e.shiftKey, e.metaKey),
    },
    zoomReset: {
      name: 'Reset Zoom',
      hotkey: ['0', 'Mod 0'],
      onKeyDown: (e, editorState) => {
        e.preventDefault();
        editorState.cameraState.resetZoom();
      },
    },
    zoomIn: {
      name: 'Zoom In',
      hotkey: ['+', 'Mod +', '=', 'Mod ='],
      onKeyDown: (e, editorState) => {
        editorState.cameraState.zoomIn();
      },
    },
    zoomOut: {
      name: 'Zoom Out',
      hotkey: ['-', 'Mod -'],
      onKeyDown: (e, editorState) => {
        editorState.cameraState.zoomOut();
      },
    },
    zoomToFit: {
      name: 'Zoom to Fit',
      hotkey: ['1', 'Shift 1'],
      onKeyDown: (e, editorState) => {
        editorState.cameraState.zoomToFit(editorState.treeUtils.totalBounds);
      },
    },
    zoomToSelection: {
      name: 'Zoom to Selection',
      hotkey: ['2', 'Shift 2'],
      onKeyDown: (_e, editorState) => {
        const bounds = editorState.selectionState.selectionBoundsRect ?? editorState.treeUtils.totalBounds;
        editorState.cameraState.zoomToFit(bounds);
      },
    },
  },
});

// Helper function for nudge operations
function handleNudge(
  state: EditorState,
  direction: 'up' | 'down' | 'left' | 'right',
  isShift: boolean,
  isMeta: boolean
) {
  const amount = isShift ? 10 : 1;
  const snapShowTimeMs = 667;

  for (const node of state.selectionState.selectedNodes) {
    if (direction === 'up') {
      if (isMeta) {
        node.setHeight(node.height - amount);
      } else {
        node.setY(node.y - amount);
      }
    } else if (direction === 'down') {
      if (isMeta) {
        node.setHeight(node.height + amount);
      } else {
        node.setY(node.y + amount);
      }
    } else if (direction === 'left') {
      if (isMeta) {
        node.setWidth(node.width - amount);
      } else {
        node.setX(node.x - amount);
      }
    } else if (direction === 'right') {
      if (isMeta) {
        node.setWidth(node.width + amount);
      } else {
        node.setX(node.x + amount);
      }
    }

    // Draw HUD after the change
    state.hudState.requestDraw();

    // Draw snaps if new position matches a snap
    if (state.selectionState.selectionBoundsRect) {
      state.snapState.findSnapsForRect(state.selectionState.selectionBoundsRect, SnapAxis.XY, 0);
      state.snapState.clearSnapsAfterDelay(snapShowTimeMs);
    }
  }
}
