import { Rect } from '@mobius/models/src/math/rect';
import { Vec2 } from '@mobius/models/src/math/vec2';
import { EditorState } from '../EditorState';
import { intersects } from '@mobius/models/src/math/collision';
import { TreeNode } from '../tree/TreeNode';

/** Looks for the next position that a rectangle could be plopped onto the canvas without colliding into anything else */
export function findNextSafeAreaForRect(
  editorState: EditorState,
  sourceRect: Rect,
  direction: 'right' | 'down' = 'right',
  paddingOnAxis: number = 20
): Vec2 {
  const { treeUtils } = editorState;

  // strategy:
  // try to place the rectangle exactly distanceFromSourceRect away from the source rect to the right
  // if there's things in that area, find the collective max that would block us, try again there
  // repeat until we find a space

  // Default to 'right' direction
  let minProperty: 'minX' | 'minY' = 'minX';
  let maxProperty: 'maxX' | 'maxY' = 'maxX';
  let sizeOfBoxOnAxis = sourceRect.maxX - sourceRect.minX;
  if (direction === 'down') {
    minProperty = 'minY';
    maxProperty = 'maxY';
    sizeOfBoxOnAxis = sourceRect.maxY - sourceRect.minY;
  }

  // Build the test bounds we'll try to place and see if there are collisions
  let testRect = { ...sourceRect };
  testRect[minProperty] = sourceRect[maxProperty] + paddingOnAxis;
  testRect[maxProperty] = sourceRect[maxProperty] + paddingOnAxis + sizeOfBoxOnAxis;

  while (true) {
    const nodesInOurSpace: TreeNode[] = [];
    for (const child of treeUtils.rootNode.children) {
      if (intersects(testRect, child.bounds)) {
        // This node would block us, keep track of it
        nodesInOurSpace.push(child);
      }
    }

    // If we found any nodes that would block us, we need to try again
    if (nodesInOurSpace.length === 0) {
      // Awesome, we found a safe spot
      return { x: testRect.minX, y: testRect.minY };
    }

    // We didn't find a safe spot, find the furthest value out that blocks us so far
    let maxBlockingValue = testRect[minProperty];
    for (const node of nodesInOurSpace) {
      maxBlockingValue = Math.max(maxBlockingValue, node.bounds[maxProperty]);
    }
    testRect[minProperty] = maxBlockingValue + paddingOnAxis;
    testRect[maxProperty] = maxBlockingValue + paddingOnAxis + sizeOfBoxOnAxis;
  }
}
