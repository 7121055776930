import { action, computed, makeObservable, observable } from 'mobx';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { findSnapsForPoint, findSnapsForRect, SnapAxis, SnapResult } from './find-snaps';
import { Vec2 } from '@mobius/models/src/math/vec2';
import { Rect } from '@mobius/models/src/math/rect';
import { intersects } from '@mobius/models/src/math/collision';
import { RESIZE_HANDLE_ID } from '../selection/resize-handles-logic';

/** The distance in pixels at 1x zoom to match a snap against another surface */
export const SNAP_DISTANCE_AT_1_SCALE = 4;

export class SnapState {
  constructor(private editorState: EditorState) {
    makeObservable(this);
  }

  /** The bounds of the item being snapped, should be set after adjusting for snaps, used to find "self snaps" to draw graphics */
  @observable accessor currentBounds: Rect | null = null;
  @action setCurrentBounds = (bounds: Rect): void => {
    this.currentBounds = bounds;
  };

  /** The currently active snaps */
  @observable accessor currentSnaps: SnapResult | null = null;

  /** Find the snaps for a point and both set them as the current snaps and return them*/
  @action findSnapsForPoint = (
    point: Vec2,
    searchAxis: SnapAxis = SnapAxis.XY,
    snapDistance: number = SNAP_DISTANCE_AT_1_SCALE
  ): SnapResult => {
    const snappableNodes = this.validNodesForSnaps;
    const foundSnaps = findSnapsForPoint(this.editorState, searchAxis, point, snappableNodes, snapDistance);
    if (foundSnaps === null) {
      this.currentSnaps = null;
    } else {
      this.currentSnaps = { ...foundSnaps };
    }
    this.editorState.hudState.requestDraw();
    return foundSnaps;
  };

  @action findSnapsForRect = (
    rect: Rect,
    searchAxis: SnapAxis = SnapAxis.XY,
    snapDistance: number = SNAP_DISTANCE_AT_1_SCALE
  ): SnapResult => {
    const snappableNodes = this.validNodesForSnaps;
    const foundSnaps = findSnapsForRect(this.editorState, rect, snappableNodes, snapDistance, searchAxis);
    if (foundSnaps === null) {
      this.currentSnaps = null;
    } else {
      this.currentSnaps = foundSnaps;
    }
    this.editorState.hudState.requestDraw();
    return foundSnaps;
  };

  /** Clear the current snaps */
  @action clearSnaps = (): void => {
    this.currentSnaps = null;
    this.currentBounds = null;
    this.editorState.hudState.requestDraw();
  };

  clearSnapDelayTimer: number = 0;
  /** Clear the current snaps after a delay. Subsequent calls will reset the timer. */
  @action clearSnapsAfterDelay = (delayMs: number): void => {
    clearTimeout(this.clearSnapDelayTimer);
    this.clearSnapDelayTimer = window.setTimeout(() => {
      this.clearSnaps();
    }, delayMs);
  };

  /** Drawing tools can provide their control point (usually cursor position) and get back a control point that's snapped to the best snaps */
  makeSnappedControlPointForDrawingTools = (
    rawControlPoint: Vec2,
    currentBounds: Rect,
    forceAspectRatio: boolean,
    lockXTo: number | null,
    lockYTo: number | null
  ): Vec2 => {
    const controlPoint = { ...rawControlPoint };

    let searchAxis: SnapAxis = SnapAxis.XY;
    // If we have one axis locked, spoof the value for that axis over top of the pointer position
    if (typeof lockXTo === 'number') {
      controlPoint.x = lockXTo; // lock the control point x to ignore the cursor X
      searchAxis = SnapAxis.Y; // only check snaps on the Y axis
    }
    if (typeof lockYTo === 'number') {
      controlPoint.y = lockYTo; // lock the control point y to ignore the cursor Y
      searchAxis = SnapAxis.X; // only check snaps on the X axis
    }

    // Proportional resizing should only check on the axis the cursor is closest to (to avoid weird snapping on the cursor pos when it doesn't reflect the box size on the other axis)
    if (forceAspectRatio) {
      if (lockXTo !== null) {
        searchAxis = SnapAxis.Y;
      } else if (lockYTo !== null) {
        searchAxis = SnapAxis.X;
      } else {
        // If no axis is locked, check which axis the cursor is closest to
        const closestXEdgeToCursor = Math.min(
          Math.abs(controlPoint.x - currentBounds.minX),
          Math.abs(controlPoint.x - currentBounds.maxX)
        );
        const closestYEdgeToCursor = Math.min(
          Math.abs(controlPoint.y - currentBounds.minY),
          Math.abs(controlPoint.y - currentBounds.maxY)
        );
        if (closestXEdgeToCursor < closestYEdgeToCursor) {
          searchAxis = SnapAxis.X; // only check snaps on the X axis
        } else {
          searchAxis = SnapAxis.Y; // only check snaps on the Y axis
        }
      }
    }

    // ----- Look for snaps ----- //
    const snaps = this.findSnapsForPoint(controlPoint, searchAxis);
    if (snaps.xSnaps.length > 0) {
      controlPoint.x = snaps.xSnaps[0]!.primaryAxisValue;
    }
    if (snaps.ySnaps.length > 0) {
      controlPoint.y = snaps.ySnaps[0]!.primaryAxisValue;
    }
    return controlPoint;
  };

  /** Get the currently visible nodes that intersect with the viewport and are not selected */
  @computed({ keepAlive: true }) get validNodesForSnaps(): TreeNode[] {
    // Check if any selected nodes are in a container frame
    let scopeSnapsToThisFrame: TreeNode | null = null;
    for (const node of this.editorState.selectionState.selectedNodes) {
      if (node.containerFrame) {
        // Found a node in a container frame
        if (scopeSnapsToThisFrame === null) {
          // Snaps should be scoped to this Frame unless we find another frame
          scopeSnapsToThisFrame = node.containerFrame;
        } else {
          // Found a second container frame, so snaps are not scoped to any frame
          scopeSnapsToThisFrame = null;
          break;
        }
      }
    }

    // TODO: prototype code, refactor once happy with behavior
    // (would this fully replace the code above?)
    if (this.editorState.moveToolState.dragState.dropTarget) {
      scopeSnapsToThisFrame = this.editorState.moveToolState.dragState.dropTarget.node;
    }

    // Pick possible snaps either inside a frame context or for the entire canvas
    let possibleSnapNodes: Set<TreeNode>;
    // Pick whether we're inside a frame context or in the global canvas snap context
    if (scopeSnapsToThisFrame) {
      possibleSnapNodes = new Set([scopeSnapsToThisFrame, ...scopeSnapsToThisFrame.descendants]);
    } else {
      possibleSnapNodes = new Set(this.editorState.treeUtils.rootNode.children);
    }

    // Grab the viewable area of the camera
    const viewableArea = this.editorState.cameraState.currentViewableAreaWorld;

    // Filter out nodes that are off screen or within the selection
    const matchingNodes = new Set<TreeNode>();
    for (const node of possibleSnapNodes) {
      if (node.isWithinSelection) continue;
      const isOnScreen = intersects(viewableArea, node.bounds);
      if (!isOnScreen) continue;
      matchingNodes.add(node);
    }

    // Special case: if resizing a single node with children and the layout mode is free form it should snap to the edges of its descendants too
    if (
      this.editorState.pointerState.isControlledByHandle === RESIZE_HANDLE_ID &&
      this.editorState.selectionState.selectedNodes.length === 1
    ) {
      const selectedNode = this.editorState.selectionState.selectedNodes[0]!;
      if (selectedNode.childrenAreFixed === true) {
        selectedNode.descendants.forEach((child) => {
          matchingNodes.add(child);
        });
      }
    }

    return Array.from(matchingNodes);
  }
}
