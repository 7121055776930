import { observer } from 'mobx-react-lite';
import { PropertyPanels } from '../properties/property-panel';
import { useEditor } from '../editor-context';
import { reducePropertyValues } from '../properties/reduce-property-values';
import { ToggleGroup } from '../../components/toggle-group';

export const TextDecorationProperty = observer(() => {
  const { propertiesState } = useEditor();

  const values = propertiesState.getStyleValues('textDecoration');
  const currentValue = reducePropertyValues(values, 'none');

  function handleChange(value: string) {
    for (const node of propertiesState.nodesPerProperty[PropertyPanels.Typography]) {
      node.setStyle('textDecoration', value);
    }
  }

  return (
    <ToggleGroup.Root type="single" value={currentValue} onValueChange={handleChange}>
      <ToggleGroup.Item value="none">
        <TextIcon />
      </ToggleGroup.Item>
      <ToggleGroup.Item value="underline">
        <TextIcon>
          <rect x="1" y="13" width="14" height="1" />
        </TextIcon>
      </ToggleGroup.Item>
      <ToggleGroup.Item value="line-through">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.15679 3.07003C5.08232 2.87701 4.89667 2.74979 4.68979 2.75C4.4829 2.75021 4.29751 2.87782 4.22344 3.07099L2.3335 8H1V9H1.95006L1.03328 11.391C0.934414 11.6488 1.06329 11.938 1.32113 12.0369C1.57896 12.1357 1.86813 12.0068 1.96699 11.749L3.01133 9.02537H6.3825L7.43365 11.75C7.53304 12.0076 7.82247 12.1359 8.0801 12.0365C8.33774 11.9371 8.46602 11.6477 8.36662 11.39L7.44456 9H9.02628C9.1872 10.8089 10.4594 12.0124 11.8989 12.0124C12.7214 12.0124 13.5744 11.6692 14.1543 11.0219V11.53C14.1543 11.7785 14.3557 11.98 14.6043 11.98C14.8528 11.98 15.0543 11.7785 15.0543 11.53V5.72C15.0543 5.47147 14.8528 5.27 14.6043 5.27C14.3557 5.27 14.1543 5.47147 14.1543 5.72V6.22317C13.6054 5.60095 12.7924 5.20703 11.8989 5.20703C10.4363 5.20703 9.28504 6.38199 9.05224 8H7.05876L5.15679 3.07003ZM5.98692 8L4.69174 4.64284L3.40448 8H5.98692ZM10.0566 9C10.2053 10.3211 11.1026 11.1615 12.0868 11.1615C12.9701 11.1615 13.7719 10.4952 14.1543 9.79823V9H10.0566ZM14.1543 8H10.088C10.3156 6.74278 11.2373 6.05757 12.0868 6.05757C12.9414 6.05757 13.7639 6.58101 14.1543 7.30195V8Z"
          />
        </svg>
      </ToggleGroup.Item>
    </ToggleGroup.Root>
  );
});

function TextIcon({ children }: React.ComponentProps<'svg'>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.68979 2.75C4.89667 2.74979 5.08232 2.87701 5.15679 3.07003L8.36662 11.39C8.46602 11.6477 8.33774 11.9371 8.0801 12.0365C7.82247 12.1359 7.53304 12.0076 7.43365 11.75L6.3825 9.02537H3.01133L1.96699 11.749C1.86813 12.0068 1.57896 12.1357 1.32113 12.0369C1.06329 11.938 0.934414 11.6488 1.03328 11.391L4.22344 3.07099C4.29751 2.87782 4.4829 2.75021 4.68979 2.75ZM4.69174 4.64284L6.05458 8.17537H3.33724L4.69174 4.64284ZM11.8989 5.20703C10.2582 5.20703 9.00915 6.68569 9.00915 8.60972C9.00915 10.6337 10.3582 12.0124 11.8989 12.0124C12.7214 12.0124 13.5744 11.6692 14.1543 11.0219V11.53C14.1543 11.7785 14.3557 11.98 14.6043 11.98C14.8528 11.98 15.0543 11.7785 15.0543 11.53V5.72C15.0543 5.47147 14.8528 5.27 14.6043 5.27C14.3557 5.27 14.1543 5.47147 14.1543 5.72V6.22317C13.6054 5.60095 12.7924 5.20703 11.8989 5.20703ZM14.1543 9.79823V7.30195C13.7639 6.58101 12.9414 6.05757 12.0868 6.05757C11.1088 6.05757 10.035 6.96581 10.035 8.60955C10.035 10.1533 11.0088 11.1615 12.0868 11.1615C12.9701 11.1615 13.7719 10.4952 14.1543 9.79823Z"
      />
      {children}
    </svg>
  );
}
