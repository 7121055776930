import { Fragment } from 'react/jsx-runtime';
import { magicFlowImageSwatches } from '../color-extractor/magic-flow-image-swatches';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import * as MenuPrim from '@radix-ui/react-context-menu';
import { BuiltInComponentMap } from '../built-in-ui/built-in-ui';
import { copyAsJsx } from './copy-as-jsx';
import { wrapNodesWithFlex } from '../tree/wrap-nodes-with-flex';
import { WrapInFlexOrRemoveFlex } from './wrap-in-flex-or-remove-flex';

/** Used in both the canvas and layer tree context menus */
export function MenuForNodes({ editorState, nodes }: { editorState: EditorState; nodes: Array<TreeNode> }) {
  if (nodes.length === 0) return null;

  // We'll build out the items over this function
  const items = [];

  // ----- General node items ----- //
  items.push(
    <MenuPrim.Group key="general-node-items">
      <MenuPrim.Item>
        <div style={{ backgroundColor: 'white', padding: 20, color: 'black' }}>Copy {nodes?.length} nodes</div>
      </MenuPrim.Item>

      <WrapInFlexOrRemoveFlex editorState={editorState} nodes={nodes} />
    </MenuPrim.Group>
  );

  // ----- Copy as JSX ----- //
  if (nodes.some((node) => BuiltInComponentMap[node.component]?.generateJsx)) {
    items.push(
      <MenuPrim.Group key="copy-as-jsx-items">
        <MenuPrim.Item onSelect={() => copyAsJsx(editorState, nodes)}>
          <div style={{ backgroundColor: 'white', padding: 20, color: 'black' }}>Copy as JSX</div>
        </MenuPrim.Item>
      </MenuPrim.Group>
    );
  }

  // ----- Image and Canvas specific items ----- //

  if (nodes.some((node) => node.component === 'Image' || node.component === 'Shader')) {
    items.push(
      <MenuPrim.Group key="image-items">
        <MenuPrim.Item onSelect={() => magicFlowImageSwatches(editorState, nodes)}>
          <div style={{ backgroundColor: 'white', padding: 20, color: 'black' }}>Extract colors</div>
        </MenuPrim.Item>
        <MenuPrim.Separator />
      </MenuPrim.Group>
    );
  }

  return items;
}
