export function EyeIcon(props: React.ComponentProps<'svg'>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14 8C14 8 12.5 4.5 8 4.5C3.5 4.5 2 8 2 8C2 8 3.5 11.5 8 11.5C12.5 11.5 14 8 14 8Z"
        stroke="currentcolor"
      />
      <circle cx="8" cy="8" r="2" fill="currentcolor" />
    </svg>
  );
}
