import { action, computed, makeObservable, observable } from 'mobx';
import { EditorState } from './EditorState';
import { PageType } from '@mobius/models/src/file/page-schema';
import { assert } from '../assert';
import { FileType } from '@mobius/models/src/file/file-schema';

/** Keeps track of the active page */
export class PageState {
  constructor(
    public editorState: EditorState,
    fileData: FileType
  ) {
    makeObservable(this);

    // Set the initial active page
    // Check if the URL specified a page ID
    const pageIdFromUrl = new URLSearchParams(window.location.search).get('page');
    if (pageIdFromUrl) {
      this.activePageId = pageIdFromUrl;
    } else {
      // Otherwise, just use the first page
      const pageId = fileData.pages[0]?.id;
      assert(pageId, 'Unexpected: No pages in file');
      this.activePageId = pageId;
    }
  }

  /** Whether the page list is open – possible TODO to persist this per file per user */
  @observable accessor pageListIsOpen: boolean = true;
  @action setPageListIsOpen(isOpen: boolean) {
    this.pageListIsOpen = isOpen;
  }

  @observable accessor activePageId: string;

  @action setActivePage(pageId: string, isInitialPageInit = false) {
    const cameraState = this.editorState.cameraState;
    const selectionState = this.editorState.selectionState;

    if (isInitialPageInit === false) {
      // Save our camera and selection settings for this page
      const lastPageId = this.activePageId;
      cameraState.stashSettingsForPage(lastPageId);
      selectionState.stashSettingsForPage(lastPageId);

      // Update the URL with the new page id
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('page', pageId);
      window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    }

    // Inform the undo manager of the page change, giving it the old page Id – this is good to do even for the first page of the load
    this.editorState.undoManager.addPageChange(this.activePageId);

    // Update the active page id
    this.activePageId = pageId;

    // Tell the server about the new active page
    this.editorState.multiplayerState.sendPageChange(true);

    // Restore any selection settings for this page
    selectionState.restoreSettingsForPage(pageId);
    // Let everything render and then restore any camera settings for this page
    requestIdleCallback(() => {
      cameraState.restoreSettingsForPage(pageId);
    });
  }

  @computed get activePage(): PageType {
    const activePage = this.editorState.fileState.data.pages.find((page) => page.id === this.activePageId);
    assert(activePage, 'Error: could not find the active page');
    return activePage;
  }

  @action setActivePageCanvasColor(color: string) {
    this.activePage.canvasColor = color;
  }
}
