import React, { useContext } from 'react';
import { EditorState } from './EditorState';

export const EditorReactContext = React.createContext<EditorState | null>(null);

export function useEditor(): EditorState {
  const editorState = useContext(EditorReactContext);

  if (!editorState) {
    throw new Error(`Can't find EditorState — are you using useEditor outside the EditorReactContext.Provider?`);
  }

  return editorState;
}
