import { TreeNode } from '../tree/TreeNode';
import { ResizeHandlePosition } from './resize-handles-logic';

export function resizeHandleDoubleClickLogic(nodes: TreeNode[], handle: ResizeHandlePosition, clickCount: number) {
  // Height-only auto case:
  if (handle === ResizeHandlePosition.Bottom || handle === ResizeHandlePosition.Top) {
    nodes.forEach((node) => {
      node.setHeight('auto');
    });
  }

  // Width-only auto case:
  if (handle === ResizeHandlePosition.Left || handle === ResizeHandlePosition.Right) {
    nodes.forEach((node) => {
      node.setWidth('auto');
    });
  }

  // Both height and width auto case:
  if (
    handle === ResizeHandlePosition.TopLeft ||
    handle === ResizeHandlePosition.BottomRight ||
    handle === ResizeHandlePosition.BottomLeft ||
    handle === ResizeHandlePosition.TopRight
  ) {
    nodes.forEach((node) => {
      node.setHeight('auto');
      node.setWidth('auto');
    });
  }
}
