import { RefObject, useEffect, useRef } from 'react';
import { useEditor } from '../editor-context';
import { Vec2, Vec2Utils } from '@mobius/models/src/math/vec2';
import { assert } from '../../assert';
import { TreeNode } from '../tree/TreeNode';
import { createMultipleSortOrderKeys } from '@mobius/models/src/file/create-sort-order-key';
import { transaction } from 'mobx';
import { cloneNodesIntoTempTree } from '../tree/clone-nodes-into-temp-tree';
import { cloneTempTreeIntoFile } from '../tree/clone-temp-tree-into-file';
import { makeTreeRelationshipString } from '@mobius/models/src/file/tree-node-relationships';
import { selectAllBetween } from './select-all-between';
import { RectWithSize } from '@mobius/models/src/math/rect';

/** How far a pointer needs to move with the pointer down to qualify as a drag instead of a click */
export const CLICK_VS_DRAG_THRESHOLD_LAYER_TREE_PX = 5;

export function useLayerTreePointerEvents(): [RefObject<HTMLDivElement>, RefObject<HTMLDivElement>] {
  const editorState = useEditor();
  const containerRef = useRef<HTMLDivElement>(null);
  const rowWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { selectionState, layerTreeState, treeUtils, pointerState, tickState } = editorState;

    /** Keeps track of where the pointer went down when first pressed */
    let pointerDownPosition: Vec2 = { x: 0, y: 0 };

    /** After a pointer down, the mouse must move a certain amount before it qualifies as a drag instead of a messy click */
    let clickQualifiesAsDrag = false;
    /** Whether the pointer is currently down and possibly dragging – can be ended early by the escape key before pointerUp */
    let provisionalDragStarted = false;
    /** The node ID that the pointer started its down on */
    let pointerDownNodeId: string | null = null;
    /** The pointerId at pointerDown, used since we don't start capturing until pointerMove */
    let pointerIdToRelease: number | null = null;
    /** Stores the client bounding rect of the containerEl */
    let containerElBounds: DOMRect | null = null;
    /** Stores the client bounding rect of the rowWrapperEl, which is the size of all the rows but no more */
    let rowWrapperElBounds: DOMRect | null = null;
    /** A set of the nodes being dragged, calculated on pointerDown using selectedNodes and all of their descendants */
    let nodesBeingDragged: Set<TreeNode> = new Set();

    const containerEl = containerRef.current;
    const rowWrapperEl = rowWrapperRef.current;
    assert(containerEl && rowWrapperEl);

    // ----- Pointer down ----- //
    function handlePointerDown(e: PointerEvent) {
      if (e.button !== 0) return; // only left clicks

      const targetNodeEl = ((e.target as HTMLElement)?.closest('[data-node-id]') as HTMLElement) ?? null;
      const nodeId = targetNodeEl?.getAttribute('data-node-id');
      if (nodeId) {
        const node = treeUtils.getNode(nodeId);
        assert(node);

        // ----- Meta: Add or remove single node from selection ----- //
        if (e.metaKey) {
          // Meta key means add or remove this node from the current selection (can't start a drag at the same time)
          if (selectionState.selectedNodeIds.has(nodeId)) {
            selectionState.removeSelectedNodeIds([nodeId]);
          } else {
            selectionState.selectIds([nodeId], { additive: true });
          }
          return;
        }

        // ----- Shift: select all nodes between the last selected node and this one ----- //
        if (e.shiftKey) {
          selectAllBetween(editorState, containerEl!, nodeId, targetNodeEl);
          return;
        }

        // Build the set of nodes that are selected and  all of their descendants
        const selectedNodesWithDescendants: Set<TreeNode> = new Set();
        for (const node of selectionState.selectedNodes) {
          selectedNodesWithDescendants.add(node);
          for (const descendant of node.descendants) {
            selectedNodesWithDescendants.add(descendant);
          }
        }

        // ----- Select new node and/or start drag ----- //
        if (selectedNodesWithDescendants.has(node) === false) {
          // If this node wasn't already selected, clear out previous selections and select it
          selectionState.selectIds([nodeId]);
          nodesBeingDragged.clear();
          nodesBeingDragged.add(node);
        } else {
          // The pointer down is within the selected nodes, so the selectedNodesWithDescendants set is our potential dragged nodes
          nodesBeingDragged = selectedNodesWithDescendants;
        }

        pointerIdToRelease = e.pointerId;
        pointerDownPosition = { x: e.clientX, y: e.clientY };
        provisionalDragStarted = true;
        pointerDownNodeId = nodeId;
        containerElBounds = containerEl!.getBoundingClientRect();
        rowWrapperElBounds = rowWrapperEl!.getBoundingClientRect();
        // Bind the drag handlers
        bindDragHandlers();
      }
    }

    // While the pointer is down, keep track of movement each frame so we can track a rolling average
    let last10PointerDeltas: Array<number> = [];
    let lastFramePointerY: number | null = null;

    function frameLoopWhilePointerDown() {
      // ----- Track the rolling average of pointer movement ----- //
      if (lastFramePointerY !== null) {
        const movement = Math.ceil(Math.abs(pointerState.cursorPos.y - lastFramePointerY)) ** 3;
        last10PointerDeltas.push(movement);
        if (last10PointerDeltas.length > 10) {
          last10PointerDeltas.shift();
        }
      }
      lastFramePointerY = pointerState.cursorPos.y;

      // ----- Auto scroll if we're near the edge ----- //
      const scrollThreshold = 20;
      const scrollAmountPerFrame = 6;
      const distanceFromLeftEdge = pointerState.cursorPos.x - containerElBounds!.left;
      const distanceFromRightEdge = containerElBounds!.right - pointerState.cursorPos.x;
      if (distanceFromLeftEdge < scrollThreshold) {
        autoScrollDirection.x = -scrollAmountPerFrame * (1 - distanceFromLeftEdge / scrollThreshold);
      } else if (distanceFromRightEdge < scrollThreshold) {
        autoScrollDirection.x = scrollAmountPerFrame * (1 - distanceFromRightEdge / scrollThreshold);
      } else {
        autoScrollDirection.x = 0;
      }
      const distanceFromTopEdge = pointerState.cursorPos.y - containerElBounds!.top;
      const distanceFromBottomEdge = containerElBounds!.bottom - pointerState.cursorPos.y;
      if (distanceFromTopEdge < scrollThreshold) {
        autoScrollDirection.y = -scrollAmountPerFrame * (1 - distanceFromTopEdge / scrollThreshold);
      } else if (distanceFromBottomEdge < scrollThreshold) {
        autoScrollDirection.y = scrollAmountPerFrame * (1 - distanceFromBottomEdge / scrollThreshold);
      } else {
        autoScrollDirection.y = 0;
      }

      if (autoScrollDirection.x !== 0 || autoScrollDirection.y !== 0) {
        autoScroll();
      }

      handleNewInput();
    }

    function handleNewInput() {
      // Bail if we're not holding down the pointer
      if (!provisionalDragStarted || !pointerIdToRelease || !containerEl || !rowWrapperEl) return;

      // Check if the pointer has moved far enough to qualify as a drag
      if (clickQualifiesAsDrag === false) {
        // ----- Convert the provisional drag into a real drag ----- //
        const movementVector = Vec2Utils.subtract(pointerDownPosition, pointerState.cursorPos);
        const movementDistance = Vec2Utils.length(movementVector);

        // Note: no need to use a time based check here because layer tree requires big movements (rather than 1px detail like on the canvas)
        if (movementDistance > CLICK_VS_DRAG_THRESHOLD_LAYER_TREE_PX) {
          // We've moved far enough to qualify as a drag
          containerEl.setPointerCapture(pointerIdToRelease);
          clickQualifiesAsDrag = true;
          containerEl.setAttribute('data-is-dragging', 'true');

          // Mark the nodes that are being dragged
          for (const node of nodesBeingDragged) {
            // Note: this is pretty inefficient way to do this but it should be fine in practice and more bug-proof than optimizations
            const nodeEl = containerEl?.querySelector(`[data-node-id="${node.id}"]`);
            if (nodeEl) {
              nodeEl.setAttribute('data-is-being-dragged', 'true');
            }
          }
        } else {
          // Not yet qualified as a drag, bail out
          return;
        }
      }

      // ----- Find the row under the cursor ----- //
      rowWrapperElBounds = rowWrapperEl.getBoundingClientRect(); // we need to grab this on every input because auto-opening closed layers can change it during drag
      const hitTestPoint = {
        // Just indent a little into the layer tree because the cursor might be outside of it
        x: 2,
        // Constrain the y position to be within the layer tree's bounds, just in case the cursor is outside of it
        y: Math.min(Math.max(rowWrapperElBounds!.y + 1, pointerState.cursorPos.y), rowWrapperElBounds!.bottom - 1),
      };
      const hoverNodeEl = document.elementFromPoint(hitTestPoint.x, hitTestPoint.y)?.closest('[data-node-id]');
      const hoverNodeId = hoverNodeEl?.getAttribute('data-node-id');

      // Bail if we can't find a node ID
      if (!hoverNodeEl || !hoverNodeId) {
        layerTreeState.setDropTarget(null, null, null);
        return;
      }
      const hoverNode = treeUtils.getNode(hoverNodeId);
      assert(hoverNode);

      // If the hover node is part of the dragged nodes, treat this entire chunk of selected nodes as one entity
      // Build out the "hover chunk" which is either the hovered node or
      // if the hover is part of the dragged nodes, the continuous block of visible dragged nodes around it
      let topNodeOfHoverChunk: TreeNode = hoverNode;
      let bottomNodeOfHoverChunk: TreeNode = hoverNode;
      if (nodesBeingDragged.has(hoverNode)) {
        // Test backward to find the start of the visible selected chunk
        let testNode: TreeNode | null = topNodeOfHoverChunk;
        while (testNode) {
          // If the previous node is part of the dragged nodes, move to it
          const previousVisibleNode = layerTreeState.getPreviousVisibleNode(testNode);
          if (previousVisibleNode && nodesBeingDragged.has(previousVisibleNode)) {
            testNode = previousVisibleNode;
          } else {
            // Found the end of the continuous block of dragged nodes
            break;
          }
        }
        topNodeOfHoverChunk = testNode;

        // Test forward to find the end of the visible selected chunk
        testNode = bottomNodeOfHoverChunk;
        while (testNode) {
          // If the next node is part of the dragged nodes, move to it
          const nextVisibleNode = layerTreeState.getNextVisibleNode(testNode);
          if (nextVisibleNode && nodesBeingDragged.has(nextVisibleNode)) {
            testNode = nextVisibleNode;
          } else {
            // Found the end of the continuous block of dragged nodes
            break;
          }
        }
        bottomNodeOfHoverChunk = testNode;
      }

      // Grab the DOM elements for the top and bottom of the hover chunk (hover chunk is either the hovered node or, if the hover is part of the dragged nodes, the continuous block of dragged nodes around it)
      if (!topNodeOfHoverChunk || !bottomNodeOfHoverChunk) {
        console.error('Unexpected: could not find top and bottom of dragged hover intent');
        layerTreeState.setDropTarget(null, null, null);
        return;
      }
      let topNodeOfHoverChunkDomEl: HTMLElement | null = containerEl.querySelector(
        `[data-node-id="${topNodeOfHoverChunk.id}"]`
      );
      let bottomNodeOfHoverChunkDomEl: HTMLElement | null =
        topNodeOfHoverChunk === bottomNodeOfHoverChunk
          ? topNodeOfHoverChunkDomEl
          : containerEl.querySelector(`[data-node-id="${bottomNodeOfHoverChunk.id}"]`);
      if (!topNodeOfHoverChunkDomEl || !bottomNodeOfHoverChunkDomEl) {
        console.error('Unexpected: could not find top and bottom DOM elements for dragged hover intent');
        layerTreeState.setDropTarget(null, null, null);
        return;
      }
      // Build the size of the hover chunke can decide if the mouse is trying to indicate above or below it
      let hoverRect: RectWithSize = { minX: 0, minY: 0, maxX: 0, maxY: 0, width: 0, height: 0 };
      const topNodeOfHoverChunkRect = topNodeOfHoverChunkDomEl.getBoundingClientRect();
      const bottomNodeOfHoverChunkRect = bottomNodeOfHoverChunkDomEl.getBoundingClientRect();
      hoverRect.minX = topNodeOfHoverChunkRect.left;
      hoverRect.minY = topNodeOfHoverChunkRect.top;
      hoverRect.maxX = bottomNodeOfHoverChunkRect.right;
      hoverRect.maxY = bottomNodeOfHoverChunkRect.bottom;
      hoverRect.width = hoverRect.maxX - hoverRect.minX;
      hoverRect.height = hoverRect.maxY - hoverRect.minY;

      // Find where the pointer is positioned relative to the node that it's over
      const cursorToHoverY = pointerState.cursorPos.y - hoverRect.minY;
      const cursorToHoverYRatio = cursorToHoverY / hoverRect.height;

      // ----- Build possible drop targets ----- //
      /** What's the least deep new parent this drag can have? We'll update this as we search but default to the root itself */
      let highestPossibleParent = treeUtils.rootNode;
      /** The index to drop within the highest possible parent */
      let highestPossibleParentDropIndex = treeUtils.rootNode.children.length;
      /** Whether this possible drop target was inferred up the tree or direct from the cursor being over it */
      let dropTargetInferred = true; // root node can only be inferred, not directly hovered, so we start inferred

      // Check if this node can have children from a drag, or if it will just give before-node and after-node results
      const allowDirectDrop = nodesBeingDragged.has(hoverNode) === false && hoverNode.canHaveChildren;
      /** The percentage of the hover rect where, if the cursor is inside it, it counts as intended to drop above it */
      let aboveIntentRatio = allowDirectDrop ? 0.24 : 0.5;
      /** The percentage of the hover rect where, if the cursor is inside it, it counts as intended to drop below it */
      let belowIntentRatio = allowDirectDrop ? 0.76 : 0.5;

      if (cursorToHoverYRatio < aboveIntentRatio) {
        // Cursor is at the TOP of the row, intent is ABOVE the row
        // So the drop can be inside this row's parent
        highestPossibleParent = topNodeOfHoverChunk.parent!;
        highestPossibleParentDropIndex = topNodeOfHoverChunk.parent!.children.indexOf(topNodeOfHoverChunk);
        dropTargetInferred = true;
      } else if (cursorToHoverYRatio > belowIntentRatio) {
        // Cursor is at the BOTTOM of the row, intent is BELOW the row
        // Use DOM to find the next row instead of TreeUtils because we don't want to count nodes that are hidden in the layer tree
        const nextRowEl = bottomNodeOfHoverChunkDomEl.nextElementSibling;
        const nextRowId = nextRowEl?.getAttribute('data-node-id');
        if (nextRowId) {
          const nextRow = treeUtils.getNode(nextRowId);
          assert(nextRow);
          highestPossibleParent = nextRow.parent!;
          highestPossibleParentDropIndex = nextRow.parent!.children.indexOf(nextRow);
          dropTargetInferred = true;
        }
      } else {
        // Cursor is INSIDE the row, intent is to drop INSIDE this row
        // The only possible drop target here is first child of the row itself
        // But we prefer sibling drops so we require the pointer to settle movement to trust this intent
        const movementRollingAverage =
          last10PointerDeltas.reduce((acc, curr) => acc + curr, 0) / last10PointerDeltas.length;
        if (movementRollingAverage > 1) {
          // Ignore if pointer is moving quickly
          return;
        }
        highestPossibleParent = hoverNode;
        highestPossibleParentDropIndex = 0;
        dropTargetInferred = false;
      }

      // Bail if the highest possible parent can't have children
      if (highestPossibleParent.canHaveChildren === false) {
        layerTreeState.setDropTarget(null, null, null);
        return;
      }

      // Now that we have our highest possible parent, build out an array of possible drop targets
      // After we build the array, we'll look at cursor X position to choose from among them
      type PossibleDropTarget = { node: TreeNode; dropIndex: number; inferred: boolean };
      const possibleDropTargets: Array<PossibleDropTarget> = [
        { node: highestPossibleParent, dropIndex: highestPossibleParentDropIndex, inferred: dropTargetInferred },
      ];

      /**
       * From the place where we'd currently insert the dragged nodes (so at highestParent, highestDropIndex)
       * get the previous sibling. Any of its descendants can be a drop target.
       */
      const previousSiblingNode = highestPossibleParent.children[highestPossibleParentDropIndex - 1];
      if (previousSiblingNode) {
        // For each descendant of the previous sibling, add a potential drop target
        for (const descendant of previousSiblingNode.selfAndDescendingLastChildren) {
          // Must be able to have children
          if (descendant.canHaveChildren === false) {
            continue;
          }

          // Must not be part of the dragged nodes
          if (nodesBeingDragged.has(descendant)) {
            continue;
          }

          // If we made it this far, it's a valid potential drop target:
          possibleDropTargets.push({ node: descendant, dropIndex: descendant.children.length, inferred: true });

          if (descendant.children.length > 0 && layerTreeState.expandedNodeIds.has(descendant.id) === false) {
            // Break out of the loop if further descendants are hidden, they can't be drop targets
            break;
          }
        }
      }

      // If it's an inferred drop with more than one choice and the last possible drop target is right above the cursor, remove it
      // because it never feels good to drop into the parent directly above the cursor while between nodes and doing horizontal movement
      // and we also want to give priority to sibling drops and require direct drops into rows near the cursor
      const lastPossibleDropTarget = possibleDropTargets[possibleDropTargets.length - 1]!;
      if (
        possibleDropTargets.length > 1 &&
        lastPossibleDropTarget.inferred &&
        topNodeOfHoverChunk.canHaveChildren === true && // Only remove if the hover can have children, otherwise the lastPossibleDropTarget is by definition far away from the cursor
        (lastPossibleDropTarget.node === topNodeOfHoverChunk ||
          lastPossibleDropTarget.node === layerTreeState.getPreviousVisibleNode(topNodeOfHoverChunk))
      ) {
        possibleDropTargets.splice(possibleDropTargets.length - 1, 1);
      }

      // ----- Determine the horizontal intent ----- //
      // Intended depth is calculated relative to the depth of the dragged node and based on horizontal movement of the cursor

      // Depth of top most parent in the possible drop targets
      const topMostPossibleParentDepth = possibleDropTargets[0]!.node.ancestors.length;
      // Depth of the previous node in the possible drop targets
      const nearestPossibleParentDepth = possibleDropTargets[possibleDropTargets.length - 1]!.node.ancestors.length;
      // Depth to be a sibling of the nearest potential parent (default placement before moving mouse horizontally)
      const indexOfNearestParent = nearestPossibleParentDepth - topMostPossibleParentDepth;
      // How far does the pointer need to move to switch intents?
      const horizontalIntentSwitchThreshold = 15;
      // How far has the cursor moved horizontally?
      const cursorMovement = Vec2Utils.subtract(pointerState.cursorPos, pointerDownPosition);
      // Calculate the number of horziontal intents, positive or negative, the cursor movement indicates
      // When starting from zero, you can move a full horizontalIntentSwitchThreshold left or right before changing intents
      // but then further movement in that direction only requires one horizontalIntentSwitchThreshold to change intents
      // so "zero" basically has plus or minus horizontalIntentSwitchThreshold on each side before changing intents
      let cursorMovementIntents = 0;
      if (cursorMovement.x < -horizontalIntentSwitchThreshold) {
        // Important to use Math.ceil so it doesn't go into a lower intent until the mouse moves fully through the threshold
        cursorMovementIntents = Math.ceil(cursorMovement.x / horizontalIntentSwitchThreshold);
      } else if (cursorMovement.x > horizontalIntentSwitchThreshold) {
        // Important to use Math.floor so it doesn't go into a higher intent until the mouse moves fully through the threshold
        // NOTE: I don't think it's currently possible to have a drop target that requires going to the right of 0
        // because we currently start by targeting the deepest parent and then we move horizontally from there
        // but I'll leave this code here for completion in case we ever switch this
        cursorMovementIntents = Math.floor(cursorMovement.x / horizontalIntentSwitchThreshold);
      }
      // Based on the default parent and the cursor movement, calculate the target index (may overflow the possible drop targets)
      const targetIndexUnbounded = indexOfNearestParent + cursorMovementIntents;
      // Clamp to the possible drop targets
      const targetIndex = Math.min(Math.max(targetIndexUnbounded, 0), possibleDropTargets.length - 1);
      // Grab the new drop target
      const newDropTarget = possibleDropTargets[targetIndex]!;

      // Bail if this would cause circular nesting
      if (nodesBeingDragged.has(newDropTarget.node)) {
        layerTreeState.setDropTarget(null, null, null);
        return;
      } else {
        layerTreeState.setDropTarget(
          newDropTarget.node,
          newDropTarget.dropIndex,
          newDropTarget.inferred ? 'inferred-by-x-axis-cursor-movement' : 'direct-hover'
        );
      }
    }

    // ----- Pointer up ----- //
    function handlePointerUp(e: PointerEvent) {
      if (pointerIdToRelease) {
        containerEl!.releasePointerCapture(pointerIdToRelease);
        pointerIdToRelease = null;
      }

      // Reset the rolling average of pointer movement
      last10PointerDeltas = [];
      lastFramePointerY = null;

      // Bail if the pointer down didn't potentially start a drag
      if (!provisionalDragStarted) return;

      // Single click inside a multi-selection should select only the node the cursor is over
      if (clickQualifiesAsDrag === false) {
        // We didn't move far enough to qualify as a drag, so this counts as a click
        // If there was more than one thing selected, including descendants, we should just select the node the cursor is on
        if (nodesBeingDragged.size > 1 && pointerDownNodeId) {
          selectionState.selectIds([pointerDownNodeId]);
        }
      }

      // Reset any auto-scrolling
      autoScrollDirection.x = 0;
      autoScrollDirection.y = 0;

      // Grab the new parent node and index before we end the drag
      const newParentNode = layerTreeState.targetParentNode;
      const newDropIndex = layerTreeState.targetChildIndex ?? 0;
      // End the drag
      endDrag();
      // Bail if we don't have a new parent to move the selected nodes to
      if (!newParentNode) return;

      // Generate sort keys for the selected nodes, sorting them by tree order
      const sortedDraggedNodes = selectionState.selectedNodes.slice();
      treeUtils.sortNodesByTreeOrderInPlace(sortedDraggedNodes);
      assert(sortedDraggedNodes.length > 0);
      const prevSiblingSortKey = newParentNode.children[newDropIndex - 1]?.sortKey ?? null;
      const nextSiblingSortKey = newParentNode.children[newDropIndex]?.sortKey ?? null;
      const newSortKeys = createMultipleSortOrderKeys(
        sortedDraggedNodes.length,
        prevSiblingSortKey,
        nextSiblingSortKey
      );

      if (e.altKey) {
        transaction(() => {
          // It's a clone drag, clone all the nodes
          const tempTree = cloneNodesIntoTempTree(
            editorState,
            sortedDraggedNodes.map((node) => node.id)
          );
          assert(tempTree.topLevelNodeIds.length === sortedDraggedNodes.length);
          // Build new relationships targeting the new parent
          const topLevelNodeRelationships: Record<string, string> = {};
          for (let i = 0; i < tempTree.topLevelNodeIds.length; i++) {
            topLevelNodeRelationships[tempTree.topLevelNodeIds[i]!] = makeTreeRelationshipString(
              newParentNode.id,
              newSortKeys[i]!
            );
          }
          const clonedIdMap = cloneTempTreeIntoFile(editorState, tempTree, topLevelNodeRelationships);
          const cloneNodeIds = clonedIdMap.values();
          // Select the clones
          selectionState.selectIds(cloneNodeIds);
        });
      } else {
        transaction(() => {
          for (let i = 0; i < sortedDraggedNodes.length; i++) {
            const node = sortedDraggedNodes[i]!;
            treeUtils.moveNodeToNewParent(node, newParentNode, newSortKeys[i]);
          }
        });
      }

      // Both clones and moves will end up selected, so use selectedNodes to ensure visibility
      layerTreeState.ensureNodesAreVisibleInLayerTree(selectionState.selectedNodes);
    }

    // ----- Check for modifier keys and escape ----- //
    function checkForModifierAndEscape(e: KeyboardEvent) {
      if (e.key === 'Alt' || e.key === 'Meta' || e.key === 'Shift') {
        handleNewInput();
      }

      if (e.key === 'Escape') {
        endDrag();
      }
    }

    // ----- Auto scroll RAF ----- //
    let autoScrollRaf: number | null = null;
    /** Stores directional instructions for auto-scrolling next RAF */
    const autoScrollDirection: Vec2 = { x: 0, y: 0 };
    function autoScroll() {
      if (autoScrollRaf !== null) return;

      autoScrollRaf = requestAnimationFrame(() => {
        // Scroll the container
        containerEl!.scrollBy(autoScrollDirection.x, autoScrollDirection.y);
        // Keep auto-scrolling until we're told not to
        autoScrollRaf = null;
        if (autoScrollDirection.x !== 0 || autoScrollDirection.y !== 0) {
          autoScroll();
        }
      });
    }

    // ----- End drag ----- //
    function endDrag() {
      // Unbind the drag handlers
      unbindDragHandlers();
      // Reset the drop target
      layerTreeState.setDropTarget(null, null, null);
      // Reset the nodes being dragged
      nodesBeingDragged.clear();
      // Reset the dragging attribute
      containerEl?.removeAttribute('data-is-dragging');
      const draggedRowEls = containerEl?.querySelectorAll('[data-is-being-dragged]') ?? [];
      for (const el of draggedRowEls) {
        el.removeAttribute('data-is-being-dragged');
      }

      // Reset caches
      clickQualifiesAsDrag = false;
      provisionalDragStarted = false;
      autoScrollDirection.x = 0;
      autoScrollDirection.y = 0;
    }

    // ----- Bind drag handlers ----- //
    function bindDragHandlers() {
      tickState.subToTick(frameLoopWhilePointerDown);

      window.addEventListener('keydown', checkForModifierAndEscape);
      window.addEventListener('keyup', checkForModifierAndEscape);
      containerEl!.addEventListener('pointerup', handlePointerUp);
    }

    // ----- Unbind drag handlers ----- //
    function unbindDragHandlers() {
      tickState.unsubToTick(frameLoopWhilePointerDown);

      window.removeEventListener('keydown', checkForModifierAndEscape);
      window.removeEventListener('keyup', checkForModifierAndEscape);
      containerEl!.removeEventListener('pointerup', handlePointerUp);
    }

    containerEl.addEventListener('pointerdown', handlePointerDown);
    return () => {
      containerEl.removeEventListener('pointerdown', handlePointerDown);
      unbindDragHandlers();
    };
  }, [editorState, containerRef, rowWrapperRef]);

  return [containerRef, rowWrapperRef];
}
