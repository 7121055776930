import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { NumberInput } from '../properties/number-input';
import { Field } from '../../components/field';
import { reducePropertyValues } from '../properties/reduce-property-values';

export const FlexDirectionProperty = observer(() => {
  const { propertiesState } = useEditor();

  const currentValue = propertiesState.getStyleValues('flexDirection');
  const inputValue = reducePropertyValues(currentValue, 'row');

  function commitValue(value: string) {
    for (const node of propertiesState.allNodes) {
      if (node.layoutModeForChildren === 'flex') {
        node.setStyle('flexDirection', value);
      }
    }
  }

  return (
    <Field.Root>
      <Field.Icon>FlexDirection</Field.Icon>
      <Field.Control>
        <select value={inputValue} onChange={(e) => commitValue(e.target.value)}>
          <option value="row">Row</option>
          <option value="column">Column</option>
        </select>
      </Field.Control>
    </Field.Root>
  );
});
