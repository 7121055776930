import { TreeNode } from '../tree/TreeNode';

/** Provide an array of nodes and get back the IDs of the nodes to select */
export function chooseSelectionFromBrush(
  matchingNodes: Array<{ node: TreeNode; isEnclosed: boolean }>,
  isMetaKeyDown: boolean
): Array<string> {
  let selection: Array<string> = [];

  // ----- Fully enclosed nodes -----
  for (let i = 0; i < matchingNodes.length; i++) {
    const node = matchingNodes[i]!.node;
    const isEnclosed = matchingNodes[i]!.isEnclosed;

    // Meta key behavior - when meta is held, nodes that can have children must be fully enclosed to be selected
    if (isMetaKeyDown && node.canHaveChildren && isEnclosed === false) {
      continue;
    }

    // Top level boxes – require full enclosure unless they are empty
    if (node.isFrame && isEnclosed === false && node.children.length > 0) {
      continue;
    }

    // If we made it this far, go ahead and add it to the selection
    selection.push(node.id);
  }

  return selection;
}
