import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { NumberInput } from './number-input';
import { Field } from '../../components/field';
import { reducePropertyValues } from './reduce-property-values';
import { PropertyPanels } from './property-panel';

export const BorderRadiusProperty = observer(function BorderRadiusProperty() {
  const { propertiesState } = useEditor();

  const values = propertiesState.getStyleValues('borderRadius');
  const inputValue = reducePropertyValues(values, '0px');

  function commitValue(value: string) {
    for (const node of propertiesState.nodesPerProperty[PropertyPanels.Layout]) {
      node.setStyle('borderRadius', value);
    }
  }

  return (
    <Field.Root>
      <Field.Icon>
        <BorderRadiusIcon />
      </Field.Icon>
      <Field.Control>
        <NumberInput min={0} value={inputValue} onValueCommit={commitValue} />
      </Field.Control>
    </Field.Root>
  );
});

const BorderRadiusIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M3.5 12V7.5C3.5 5.29086 5.29086 3.5 7.5 3.5H12" stroke="currentcolor" />
    </svg>
  );
};
