/**
 * Asserts that the condition is true, throws an error if it's not.
 * This function narrows the type if possible.
 *
 * @param condition - The condition to assert
 * @param message - Optional error message
 */
export function assert(condition: any, message?: string): asserts condition {
  if (!condition) {
    throw new Error(message || 'Assertion failed');
  }
}
