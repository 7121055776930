import { EditorState } from '../EditorState';
import { createMultipleSortOrderKeys } from '@mobius/models/src/file/create-sort-order-key';
import { TreeNodeType } from '@mobius/models/src/file/tree-node-schema';
import { ulid } from 'ulid';
import { NodeRelationshipsKeys } from '@mobius/models/src/file/file-schema';
import {
  makeTreeRelationshipString,
  splitTreeRelationshipString,
} from '@mobius/models/src/file/tree-node-relationships';
import { assert } from '@mobius/models/src/assert';
import { action } from 'mobx';
import { TempTreeType } from '@mobius/models/src/file/temp-tree-schema';
import { FileState } from '../file-and-observers/FileState';

/**
 * Takes a Temp Tree and adds it into a File's real tree
 *
 * @param editorState - The editor state
 * @param tempTree - The temp tree
 * @param topLevelNodeRelationships - The top level node relationships
 * @returns A map of the temp node IDs to the new cloned node IDs
 */
export const cloneTempTreeIntoFile = action(
  (
    editorState: EditorState,
    tempTree: TempTreeType,
    topLevelNodeRelationships: Record<string, string>
  ): Map<string, string> => {
    /**
     * Key: NodeIDs from the tempTree
     * Value: new cloned Node ID
     */
    const clonedIds = new Map<string, string>();

    // Loop through the top level nodes in the temp tree and add them to the tree
    for (let i = 0; i < tempTree.topLevelNodeIds.length; i++) {
      const tempNode = tempTree.nodes[tempTree.topLevelNodeIds[i]!]!;
      assert(tempNode, `Could not find temp node: ${tempTree.topLevelNodeIds[i]}`);
      // Grab the relationship for this node
      const relationship = topLevelNodeRelationships[tempNode.id];
      assert(typeof relationship === 'string', `Could not find relationship for top level node: ${tempNode.id}`);
      const [parentId, sortKey] = splitTreeRelationshipString(relationship);
      // Clone it!
      const cloneId = cloneNodeIntoFile(editorState.fileState, tempTree, clonedIds, tempNode, parentId, sortKey);
      clonedIds.set(tempNode.id, cloneId);
    }

    return clonedIds;
  }
);

/** Clones a node into a file, mutating the file, and recursively calls itself to travel down the tree, cloning descendants */
function cloneNodeIntoFile(
  fileState: FileState,
  tempTree: TempTreeType,
  clonedIds: Map<string, string>,
  sourceNode: TreeNodeType,
  parentId: string,
  sortKey: string
) {
  const fileData = fileState.data;
  const clone: TreeNodeType = JSON.parse(JSON.stringify(sourceNode));
  // Create a new ID
  clone.id = ulid();
  // DO NOT: you might be tempted to create a new label here, but for clones we want to use the original labels – so set new labels after cloning back into the file
  // Pop it into the file tree
  fileData.nodes[clone.id] = clone;
  // Add the relationship to the parent
  fileData[NodeRelationshipsKeys][clone.id] = makeTreeRelationshipString(parentId, sortKey);

  // Generate sort keys for any children
  const childrenIds = tempTree.parentToChildrenIndex[sourceNode.id] ?? [];
  if (childrenIds.length > 0) {
    const childSortKeys = createMultipleSortOrderKeys(childrenIds.length, null, null);
    assert(childSortKeys.length === childrenIds.length, 'Child sort keys length mismatch');
    for (let i = 0; i < childrenIds.length; i++) {
      const childId = childrenIds[i]!;
      const childNode = tempTree.nodes[childId];
      assert(childNode, `Could not find temp child node: ${childId}`);
      const childSortKey = childSortKeys[i]!;
      const newCloneId = cloneNodeIntoFile(fileState, tempTree, clonedIds, childNode, clone.id, childSortKey);
      clonedIds.set(childId, newCloneId);
    }
  }

  return clone.id;
}
