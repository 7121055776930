import { lazy, Suspense } from 'react';

const Layout = lazy(() => import('./layout'));
const HotkeysCheatSheetComponent = lazy(() => import('./hotkeys-cheat-sheet'));

export default function Demo() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Layout>
        <HotkeysCheatSheetComponent />
      </Layout>
    </Suspense>
  );
}
