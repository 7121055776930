/**
 * Deletes a value from a nested object using a JSON Pointer string.
 * @param {Object} obj - The object to modify
 * @param {string} pointer - JSON Pointer string (e.g., "/a/b/c") – will NOT handle slashes in keys
 * @returns {boolean} True if the property was deleted, false if it didn't exist
 */
export function deepDelete(obj: Record<any, any>, pointer: string) {
  if (typeof obj !== 'object' || obj === null) {
    console.warn(`deepDelete: obj is not an object, obj: ${obj}`);
    return false;
  }

  // Remove leading slash if present
  const path = pointer.charAt(0) === '/' ? pointer.substr(1) : pointer;
  const keys = path.split('/');

  let current = obj;

  // Traverse the object
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i] as string;
    if (current[key] === undefined) {
      console.warn(`deepDelete: path does not exist, path: ${path}, key: ${key}`);
      return false;
    }
    current = current[key];

    if (typeof current !== 'object' || current === null) {
      console.warn(`deepDelete: cannot traverse further, path: ${path}, key: ${key}`);
      return false;
    }
  }

  const lastKey = keys[keys.length - 1] as string;

  // Delete the property
  if (Object.prototype.hasOwnProperty.call(current, lastKey)) {
    delete current[lastKey];
  } else {
    return false;
  }

  return true;
}
