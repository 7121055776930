/**
 * Performs a splice operation on an array deep within an object structure.
 * @param {Object} obj - The target object
 * @param {string} pointer - JSON Pointer string to the array (including the start index) – will NOT handle slashes in keys
 * @param {number} deleteCount - Number of elements to delete
 * @param {Array} [insertItems=[]] - Items to insert
 * @returns {Array|null} The deleted items, or null if operation failed
 */
export function deepSplice(obj: Record<any, any>, pointer: string, deleteCount: number, insertItems: any[] = []) {
  if (typeof obj !== 'object' || obj === null) {
    console.warn('deepSplice: obj is not an object');
    return null;
  }

  const keys = pointer.charAt(0) === '/' ? pointer.slice(1).split('/') : pointer.split('/');
  const lastKey = keys.pop() as string;
  let current = obj;

  // Traverse to the parent of the target array
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i] as string;
    if (current[key] === undefined) {
      console.warn('deepSplice: path does not exist', { pointer, key });
      return null;
    }
    current = current[key];
    if (typeof current !== 'object' || current === null) {
      console.warn('deepSplice: cannot traverse further', { pointer, key });
      return null;
    }
  }

  // Check if we've reached an array and have a valid index
  if (!Array.isArray(current)) {
    console.warn('deepSplice: target is not an array', { pointer, lastKey });
    return null;
  }

  const index = Number(lastKey);
  if (index < 0 || !Number.isInteger(index) || index > current.length) {
    console.warn('deepSplice: invalid array index', { pointer, lastKey });
    return null;
  }

  // Perform the splice operation
  return current.splice(index, deleteCount, ...insertItems);
}
