import { composeRefs } from '@radix-ui/react-compose-refs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { ColorUtils, oklchToRgb, rgbToOklch, type Color } from '../editor/properties/Color';
import { baseInputProps, defaultPointerDownHandler } from './input';
import { getNumberAndUnit } from '../editor/properties/get-number-and-unit';

export interface ColorInputProps extends Omit<React.ComponentProps<'input'>, 'color'> {
  color: Color;
  mode?: Color['mode'];
  onColorCommit?: (value: Color) => void;
}

export function ColorInput({ color, mode, onColorCommit, ...props }: ColorInputProps) {
  const [input, setInput] = useState<HTMLInputElement | null>(null);

  const sourceValue = ColorUtils.pretty(color, mode);
  const [value, setValue] = useState(sourceValue);
  useEffect(() => setValue(sourceValue), [sourceValue]);

  const commitValue = useCallback(
    function (value: string) {
      const color = ColorUtils.new(value);

      if (color.badInput) {
        setValue(sourceValue);
        return;
      }

      const formatted = ColorUtils.pretty(color);
      setValue(formatted);
      onColorCommit?.(color);
    },
    [onColorCommit, sourceValue, setValue]
  );

  function handleBlur() {
    // Commit the value if it's new
    if (value !== sourceValue) {
      commitValue(value);
    }
  }

  // Run the blur handler on unmount
  const handleBlurRef = useRef(handleBlur);
  useEffect(() => {
    handleBlurRef.current = handleBlur;
  });
  useEffect(() => {
    return () => handleBlurRef.current();
  }, []);

  return (
    <input
      {...baseInputProps}
      {...props}
      ref={(node) => {
        setInput(node);
        if (typeof props.ref === 'object') {
          composeRefs(props.ref, { current: node });
        }
      }}
      value={value}
      onBlur={(event) => {
        handleBlur();
        props.onBlur?.(event);
      }}
      onChange={(event) => {
        setValue(event.target.value);
        props.onChange?.(event);
      }}
      onKeyDown={(event) => {
        if (!input) {
          return;
        }

        if (event.key === 'Escape') {
          if (value === sourceValue) {
            input?.blur();
          } else {
            flushSync(() => setValue(sourceValue));
            input?.select();
          }
        }

        if (event.key === 'Enter') {
          input?.blur();
        }

        const start = input.selectionStart;
        const end = input.selectionEnd;

        if (!event.key.startsWith('Arrow') || start === null || end === null || event.altKey || event.metaKey) {
          return;
        }

        const groups = splitValue(input.value);

        if (event.key === 'ArrowDown') {
          const group = getGroupAt(groups, start);

          if (group) {
            event.preventDefault();
            const sub = nudgeColor({
              value: input.value,
              start: group.start,
              end: group.end,
              amount: event.shiftKey ? -10 : -1,
              min: 0,
              max: 255,
            });

            const newValue = input.value.substring(0, group.start) + sub + input.value.substring(group.end);
            flushSync(() => commitValue(newValue));
            input.selectionStart = group.start;
            input.selectionEnd = group.end + sub.length - group.sub.length;
          }
        }

        if (event.key === 'ArrowUp') {
          const group = getGroupAt(groups, end);

          if (group) {
            event.preventDefault();
            const sub = nudgeColor({
              value: input.value,
              start: group.start,
              end: group.end,
              amount: event.shiftKey ? 10 : 1,
              min: 0,
              max: 255,
            });

            const newValue = input.value.substring(0, group.start) + sub + input.value.substring(group.end);
            flushSync(() => commitValue(newValue));
            input.selectionStart = group.start;
            input.selectionEnd = group.end + sub.length - group.sub.length;
          }
        }

        if (event.shiftKey) {
          return;
        }

        if (start !== end) {
          if (event.key === 'ArrowLeft') {
            const group = getGroupBefore(groups, start, end);
            if (group) {
              event.preventDefault();
              input.selectionStart = group.start;
              input.selectionEnd = group.end;
            }
          }

          if (event.key === 'ArrowRight') {
            const group = getGroupAfter(groups, start, end);
            if (group) {
              event.preventDefault();
              input.selectionStart = group.start;
              input.selectionEnd = group.end;
            }
          }
        }
      }}
      onPointerDown={(event) => {
        defaultPointerDownHandler(input);
        props.onPointerDown?.(event);
      }}
    />
  );
}

// Split the input value into distinct groups that are navigated via arrow keys
function splitValue(value: string) {
  let pos = 0;
  return value
    .trim()
    .split(/\s*,\s*|\s*\/\s*|\s+/gi)
    .map((sub) => {
      const index = value.indexOf(sub, pos);
      pos = index + sub.length;
      return { sub, start: index, end: index + sub.length };
    });
}

interface NudgeColorArgs {
  value: string;
  start: number;
  end: number;
  amount: number;
  min: number;
  max: number;
}

function nudgeColor({ value, start, end, amount, min, max }: NudgeColorArgs) {
  const sub = value.substring(start, end);
  const colorFromSub = ColorUtils.new(sub, 1);
  const colorFromValue = ColorUtils.new(value, 1);

  // We are nudging the hex part of the hex if both substring and full value are parsed as an equivalent hex
  if (!colorFromValue.badInput && colorFromValue.mode === 'hex' && ColorUtils.isEqual(colorFromSub, colorFromValue)) {
    const rgb = oklchToRgb(colorFromValue.value);
    rgb.r = Math.min(1, Math.max(0, (rgb.r * 255 + amount) / 255));
    rgb.g = Math.min(1, Math.max(0, (rgb.g * 255 + amount) / 255));
    rgb.b = Math.min(1, Math.max(0, (rgb.b * 255 + amount) / 255));
    const color: Color = { value: rgbToOklch(rgb), mode: 'hex' };
    return ColorUtils.cssString(color).substring(1);
  }

  let [number, unit] = getNumberAndUnit(sub);

  if (Number.isNaN(number)) {
    return sub;
  }

  return Math.min(max, Math.max(min, number + amount)) + unit;
}

function getGroupAt(groups: ReturnType<typeof splitValue>, pos: number) {
  return groups.find((group) => group.start <= pos && group.end >= pos);
}

function getGroupBefore(groups: ReturnType<typeof splitValue>, start: number, end: number) {
  return (
    groups.toReversed().find((group) => (group.start === start ? group.end < end : group.start < start)) || groups[0]
  );
}

function getGroupAfter(groups: ReturnType<typeof splitValue>, start: number, end: number) {
  return (
    groups.find((group) => (group.end === end ? group.start > start : group.end > end)) || groups[groups.length - 1]
  );
}
