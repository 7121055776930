import { RectWithSize } from '@mobius/models/src/math/rect';
import { TreeNode } from './TreeNode';

/**
 * Gives an array or a set of Nodes, builds the maximum bounds around them
 */
export const getBoundsForNodes = (nodes: Array<TreeNode> | Set<TreeNode>): RectWithSize => {
  const bounds: RectWithSize = {
    minX: Infinity,
    minY: Infinity,
    maxX: -Infinity,
    maxY: -Infinity,
    width: 0,
    height: 0,
  };

  for (const node of nodes) {
    if (!node) {
      continue;
    }
    bounds.minX = Math.min(bounds.minX, node.x);
    bounds.minY = Math.min(bounds.minY, node.y);
    bounds.maxX = Math.max(bounds.maxX, node.x + node.width);
    bounds.maxY = Math.max(bounds.maxY, node.y + node.height);
  }
  bounds.width = bounds.maxX - bounds.minX;
  bounds.height = bounds.maxY - bounds.minY;

  return bounds;
};
