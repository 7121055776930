/** The result of a style value where multiple selected nodes have different values */
export const MIXED_STYLE_VALUE = 'Mixed';

/**
 * - Reduces `SelectionValues` object to a single common value, fast
 * - Produces `MIXED_STYLE_VALUE` when values are mixed
 * - Imposes `defaultValue` on undefined values
 *   (So that e.g. `borderRadius: "0px"` and `borderRadius: undefined` can produce "0px").
 */
export function reducePropertyValues(values: Record<string, string[]>, defaultValue: string) {
  let acc: string | undefined;

  for (const value in values) {
    // Empty strings must use the fallback value
    const thisValue = value || defaultValue;

    if (acc === undefined) {
      acc = thisValue;
      continue;
    }

    if (acc !== thisValue) {
      // Bail as soon as we run into a mixed value
      return MIXED_STYLE_VALUE;
    }
  }

  return acc as string;
}
