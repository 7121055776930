import * as Slider from '@radix-ui/react-slider';
import { useEditor } from '../editor-context';
import { Color, ColorUtils, hslToOklch, oklchToHsl } from '../properties/Color';

type Props = {
  color: Color;
  onChange: (color: Color) => void;
  isUniform?: boolean;
};

export function OpacitySlider({ color, onChange }: Props) {
  const { fileState } = useEditor();
  const opaqueColor = ColorUtils.new(ColorUtils.cssString(color), 1);

  const handleChange = (value: number[]) => {
    if (value[0] === undefined) return;
    const newColor: Color = { ...color, value: { ...color.value, alpha: value[0] } };
    onChange(newColor);
  };

  return (
    <div
      className="border-gray-5 rounded-1 relative w-4 border bg-white bg-origin-border"
      style={{
        backgroundImage: `
          linear-gradient(to bottom, ${ColorUtils.cssString(opaqueColor)} 0%, transparent 100%),
          linear-gradient(45deg, #e6e6e6 25%, transparent 25%),
          linear-gradient(-45deg, #e6e6e6 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, #e6e6e6 75%),
          linear-gradient(-45deg, transparent 75%, #e6e6e6 75%)
        `,
        backgroundSize: '100%, 16px 16px, 16px 16px, 16px 16px, 16px 16px',
        backgroundPosition: '0 0, 0px 8px, 0px 16px, 8px 0px, -8px 8px',
      }}
    >
      <Slider.Root
        value={[color.value.alpha ?? 1]}
        max={1}
        min={0}
        step={0.01}
        orientation="vertical"
        onValueChange={handleChange}
        className="block h-full"
        onPointerDown={() => fileState.fileDataObserver.startTreatingChangesAsTransient()}
        onPointerUp={() => fileState.fileDataObserver.endTreatingChangesAsTransient()}
      >
        <Slider.Thumb
          className="block h-1_5 w-3_5 rounded-[1px] bg-white outline-none"
          style={{ boxShadow: '0px 0.5px 0.5px 1px hsla(0, 0%, 0%, 0.25)' }}
          tabIndex={-1}
        />
      </Slider.Root>
    </div>
  );
}

export function HueSlider({ color, onChange, isUniform }: Props) {
  const { fileState } = useEditor();

  const hsl = oklchToHsl(color.value);

  const handleChange = (value: number[]) => {
    if (value[0] === undefined) return;

    const h = value[0];

    if (isUniform) {
      const newOklch = { ...color.value, h };
      onChange({ ...color, value: newOklch });
    } else {
      const newOklch = hslToOklch({ ...hsl, h });
      onChange({ ...color, value: newOklch });
    }
  };

  return (
    <div
      className="border-gray-5 rounded-1 relative w-4 border"
      style={{
        background: isUniform
          ? 'linear-gradient(to bottom in oklch longer hue, oklch(0.65 0.4 29), oklch(0.65 0.4 29))'
          : 'linear-gradient(to bottom in hsl longer hue, hsl(0 92% 52%), hsl(0 92% 52%))',
      }}
    >
      <Slider.Root
        value={[isUniform ? color.value.h : hsl.h]}
        max={360}
        min={0}
        step={1}
        inverted
        orientation="vertical"
        onValueChange={handleChange}
        className="block h-full"
        onPointerDown={() => fileState.fileDataObserver.startTreatingChangesAsTransient()}
        onPointerUp={() => fileState.fileDataObserver.endTreatingChangesAsTransient()}
      >
        <Slider.Thumb
          className="block h-1_5 w-3_5 rounded-[1px] bg-white outline-none"
          style={{ boxShadow: '0px 0.5px 0.5px 1px hsla(0, 0%, 0%, 0.25)' }}
          tabIndex={-1}
        />
      </Slider.Root>
    </div>
  );
}
