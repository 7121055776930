import { makeObservable, observable, action, computed } from 'mobx';
import { EditorState } from './EditorState';

/**
 * There are two priorities of cursors: tool cursors and handle cursors
 * Each category is exclusive, you can only have one tool cursor at a time and one handle cursor at a time
 * Handle cursors take priority over tool cursors, so if a handle cursor is set, it will be displayed even if a tool cursor is set
 */
export class CursorState {
  constructor(public editorState: EditorState) {
    makeObservable(this);
  }

  @computed({ keepAlive: true }) get currentCursorClass(): string | null {
    return this.handleCursorClass ?? this.toolCursorClass;
  }

  @observable accessor toolCursorClass: string | null = null;
  @action setToolCursorClass = (cursorClass: string | null) => {
    this.toolCursorClass = cursorClass;
  };

  @observable accessor handleCursorClass: string | null = null;
  @action setHandleCursorClass = (cursorClass: string | null) => {
    this.handleCursorClass = cursorClass;
  };
}
