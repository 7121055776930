/**
 * We store relatationships with parentId and then a sort order key
 * Each time the order changes, we halve the distance between siblings as the new sort order
 */

import { assert } from '../assert';

export type TreeRelationshipString = string;

export type TreeRelationship = [parentId: string, sortOrder: string];

export const TREE_RELATIONSHIP_SEPARATOR = ':';

/** Splits a tree relationship string into a parent and a sort key */
export function splitTreeRelationshipString(relationship: TreeRelationshipString): TreeRelationship {
  const [parentId, sortKey] = relationship.split(TREE_RELATIONSHIP_SEPARATOR, 2);
  assert(typeof parentId === 'string' && typeof sortKey === 'string', `Invalid tree relationship: ${relationship}`);
  return [parentId, sortKey];
}

/** Makes a tree relationship string from a parent and a sort key */
export function makeTreeRelationshipString(parentId: string, sortKey: string): TreeRelationshipString {
  return `${parentId}${TREE_RELATIONSHIP_SEPARATOR}${sortKey}`;
}
