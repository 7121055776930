import { UserLeftFileMessage } from '@mobius/models/src/websocket/user-left-file-message';
import { EditorState } from '../EditorState';
import { runInAction } from 'mobx';

export const handleClientLeftFileMessage = (editorState: EditorState, messageData: UserLeftFileMessage) => {
  const { multiplayerState } = editorState;

  const clientId = messageData.clientId;
  runInAction(() => {
    delete multiplayerState.userData[clientId];
  });
};
