import { Value } from '@sinclair/typebox/value';
import { type Static, Type } from '@sinclair/typebox';
import { ulid } from 'ulid';

/** The schema for a page */
export const PageSchema = Type.Object({
  id: Type.String(),
  label: Type.String(),
  createdDate: Type.Number(),
  lastModifiedDate: Type.Number(),
  canvasColor: Type.String(),
});
export type PageType = Static<typeof PageSchema>;

export const CANVAS_DEFAULT_COLOR = '#333333';

/** Temporary function to create a blank page, may move to the client later */
export function createPage(label: string): PageType {
  const blankPage = Value.Create(PageSchema);
  blankPage.id = ulid();
  blankPage.label = label;

  blankPage.createdDate = Date.now();
  blankPage.lastModifiedDate = Date.now();
  blankPage.canvasColor = CANVAS_DEFAULT_COLOR;

  return blankPage;
}
