import { observer } from 'mobx-react-lite';
import { PropertyPanels } from '../properties/property-panel';
import { useEditor } from '../editor-context';
import { reducePropertyValues } from '../properties/reduce-property-values';
import { ToggleGroup } from '../../components/toggle-group';

export const TextAlignProperty = observer(() => {
  const { propertiesState } = useEditor();

  const values = propertiesState.getStyleValues('textAlign');
  const currentValue = reducePropertyValues(values, 'left');

  function handleChange(value: string) {
    for (const node of propertiesState.nodesPerProperty[PropertyPanels.Typography]) {
      node.setStyle('textAlign', value);
    }
  }

  return (
    <ToggleGroup.Root type="single" value={currentValue} onValueChange={handleChange}>
      <ToggleGroup.Item value="left">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 3V4H14V3H2ZM2 6V7H10V6H2ZM2 9V10H14V9H2ZM2 12V13H10V12H2Z" />
        </svg>
      </ToggleGroup.Item>
      <ToggleGroup.Item value="center">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 3V4H14V3H2ZM4 6V7H12V6H4ZM2 9V10H14V9H2ZM4 12V13H12V12H4Z" />
        </svg>
      </ToggleGroup.Item>
      <ToggleGroup.Item value="right">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="currentcolor" xmlns="http://www.w3.org/2000/svg">
          <path d="M2 3V4H14V3H2ZM6 6V7H14V6H6ZM2 9V10H14V9H2ZM6 12V13H14V12H6Z" />
        </svg>
      </ToggleGroup.Item>
    </ToggleGroup.Root>
  );
});
