import { observer } from 'mobx-react-lite';
import { TreeItemIcon } from '../../components/tree-item-icons';
import { useEditor } from '../editor-context';
import { TreeItem } from '../../components/tree-item';
import { PageType } from '@mobius/models/src/file/page-schema';
import { COLLAPSIBLE_INDICATOR_SPACE } from '../layer-tree/expand-and-collapse-button';
import { Spacer } from '../../components/spacer';
import { CheckIcon } from '../../icons/check-icon';

export const PageListRow = observer(({ page }: { page: PageType }) => {
  const { pageState } = useEditor();

  function handlePageClick(pageId: string) {
    pageState.setActivePage(pageId);
  }

  return (
    <TreeItem key={page.id} data-page-id={page.id} onClick={() => handlePageClick(page.id)}>
      <Spacer width={COLLAPSIBLE_INDICATOR_SPACE} />
      <TreeItemIcon icon="page" />
      <Spacer width={8} />
      <span className="grow">{page.label}</span>
      {page.id === pageState.activePageId && <CheckIcon />}
    </TreeItem>
  );
});
