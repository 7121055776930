import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { GapProperty } from './gap-property';
import { FlexDirectionProperty } from './flex-direction-property';
import { PaddingXProperty } from './padding-x-property';
import { PaddingYProperty } from './padding-y-property';
import { ChildAlignmentProperty } from './child-alignment-property';
import { AddFlexButton } from './add-flex-button';

export const LayoutModeForChildren = observer(() => {
  const { propertiesState } = useEditor();

  return (
    <div className="flex flex-col pb-1">
      <AddFlexButton />

      {/* If the layout mode is flex for all selected nodes, show the flex properties */}
      {propertiesState.allNodes.every((node) => node.layoutModeForChildren === 'flex') && (
        <div>
          <FlexDirectionProperty />
          <GapProperty />
          <PaddingXProperty />
          <PaddingYProperty />
          <ChildAlignmentProperty />
        </div>
      )}
    </div>
  );
});
