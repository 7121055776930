import { createMultipleSortOrderKeys } from '@mobius/models/src/file/create-sort-order-key';
import { assert } from '../../assert';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { action } from 'mobx';

export const moveDraggedNodesToNewParent = action(
  (editorState: EditorState, draggedNodes: TreeNode[], newParent: TreeNode, index: number) => {
    // Generate sort keys inside the new drop target
    const sortedDraggedNodes = editorState.selectionState.selectedNodes.slice();
    editorState.treeUtils.sortNodesByTreeOrderInPlace(sortedDraggedNodes);
    assert(sortedDraggedNodes.length > 0);

    const prevSiblingSortKey = newParent.children[index - 1]?.sortKey ?? null;
    const nextSiblingSortKey = newParent.children[index]?.sortKey ?? null;
    const newSortKeys = createMultipleSortOrderKeys(sortedDraggedNodes.length, prevSiblingSortKey, nextSiblingSortKey);
    assert(newSortKeys.length === sortedDraggedNodes.length);

    for (let i = 0; i < sortedDraggedNodes.length; i++) {
      const node = sortedDraggedNodes[i]!;
      if (
        // If the node's parent is already the drop target
        node.parent === newParent &&
        // And the parent is fixed layout or the node is already in the same child index
        (node.isFixedLayout === true || node.indexOfNodeInParent === index + i)
      ) {
        // We don't need to move it
        continue;
      }

      editorState.treeUtils.moveNodeToNewParent(node, newParent, newSortKeys[i]!);
    }
  }
);
