import { EditorState } from '../EditorState';

export function selectAllBetween(
  editorState: EditorState,
  containerEl: HTMLElement,
  targetNodeId: string,
  targetNodeEl: HTMLElement
) {
  const { selectionState, treeUtils } = editorState;

  const lastSelectedNode = selectionState.selectedNodes[selectionState.selectedNodes.length - 1];
  if (lastSelectedNode) {
    const lastSelectedNodeEl = containerEl!.querySelector(`[data-node-id="${lastSelectedNode.id}"]`) as HTMLElement;

    const newNode = treeUtils.getNode(targetNodeId);
    if (newNode === lastSelectedNode) return; // bail if you're shift clicking your own selected node
    if (newNode && lastSelectedNodeEl) {
      // Select everything between this node and the last node
      const newSelectionIds: string[] = [newNode.id];
      const crawlDirectionGetterName =
        lastSelectedNodeEl.offsetTop > targetNodeEl.offsetTop ? 'nextNode' : 'previousNode';
      let addNode = newNode[crawlDirectionGetterName] ?? null;
      while (addNode && addNode.id !== lastSelectedNode.id) {
        newSelectionIds.push(addNode.id);
        addNode = addNode[crawlDirectionGetterName] ?? null;
      }

      selectionState.selectIds(newSelectionIds, { additive: true });
    }
  }
}
