import { EditorState } from '../EditorState';
import { action, makeObservable, observable } from 'mobx';
import { TreeNode } from './TreeNode';
import { Size } from '@mobius/models/src/math/size';

/** Stores observed sizes for every node in the current page, set by MeasureNodePositionAndSize */
export class SizeIndex {
  constructor(private editorState: EditorState) {
    makeObservable(this);
  }

  @observable
  accessor sizeMap = new Map<string, Size>();

  @action
  setSize = (nodeId: string, size: Size) => {
    this.sizeMap.set(nodeId, size);
  };
}
