import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { PageListRow } from './page-list-row';
import { CollapsibleHeading } from '../../components/collapsible-heading';
import { PageContextMenu } from './page-context-menu';

export const PageList = observer(() => {
  const editorState = useEditor();
  const pageState = editorState.pageState;

  if (pageState.pageListIsOpen === false) {
    return (
      <div className="flex flex-col py-1">
        <CollapsibleHeading collapsed onCollapsedChange={() => pageState.setPageListIsOpen(true)}>
          {pageState.activePage?.label ?? 'Current page'}
        </CollapsibleHeading>
      </div>
    );
  }

  return (
    <div className="flex flex-col py-1">
      <CollapsibleHeading onCollapsedChange={() => pageState.setPageListIsOpen(false)}>Pages</CollapsibleHeading>

      <PageContextMenu>
        {editorState.fileState?.data.pages.map((page) => {
          return <PageListRow key={page.id} page={page} />;
        })}
      </PageContextMenu>
    </div>
  );
});
