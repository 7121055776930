/** Attempts to figure out the DPI of a png file, which is likely to be retina if it's a screenshot from macOS */

export function findPngDpi(file: File): Promise<number | null> {
  // Read the file as an ArrayBuffer
  return new Promise<number | null>((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target && event.target.result) {
        // Convert ArrayBuffer to Buffer
        const buffer = event.target.result as ArrayBuffer;
        let dpi: number | null = null;

        // Now we can use the buffer with parseChunks
        for (let chunk of parseChunks(buffer)) {
          if (chunk.type === 'pHYs') {
            const view = new DataView(chunk.data);
            const ppuX = view.getUint32(0);
            const unit = view.getUint8(8);
            if (unit === 1) {
              // Ensure the unit is meters
              dpi = Math.round(ppuX * 0.0254);
              break;
            }
          }
        }

        resolve(dpi);
      } else {
        resolve(null);
      }
    };
    reader.onerror = () => resolve(null);
    reader.readAsArrayBuffer(file);
  });
}

function* parseChunks(data: ArrayBuffer) {
  const view = new DataView(data);
  var offset = 8; // skip PNG header

  while (offset < data.byteLength) {
    var dataLength = view.getUint32(offset);
    var chunkLength = dataLength + 12;
    var typeStart = offset + 4;
    var dataStart = offset + 8;
    var dataEnd = offset + 8 + dataLength;
    var crcEnd = dataEnd + 4;

    yield {
      type: String.fromCharCode(...new Uint8Array(data.slice(typeStart, dataStart))),
      data: data.slice(dataStart, dataEnd),
      crc: data.slice(dataEnd, crcEnd),
    };

    offset = crcEnd;
  }
}
