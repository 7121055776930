import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { NumberInput } from '../properties/number-input';
import { Field } from '../../components/field';
import { PropertyPanels } from '../properties/property-panel';
import { MIXED_STYLE_VALUE, reducePropertyValues } from '../properties/reduce-property-values';

export const PaddingXProperty = observer(() => {
  const { propertiesState } = useEditor();

  const currentValueLeft = propertiesState.getStyleValues('paddingLeft');
  const currentValueRight = propertiesState.getStyleValues('paddingRight');
  const inputValueLeft = reducePropertyValues(currentValueLeft, '0px');
  const inputValueRight = reducePropertyValues(currentValueRight, '0px');

  let finalValue = inputValueLeft;
  if (inputValueLeft !== inputValueRight) {
    finalValue = MIXED_STYLE_VALUE;
  }

  function commitValue(value: string) {
    for (const node of propertiesState.allNodes) {
      if (node.layoutModeForChildren === 'flex') {
        node.setStyle('paddingLeft', value);
        node.setStyle('paddingRight', value);
      }
    }
  }

  return (
    <Field.Root>
      <Field.Icon>p x</Field.Icon>
      <Field.Control>
        <NumberInput value={finalValue} onValueCommit={commitValue} />
      </Field.Control>
    </Field.Root>
  );
});
