import { KeyState } from '../KeyState';
import { SelectionState } from '../selection/SelectionState';
import { TreeNode } from '../tree/TreeNode';

export enum CursorToNode {
  Selectable,
  NotSelectable,
  SelectableOnlyWithMetaModifier,
}

export function cursorIsOverSelectableNode(
  keyState: KeyState,
  selectionState: SelectionState,
  hoveredTreeNode: TreeNode | null
): CursorToNode {
  if (hoveredTreeNode === null) {
    return CursorToNode.NotSelectable;
  }

  // If the node is already selected, it's not selectable
  if (selectionState.selectedNodeIds.has(hoveredTreeNode.id)) {
    return CursorToNode.NotSelectable;
  }

  // Check for non-selectable frames, which can only be selected with a meta key modifier
  if (hoveredTreeNode.isNonSelectableFrame === true) {
    if (keyState.isMetaDown) {
      return CursorToNode.SelectableOnlyWithMetaModifier;
    } else {
      return CursorToNode.NotSelectable;
    }
  }

  // So we're over a selectable node, but we need to check if the meta key modifier is held
  // Meta key modifier allows brush selection over top of selectable nodes instead of selecting them
  if (keyState.isMetaDown) {
    return CursorToNode.NotSelectable;
  }

  // If we surivived, we're over a selectable node
  return CursorToNode.Selectable;
}
