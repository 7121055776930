/**
 * In the family dropdown we just want to render some families as plaintext
 * either because the preview image just looks bad or because the font itself doesn't generate a legible name
 *
 * Note: these are case sensitive!
 */
export const FAMILIES_TO_SKIP_PREVIEW_IMAGE = new Set<string>([
  'Aoboshi One',
  'Bagel Fat One',
  'BIZ UDGothic',
  'BIZ UDMincho',
  'BIZ UDPMincho',
  'BIZ UDPGothic',
  'Black And White Picture',
  'Black Han Sans',
  'Blaka Ink',
  'Bungee Spice',
  'Bungee Tint',
  'Cactus Classical Serif',
  'Cherry Bomb One',
  'Chocolate Classical Sans',
  'Chokokutai',
  'Cute Font',
  'Darumadrop One',
  'Dela Gothic One',
  'Diphylleia',
  'Do Hyeon',
  'Dokdo',
  'Dongle',
  'DotGothic16',
  'East Sea Dokdo',
  'Gaegu',
  'Gamja Flower',
  'Gasoek One',
  'Gothic A1',
  'Gowun Batang',
  'Gowun Dodum',
  'Grandiflora One',
  'Gugi',
  'Hachi Maru Pop',
  'Hahmlet',
  'Hi Melody',
  'Hina Mincho',
  'Honk',
  'IBM Plex Sans JP',
  'IBM Plex Sans KR',
  'Jua',
  'Kaisei Decol',
  'Kaisei HarunoUmi',
  'Kaisei Opti',
  'Kaisei Tokumin',
  'Kalnia Glaze',
  'Kirang Haerang',
  'Kiwi Maru',
  'Klee One',
  'Kosugi',
  'Kosugi Maru',
  'Linefont',
  'Liu Jian Mao Cao',
  'Long Cang',
  'LXGW WenKai Mono TC',
  'LXGW WenKai TC',
  'M PLUS 1',
  'M PLUS 1 Code',
  'M PLUS 1p',
  'M PLUS 2',
  'M PLUS Rounded 1c',
  'Ma Shan Zheng',
  'Material Symbols Outlined',
  'Mochiy Pop One',
  'Mochiy Pop P One',
  'Moirai One',
  'Monomaniac One',
  'Murecho',
  'Nabla',
  'Nanum Brush Script',
  'Nanum Gothic',
  'Nanum Gothic Coding',
  'Nanum Myeongjo',
  'Nanum Pen Script',
  'New Tegomin',
  'Noto Sans HK',
  'Noto Sans JP',
  'Noto Sans KR',
  'Noto Sans SC',
  'Noto Sans TC',
  'Noto Serif HK',
  'Noto Serif JP',
  'Noto Serif KR',
  'Noto Serif SC',
  'Noto Serif TC',
  'Orbit',
  'Palette Mosaic',
  'Poor Story',
  'Potta One',
  'Rampart One',
  'Redacted',
  'Redacted Script',
  'Reggae One',
  'Rock 3D',
  'RocknRoll One',
  'Sawarabi Gothic',
  'Sawarabi Mincho',
  'Shippori Antique',
  'Shippori Antique B1',
  'Shippori Mincho',
  'Shippori Mincho B1',
  'Shizuru',
  'Slackside One',
  'Song Myung',
  'Stick',
  'Stylish',
  'Train One',
  'Tsukimi Rounded',
  'Wavefont',
  'Yeon Sung',
  'Yomogi',
  'Yuji Boku',
  'Yuji Hentaigana Akari',
  'Yuji Hentaigana Akebono',
  'Yuji Hentigana Akari',
  'Yuji Hentigana Akebono',
  'Yuji Mai',
  'Yuji Syuku',
  'Yusei Magic',
  'ZCOOL KuaiLe',
  'ZCOOL QingKe HuangYou',
  'ZCOOL XiaoWei',
  'Zen Antique',
  'Zen Antique Soft',
  'Zen Kaku Gothic Antique',
  'Zen Kaku Gothic New',
  'Zen Kurenaido',
  'Zen Maru Gothic',
  'Zen Old Mincho',
  'Zhi Mang Xing',
]);
