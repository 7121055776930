export function getImageDataFromWebGLContext(gl: WebGLRenderingContext, canvas: HTMLCanvasElement) {
  // Function to read pixels from WebGL context and return as Uint8Array
  function getWebGLPixels(gl: WebGLRenderingContext, width: number, height: number) {
    // Create a buffer to store the pixel data
    const pixels = new Uint8Array(width * height * 4);

    // Read the pixels from the framebuffer
    gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels);

    return pixels;
  }

  // Function to flip the image vertically (WebGL reads from bottom-left, but we typically want top-left)
  function flipPixelsVertically(pixels: Uint8Array, width: number, height: number) {
    const flippedPixels = new Uint8Array(pixels.length);
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const sourceIndex = (x + (height - y - 1) * width) * 4;
        const targetIndex = (x + y * width) * 4;
        for (let i = 0; i < 4; i++) {
          flippedPixels[targetIndex + i] = pixels[sourceIndex + i]!;
        }
      }
    }
    return flippedPixels;
  }

  // Get the pixel data
  const pixels = getWebGLPixels(gl, canvas.width, canvas.height);

  // Flip the pixels vertically
  const flippedPixels = flipPixelsVertically(pixels, canvas.width, canvas.height);

  // If you need a Uint8ClampedArray for ImageData, you can convert it:
  const clampedArray = new Uint8ClampedArray(flippedPixels);

  // Create ImageData if needed
  return new ImageData(clampedArray, canvas.width, canvas.height);
}
