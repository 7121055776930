import { observer } from 'mobx-react-lite';
import { PropConfigSlider } from '../built-in-ui/built-in-ui';
import { TreeNode } from '../tree/TreeNode';
import { Slider } from '../../components/slider';

export const PropsInputSlider = observer(({ config, nodes }: { config: PropConfigSlider; nodes: Array<TreeNode> }) => {
  const currentValue = nodes[0]!.props?.[config.propName] ?? config.defaultValue ?? false;

  const commitValue = (value: number[]) => {
    for (const node of nodes) {
      node.setProp(config.propName, value[0]);
    }
  };

  return (
    <div className="flex flex-col py-1">
      <div className="pl-5 text-sm font-medium">{config.label}</div>
      <div className="flex h-7 items-center gap-2 pr-2 pl-5">
        <Slider
          defaultValue={[currentValue]}
          min={config.min}
          max={config.max}
          step={config.step}
          onValueChange={commitValue}
        />
      </div>
    </div>
  );
});
