import { PropertyPanels } from '../properties/property-panel';
import { TreeNode } from '../tree/TreeNode';
import { ComponentMeta } from './built-in-ui';

export const Image = ({
  node,
  ref,
  style,
}: {
  node: TreeNode;
  ref: React.RefObject<HTMLImageElement>;
  style: React.CSSProperties;
}) => {
  return (
    <img
      ref={ref}
      src={node.props?.imageUrl}
      alt={node.props?.imageUrl}
      width={node.width}
      height={node.height}
      style={{ objectFit: 'cover', maxWidth: 'none', maxHeight: 'none', ...style }}
    />
  );
};

export const ImageMeta: ComponentMeta = {
  label: 'Image',
  component: Image,
  canHaveChildren: false,
  props: {},
  propertyPanels: new Set([PropertyPanels.Position, PropertyPanels.Layout]),
  layerTreeIcon: 'image',
};
