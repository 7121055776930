import { action, makeObservable, observable } from 'mobx';
import { EditorState } from '../EditorState';
import { TreeNode } from '../tree/TreeNode';
import { createTransformer } from 'mobx-utils';
import { assert } from '../../assert';

export class LayerTreeState {
  constructor(readonly editorState: EditorState) {
    makeObservable(this);
  }

  // ----- Drag and drop ----- //
  /** The new targeted parent node for the drag and drop */
  @observable accessor targetParentNode: TreeNode | null = null;
  /** Which child index will the drop slide into? */
  @observable accessor targetChildIndex: number | null = null;
  /** A drop can either be a direct hover over the middle of a new parent or in a position that has multiple potential parents, decided by the x-axis movement of the cursor */
  @observable accessor targetDropType: 'direct-hover' | 'inferred-by-x-axis-cursor-movement' | null = null;
  /** Set a new drop target */
  @action setDropTarget = (
    parentNode: TreeNode | null,
    childIndex: number | null,
    dropType: 'direct-hover' | 'inferred-by-x-axis-cursor-movement' | null
  ) => {
    if (
      parentNode === this.targetParentNode &&
      childIndex === this.targetChildIndex &&
      dropType === this.targetDropType
    ) {
      // Bail if there's no change
      return;
    }
    this.targetParentNode = parentNode;
    this.targetChildIndex = childIndex;
    this.targetDropType = dropType;

    // Automatically open target nodes if they can have children and are closed after a short delay
    if (this.dropTargetAutoOpenTimer) {
      window.clearTimeout(this.dropTargetAutoOpenTimer);
    }
    if (parentNode) {
      this.dropTargetAutoOpenTimer = window.setTimeout(() => {
        this.toggleExpandedOrCollapsed(parentNode.id, true);
      }, 600);
    } else {
      if (this.dropTargetAutoOpenTimer) {
        window.clearTimeout(this.dropTargetAutoOpenTimer);
      }
    }
  };

  /** Stores a timer to open drop targets if they're closed after a short delay */
  dropTargetAutoOpenTimer: number | null = null;

  // ----- Expansion ----- //
  @observable accessor expandedNodeIds: Set<string> = new Set();

  /** Toggles the expanded/collapsed state of a node, or you can force it to be expanded regardless of current state */
  @action toggleExpandedOrCollapsed = (nodeId: string, forceExpanded?: boolean) => {
    if (forceExpanded || this.expandedNodeIds.has(nodeId) === false) {
      this.expandedNodeIds.add(nodeId);
    } else {
      this.expandedNodeIds.delete(nodeId);
    }
  };

  /** Expands all ancestors of provided nodes so that they are visible in the layer tree and makes sure scroll is set to show the node */
  @action ensureNodesAreVisibleInLayerTree(nodes: TreeNode[]) {
    for (const node of nodes) {
      for (const ancestor of node.ancestors) {
        this.expandedNodeIds.add(ancestor.id);
      }
    }
  }

  /** Returns the first visible node in the layer tree */
  get firstVisibleNodeInTree(): TreeNode | null {
    // The first child of the root node is always visible, so if it exists return it:
    return this.editorState.treeUtils.rootNode.firstChild;
  }

  /** Returns the last visible node in the layer tree */
  get lastVisibleNodeInTree(): TreeNode | null {
    // The last child of the root node is always visible, so find it or its deepest visible descendant
    const lastChildOfRoot =
      this.editorState.treeUtils.rootNode.children[this.editorState.treeUtils.rootNode.children.length - 1];

    // If there are no children, there are no visible nodes
    if (!lastChildOfRoot) {
      return null;
    }

    // Walk down the lastChildOfRoot's deepest descendants to find the last visible node
    let lastVisibleNode = lastChildOfRoot;
    while (this.expandedNodeIds.has(lastVisibleNode.id) && lastVisibleNode.lastChild) {
      lastVisibleNode = lastVisibleNode.lastChild;
    }
    return lastVisibleNode;
  }

  /** Returns the next visible node in the layer tree from a given node */
  getNextVisibleNode = (fromNode: TreeNode): TreeNode | null => {
    let testNode = fromNode;
    while (testNode.nextNode) {
      testNode = testNode.nextNode;
      if (testNode.isVisibleInLayerTree) {
        return testNode;
      }
    }
    return null;
  };

  /** Returns the previous visible node in the layer tree from a given node */
  getPreviousVisibleNode = (fromNode: TreeNode): TreeNode | null => {
    let testNode = fromNode;
    while (testNode.previousNode) {
      testNode = testNode.previousNode;
      if (testNode.isVisibleInLayerTree) {
        return testNode;
      }
    }
    return null;
  };

  // ----- Label editing ----- //
  @observable accessor editingLabelNodeId: string | null = null;
  @action setEditingLabelNodeId(nodeId: string | null) {
    this.editingLabelNodeId = nodeId;
  }
}
