/** A 2D vector, our basic building block for points and directions*/
export type Vec2 = {
  x: number;
  y: number;
};

export abstract class Vec2Utils {
  static add(a: Vec2, b: Vec2): Vec2 {
    return { x: a.x + b.x, y: a.y + b.y };
  }
  static addInPlace(a: Vec2, b: Vec2): Vec2 {
    a.x += b.x;
    a.y += b.y;
    return a;
  }

  static subtract(a: Vec2, b: Vec2): Vec2 {
    return { x: a.x - b.x, y: a.y - b.y };
  }
  static subtractInPlace(a: Vec2, b: Vec2): Vec2 {
    a.x -= b.x;
    a.y -= b.y;
    return a;
  }

  static scale(a: Vec2, b: number): Vec2 {
    return { x: a.x * b, y: a.y * b };
  }
  static scaleInPlace(a: Vec2, b: number): Vec2 {
    a.x *= b;
    a.y *= b;
    return a;
  }

  static divide(a: Vec2, b: number): Vec2 {
    return { x: a.x / b, y: a.y / b };
  }
  static divideInPlace(a: Vec2, b: number): Vec2 {
    a.x /= b;
    a.y /= b;
    return a;
  }

  static dot(a: Vec2, b: Vec2): number {
    return a.x * b.x + a.y * b.y;
  }

  static length(a: Vec2): number {
    return Math.sqrt(a.x * a.x + a.y * a.y);
  }

  static normalize(a: Vec2): Vec2 {
    const length = Vec2Utils.length(a);
    return { x: a.x / length, y: a.y / length };
  }

  static roundInPlace(a: Vec2): Vec2 {
    a.x = Math.round(a.x);
    a.y = Math.round(a.y);
    return a;
  }

  static normalizeInPlace(a: Vec2): Vec2 {
    const length = Vec2Utils.length(a);
    a.x /= length;
    a.y /= length;
    return a;
  }
}
