import { observer } from 'mobx-react-lite';
import { Field } from '../../components/field';
import { Input } from '../../components/input';
import { useState } from 'react';
import { PropConfig } from '../built-in-ui/built-in-ui';
import { TreeNode } from '../tree/TreeNode';

export const PropsInputColor = observer(({ config, nodes }: { config: PropConfig; nodes: Array<TreeNode> }) => {
  const currentValue = String(nodes[0]?.props?.[config.propName] ?? config.defaultValue ?? '#000000');

  const commitValue = (value: string) => {
    for (const node of nodes) {
      node.setProp(config.propName, value);
    }
  };

  return (
    <div className="flex flex-col py-1">
      <div className="pl-5 text-sm font-medium">{config.label}</div>
      <div className="flex h-7 items-center gap-2 pr-2 pl-5">
        <input
          className="TemporaryColorInput"
          type="color"
          value={currentValue}
          onChange={(e) => commitValue(e.target.value)}
        />
        <Field.Control>
          <Input
            value={currentValue}
            onValueCommit={commitValue}
            format={(value) => (value.startsWith('#') ? value.toUpperCase() : value)}
            parse={(value) => (value.startsWith('#') ? value.trim().replace(/\s+/g, ' ') || null : null)}
          />
        </Field.Control>
      </div>
    </div>
  );
});
