import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { autorun } from 'mobx';
import { Font } from './FontState';
import { googleFontStyles } from './font-styles';
import { isBuiltInFamily } from './built-in-fonts';

// Possible TODO:
// - When nodes are deleted, their fonts are not removed from the in memory index (only matters for the duration of the current page load)
// - There is a FOUT when font style is being loaded from Google
//   - Before setting node.style, need to wait for its fonts to load (could use listeners on document.fonts)
//   - Before unloading a font, wait for pending style updates to complete

/** Loads fonts into the HTML document as they're needed */
export const FontLoader = observer(function FontLoaderImpl() {
  const { fontState } = useEditor();

  useEffect(() => {
    const loadedFonts = new Set<Font>();

    function loadFont(font: Font) {
      // Skip loaded fonts
      if (loadedFonts.has(font)) return;
      loadedFonts.add(font);

      // Add a <link> to Google Fonts if the font is on Google and not available locally
      if (
        !isBuiltInFamily(font.family) &&
        !fontState.isAvailableLocally(font) &&
        fontState.isAvailableFromGoogle(font)
      ) {
        console.log('Loading Google Font', font);
        const link = document.createElement('link');
        link.href = getGoogleFontsLink(font);
        link.rel = 'stylesheet';
        document.head.appendChild(link);
      }

      // Otherwise, if the font is available locally, it's considered loaded
    }

    function unloadFont(font: Font) {
      if (
        !isBuiltInFamily(font.family) &&
        !fontState.isAvailableLocally(font) &&
        fontState.isAvailableFromGoogle(font)
      ) {
        console.log('Unloading Google Font', font);
        const href = getGoogleFontsLink(font);
        const link = document.querySelector(`link[href="${href}"]`);
        link?.remove();
      }

      loadedFonts.delete(font);
    }

    /** Watch the font index and load/unload fonts as needed */
    const fontWatcherDispose = autorun(() => {
      // (1) For each family in the index...
      fontState.fontToNodeIndex.forEach((styleMap, family) => {
        // (2) Check each font style...
        styleMap.forEach((_, style) => {
          // (3) ...and load the corresponding font if it's not among the loaded fonts
          const nodeFont = { family, style };
          const loadedFontsValues = Array.from(loadedFonts.values());
          if (!loadedFontsValues.some((loadedFont) => isEqual(loadedFont, nodeFont))) {
            loadFont(nodeFont);
          }
        });
      });

      // Remove any loaded fonts that are no longer needed
      for (const font of loadedFonts) {
        if (!fontState.fontToNodeIndex.get(font.family)?.has(font.style)) {
          unloadFont(font);
        }
      }
    });

    return () => {
      for (const font of loadedFonts) {
        unloadFont(font);
      }

      fontWatcherDispose();
    };
  }, [fontState]);

  return null;
});

function getGoogleFontsLink(font: Font) {
  const isItalic = font.style.includes('Italic');
  const weight = googleFontStyles[font.style]!;
  const query = isItalic ? `1,${weight}` : `0,${weight}`;
  return `https://fonts.googleapis.com/css2?family=${font.family.replace(' ', '+')}:ital,wght@${query}&display=swap`;
}

function isEqual(a: Font, b: Font) {
  return a.family === b.family && a.style === b.style;
}
