import { runInAction } from 'mobx';
import { EditorState } from '../EditorState';
import type { UserFocusChangeMessage } from '@mobius/models/src/websocket/user-focus-change-message';

export const handleUserFocusChangeMessage = (editorState: EditorState, messageData: UserFocusChangeMessage) => {
  const multiplayerState = editorState.multiplayerState;
  const clientId = messageData.clientId;

  if (typeof clientId !== 'string') {
    console.warn('clientId is missing from UserFocusChangeMessage, ignoring message');
    return;
  }

  const userData = multiplayerState.userData[clientId];
  if (userData === undefined) {
    // Make sure the user join has made it through first
    console.warn('Received UserFocusChangeMessage before the user was in local cache, ignoring focus change.');
    return;
  }

  runInAction(() => {
    userData.focus = messageData.focus;
  });

  // Redraw the graphics
  editorState.hudState.requestDraw();
};
