import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { Field } from '../../components/field';
import { reducePropertyValues } from '../properties/reduce-property-values';

/** Note: replace this with a more intuitive grid buttons for alignment */
export const ChildAlignmentProperty = observer(() => {
  const { propertiesState } = useEditor();

  const currentValueJustifyContent = propertiesState.getStyleValues('justifyContent');
  const inputValueJustifyContent = reducePropertyValues(currentValueJustifyContent, 'flex-start');

  const currentValueAlignItems = propertiesState.getStyleValues('alignItems');
  const inputValueAlignItems = reducePropertyValues(currentValueAlignItems, 'flex-start');

  function commitValue(property: string, value: string) {
    for (const node of propertiesState.allNodes) {
      if (node.layoutModeForChildren === 'flex') {
        node.setStyle(property, value);
      }
    }
  }

  return (
    <>
      <Field.Root>
        <Field.Icon>JC</Field.Icon>
        <Field.Control>
          <select value={inputValueJustifyContent} onChange={(e) => commitValue('justifyContent', e.target.value)}>
            <option value="flex-start">Start</option>
            <option value="flex-end">End</option>
            <option value="center">Center</option>
            <option value="space-between">Space Between</option>
            <option value="space-around">Space Around</option>
            <option value="stretch">Stretch</option>
          </select>
        </Field.Control>
      </Field.Root>
      <Field.Root>
        <Field.Icon>AI</Field.Icon>
        <Field.Control>
          <select value={inputValueAlignItems} onChange={(e) => commitValue('alignItems', e.target.value)}>
            <option value="flex-start">Start</option>
            <option value="flex-end">End</option>
            <option value="center">Center</option>
            <option value="space-between">Space Between</option>
            <option value="space-around">Space Around</option>
            <option value="stretch">Stretch</option>
          </select>
        </Field.Control>
      </Field.Root>
    </>
  );
});
