import { observer } from 'mobx-react-lite';
import { Button } from '../components/button';
import { Link } from 'wouter';
import { API_ADDRESS } from '../root/api-address';
import { useEffect, useState } from 'react';
import { FileType } from '@mobius/models/src/file/file-schema';

export const FileList = observer(() => {
  const [files, setFiles] = useState<FileType[]>([]);

  useEffect(() => {
    const fetchFiles = async () => {
      const response = await fetch(API_ADDRESS.API_URI + '/files');
      const data = await response.json();
      setFiles(data);
    };
    fetchFiles();
  }, []);

  if (files.length === 0) {
    return <div className="mt-4 flex flex-col gap-3 p-4">No files yet</div>;
  }

  return (
    <div>
      <div className="mt-4 flex flex-col gap-3 p-4">
        <div>choose a file:</div>
        {files.map((data) => {
          return (
            <Link href={`/design/${data.id}`} key={data.id}>
              <Button>
                {data.name} – {data.id}
              </Button>
            </Link>
          );
        })}
      </div>
    </div>
  );
});
