import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect, useRef } from 'react';
import { Tool } from '../toolbar/ToolState';
import { TreeNode } from '../tree/TreeNode';
import { RectUtils } from '@mobius/models/src/math/rect';
import { htmlToPlainText } from '../copy-paste/parse-html-to-text';
import { autorun } from 'mobx';

export const TextareaHiddenForTextEditing = observer(() => {
  const editorState = useEditor();
  const { textTool } = editorState;
  const ref = useRef<HTMLTextAreaElement>(null);

  // Keep the textarea at the cursor so that the emoji picker opens in the right place
  useEffect(() => {
    const disposer = autorun(() => {
      let newX = 0;
      let newY = 0;

      const charAfterCaret = textTool.chars[textTool.caretPosition];
      if (charAfterCaret) {
        newX = charAfterCaret.minX;
        newY = textTool.caretLine?.rect.minY ?? 0;
      } else {
        // Position at the end of the last line
        const lastLine = textTool.lines[textTool.lines.length - 1]!;
        if (lastLine) {
          newX = lastLine.rect.maxX;
          newY = lastLine.rect.minY;
        }
      }

      if (ref.current) {
        ref.current.style.transform = `translate(${newX}px, ${newY}px)`;
      }
    });

    return () => disposer();
  }, [editorState, textTool]);

  useEffect(() => {
    const { textTool, toolState, treeUtils, selectionState, cameraState } = editorState;

    const el = ref.current;
    if (!el) return;

    // Register the textarea with the text editing state
    textTool.registerTextareaEl(el);

    let stringSoFar = '';
    function handleBeforeInput(e: InputEvent) {
      e.stopPropagation();
      // Note: if we want undo/redo events from the textarea, we can't preventDefault here
      // e.preventDefault();

      switch (e.inputType) {
        case 'insertText': {
          if (e.data) {
            textTool.insertText(e.data);
          }
          break;
        }
        case 'insertLineBreak': {
          // Normal enter, inserts a line break
          textTool.insertText('\n');
          break;
        }
        case 'deleteContentBackward': {
          // Normal backspace
          textTool.deleteText(-1);
          break;
        }
        case 'deleteContentForward': {
          // Normal delete
          textTool.deleteText(1);
          break;
        }
        case 'deleteSoftLineBackward': {
          // Mac: cmd + backspace, deletes from the cursor position back to the start of the line
          console.log('TODO: deleteSoftLineBackward');
          break;
        }
        case 'deleteSoftLineForward': {
          // Mac: cmd + delete, deletes from the cursor position forward to the end of the line
          console.log('TODO: deleteSoftLineForward');
          break;
        }
        case 'deleteWordBackward': {
          // Mac: ctrl + backspace, deletes from the cursor position back to the start of the word
          textTool.deleteWordBackward();
          break;
        }
        case 'deleteWordForward': {
          // Mac: ctrl + delete, deletes from the cursor position forward to the end of the word
          textTool.deleteWordForward();
          break;
        }

        default: {
          console.log('unknown input type', e.inputType);
          break;
        }
      }
    }

    function handleKeyDown(e: KeyboardEvent) {
      e.stopPropagation();

      // ----- Escape and cmd+enter ----- //
      if (e.key === 'Escape' || (e.metaKey && e.key === 'Enter')) {
        // Commit and end
        textTool.stopEditingTextNode();
        toolState.setActiveTool(Tool.Move);
      }

      // If we're moving the caret, move the anchor too unless the shift key is pressed to create a selection
      const moveAnchorToo = e.shiftKey === false;

      // ----- Tab to move to other text fields ----- //
      if (e.key === 'Tab') {
        e.preventDefault();
        let oldEditingNode = textTool.editingTextNode;
        const direction = e.shiftKey ? 'previousNode' : 'nextNode';
        const loopToNode = e.shiftKey ? treeUtils.lastNodeInTree : treeUtils.firstNodeInTree;
        let searchNode = oldEditingNode?.[direction] ?? loopToNode;
        let nodeToEdit: TreeNode | null = null;
        while (searchNode && searchNode !== oldEditingNode) {
          if (searchNode && searchNode.canEditText) {
            nodeToEdit = searchNode;
            break;
          }
          searchNode = searchNode[direction];
          if (searchNode === null) {
            // Loop around to the beginning
            searchNode = loopToNode;
          }
        }

        if (nodeToEdit) {
          // Stop editing the old node
          textTool.stopEditingTextNode();
          // Select and edit the new node
          textTool.startEditingTextNode(nodeToEdit, true);
          selectionState.selectIds([nodeToEdit.id]);
          // Zoom to center and fit the new node (but don't zoom in more than we currently are)
          const currentCameraScale = cameraState.scale;
          cameraState.zoomToFit(nodeToEdit.bounds, 50);
          if (cameraState.scale > currentCameraScale) {
            cameraState.centerOnPoint(RectUtils.center(nodeToEdit.bounds), currentCameraScale, true);
          }
        }

        return;
      }

      // ----- Home and end ----- //
      if (e.key === 'Home') {
        if (e.metaKey) {
          textTool.moveCaretBeginningOfText(moveAnchorToo);
        } else {
          textTool.moveCaretBeginningOfLine(moveAnchorToo);
        }
        return;
      }
      if (e.key === 'End') {
        if (e.metaKey) {
          textTool.moveCaretEndOfText(moveAnchorToo);
        } else {
          textTool.moveCaretEndOfLine(moveAnchorToo);
        }
        return;
      }

      // ----- Select all ----- //
      if (e.key === 'a' && e.metaKey) {
        textTool.selectAll();
        return;
      }

      // ----- Arrow keys ----- //
      if (e.key === 'ArrowLeft') {
        if (e.altKey) {
          textTool.moveCaretToStartOfPreviousWord(moveAnchorToo);
        } else if (e.metaKey) {
          textTool.moveCaretBeginningOfLine(moveAnchorToo);
        } else {
          textTool.moveCaretPreviousChar(moveAnchorToo);
        }
      } else if (e.key === 'ArrowRight') {
        if (e.altKey) {
          textTool.moveCaretToEndOfNextWord(moveAnchorToo);
        } else if (e.metaKey) {
          textTool.moveCaretEndOfLine(moveAnchorToo);
        } else {
          textTool.moveCaretNextChar(moveAnchorToo);
        }
      } else if (e.key === 'ArrowUp') {
        if (e.metaKey) {
          textTool.moveCaretBeginningOfText(moveAnchorToo);
        } else {
          textTool.moveCaretUpOrDownOneLine(-1, moveAnchorToo);
        }
      } else if (e.key === 'ArrowDown') {
        if (e.metaKey) {
          textTool.moveCaretEndOfText(moveAnchorToo);
        } else {
          textTool.moveCaretUpOrDownOneLine(1, moveAnchorToo);
        }
      }
    }

    function handleFocus() {
      textTool.isFocused = true;
    }
    function handleBlur() {
      textTool.isFocused = false;
    }

    function handleCopy(e: ClipboardEvent) {
      e.stopPropagation();
      e.preventDefault();
      e.clipboardData?.setData('text/plain', textTool.getCurrentSelectionAsString());
    }

    function handleCut(e: ClipboardEvent) {
      e.stopPropagation();
      e.preventDefault();
      e.clipboardData?.setData('text/plain', textTool.getCurrentSelectionAsString());
      textTool.deleteText();
    }

    async function handlePaste(e: ClipboardEvent) {
      e.stopPropagation();
      e.preventDefault();

      // Grab the data from the clipboard
      const clipboard = e.clipboardData;
      if (!clipboard) return;

      // Return if it's a file paste
      if (clipboard.files.length > 0) return;

      const html = clipboard.getData('text/html');
      const plainText = clipboard.getData('text/plain');
      // Parse the HTML if there's formatted text, otherwise use the plain text
      const textToInsert = html ? await htmlToPlainText(html) : plainText;
      // Insert the text
      textTool.insertText(textToInsert);
    }

    el.addEventListener('beforeinput', handleBeforeInput as EventListener);
    // Don't allow the key events to escape the textarea
    el.addEventListener('keydown', handleKeyDown);
    el.addEventListener('keyup', (e) => e.stopPropagation());
    el.addEventListener('focus', handleFocus);
    el.addEventListener('blur', handleBlur);
    el.addEventListener('copy', handleCopy);
    el.addEventListener('cut', handleCut);
    el.addEventListener('paste', handlePaste);

    return () => {
      el.removeEventListener('beforeinput', handleBeforeInput as EventListener);
      el.removeEventListener('keydown', handleKeyDown);
      el.removeEventListener('keyup', (e) => e.stopPropagation());
      el.removeEventListener('focus', handleFocus);
      el.removeEventListener('blur', handleBlur);
      el.removeEventListener('copy', handleCopy);
      el.removeEventListener('cut', handleCut);
      el.removeEventListener('paste', handlePaste);
    };
  }, [editorState]);

  return (
    <textarea
      tabIndex={-1}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        opacity: 0,
        zIndex: -1,
        backgroundColor: 'pink',
        pointerEvents: 'none',
        width: 1,
        height: 1,
      }}
      ref={ref}
    />
  );
});
