import { Root } from '../root/Root';
import { SocketState } from './sync/SocketState';
import { FileState } from './file-and-observers/FileState';
import { MultiplayerState } from './multiplayer/MultiplayerState';
import { PointerState } from './PointerState';
import { ToolState } from './toolbar/ToolState';
import { SelectionState } from './selection/SelectionState';
import { CameraState } from './camera/CameraState';
import { PageState } from './PageState';
import { TreeUtils } from './tree/TreeUtils';
import { HUDState } from './hud/HUDState';
import { KeyState } from './KeyState';
import { UndoManager } from './undo-redo/UndoManager';
import { TreeIndex } from './tree/TreeIndex';
import { SnapState } from './snaps/SnapState';
import { LayerTreeState } from './layer-tree/LayerTreeState';
import { MoveToolState } from './move-tool/MoveToolState';
import { DrawToolState } from './draw-tool/DrawToolState';
import { action, makeObservable, observable } from 'mobx';
import { CursorState } from './CursorState';
import { TextToolState } from './text-tool/TextToolState';
import { TickState } from './TickState';
import { PropertiesState } from './properties/PropertiesState';
import { FontState } from './fonts/FontState';
import { FileType } from '@mobius/models/src/file/file-schema';
import { SizeIndex } from './tree/SizeIndex';

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('EditorState change detected - reloading');
    window.location.reload();
  });
}

/** TODO: turn this into a user setting somewhere */
export const GRID_SIZE = 4;

export class EditorState {
  root: Root;

  constructor(
    root: Root,

    /** DIing the SocketState into the EditorState, since the SocketState is created first */
    public socketState: SocketState,

    /** The loaded raw file data to hydrate into the states */
    private fileData: FileType
  ) {
    makeObservable(this);
    this.root = root;

    this.fileState = new FileState(this, this.fileData);
    this.pageState = new PageState(this, this.fileData);
    this.cameraState = new CameraState(this);
    this.undoManager = new UndoManager(this);
    this.multiplayerState = new MultiplayerState(this);
    this.textTool = new TextToolState(this);
    this.moveToolState = new MoveToolState(this);
    this.drawToolState = new DrawToolState(this);
    this.toolState = new ToolState(this);
    this.pointerState = new PointerState(this);
    this.keyState = new KeyState(this);
    this.treeIndex = new TreeIndex(this);
    this.sizeIndex = new SizeIndex(this);
    this.layerTreeState = new LayerTreeState(this);
    this.selectionState = new SelectionState(this);
    this.propertiesState = new PropertiesState(this);
    this.hudState = new HUDState(this);
    this.treeUtils = new TreeUtils(this);
    this.snapState = new SnapState(this);
    this.cursorState = new CursorState(this);
    this.fontState = new FontState(this);
    this.tickState = new TickState(this);

    // Let the SocketState know that we're loaded and ready
    this.socketState.setEditorStateIsReady(this);

    // Initialize the active page (the pageState already has the pageId set from its constructor but hasn't initialized it yet)
    this.pageState.setActivePage(this.pageState.activePageId, true);

    this.root.registerEditorState(this);
  }

  /** Main file state */
  fileState: FileState;

  /** Keeps track of any multiplayer users in the subscribed document */
  multiplayerState: MultiplayerState;

  /** Camera state */
  cameraState: CameraState;

  /** State of text editing */
  textTool: TextToolState;

  /** State of move tool */
  moveToolState: MoveToolState;

  /** State of draw tool */
  drawToolState: DrawToolState;

  /** Tracks the active tool and intent of input */
  toolState: ToolState;

  /** State of the pointer */
  pointerState: PointerState;

  /** State of the keyboard */
  keyState: KeyState;

  /** Builds and keeps track of relationships between pages, nodes, and their children */
  treeIndex: TreeIndex;

  /** Observed sizes for every node in the current page */
  sizeIndex: SizeIndex;

  /** State of the layer tree, like which nodes are open or closed */
  layerTreeState: LayerTreeState;

  /** Keeps track of the active page */
  pageState: PageState;

  /** Tracks the currently selected nodes */
  selectionState: SelectionState;

  /** State of the properties panel */
  propertiesState: PropertiesState;

  /** State of the HUD, an HTML canvas element rendered over the Canvas */
  hudState: HUDState;

  /** Tree helpers */
  treeUtils: TreeUtils;

  /** Snaps */
  snapState: SnapState;

  /** Undo manager */
  undoManager: UndoManager;

  /** State of the canvas cursors */
  cursorState: CursorState;

  /** Available and loaded fonts */
  fontState: FontState;

  /** Runs our global RAF and lets things register for update calls in priority groups */
  tickState: TickState;

  /** Whether the side panels render or hide */
  @observable accessor renderUI: boolean = true;
  @action setRenderUI(renderUI: boolean) {
    this.renderUI = renderUI;
  }

  dispose = () => disposeAllChildrenKeys(this, new Set(['root']));
}

/** A standard disposer that loops all keys and disposes they if they have a dispose function (with an optional set of keys to skip) */
export function disposeAllChildrenKeys(obj: any, upwardsKeysToSkip: Set<string>) {
  for (const key in obj) {
    // don't dispose "upwards", like towards editor or root
    if (upwardsKeysToSkip.has(key)) {
      continue;
    }

    const property = obj[key] as any;
    if (typeof property === 'object' && property !== null && typeof property.dispose === 'function') {
      property.dispose();
    }
  }
}
