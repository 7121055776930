import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect, useRef } from 'react';
import { TREE_ITEM_INDENT_WIDTH } from '../../components/tree-item';
import { autorun } from 'mobx';
import { TreeNode } from '../tree/TreeNode';

const DROP_INDICATOR_HEIGHT = 2;

export const LayerTreeDropIndicators = observer(({ containerRef }: { containerRef: React.RefObject<HTMLElement> }) => {
  const { layerTreeState } = useEditor();

  const dropIndicatorRef = useRef<HTMLDivElement>(null);

  // ----- Parent target
  useEffect(() => {
    let previousEls: HTMLDivElement[] = [];

    const disposer = autorun(() => {
      const targetParentNode = layerTreeState.targetParentNode;

      // Remove the data-is-drop-target attribute from the old parent
      if (previousEls.length > 0) {
        for (const el of previousEls) {
          el.removeAttribute('data-is-drop-target');
          el.removeAttribute('data-is-part-of-drop-target-group');
        }
        previousEls = [];
      }

      const containerEl = containerRef.current;
      if (!containerEl || !targetParentNode) return;
      if (targetParentNode.isRoot) {
        // No states to set if the target is the root node
        // This dims everything out when doing top level sibling drops
        // So if we ever want non-dimmed top level sibling drops, we'll need to change this
        return;
      }

      // Set the data-is-drop-target attribute on the new parent
      const parentEl = containerEl.querySelector(`[data-node-id="${targetParentNode?.id}"]`);
      if (parentEl && layerTreeState.targetDropType === 'direct-hover') {
        parentEl.setAttribute('data-is-drop-target', 'true');
        previousEls.push(parentEl as HTMLDivElement);
      }

      const nodes = [targetParentNode, ...targetParentNode.descendants];

      for (const descendant of nodes) {
        const descendantEl = containerEl.querySelector(`[data-node-id="${descendant.id}"]`);
        if (descendantEl) {
          descendantEl.setAttribute('data-is-part-of-drop-target-group', 'true');
          previousEls.push(descendantEl as HTMLDivElement);
        }
      }
    });

    return () => {
      disposer();
    };
  }, [layerTreeState, containerRef]);

  // ----- Drop indicator line ----- //
  useEffect(() => {
    const disposer = autorun(() => {
      const { targetParentNode, targetChildIndex, targetDropType } = layerTreeState;
      const containerEl = containerRef.current;
      const containerElRect = containerEl?.getBoundingClientRect();
      const indicatorEl = dropIndicatorRef.current;
      if (!containerEl || !containerElRect || !indicatorEl) return;

      // Hide the drop indicator by default
      indicatorEl.style.display = 'none';

      // Only display the drop indicator if the drop target is inferred by x-axis cursor movement
      // NOTE: commenting for now, we're choosing to show the line even for direct drops without a parent highlight
      // If we change our minds, this is the place to change it
      // if (layerTreeState.targetDropType !== 'inferred-by-x-axis-cursor-movement') return;

      // Bail if there's no drag/drop in progress
      if (targetParentNode === null || targetChildIndex === null || targetDropType === null) return;

      // Find the y-position of the drop indicator
      let yPos: number | null = null;
      let xPos: number | null = null;
      if (targetChildIndex === 0) {
        // Drop indicator is directly below the parent
        if (targetParentNode.isRoot) {
          // If we're dropping at the very top of the layer tree, position the drop indicator at the top of the layer tree
          yPos = containerElRect.y + DROP_INDICATOR_HEIGHT; // we need to adjust the indicator lower to not get clipped
        } else {
          const parentRect = containerEl
            .querySelector(`[data-node-id="${targetParentNode.id}"]`)
            ?.getBoundingClientRect();
          if (parentRect) {
            yPos = parentRect.bottom;
          }
        }
      } else {
        // Drop indicator is below a previous sibling
        // So find the deepest visible last descendant of the drop position as that will be the node right above the drop position
        const prevSibAndDescendingLastChildren =
          targetParentNode.children[targetChildIndex - 1]?.selfAndDescendingLastChildren ?? [];
        let nodeForPosition: TreeNode | null = null;
        // Search for the deepest last child that's visible:
        for (const child of prevSibAndDescendingLastChildren) {
          if (layerTreeState.expandedNodeIds.has(child.parentId!) || child.parent?.isRoot) {
            nodeForPosition = child;
          } else {
            // If this one is not visible, we break out... the previous loop will have found the correct node to use for positioning
            break;
          }
        }

        // Find the y position based on the node that's directly before our drop position
        const nodeForPositionRect = containerEl
          ?.querySelector(`[data-node-id="${nodeForPosition?.id}"]`)
          ?.getBoundingClientRect();
        if (nodeForPosition && nodeForPositionRect) {
          yPos = nodeForPositionRect.bottom;
        }
      }

      // Drop indicator line - x pos (only shown for inferred drop targets)
      if (targetParentNode) {
        // Add 20px to account for the width of the collapse arrow
        const depth = targetParentNode.isRoot ? 0 : targetParentNode.ancestors.length;
        xPos = depth * TREE_ITEM_INDENT_WIDTH + 20;
      }

      // Set the drop indicator position and size
      if (xPos !== null && yPos !== null) {
        indicatorEl.style.top = `${yPos - containerElRect.y + containerEl.scrollTop - DROP_INDICATOR_HEIGHT / 2}px`;
        indicatorEl.style.left = `${xPos - containerElRect.x + containerEl.scrollLeft}px`;
        indicatorEl.style.width = `calc(100% - ${xPos}px)`;

        indicatorEl.style.display = 'block';
      }

      return () => disposer();
    });
  }, [layerTreeState, containerRef]);

  return (
    <div
      ref={dropIndicatorRef}
      style={{
        display: 'none',
        position: 'absolute',
        pointerEvents: 'none',
        height: `${DROP_INDICATOR_HEIGHT}px`,
        width: '100%',
        left: 0,
        top: 0,
        backgroundColor: 'var(--color-gray-1)',
      }}
    />
  );
});
