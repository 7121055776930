import { Vec2 } from '@mobius/models/src/math/vec2';
import { runInAction } from 'mobx';
import { TreeNodeType } from '@mobius/models/src/file/tree-node-schema';

/**
 * Useful for arranging nodes into a grid position around a center point
 * Will mutate the nodes in place
 * Note that the nodes must have styles.width and styles.height set to a Number or they will be treated as 0 size
 */
export function positionPastedNodes(nodes: TreeNodeType[], centerPoint: Vec2) {
  const count = nodes.length;

  // Find the best grid dimensions
  let rows = Math.floor(Math.sqrt(count));
  let cols = Math.ceil(count / rows);

  // Adjust if we can make it wider than taller
  while (rows > 1 && (rows - 1) * cols >= count) {
    rows--;
    cols = Math.ceil(count / rows);
  }

  // Calculate the maximum width and height for each cell
  const maxWidth = Math.max(...nodes.map((r) => Number(r.styles.width) || 0));
  const maxHeight = Math.max(...nodes.map((r) => Number(r.styles.height) || 0));

  const padding = 10;
  const totalWidth = cols * maxWidth + (cols - 1) * padding;
  const totalHeight = rows * maxHeight + (rows - 1) * padding;
  const startingX = centerPoint.x - totalWidth / 2;
  const startingY = centerPoint.y - totalHeight / 2;

  // Arrange rectangles
  runInAction(() => {
    for (const [index, node] of nodes.entries()) {
      const row = Math.floor(index / cols);
      const col = index % cols;
      node.x = startingX + col * (maxWidth + padding);
      node.y = startingY + row * (maxHeight + padding);
    }
  });
}
