import { observer } from 'mobx-react-lite';
import { useEditor } from '../editor-context';
import { useEffect } from 'react';
import { SELECTION_BOUNDS_COLOR } from '../move-tool/select-brush-graphic';
import { TreeNode } from '../tree/TreeNode';
import { LayoutDirection } from './calculate-dom-layout-direction';
import { CameraState } from '../camera/CameraState';

/** How many pixels wide should the drop line be on the secondary axis? */
const HIGHLIGHT_SIZE = 4;

/** End of line ball radius on each side of the drop line */
const END_BALL_RADIUS = 3;

export const DROP_PREVIEW_COLOR = SELECTION_BOUNDS_COLOR;

/**
 * Renders a box around the currently selected node
 */
export const DropTargetHighlight = observer(() => {
  const editorState = useEditor();

  useEffect(() => {
    const { moveToolState, cameraState, hudState } = editorState;
    const dragState = moveToolState.dragState;

    function drawDropTargetHighlight(ctx: CanvasRenderingContext2D) {
      const dropTargetNode = dragState.dropTargetNode;
      const dropIndex = dragState.dropTargetIndex;
      const layoutDirection = dragState.dropTargetLayoutDirection;

      // Don't do any work if there is no drop target
      if (dropTargetNode === null || dropIndex === null || layoutDirection === null) return;
      // Don't draw if we're not dragging
      if (dragState.isDragging === false) return;

      drawParentHighlight(ctx, cameraState, dropTargetNode);
      drawChildrenIndexIndicator(ctx, cameraState, dropTargetNode, dropIndex, layoutDirection);
    }

    hudState.worldDrawingFunctions.add(drawDropTargetHighlight);
    return () => {
      hudState.worldDrawingFunctions.delete(drawDropTargetHighlight);
    };
  }, [editorState]);

  return null;
});

// ----- Drop target parent highlight ----- //
function drawParentHighlight(ctx: CanvasRenderingContext2D, cameraState: CameraState, dropTarget: TreeNode) {
  // Calculate line width and offset to make the line be on exact pixels
  const lineWidth = 2 * cameraState.scaleInverse;
  const offset = lineWidth / 2;

  ctx.strokeStyle = SELECTION_BOUNDS_COLOR;
  ctx.lineWidth = lineWidth;
  ctx.strokeRect(
    dropTarget.x - offset,
    dropTarget.y - offset,
    dropTarget.width + lineWidth,
    dropTarget.height + lineWidth
  );
}

// ----- Children index indicator ----- //
function drawChildrenIndexIndicator(
  ctx: CanvasRenderingContext2D,
  cameraState: CameraState,
  dropTarget: TreeNode,
  dropIndex: number,
  layoutDirection: LayoutDirection
) {
  // No child indicator if there are no children or if the drop target is in free form layout mode
  if (dropTarget.children.length === 0 || dropTarget.childrenAreFixed) return;

  // Find the previous and next children
  const prevChild = dropTarget.children[dropIndex - 1];
  const nextChild = dropTarget.children[dropIndex];

  // Build the position and size for the actual drawing of the highlight
  let highlightStartX: number = 0;
  let highlightStartY: number = 0;
  let highlightEndX: number = 0;
  let highlightEndY: number = 0;

  // Calculate the position on the primary axis
  // - The layout direction
  // - The previous and next children's edges
  // - If a child doesn't exist, use the edge of the parent
  // ^^^ TODO: use the gap setting of the parent instead of its edge for more realistic positioning

  /** a small distance to leave between the highlight and the drop target edge so it doesn't mix with the target's own parent highlight */
  const minimumDistanceFromDropTargetEdge = 5;
  if (layoutDirection === LayoutDirection.LTR) {
    const prevChildEdge = prevChild?.bounds.maxX ?? dropTarget.bounds.minX + minimumDistanceFromDropTargetEdge;
    const nextChildEdge = nextChild?.bounds.minX ?? dropTarget.bounds.maxX - minimumDistanceFromDropTargetEdge;
    highlightStartX = prevChildEdge + (nextChildEdge - prevChildEdge) / 2;
    highlightEndX = highlightStartX;
  } else if (layoutDirection === LayoutDirection.RTL) {
    const prevChildEdge = prevChild?.bounds.minX ?? dropTarget.bounds.maxX - minimumDistanceFromDropTargetEdge;
    const nextChildEdge = nextChild?.bounds.maxX ?? dropTarget.bounds.minX + minimumDistanceFromDropTargetEdge;
    highlightStartX = prevChildEdge + (nextChildEdge - prevChildEdge) / 2;
    highlightEndX = highlightStartX;
  } else if (layoutDirection === LayoutDirection.TTB) {
    const prevChildEdge = prevChild?.bounds.maxY ?? dropTarget.bounds.minY + minimumDistanceFromDropTargetEdge;
    const nextChildEdge = nextChild?.bounds.minY ?? dropTarget.bounds.maxY - minimumDistanceFromDropTargetEdge;
    highlightStartY = prevChildEdge + (nextChildEdge - prevChildEdge) / 2;
    highlightEndY = highlightStartY;
  } else if (layoutDirection === LayoutDirection.BTT) {
    const prevChildEdge = prevChild?.bounds.minY ?? dropTarget.bounds.maxY - minimumDistanceFromDropTargetEdge;
    const nextChildEdge = nextChild?.bounds.maxY ?? dropTarget.bounds.minY + minimumDistanceFromDropTargetEdge;
    highlightStartY = prevChildEdge + (nextChildEdge - prevChildEdge) / 2;
    highlightEndY = highlightStartY;
  } else {
    // Free form leaked into this drawing function somehow, shouldn't happen
    console.warn(
      'Unexpected: trying to draw DOM drop line for freeform layout drop target',
      dropTarget.label,
      layoutDirection
    );
    return;
  }

  // Calculate the size and position on the secondary axis
  // Note we draw the lines a little bit beyond the discovered height so they stick out and are easier to see in nested layouts
  const extraStickOutBitPercentage = 0.03;
  if (layoutDirection === LayoutDirection.LTR || layoutDirection === LayoutDirection.RTL) {
    // We want to position at whichever sibling starts higher
    const prevChildMinY = prevChild?.bounds.minY ?? Infinity;
    const nextChildMinY = nextChild?.bounds.minY ?? Infinity;
    // Calculate the height based on the larger sibling (substitute 0 if there is no sibling so the existing sibling wins)
    const prevChildHeight = prevChild?.bounds.height ?? 0;
    const nextChildHeight = nextChild?.bounds.height ?? 0;
    const largerHeight = Math.max(prevChildHeight, nextChildHeight);
    const extraStickOutBit = largerHeight * extraStickOutBitPercentage;

    highlightStartY = Math.min(prevChildMinY, nextChildMinY) - extraStickOutBit;
    highlightEndY = highlightStartY + largerHeight + extraStickOutBit * 2;
  } else {
    // TTB or BTT
    // We want to position at whichever sibling starts further to the left
    const prevChildMinX = prevChild?.bounds.minX ?? Infinity;
    const nextChildMinX = nextChild?.bounds.minX ?? Infinity;
    // Calculate the height based on the larger sibling (substitute 0 if there is no sibling so the existing sibling wins)
    const prevChildWidth = prevChild?.bounds.width ?? 0;
    const nextChildWidth = nextChild?.bounds.width ?? 0;
    const largerWidth = Math.max(prevChildWidth, nextChildWidth);
    const extraStickOutBit = largerWidth * extraStickOutBitPercentage;

    highlightStartX = Math.min(prevChildMinX, nextChildMinX) - extraStickOutBit;
    highlightEndX = highlightStartX + largerWidth + extraStickOutBit * 2;
  }

  // Draw the indicator
  // ctx.strokeRect(highlightPosX, highlightPosY, highlightWidth, highlightHeight);
  ctx.strokeStyle = DROP_PREVIEW_COLOR;
  ctx.fillStyle = DROP_PREVIEW_COLOR;
  ctx.lineWidth = HIGHLIGHT_SIZE * cameraState.scaleInverse;
  ctx.beginPath();
  ctx.moveTo(highlightStartX, highlightStartY);
  ctx.lineTo(highlightEndX, highlightEndY);
  ctx.stroke();
  ctx.closePath();

  // Draw circles at the ends instead of lines
  const circleRadius = END_BALL_RADIUS * cameraState.scaleInverse;
  if (layoutDirection === LayoutDirection.LTR || layoutDirection === LayoutDirection.RTL) {
    // Draw at top and bottom ends
    ctx.beginPath();
    ctx.arc(highlightStartX, highlightStartY, circleRadius, 0, Math.PI * 2);
    ctx.fill();
    ctx.beginPath();
    ctx.arc(highlightStartX, highlightEndY, circleRadius, 0, Math.PI * 2);
    ctx.fill();
  } else {
    // Draw at left and right ends
    ctx.beginPath();
    ctx.arc(highlightStartX, highlightStartY, circleRadius, 0, Math.PI * 2);
    ctx.fill();
    ctx.beginPath();
    ctx.arc(highlightEndX, highlightStartY, circleRadius, 0, Math.PI * 2);
    ctx.fill();
  }
}
