import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';

function FieldRoot({ className, ...props }: React.ComponentProps<'div'>) {
  return <div className={clsx('FieldRoot', className)} {...props} />;
}

function FieldControl({ className, ...props }: React.ComponentProps<'div'>) {
  return <Slot className={clsx('FieldControl', className)} {...props} />;
}

function FieldIcon({ className, ...props }: React.ComponentProps<'div'>) {
  return <div className={clsx('FieldIcon', className)} {...props} />;
}

export const Field = {
  Root: FieldRoot,
  Control: FieldControl,
  Icon: FieldIcon,
};

export function FieldGroup({ className, ...props }: React.ComponentProps<'div'>) {
  return <div className={clsx('FieldGroup', className)} {...props} />;
}
