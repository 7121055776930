import { UsersAlreadyInFileMessage } from '@mobius/models/src/websocket/users-already-in-file-message';
import { EditorState } from '../EditorState';
import { runInAction } from 'mobx';

export const handleClientsAlreadyInFileMessage = (editorState: EditorState, messageData: UsersAlreadyInFileMessage) => {
  const { multiplayerState } = editorState;

  runInAction(() => {
    // Add all the other clients to the multiplayer state
    for (const user of messageData.users) {
      multiplayerState.userData[user.clientId] = user;
    }
  });
};
